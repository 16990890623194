import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  fade,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import {
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  ArrowForwardOutlined as ArrowForwardOutlinedIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon,
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  FileCopy as FileCopyIcon,
  FlagOutlined as FlagOutlinedIcon,
  MoreVert as MoreVertIcon
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import cx from 'classnames'

import { Theme } from 'common/theme'
import moment from 'moment'
import React, { FunctionComponentElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AlertBar } from '../../../components'
import { InlineEdit } from '../../../components/form/InlineEdit'
import { HubEventReviewBadge } from '../../../components/HubEventReviewBadge'
import { MediaPreview } from '../../../components/MediaPreview'
import { formatQuantity } from '../../../helpers/quantity.helpers'
import { apiFetchWithDispatch } from '../../../lib/fetch'
import { SelectOption, useForm } from '../../../lib/form'
import { getCountryByCurrentUserLang } from '../../../services/country'
import {
  HubEventDto,
  HubEventReviewStatus,
  HubEventReviewTag
} from '../../../services/hub-events'
import { MassBalanceAccount } from '../../../services/mass-balance'
import { QuantityUnit, QuantityUnits } from '../../../services/quantityunit'
import { useStateValue } from '../../../state'

import { DeleteDuplicateHubEventModal } from './DeleteDuplicateHubEventModal'
import { RegistrationUnit } from '../../../services/registrationunit'
import { MaterialRegistrationUnit } from '../../../services/collected-materials/collected-materials.types'
import { DeleteHubEventModal } from './DeleteHubEventModal'

export interface HubEventDrawerProps {
  hubEvent: HubEventDto | null

  onClose?: () => void
  onNextClick?: () => void
  onPreviousClick?: () => void
  onSave?: () => void
}

interface FormData {
  comment: string
  tags: SelectOption<HubEventReviewTag>[]
  weight: string
}

enum SubmitAction {
  APPROVE = 'approve',
  FLAG = 'flag'
}

const useStyles = makeStyles<Theme>((theme) => ({
  hubEventDrawer: {
    width: 800,
    height: '100vh',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box'
  },
  alertBar: {
    marginBottom: theme.spacing(3),
    boxShadow: 'none'
  },
  buttonApprove: {
    color: green[800],
    borderColor: green[300],

    '&:hover': {
      backgroundColor: green[50]
    }
  },
  buttonFlag: {
    '&.MuiButton-contained': {
      backgroundColor: red[500],
      color: theme.palette.common.white,

      '&:hover': {
        backgroundColor: red[700]
      }
    },

    '&.MuiButton-outlined': {
      color: red[800],
      borderColor: red[300],

      '&:hover': {
        backgroundColor: red[50]
      }
    }
  },
  columnHeader: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(-3),
    marginBottom: theme.spacing(0),
    boxShadow: `-10px 0px 20px ${fade(theme.palette.common.black, 0)}`,
    transition: 'box-shadow 300ms',

    '&.scrolled': {
      boxShadow: `-10px 0px 20px ${fade(theme.palette.common.black, 0.2)}`
    }
  },
  mediaPreviewWrapper: {
    border: `1px solid ${theme.palette.common.black}`
  },
  scrollableColumn: {
    height: '100%',
    overflowY: 'auto'
  },
  splitLayout: {
    height: `calc(100% + ${theme.spacing(3)}px)`
  },
  splitLayoutColumn: {
    height: '100%',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column'
  },
  tableCell: {
    padding: theme.spacing(1.5, 1),
    border: 'none',

    '&:first-of-type': {
      paddingLeft: 0
    },

    '&:last-of-type': {
      paddingRight: 0
    }
  },
  tableRow: {
    height: 0
  },
  tagChip: {
    backgroundColor: red[50],
    color: red[900]
  },
  tagChipIcon: {
    color: red[800],

    '&:hover': {
      color: red[900]
    }
  },
  deleteAction: {
    color: theme.palette.danger.main
  },
  copyIcon: {
    width: 14,
    height: 14,
    transition: 'color 200ms',
    margin: 0
  },
  buttonCopy: {
    minWidth: 20,
    padding: 0
  }
}))

const formSchema = {}

export function HubEventDrawer({
  hubEvent,
  onClose,
  onNextClick,
  onPreviousClick,
  onSave
}: HubEventDrawerProps): FunctionComponentElement<HubEventDrawerProps> {
  const classes = useStyles()

  const [, dispatch] = useStateValue()
  const { t } = useTranslation()

  const [
    isDetailsColumnScrolled,
    setIsDetailsColumnScrolled
  ] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [tagOptions] = useState<SelectOption<HubEventReviewTag>[]>(
    Object.values<HubEventReviewTag>(HubEventReviewTag).map((value) => ({
      value,
      label: t(`HubEventReviewTag.${value}`)
    }))
  )

  const [anchorEl, setAnchorEl] = useState(null)
  const [actionMenuOpen, setActionMenuOpen] = useState(false)
  const [showDeleteDuplicateModal, setShowDeleteDuplicateModal] = useState(
    false
  )
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const {
    formState: { values },
    handleChange,
    resetForm,
    setValue,
    setValues
  } = useForm<FormData>(formSchema, {
    comment: '',
    tags: [],
    weight: ''
  })

  function reset(): void {
    resetForm()
    setIsSaving(false)
  }

  function handleClose(): void {
    reset()
    onClose?.()
  }

  function handleSave(): void {
    reset()
    onSave?.()
  }

  function handleOpenActionMenu(event) {
    setAnchorEl(event.currentTarget)
    setActionMenuOpen(true)
  }

  function handleCloseActionMenu() {
    setAnchorEl(null)
    setActionMenuOpen(false)
  }

  function handleClickDeleteDuplicate() {
    setShowDeleteDuplicateModal(true)

    setActionMenuOpen(false)
  }

  function handleClickDelete() {
    setShowDeleteModal(true)

    setActionMenuOpen(false)
  }

  function handleCloseDeleteDuplicateModal() {
    setShowDeleteDuplicateModal(false)

    handleClose()
  }

  function handleCloseDeleteModal() {
    setShowDeleteModal(false)

    handleClose()
  }

  function weightCanBeEdited(hubEvent) {
    return (
      [
        MassBalanceAccount.REGISTRATION,
        MassBalanceAccount.SEGREGATION,
        MassBalanceAccount.CLEARANCE
      ].includes(hubEvent.type) &&
      hubEvent.registrationUnit !== RegistrationUnit.BALE
    )
  }

  function eventCanBeRemoved(hubEvent) {
    return [
      MassBalanceAccount.REGISTRATION,
      MassBalanceAccount.SEGREGATION,
      MassBalanceAccount.CLEARANCE
    ].includes(hubEvent.type)
  }

  async function submit(action: SubmitAction): Promise<void> {
    setIsSaving(true)

    const result = await apiFetchWithDispatch(
      dispatch,
      `/api/admin/hubEvents/${hubEvent?.uuid}/${action}`,
      {
        method: 'POST',
        body: JSON.stringify({
          ...values,
          tags: values.tags.map((item) => item.value)
        })
      }
    )

    if (result) {
      handleSave()
    }
  }

  useEffect(() => {
    setValues({
      comment: hubEvent?.review?.comment ?? '',
      weight: (hubEvent?.weight ?? 0).toString(),
      tags: (hubEvent?.review?.tags ?? [])
        .map((item) => tagOptions.find((option) => item === option.value))
        .filter((item): item is SelectOption<HubEventReviewTag> => !!item)
    })
  }, [hubEvent])

  return (
    <Drawer anchor="right" open={!!hubEvent} onClose={handleClose}>
      <Box className={classes.hubEventDrawer}>
        {hubEvent && (
          <Grid
            className={classes.splitLayout}
            container={true}
            alignItems="stretch"
            spacing={3}
          >
            <Grid
              className={classes.splitLayoutColumn}
              item={true}
              xs={12}
              lg={6}
            >
              <Box
                className={cx(classes.columnHeader, {
                  scrolled: isDetailsColumnScrolled
                })}
              >
                <Box ml={-0.5} mb={2}>
                  <Button
                    startIcon={<ArrowBackOutlinedIcon style={{ height: 18 }} />}
                    size="small"
                    variant="text"
                    onClick={handleClose}
                  >
                    Back
                  </Button>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h2">Details</Typography>

                    {hubEvent?.review && (
                      <Box mr={1}>
                        <Box ml={1}>
                          <HubEventReviewBadge review={hubEvent.review} />
                        </Box>
                      </Box>
                    )}
                  </Box>

                  {hubEvent?.review?.status !==
                    HubEventReviewStatus.APPROVED && (
                    <Box mr={1}>
                      <Button
                        className={classes.buttonApprove}
                        disabled={isSaving || values.tags.length > 0}
                        size="small"
                        variant="outlined"
                        onClick={() => submit(SubmitAction.APPROVE)}
                      >
                        <Box display="flex" alignItems="center">
                          <CheckCircleOutlineOutlinedIcon />
                          <Box ml={1}>Approve</Box>
                        </Box>
                      </Button>
                    </Box>
                  )}
                  {eventCanBeRemoved(hubEvent) && (
                    <Box mr={1}>
                      <IconButton
                        size="medium"
                        aria-label="More"
                        aria-controls="actionMenu"
                        aria-haspopup="true"
                        onClick={(event) => handleOpenActionMenu(event)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="actionMenu"
                        anchorEl={anchorEl}
                        className={classes.actionMenu}
                        keepMounted
                        open={actionMenuOpen}
                        onClose={handleCloseActionMenu}
                        PaperProps={{
                          className: classes.actionMenuPaper
                        }}
                      >
                        <MenuItem
                          key="Delete"
                          className={classes.deleteAction}
                          onClick={handleClickDelete}
                        >
                          Delete
                        </MenuItem>
                        <MenuItem
                          key="Delete as duplicate"
                          className={classes.deleteAction}
                          onClick={handleClickDeleteDuplicate}
                        >
                          Delete as duplicate
                        </MenuItem>
                      </Menu>
                    </Box>
                  )}
                </Box>
              </Box>

              <AlertBar className={classes.alertBar} />

              <Box
                className={classes.scrollableColumn}
                mt={1}
                onScroll={(event) => {
                  setIsDetailsColumnScrolled(
                    (event.target as any)?.scrollTop > 0
                  )
                }}
              >
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          <strong>ID</strong>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {hubEvent.uuid}
                          <Tooltip title="Copy ID">
                            <Button
                              className={classes.buttonCopy}
                              onClick={() =>
                                navigator.clipboard.writeText(hubEvent.uuid)
                              }
                            >
                              <FileCopyIcon className={classes.copyIcon} />
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          <strong>Event type</strong>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {t(`HubEventType.${hubEvent.type}`)}
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          <strong>Amount</strong>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          <InlineEdit
                            disabled={!weightCanBeEdited(hubEvent)}
                            displayText={
                              <>
                                {formatQuantity(
                                  parseFloat(values.weight),
                                  hubEvent.quantityUnit
                                )}
                              </>
                            }
                            formSchema={{
                              presence: {
                                allowEmpty: false,
                                message: 'validationRequired'
                              },
                              numericality: {
                                greaterThanOrEqualTo: 0
                              }
                            }}
                            InputProps={{
                              InputProps: {
                                endAdornment:
                                  QuantityUnits[hubEvent.quantityUnit]
                              }
                            }}
                            value={values.weight}
                            onConfirm={async (weight) => {
                              const result = await apiFetchWithDispatch(
                                dispatch,
                                `/api/admin/hubEvents/${hubEvent?.uuid}/amendWeight`,
                                {
                                  method: 'POST',
                                  body: JSON.stringify({
                                    weight
                                  })
                                }
                              )

                              return result || Promise.reject(new Error())
                            }}
                            onSave={(weight) => setValue('weight', weight)}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          <strong>Material type</strong>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {t(`materialType_${hubEvent.materialType}`)}
                        </TableCell>
                      </TableRow>

                      {hubEvent.productType && (
                        <TableRow className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            <strong>Product type</strong>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {t(`productType_${hubEvent.productType}`)}
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          <strong>Assigned hub</strong>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {hubEvent.company.name}
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          <strong>Date & time</strong>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {moment(hubEvent.createdAt).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )}
                        </TableCell>
                      </TableRow>

                      {hubEvent.registrationUnit ===
                        MaterialRegistrationUnit.TRUCK_LOAD && (
                        <TableRow className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            <strong>Weighted at</strong>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {hubEvent.weighedAt
                              ? moment(hubEvent.weighedAt).format(
                                  'YYYY-MM-DD HH:mm:ss'
                                )
                              : '-'}
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          <strong>Pipeline owner</strong>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {hubEvent.pipelineOwner}
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          <strong>Location</strong>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {hubEvent.company.city}
                          {', '}
                          {getCountryByCurrentUserLang(
                            hubEvent.company.country
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          <strong>User</strong>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {hubEvent.createdBy.lastName}{' '}
                          {hubEvent.createdBy.firstName}
                        </TableCell>
                      </TableRow>

                      {hubEvent.licensePlate && (
                        <TableRow className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            <strong>License plate</strong>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {hubEvent.licensePlate}
                          </TableCell>
                        </TableRow>
                      )}

                      {hubEvent.transitDestination && (
                        <TableRow className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            <strong>Transit destination</strong>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {hubEvent.transitDestination}
                          </TableCell>
                        </TableRow>
                      )}

                      {hubEvent.type === MassBalanceAccount.CERTIFICATION &&
                        hubEvent.moisturePercentage && (
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                              <strong>Moisture percentage</strong>
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {hubEvent.moisturePercentage} %
                            </TableCell>
                          </TableRow>
                        )}

                      {hubEvent.type === MassBalanceAccount.CERTIFICATION &&
                        hubEvent.ashPercentage && (
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                              <strong>Ash percentage</strong>
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {hubEvent.ashPercentage} %
                            </TableCell>
                          </TableRow>
                        )}

                      {hubEvent.type === MassBalanceAccount.CERTIFICATION &&
                        hubEvent.calorificValue && (
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                              <strong>Calorific value</strong>
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {hubEvent.calorificValue}{' '}
                              {QuantityUnits[QuantityUnit.CALORIES_PER_GRAM]}
                            </TableCell>
                          </TableRow>
                        )}

                      {hubEvent.type === MassBalanceAccount.CERTIFICATION &&
                        hubEvent.certifiedWeight && (
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                              <strong>Certified weight</strong>
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {formatQuantity(
                                hubEvent.certifiedWeight,
                                hubEvent.quantityUnit
                              )}
                            </TableCell>
                          </TableRow>
                        )}

                      {hubEvent.collectorTrackingNumber && (
                        <TableRow className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            <strong>{t('collectorTrackingNumber')}</strong>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {hubEvent.collectorTrackingNumber}
                          </TableCell>
                        </TableRow>
                      )}

                      {hubEvent.collectorHouseName && (
                        <TableRow className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            <strong>{t('collectorHouseName')}</strong>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {hubEvent.collectorHouseName}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box mt={4} mb={5}>
                  <Divider />
                </Box>

                <Box mb={3}>
                  <Typography variant="h2">Review</Typography>
                </Box>

                <Box mb={3}>
                  <Autocomplete
                    disableCloseOnSelect={true}
                    getOptionLabel={(option) => option.label}
                    multiple={true}
                    options={tagOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Anomalies"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option, { selected }) => (
                      <>
                        <Checkbox
                          icon={
                            <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                          }
                          checkedIcon={
                            <CheckBoxIcon color="primary" fontSize="small" />
                          }
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </>
                    )}
                    value={values.tags}
                    ChipProps={{
                      classes: {
                        deletable: classes.tagChip,
                        deleteIcon: classes.tagChipIcon
                      },
                      size: 'small'
                    }}
                    onChange={(_, value) => {
                      setValue('tags', value)
                    }}
                  />
                </Box>

                <Box mb={3}>
                  <TextField
                    name="comment"
                    label="Comment"
                    fullWidth={true}
                    value={values.comment}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Box>

                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6}>
                    <Button
                      className={classes.buttonFlag}
                      disabled={isSaving}
                      fullWidth={true}
                      size="large"
                      variant={
                        values.tags.length > 0 ? 'contained' : 'outlined'
                      }
                      onClick={() => submit(SubmitAction.FLAG)}
                    >
                      <Box display="flex" alignItems="center">
                        <FlagOutlinedIcon />
                        <Box ml={1}>Flag</Box>
                      </Box>
                    </Button>
                  </Grid>

                  <Grid item={true} xs={12} sm={6}>
                    <Button
                      className={classes.buttonApprove}
                      disabled={isSaving || values.tags.length > 0}
                      fullWidth={true}
                      size="large"
                      variant="outlined"
                      onClick={() => submit(SubmitAction.APPROVE)}
                    >
                      <Box display="flex" alignItems="center">
                        <CheckCircleOutlineOutlinedIcon />
                        <Box ml={1}>Approve</Box>
                      </Box>
                    </Button>
                  </Grid>
                </Grid>

                <Box mt={5} mb={3}>
                  <Divider />
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Button
                    disabled={!onPreviousClick}
                    startIcon={<ArrowBackOutlinedIcon style={{ height: 18 }} />}
                    onClick={onPreviousClick}
                  >
                    Previous
                  </Button>

                  <Button
                    disabled={!onNextClick}
                    endIcon={
                      <ArrowForwardOutlinedIcon style={{ height: 18 }} />
                    }
                    onClick={onNextClick}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid
              className={classes.scrollableColumn}
              item={true}
              xs={12}
              lg={6}
            >
              {hubEvent.picture && (
                <Box mt={3}>
                  <Box className={classes.mediaPreviewWrapper}>
                    <MediaPreview
                      displayFullImage={true}
                      file={hubEvent.picture}
                    />
                  </Box>

                  {hubEvent.pictureOwnerType && (
                    <Box mt={1}>
                      <Typography>
                        {t(`pictureDescription_${hubEvent.pictureOwnerType}`)}
                      </Typography>
                    </Box>
                  )}

                  {hubEvent.picture.processedDirectLink && (
                    <Box className={classes.mediaPreviewWrapper}>
                      <MediaPreview
                        displayFullImage={true}
                        displayProcessedImage={true}
                        file={hubEvent.picture}
                      />
                    </Box>
                  )}
                  {hubEvent.pictureOwnerType &&
                    hubEvent.picture.processedDirectLink && (
                      <Box mt={1}>
                        <Typography>
                          Blurred{' '}
                          {t(`pictureDescription_${hubEvent.pictureOwnerType}`)}
                        </Typography>
                      </Box>
                    )}
                </Box>
              )}

              {hubEvent.secondPicture && (
                <Box mt={3}>
                  <Box className={classes.mediaPreviewWrapper}>
                    <MediaPreview
                      displayFullImage={true}
                      file={hubEvent.secondPicture}
                    />
                  </Box>

                  {hubEvent.pictureOwnerType && (
                    <Box mt={1}>
                      <Typography>
                        {t(
                          `secondPictureDescription_${hubEvent.pictureOwnerType}`
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Box>

      {showDeleteDuplicateModal && (
        <DeleteDuplicateHubEventModal
          hubEvent={hubEvent}
          show={showDeleteDuplicateModal}
          onError={() => setShowDeleteDuplicateModal(false)}
          onClose={() => handleCloseDeleteDuplicateModal()}
        />
      )}
      {showDeleteModal && (
        <DeleteHubEventModal
          hubEvent={hubEvent}
          show={showDeleteModal}
          onError={() => setShowDeleteModal(false)}
          onClose={() => handleCloseDeleteModal()}
        />
      )}
    </Drawer>
  )
}
