import { Box, makeStyles } from '@material-ui/core'
import React, { FunctionComponentElement } from 'react'

import { Theme } from '../../common/theme'

import {
  EntityFilterConfig,
  EntityFilterOperator,
  EntityFilterType
} from './entity-filters.types'
import { EntityFilterDate } from './EntityFilterDate'
import { EntityFilterSelect } from './EntityFilterSelect'
import { EntityFilterText } from './EntityFilterText'

export interface EntityFilterProps {
  config: EntityFilterConfig

  onChange: (
    value: EntityFilterConfig['value'] | null,
    operator: EntityFilterOperator
  ) => void
}

const useStyles = makeStyles<Theme>(() => ({
  entityFilter: {
    //
  }
}))

export function EntityFilter({
  config,
  onChange
}: EntityFilterProps): FunctionComponentElement<EntityFilterProps> {
  const classes = useStyles()

  return (
    <Box className={classes.entityFilter}>
      {config.type === EntityFilterType.DATE && (
        <EntityFilterDate config={config} onChange={onChange} />
      )}

      {config.type === EntityFilterType.SELECT && (
        <EntityFilterSelect config={config} onChange={onChange} />
      )}

      {config.type === EntityFilterType.TEXT && (
        <EntityFilterText config={config} onChange={onChange} />
      )}
    </Box>
  )
}
