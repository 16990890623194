import { Box, makeStyles } from '@material-ui/core'
import React, { FunctionComponentElement, useState } from 'react'
import { Document, Page } from 'react-pdf'

import { Theme } from 'common/theme'

import { MinimalFileUploadDto } from '../services/fileupload/file-upload.types'

import { MediaViewer } from './MediaViewer'

export interface MediaPreviewProps {
  displayFullImage?: boolean
  file: MinimalFileUploadDto
  fileName?: string
  width?: number
  displayProcessedImage?: boolean
}

const useStyles = makeStyles<Theme>(() => ({
  mediaPreview: {
    cursor: 'pointer'
  },
  imagePreview: {
    maxWidth: '100%',
    display: 'block'
  }
}))

export function MediaPreview({
  displayFullImage,
  file,
  fileName,
  width,
  displayProcessedImage
}: MediaPreviewProps): FunctionComponentElement<MediaPreviewProps> {
  const classes = useStyles()

  const [isMediaViewerOpen, setIsMediaViewerOpen] = useState<boolean>(false)

  function getImageLink(): string {
    if (displayProcessedImage) {
      return displayFullImage
        ? file.processedDirectLink
        : file.processedThumbnailDirectLink
    }
    return displayFullImage ? file.directLink : file.thumbnailDirectLink
  }

  return (
    <>
      <Box
        className={classes.mediaPreview}
        onClick={() => setIsMediaViewerOpen(true)}
      >
        {file.mimeType === 'application/pdf' ? (
          <Document file={file.directLink}>
            <Page
              pageNumber={1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              width={width}
            />
          </Document>
        ) : (
          <img className={classes.imagePreview} alt="" src={getImageLink()} />
        )}
      </Box>

      {isMediaViewerOpen && (
        <MediaViewer
          file={file}
          fileName={fileName}
          onClose={() => setIsMediaViewerOpen(false)}
        />
      )}
    </>
  )
}
