import {
  Box,
  Button,
  FormControl,
  FormLabel,
  makeStyles,
  Paper,
  TextField,
  Typography
} from '@material-ui/core'
import React, { FunctionComponentElement, useState } from 'react'
import { PortalContent } from '../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../layouts/SecurePortalLayout'
import { useStateValue } from '../../state'
import { useForm } from '../../lib/form'
import { apiFetchWithDispatch } from '../../lib/fetch'
import { useGeneralStyles } from '../../common/styles/general'

import LinkButton from '../../components/LinkButton'

import { match, useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { useFormStyles } from '../../common/styles/form'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { useCamera } from '../../lib/camera'
import FullScreenCamera from 'components/FullScreenCamera'
import ImagePreview from 'components/ImagePreview'

interface Props {
  match: match<{ collectorUuid: string }>
}

const useStyles = makeStyles(() => ({
  defineCollector: {
    //
  }
}))

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  },
  idNumber: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  }
}

interface FormData {
  name: string
  birthDate: Date
  idNumber: string
}

function DefineCollector({ match }: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()
  const formClasses = useFormStyles()

  const history = useHistory()
  const { t } = useTranslation()

  const {
    params: { collectorUuid }
  } = match

  const camera = useCamera()

  const maxBirthDate = new Date()

  const [isLoading, setIsLoading] = useState(false)

  const [, dispatch] = useStateValue()

  const {
    formState: { values, isValid },
    setValues,
    getErrorMessage,
    handleChange,
    resetForm,
    hasError
  } = useForm<FormData>(schema, {
    name: '',
    birthDate: new Date(),
    idNumber: ''
  })

  function handleBirthDateChange(e) {
    const newValues = { ...values }
    newValues.birthDate = e
    setValues(newValues)
  }

  async function handleSave() {
    setIsLoading(true)

    const { name, birthDate, idNumber } = values

    const response = await apiFetchWithDispatch(
      dispatch,
      `/api/collectors/${collectorUuid}`,
      {
        method: 'POST',
        body: JSON.stringify({
          name,
          birthDate,
          idNumber,
          idDocumentFileUploadUuid: camera.pictureFileUpload?.uuid ?? null
        })
      }
    )

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push(`/active-collector/${collectorUuid}`)
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('defineCollectorTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!camera.pictureConfirmed && camera.showCamera) {
    return (
      <FullScreenCamera
        key="collector-id-document-picture"
        fileName={`collector-id-document-${Date.now()}`}
        headerMessage={t('collectorIdDocumentExplanation')}
        onTakePhotoFinished={camera.handleTakePhotoFinished}
        onFileUploadFinished={camera.handleFileUploadFinished}
        onConfirmPicture={camera.handleConfirmPicture}
        onCameraError={camera.handleCameraError}
      />
    )
  }

  return (
    <SecurePortalLayout title={t('defineCollectorTitle')}>
      <PortalContent>
        <Box className={classes.defineCollector}>
          <Box className={generalClasses.content}>
            <form autoComplete="off" noValidate>
              <Paper className={formClasses.formSection}>
                <Typography className={generalClasses.title} variant="h3">
                  {t('defineCollectorTitle')}
                </Typography>

                <FormControl component="fieldset">
                  <FormLabel component="legend" required>
                    {t('idDocumentPicture')}
                  </FormLabel>
                  <div className={generalClasses.imagePreview}>
                    <ImagePreview
                      dataUri={camera.pictureDataUri}
                      isPictureUploading={!camera.pictureFileUpload}
                    />
                  </div>
                </FormControl>

                <Box className={formClasses.field}>
                  <TextField
                    name="idNumber"
                    required
                    label={t('idNumber')}
                    className={formClasses.textField}
                    value={values.idNumber}
                    onChange={handleChange}
                    margin="dense"
                    error={hasError('idNumber')}
                    helperText={t(getErrorMessage('name'))}
                  />
                </Box>

                <Box className={formClasses.field}>
                  <TextField
                    name="name"
                    required
                    label={t('name')}
                    className={formClasses.textField}
                    value={values.name}
                    onChange={handleChange}
                    margin="dense"
                    error={hasError('name')}
                    helperText={t(getErrorMessage('name'))}
                  />
                </Box>

                <Box className={formClasses.field}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      className={formClasses.dateField}
                      id="mui-pickers-date"
                      name="birthDate"
                      label={t('birthDate')}
                      value={values.birthDate}
                      maxDate={maxBirthDate}
                      onChange={handleBirthDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>

                <Box className={formClasses.actionButtons}>
                  <LinkButton
                    size="large"
                    className={formClasses.cancelButton}
                    color="default"
                    variant="outlined"
                    href="/hub-overview"
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size="large"
                    variant="contained"
                    className={generalClasses.primaryButton}
                    disabled={
                      !isValid || isLoading || !camera.pictureFileUpload
                    }
                    onClick={handleSave}
                  >
                    {t('saveButton')}
                  </Button>
                </Box>
              </Paper>
            </form>
          </Box>
        </Box>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default DefineCollector
