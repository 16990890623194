import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FunctionComponentElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from '../common/theme'
import { formatQuantity } from '../helpers/quantity.helpers'
import { MaterialType } from '../services/materialtype/material-type.types'
import { ProductType } from '../services/producttype/product-type.types'
import { QuantityUnit } from '../services/quantityunit'

import { InputRadio } from './InputRadio'

interface InputRadioMaterialProductTypeProps {
  isSelected: boolean
  materialType: MaterialType
  productType?: ProductType | null
  quantity?: number
  quantityUnit?: QuantityUnit
}

export interface InputRadioMaterialProductTypeValue {
  materialType: MaterialType
  productType: ProductType | null
}

const useStyles = makeStyles<Theme>(() => ({
  inputRadioMaterialType: {
    //
  }
}))

export function InputRadioMaterialProductType({
  isSelected,
  materialType,
  productType,
  quantity,
  quantityUnit
}: InputRadioMaterialProductTypeProps): FunctionComponentElement<InputRadioMaterialProductTypeProps> {
  const classes = useStyles()

  const { t } = useTranslation()

  const [value, setValue] = useState<string>()

  useEffect(() => {
    setValue(JSON.stringify({ materialType, productType }))
  }, [materialType, productType])

  return (
    <Box className={classes.inputRadioMaterialType}>
      <InputRadio
        label={
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py={1}
          >
            <Box mr={2}>
              {t(`materialType_${materialType}`)}

              {productType ? ` - ${t(`productType_${productType}`)}` : ''}
            </Box>

            {(quantity || quantity === 0) && quantityUnit && (
              <Box textAlign="right" style={{ whiteSpace: 'nowrap' }}>
                <Typography color="textSecondary" variant="caption">
                  {formatQuantity(quantity, quantityUnit)}
                </Typography>
              </Box>
            )}
          </Box>
        }
        selected={isSelected}
        value={value}
      />
    </Box>
  )
}
