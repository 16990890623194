import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'

import { compose } from 'recompose'

// Material components
import { withStyles } from '@material-ui/core'


// Component styles
import styles from './styles'

function HubIdentity (props) {
  const { match: { params: { hubUuid } } } = props

  return (
    <Redirect to={`/register-material-bag/${hubUuid}`} />
  )
}

export default compose(
  withRouter,
  withStyles(styles)
)(HubIdentity)
