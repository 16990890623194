import React from 'react'

// Material components
import { Slide } from '@material-ui/core'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

export default Transition
