import { LocalShipping as LocalShippingIcon } from '@material-ui/icons'
import queryString from 'query-string'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Material components
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
  withStyles
} from '@material-ui/core'

// Component styles
import styles from './styles'
import { useTranslation } from 'react-i18next'

// Shared layouts
import { ImagePreview, FullScreenCamera } from 'components'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'

import { useForm } from 'lib/form'

import { useCamera } from 'lib/camera'
import { apiFetchWithDispatch } from 'lib/fetch'

import { useStateValue } from 'state'
import LinkButton from '../../../components/LinkButton'
import { useFormStyles } from '../../../common/styles/form'
import { MaterialClearanceType } from '../../../services/materialclearance'
import { getCurrentUser } from '../../../services/user'
import {
  ProductType
} from '../../../services/producttype/product-type.types'
import {
  getDisplayedQuantityUnit,
  QuantityUnit
} from '../../../services/quantityunit'

function getFormSchema(materialClearanceType) {
  const schema = {
    buyer: {
      presence: { allowEmpty: false, message: 'validationRequired' }
    },
    materialClearanceType: {
      presence: { allowEmpty: false, message: 'validationRequired' }
    }
  }

  if (materialClearanceType === MaterialClearanceType.PARTIAL) {
    schema.estimatedWeight = {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 10, message: 'validationExceedsMaxLength' }
    }
  }

  return schema
}

function ClearMaterialStockForm({ classes, location }) {
  const queryParams = queryString.parse(location.search)
  const {
    materialTypes,
    productType,
    materialClearanceUnitUuid,
    total
  } = queryParams

  const formClasses = useFormStyles()
  const theme = useTheme()

  const materialTypesArray = materialTypes?.split(',') ?? []

  const [formSchema, setFormSchema] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const [, dispatch] = useStateValue()

  const history = useHistory()

  const { t } = useTranslation()

  const { showMaterialClearanceBuyer } = getCurrentUser()

  const [transporterLicensePlate, setTransporterLicensePlate] = useState('')

  const {
    formState: { values, errors, isValid },
    handleChange,
    hasError,
    resetForm
  } = useForm(formSchema, {
    buyer: showMaterialClearanceBuyer ? '' : '-',
    materialClearanceType: materialClearanceUnitUuid
      ? MaterialClearanceType.PARTIAL
      : '',
    estimatedWeight: total ? total : '',
    quantityUnit:
      productType === ProductType.OIL ? QuantityUnit.LITER : QuantityUnit.KG
  })

  const transporterCamera = useCamera()

  const updatePostAnalysisLicensePlate = useCallback(() => {
    if (
      !transporterCamera.pictureFileUpload ||
      !transporterCamera.pictureFileUpload.postAnalysisResults ||
      !transporterCamera.pictureFileUpload.postAnalysisResults[0]
    ) {
      return
    }

    const firstResult =
      transporterCamera.pictureFileUpload.postAnalysisResults[0]

    if (!firstResult.analysisResults) {
      return
    }

    const postAnalysisResult = firstResult.analysisResults[0]

    if (postAnalysisResult) {
      setTransporterLicensePlate(postAnalysisResult)
    }
  }, [transporterCamera.pictureFileUpload, setTransporterLicensePlate])

  useEffect(() => {
    updatePostAnalysisLicensePlate()
  }, [updatePostAnalysisLicensePlate])

  useEffect(() => {
    setFormSchema(getFormSchema(values.materialClearanceType))
  }, [values])

  useEffect(() => {
    if (!transporterCamera.showCamera) {
      history.push('/hub-overview')
    }
  }, [transporterCamera.showCamera])

  async function handleSave() {
    setIsLoading(true)

    const {
      buyer,
      materialClearanceType,
      estimatedWeight,
      quantityUnit
    } = values

    const response = await apiFetchWithDispatch(
      dispatch,
      '/api/materialClearances',
      {
        method: 'POST',
        body: {
          materialTypes: materialTypesArray,
          productType: productType,
          transporterLicensePlate,
          partialClearance:
            materialClearanceType === MaterialClearanceType.PARTIAL,
          estimatedWeight,
          quantityUnit,
          buyer,
          pictureFileUploadUuid: transporterCamera.pictureFileUpload
            ? transporterCamera.pictureFileUpload.uuid
            : null,
          materialClearanceUnitUuid: materialClearanceUnitUuid
            ? materialClearanceUnitUuid
            : null
        }
      }
    )

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('clearMaterialStockTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!transporterCamera.pictureConfirmed) {
    return (
      <FullScreenCamera
        key="transporter-picture"
        fileName={`material-clearance-${Date.now()}`}
        headerMessage={t('materialClearancePictureExplanation')}
        shutterIcon={<LocalShippingIcon />}
        onTakePhotoFinished={transporterCamera.handleTakePhotoFinished}
        onFileUploadFinished={transporterCamera.handleFileUploadFinished}
        onConfirmPicture={transporterCamera.handleConfirmPicture}
        onCameraError={transporterCamera.handleCameraError}
      />
    )
  }

  return (
    <SecurePortalLayout title={t('clearMaterialStockTitle')}>
      <PortalContent>
        <div className={classes.root}>
          <div className={classes.content}>
            <form autoComplete="off" noValidate>
              <Paper className={classes.formSection}>
                <Typography className={classes.title} variant="h3">
                  {t('clearanceOf')}{' '}
                  {materialTypesArray
                    .map((materialType) => t(`materialType_${materialType}`))
                    .join(', ')}
                </Typography>

                <FormControl component="fieldset">
                  <FormLabel component="legend" required>
                    {t('transporterPicture')}
                  </FormLabel>
                  <div className={classes.imagePreview}>
                    <ImagePreview
                      dataUri={transporterCamera.pictureDataUri}
                      isPictureUploading={!transporterCamera.pictureFileUpload}
                    />
                  </div>
                </FormControl>

                <div className={classes.field}>
                  <TextField
                    name="transporterLicensePlate"
                    required
                    label={t('licensePlate')}
                    className={classes.textField}
                    value={transporterLicensePlate}
                    onChange={(event) =>
                      setTransporterLicensePlate(event.target.value)
                    }
                    margin="dense"
                  />
                </div>

                {materialTypesArray.length === 1 && (
                  <div className={formClasses.radioGroupField}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        required
                        className={formClasses.radioGroupLabel}
                      >
                        {t('partialClearanceQuestion')}
                      </FormLabel>
                      <RadioGroup
                        name="materialClearanceType"
                        value={values.materialClearanceType}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={MaterialClearanceType.PARTIAL}
                          control={<Radio />}
                          disabled={!!materialClearanceUnitUuid}
                          label={t('materialClearanceType_PARTIAL')}
                        />
                        <FormControlLabel
                          value={MaterialClearanceType.FULL}
                          control={<Radio />}
                          disabled={!!materialClearanceUnitUuid}
                          label={t('materialClearanceType_FULL')}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}

                {values.materialClearanceType ===
                  MaterialClearanceType.PARTIAL && (
                  <div className={classes.field}>
                    <TextField
                      name="estimatedWeight"
                      className={formClasses.weightField}
                      type="number"
                      label={`${t('addEstimatedWeight')}`}
                      required={true}
                      disabled={!!total}
                      margin="dense"
                      value={values.estimatedWeight}
                      onChange={handleChange}
                      error={hasError('estimatedWeight')}
                      helperText={
                        hasError('estimatedWeight')
                          ? t(errors.estimatedWeight[0])
                          : ''
                      }
                    />
                    <TextField
                      name="quantityUnit"
                      className={formClasses.quantityUnitField}
                      disabled
                      label={getDisplayedQuantityUnit(values.quantityUnit)}
                      margin="dense"
                      value=""
                      InputProps={{
                        style: {
                          color: theme.palette.text.primary
                        }
                      }}
                    />
                  </div>
                )}

                {showMaterialClearanceBuyer && (
                  <div className={classes.field}>
                    <TextField
                      name="buyer"
                      required
                      label={t('buyer')}
                      className={classes.textField}
                      value={values.buyer}
                      onChange={handleChange}
                      margin="dense"
                      error={hasError('buyer')}
                      helperText={hasError('buyer') ? t(errors.buyer[0]) : ''}
                    />
                  </div>
                )}

                <div className={classes.actionButtons}>
                  <LinkButton
                    size="large"
                    className={classes.cancelButton}
                    color="default"
                    variant="outlined"
                    href="/hub-overview"
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size="large"
                    variant="contained"
                    className={classes.clearMaterialStockButton}
                    disabled={
                      !isValid ||
                      !transporterLicensePlate ||
                      isLoading ||
                      !transporterCamera.pictureFileUpload
                    }
                    onClick={handleSave}
                  >
                    {t('clearMaterialStockButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </div>
        </div>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default withStyles(styles)(ClearMaterialStockForm)
