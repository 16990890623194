import { makeStyles } from '@material-ui/styles'

import { Theme } from '../theme'

export const useFormStyles = makeStyles<Theme>((theme) => ({
  formSection: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),

    '& h5': {
      marginBottom: theme.spacing()
    }
  },
  field: {
    width: 400,
    maxWidth: '100%'
  },
  radioGroupField: {
    width: 300,
    marginTop: theme.spacing(2)
  },
  radioGroupLabel: {
    marginBottom: theme.spacing()
  },
  weightField: {
    width: 190,
    maxWidth: '100%',
    marginTop: theme.spacing()
  },
  quantityUnitField: {
    width: 30,
    maxWidth: '100%',
    marginTop: theme.spacing()
  },
  dateField: {
    width: 150,
    maxWidth: '100%',
    marginTop: theme.spacing(2)
  },
  timeField: {
    width: 120,
    maxWidth: '100%',
    marginTop: theme.spacing(2)
  },
  textField: {
    width: 300,
    maxWidth: '100%',
    marginTop: theme.spacing()
  },
  selectField: {
    width: 200,
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing()
  },
  actionButtons: {
    marginTop: theme.spacing(3)
  },
  cancelButton: {
    marginRight: theme.spacing()
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing()
  }
}))
