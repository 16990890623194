import React from 'react';

import { useTranslation } from 'react-i18next'

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'

import { getDisplayedQuantityUnit } from 'services/quantityunit'

const MaterialBreakdown = ({ quantityUnit, materialBreakdown }) => {
  const { t } = useTranslation()

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>{t('outputMaterial')}</TableCell>
          <TableCell>{t('weight')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(materialBreakdown).map(mt => (
          <TableRow key={mt}>
            <TableCell>{t(`materialType_${mt}`)}</TableCell>
            <TableCell>{materialBreakdown[mt]} {getDisplayedQuantityUnit(quantityUnit)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default MaterialBreakdown
