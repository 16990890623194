import {
  AssessmentOutlined as AssessmentOutlinedIcon,
  DeviceHubOutlined as DeviceHubOutlinedIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon
} from '@material-ui/icons'
import React, { FunctionComponentElement, ReactNode } from 'react'

import { getCurrentUser } from '../services/user'

import { PortalLayoutProvider } from './portal/portal-layout.provider'
import { PortalLayout } from './portal/PortalLayout'
import { PortalNavigation } from './portal/PortalNavigation'

interface AdminPortalLayoutProps {
  children?: ReactNode
  title?: string
}

export function AdminPortalLayout({
  children,
  title
}: AdminPortalLayoutProps): FunctionComponentElement<AdminPortalLayoutProps> {
  const currentUser = getCurrentUser()

  return (
    <PortalLayoutProvider title={title} currentUser={currentUser}>
      <PortalLayout>
        <PortalNavigation
          collapsibleNavigation={true}
          groups={[
            {
              id: 'main',
              items: [
                {
                  icon: DeviceHubOutlinedIcon,
                  link: '/admin/hubs',
                  name: 'Hubs'
                },
                {
                  icon: PeopleAltOutlinedIcon,
                  link: '/admin/users',
                  name: 'Users'
                },
                {
                  icon: InsertDriveFileOutlinedIcon,
                  link: '/admin/hub-events',
                  name: 'Hub events'
                },
                {
                  icon: AssessmentOutlinedIcon,
                  link: '/admin/hub-stock',
                  name: 'Hub stock'
                }
              ]
            }
          ]}
        />

        {children}
      </PortalLayout>
    </PortalLayoutProvider>
  )
}
