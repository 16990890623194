import MomentUtils from '@date-io/moment'
import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  RadioGroup,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
import { Receipt as ReceiptIcon } from '@material-ui/icons'
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { useTheme } from '@material-ui/styles'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import AsyncSelect from 'react-select/async/dist/react-select.esm'
import { compose } from 'recompose'

import { useFormStyles } from '../../../common/styles/form'
import { FullScreenCamera, ImagePreview } from '../../../components'
import { DocumentWithoutLocationUpload } from '../../../components/DocumentWithoutLocationUpload'
import LinkButton from '../../../components/LinkButton'
import { mergeDateAndTime } from '../../../helpers/date-time.helpers'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'
import { useCamera } from '../../../lib/camera'
import { apiFetchWithDispatch } from '../../../lib/fetch'
import { useForm } from '../../../lib/form'
import { MaterialSourceTypes } from '../../../services/materialsource'
import { getCollectedMaterialTypeOptions } from '../../../services/materialtype'
import { OngoingTransportsApiService } from '../../../services/ongoing-transport/ongoing-transports-api.service'
import { QuantityUnits } from '../../../services/quantityunit'
import { RegistrationUnit } from '../../../services/registrationunit'
import { getCurrentUser } from '../../../services/user'
import { useStateValue } from '../../../state'

import styles from './styles'

function getFormSchema(
  selectedOngoingTransport,
  cleanhubSupplier,
  collectedMaterialTypes
) {
  const schema = {
    weight: {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 10, message: 'validationExceedsMaxLength' }
    }
  }

  if (
    (cleanhubSupplier !== 'true' && collectedMaterialTypes.length !== 1) ||
    (cleanhubSupplier === 'true' &&
      selectedOngoingTransport.materialTypes.length === 0)
  ) {
    schema.materialType = {
      presence: { allowEmpty: false, message: 'validationRequired' }
    }
  }

  return schema
}

function RegisterCollectedMaterialTruckLoad(props) {
  const { classes, history } = props
  const queryParams = queryString.parse(props.location.search)
  const formClasses = useFormStyles()

  const [documentUpload, setDocumentUpload] = useState(null)
  const [formSchema, setFormSchema] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()
  const theme = useTheme()
  const [, dispatch] = useStateValue()

  const { enableDocumentUpload } = getCurrentUser()
  const collectedMaterialTypes = getCurrentUser().collectedMaterialTypes || []

  const {
    pictureDataUri,
    pictureFileUpload,
    showCamera,
    pictureConfirmed,
    handleTakePhotoFinished,
    handleFileUploadFinished,
    handleConfirmPicture,
    handleCameraError
  } = useCamera()

  const {
    formState: { values, errors, isValid },
    setValue,
    setValues,
    handleChange,
    resetForm,
    hasError
  } = useForm(formSchema, {
    weight: '',
    quantityUnit: QuantityUnits.KG,
    weighedAt: new Date(),
    weighedAtTime: new Date(),
    selectedOngoingTransport: {
      label: '',
      licensePlate: '',
      materialTypes: [],
      value: ''
    },
    licensePlate: '',
    materialType: '',
    location: ''
  })

  useEffect(() => {
    setFormSchema(
      getFormSchema(
        values.selectedOngoingTransport,
        queryParams.cleanhubSupplier,
        collectedMaterialTypes
      )
    )
  }, [values])

  useEffect(() => {
    if (!showCamera) {
      history.push('/hub-overview')
    }
  }, [showCamera])

  function handleSelectOngoingTransport(event) {
    setValue('selectedOngoingTransport', event)
  }

  const ongoingTransportSearchResponseOptions = (inputLicensePlate) => {
    handleSelectOngoingTransport({
      label: '',
      licensePlate: '',
      materialTypes: [],
      value: ''
    })

    return OngoingTransportsApiService.search(dispatch, t, inputLicensePlate)
  }

  const debouncedOngoingTransportSearchResponseOptions = AwesomeDebouncePromise(
    ongoingTransportSearchResponseOptions,
    500
  )

  function getDefaultMaterialType() {
    if (queryParams.cleanhubSupplier !== 'true') {
      return collectedMaterialTypes[0]
    }

    return values.selectedOngoingTransport.materialTypes[0]
  }

  function handleWeighedAtChange(e) {
    const newValues = { ...values }
    newValues.weighedAt = e
    setValues(newValues)
  }

  function handleWeighedAtTimeChange(e) {
    const newValues = { ...values }
    newValues.weighedAtTime = e
    setValues(newValues)
  }

  async function handleSave() {
    setIsLoading(true)

    const {
      weight,
      weighedAt,
      weighedAtTime,
      licensePlate,
      selectedOngoingTransport,
      materialType,
      location
    } = values

    const response = await apiFetchWithDispatch(
      dispatch,
      '/api/collectedMaterials',
      {
        method: 'POST',
        body: {
          materialSourceType: MaterialSourceTypes.CLEANUP,
          registrationUnit: RegistrationUnit.TRUCK_LOAD,
          weight,
          weighedAt: mergeDateAndTime(weighedAt, weighedAtTime).toISOString(),
          licensePlate: selectedOngoingTransport.licensePlate || licensePlate,
          transportOwner: selectedOngoingTransport.value || null,
          materialType: materialType || getDefaultMaterialType(),
          location,
          pictureFileUploadUuid: documentUpload
            ? documentUpload.uuid
            : pictureFileUpload
            ? pictureFileUpload.uuid
            : null
        }
      }
    )

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      boxShadow: 'none'
    }),
    noOptionsMessage: () => ({
      display: 'none'
    })
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('registerMaterialTruckLoad')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!enableDocumentUpload && !pictureConfirmed) {
    return (
      <FullScreenCamera
        fileName={`collected-material-truck-load-${Date.now()}`}
        headerMessage={t('registerMaterialTruckLoadPictureExplanation')}
        shutterIcon={<ReceiptIcon />}
        onTakePhotoFinished={handleTakePhotoFinished}
        onFileUploadFinished={handleFileUploadFinished}
        onConfirmPicture={handleConfirmPicture}
        onCameraError={handleCameraError}
      />
    )
  }

  return (
    <SecurePortalLayout title={t('registerMaterialTruckLoad')}>
      <PortalContent>
        <div className={classes.root}>
          <div className={classes.content}>
            <form autoComplete="off" noValidate>
              <Paper className={classes.formSection}>
                <Typography className={classes.title} variant="h3">
                  {t('registerMaterialTruckLoad')}
                </Typography>

                {enableDocumentUpload ? (
                  <DocumentWithoutLocationUpload
                    acceptedFiles={['application/pdf', 'image/*']}
                    dropzoneText={t(
                      'registerMaterialTruckLoadUploadExplanation'
                    )}
                    fileName={`collected-material-truck-load-${Date.now()}`}
                    maxFileSize={5000000}
                    onClear={() => setDocumentUpload(null)}
                    onUpload={setDocumentUpload}
                  />
                ) : (
                  <FormControl component="fieldset">
                    <FormLabel component="legend" required>
                      {t('weighSlipPicture')}
                    </FormLabel>
                    <div className={classes.imagePreview}>
                      <ImagePreview
                        dataUri={pictureDataUri}
                        isPictureUploading={!pictureFileUpload}
                      />
                    </div>
                  </FormControl>
                )}

                <div className={classes.field}>
                  <div className={classes.field}>
                    <TextField
                      name="weight"
                      autoFocus
                      className={classes.weightField}
                      type="number"
                      label={`${t('addWeight')}`}
                      margin="dense"
                      required
                      value={values.weight}
                      onChange={handleChange}
                      error={hasError('weight')}
                      helperText={hasError('weight') ? t(errors.weight[0]) : ''}
                    />
                    <TextField
                      name="quantityUnit"
                      className={classes.quantityUnitField}
                      disabled
                      label=" "
                      margin="dense"
                      value={values.quantityUnit}
                      InputProps={{
                        style: {
                          color: theme.palette.text.primary
                        }
                      }}
                    />
                  </div>
                </div>

                <div className={classes.field}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      className={classes.dateField}
                      id="mui-pickers-date"
                      name="weighedAt"
                      label={t('weighedAt')}
                      value={values.weighedAt}
                      onChange={handleWeighedAtChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />

                    <KeyboardTimePicker
                      margin="normal"
                      className={classes.timeField}
                      id="time-picker"
                      label=" "
                      value={values.weighedAtTime}
                      onChange={handleWeighedAtTimeChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change time'
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                {queryParams.cleanhubSupplier !== 'true' && (
                  <div className={classes.field}>
                    <TextField
                      name="licensePlate"
                      required
                      label={t('licensePlate')}
                      className={classes.textField}
                      value={values.licensePlate}
                      onChange={handleChange}
                      margin="dense"
                      error={hasError('licensePlate')}
                      helperText={
                        hasError('licensePlate')
                          ? t(errors.licensePlate[0])
                          : ''
                      }
                    />
                  </div>
                )}

                {queryParams.cleanhubSupplier === 'true' && (
                  <div className={formClasses.textField}>
                    <Typography
                      variant="body1"
                      className={classes.transporterLicensePlateLabel}
                    >
                      {t('licensePlate')}
                    </Typography>
                    <AsyncSelect
                      name="selectedOngoingTransport"
                      classNamePrefix="selectedOngoingTransport"
                      styles={customSelectStyles}
                      required
                      label={t('selectedOngoingTransport')}
                      value={values.selectedOngoingTransport}
                      onChange={handleSelectOngoingTransport}
                      cacheOptions
                      defaultOptions
                      loadOptions={
                        debouncedOngoingTransportSearchResponseOptions
                      }
                    />
                  </div>
                )}

                {collectedMaterialTypes.length !== 1 &&
                  queryParams.cleanhubSupplier !== 'true' && (
                    <div className={classes.radioGroupField}>
                      <FormControl component="fieldset">
                        <FormLabel
                          component="legend"
                          required
                          className={classes.radioGroupLabel}
                        >
                          {t('selectMaterial')}
                        </FormLabel>
                        <RadioGroup
                          name="materialType"
                          value={values.materialType}
                          onChange={handleChange}
                        >
                          {getCollectedMaterialTypeOptions(t)}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}

                <div className={classes.field}>
                  <TextField
                    name="location"
                    label={t('location')}
                    className={classes.textField}
                    value={values.location}
                    onChange={handleChange}
                    margin="dense"
                  />
                </div>

                <div className={classes.actionButtons}>
                  <LinkButton
                    size="large"
                    className={classes.cancelButton}
                    color="default"
                    variant="outlined"
                    href="/hub-overview"
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size="large"
                    variant="contained"
                    className={classes.saveRegistrationButton}
                    disabled={
                      !isValid ||
                      isLoading ||
                      (!documentUpload && !pictureFileUpload)
                    }
                    onClick={handleSave}
                  >
                    {t('registerButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </div>
        </div>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default compose(
  withRouter,
  withStyles(styles)
)(RegisterCollectedMaterialTruckLoad)
