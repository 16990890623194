import React, { FunctionComponentElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AlertBox } from 'components'

import { useStateValue } from '../../state'
import { ErrorActionType } from '../../state/reducers/error.reducer'

export interface AlertBarProps {
  className?: string
}

export function AlertBar({
  className
}: AlertBarProps): FunctionComponentElement<AlertBarProps> | null {
  const [{ error }, dispatch] = useStateValue()
  const { t } = useTranslation()

  const [timestamp] = useState<number>(new Date().getTime())
  const [open, setOpen] = useState(true)

  function handleClose(event, reason): void {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
    dispatch({ type: ErrorActionType.RESET_ERROR })
  }

  useEffect(() => {
    dispatch({
      type: ErrorActionType.REGISTER_ALERT_BAR,
      value: timestamp
    })

    return () => {
      dispatch({
        type: ErrorActionType.DEREGISTER_ALERT_BAR,
        value: timestamp
      })
    }
  }, [dispatch])

  if (
    !open ||
    !error ||
    error.alertBars[0] !== timestamp ||
    (!error.errorMessage && !error.errorMessageKey)
  ) {
    return null
  }

  return (
    <AlertBox
      className={className}
      message={
        error.errorMessageKey ? t(error.errorMessageKey) : error.errorMessage
      }
      variant="error"
      onClose={handleClose}
    />
  )
}
