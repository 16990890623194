import { Dashboard as DashboardIcon } from '@material-ui/icons'
import React, { FunctionComponentElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { getCurrentUser } from '../services/user'

import { PortalLayoutProvider } from './portal/portal-layout.provider'
import { PortalLayout } from './portal/PortalLayout'
import { PortalNavigation } from './portal/PortalNavigation'

interface SecurePortalLayoutProps {
  children?: ReactNode
  title?: string
}

export function SecurePortalLayout({
  children,
  title
}: SecurePortalLayoutProps): FunctionComponentElement<SecurePortalLayoutProps> {
  const currentUser = getCurrentUser()

  const { t } = useTranslation()

  return (
    <PortalLayoutProvider title={title} currentUser={currentUser}>
      <PortalLayout>
        <PortalNavigation
          groups={[
            {
              id: 'main',
              items: [
                {
                  icon: DashboardIcon,
                  link: '/hub-overview',
                  name: t('hubOverviewTitle')
                }
              ]
            }
          ]}
          showTitle={false}
        />

        {children}
      </PortalLayout>
    </PortalLayoutProvider>
  )
}
