import palette from '../palette'

export default {
  root: {
    backgroundColor: palette.primary.white
  },

  flexContainer: {
    justifyContent: 'space-around'
  },

  indicator: {
    height: 3,
    backgroundColor: palette.primary.main
  }
}
