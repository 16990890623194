import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { compose } from 'recompose'

// Material components
import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'

// Shared layouts
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'

// Component styles
import styles from './styles'
import { useTheme } from '@material-ui/styles'

import { useStateValue } from 'state'
import schema from './schema'

import { useCamera } from 'lib/camera'

import { useTranslation } from 'react-i18next'

import { apiFetchWithDispatch } from 'lib/fetch'
import { useForm } from 'lib/form'

import { ImagePreview, FullScreenCamera } from 'components'

import { QuantityUnits } from 'services/quantityunit'

import { RegistrationUnit } from 'services/registrationunit'
import { MaterialType } from 'services/materialtype/material-type.types'
import LinkButton from '../../../components/LinkButton'

function RegisterCollectedMaterialContainer (props) {
  const { classes, history } = props

  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  const theme = useTheme()

  const [, dispatch] = useStateValue()

  const {
    pictureDataUri,
    pictureFileUpload,
    showCamera,
    pictureConfirmed,
    handleTakePhotoFinished,
    handleFileUploadFinished,
    handleConfirmPicture,
    handleCameraError
  } = useCamera()

  const {
    formState: {
      values,
      errors,
      isValid
    },
    handleChange,
    resetForm,
    hasError
  } = useForm(schema, {
    weight: '',
    quantityUnit: QuantityUnits.KG,
    materialType: '',
    location: ''
  })

  useEffect(() => {
    if (!showCamera) {
      history.push('/hub-overview')
    }
  }, [showCamera])

  async function handleSave () {
    setIsLoading(true)

    const {
      weight,
      location
    } = values

    const response = await apiFetchWithDispatch(dispatch, '/api/collectedMaterials', {
      method: 'POST',
      body: JSON.stringify({
        registrationUnit: RegistrationUnit.CONTAINER,
        weight,
        materialType: MaterialType.MIXED,
        location,
        pictureFileUploadUuid: pictureFileUpload ? pictureFileUpload.uuid : null
      })
    })

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('registerMaterialContainer')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!pictureConfirmed) {
    return (
      <FullScreenCamera
        fileName={`collected-material-container-${Date.now()}`}
        headerMessage={t('registerMaterialContainerPictureExplanation')}
        onTakePhotoFinished={handleTakePhotoFinished}
        onFileUploadFinished={handleFileUploadFinished}
        onConfirmPicture={handleConfirmPicture}
        onCameraError={handleCameraError}
      />
    )
  }

  return (
    <SecurePortalLayout title={t('registerMaterialContainer')}>
      <PortalContent>
        <div className={classes.root}>
          <div className={classes.content}>
            <form autoComplete='off' noValidate>
              <Paper className={classes.formSection}>
                <Typography
                  className={classes.title}
                  variant='h3'
                >
                  {t('registerMaterialContainer')}
                </Typography>

                <FormControl component='fieldset'>
                  <FormLabel component='legend' required>
                    {t('materialContainerPicture')}
                  </FormLabel>
                  <div className={classes.imagePreview}>
                    <ImagePreview
                      dataUri={pictureDataUri}
                      isPictureUploading={!pictureFileUpload}
                    />
                  </div>
                </FormControl>

                <div className={classes.field}>
                  <div className={classes.field}>
                    <TextField
                      name='weight'
                      autoFocus
                      className={classes.weightField}
                      type='number'
                      label={`${t('addWeight')}`}
                      margin='dense'
                      required
                      value={values.weight}
                      onChange={handleChange}
                      error={hasError('weight')}
                      helperText={hasError('weight') ? t(errors.weight[0]) : ''}
                    />
                    <TextField
                      name='quantityUnit'
                      className={classes.quantityUnitField}
                      disabled
                      label=' '
                      margin='dense'
                      value={values.quantityUnit}
                      InputProps={{
                        style: {
                          color: theme.palette.text.primary
                        }
                      }}
                    />
                  </div>
                </div>

                <div className={classes.field}>
                  <TextField
                    name='location'
                    label={t('location')}
                    className={classes.textField}
                    value={values.location}
                    onChange={handleChange}
                    margin='dense'
                  />
                </div>

                <div className={classes.actionButtons}>
                  <LinkButton
                    size='large'
                    className={classes.cancelButton}
                    color='default'
                    variant='outlined'
                    href='/hub-overview'
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size='large'
                    variant='contained'
                    className={classes.saveRegistrationButton}
                    disabled={!isValid || isLoading || !pictureFileUpload}
                    onClick={handleSave}
                  >
                    {t('registerButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </div>
        </div>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default compose(
  withRouter,
  withStyles(styles)
)(RegisterCollectedMaterialContainer)
