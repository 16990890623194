export enum StorageKey {
  ACHIEVEMENTS = 'achievements',
  CONTACT_IDENTITY = 'contactIdentity',
  EXPIRES_AT = 'expiresAt',
  PAGINATION_CONFIG = 'paginationConfig',
  PORTAL_NAVIGATION_COLLAPSED = 'portalNavigationCollapsed',
  USER_IDENTITY = 'userIdentity',
  X_AUTH_TOKEN = 'x-auth-token',
  IMPERSONATION_ROLE = 'impersonationRole'
}

function getStorageItemRaw(storage: Storage, key: StorageKey): string | null {
  try {
    return storage.getItem(key) ?? null
  } catch (error) {
    return null
  }
}

function getStorageItem<T = any>(storage: Storage, key: StorageKey): T | null {
  const value = getStorageItemRaw(storage, key)
  if (value) {
    try {
      return JSON.parse(value) ?? null
    } catch (error) {
      console.error(error)

      return null
    }
  }

  return null
}

function removeStorageItem(storage: Storage, key: StorageKey): boolean {
  try {
    storage.removeItem(key)

    return true
  } catch (error) {
    console.error(error)

    return false
  }
}

function setStorageItem(
  storage: Storage,
  key: StorageKey,
  value: Record<string, any> | number | string
): boolean {
  try {
    storage.setItem(key, JSON.stringify(value))

    return true
  } catch (error) {
    console.error(error)

    return false
  }
}

function setStorageItemRaw(
  storage: Storage,
  key: StorageKey,
  value: boolean | number | string
): boolean {
  try {
    storage.setItem(key, value.toString())

    return true
  } catch (error) {
    console.error(error)

    return false
  }
}

export class LocalStorage {
  public static getItem = <T>(key: StorageKey): T | null => {
    return getStorageItem(window.localStorage, key)
  }

  public static getItemRaw = (key: StorageKey): string | null => {
    return getStorageItemRaw(window.localStorage, key)
  }

  public static removeItem = (key: StorageKey): boolean => {
    return removeStorageItem(window.localStorage, key)
  }

  public static setItem = (
    key: StorageKey,
    value: Record<string, any> | number | string
  ): boolean => {
    return setStorageItem(window.localStorage, key, value)
  }

  public static setItemRaw = (
    key: StorageKey,
    value: boolean | number | string
  ): boolean => {
    return setStorageItemRaw(window.localStorage, key, value)
  }
}

export class SessionStorage {
  public static getItem = <T>(key: StorageKey): T | null => {
    return getStorageItem(window.sessionStorage, key)
  }

  public static getItemRaw = (key: StorageKey): string | null => {
    return getStorageItemRaw(window.sessionStorage, key)
  }

  public static removeItem = (key: StorageKey): boolean => {
    return removeStorageItem(window.sessionStorage, key)
  }

  public static setItem = (
    key: StorageKey,
    value: Record<string, any> | number | string
  ): boolean => {
    return setStorageItem(window.sessionStorage, key, value)
  }

  public static setItemRaw = (
    key: StorageKey,
    value: boolean | number | string
  ): boolean => {
    return setStorageItemRaw(window.sessionStorage, key, value)
  }
}
