import { UseFetchState, useFetchState } from 'lib/fetch'

import {
  CollectionUnitSummaryDto,
  OngoingCollectionUnitOverviewDto
} from './collection-unit.types'

export class CollectionUnitsApiService {
  public static readonly BASE_URL = '/api/collectionUnits'

  public static getSummary(): UseFetchState<CollectionUnitSummaryDto> {
    return useFetchState(`${CollectionUnitsApiService.BASE_URL}/summary`)
  }

  public static getOngoing(): UseFetchState<OngoingCollectionUnitOverviewDto> {
    return useFetchState(`${CollectionUnitsApiService.BASE_URL}/ongoing`)
  }
}
