import {
  Box,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import {
  CloudDownload as CloudDownloadIcon,
  LocalShipping as LocalShippingIcon,
  Pages as PagesIcon
} from '@material-ui/icons'
import { ReactComponent as BagBlackIcon } from 'icons/bag-black.svg'
import moment from 'moment'
import React, { FunctionComponentElement, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from '../../../common/theme'
import { Paper, Portlet, PortletContent } from '../../../components'
import {
  HubEventsReviewCelebrationModal,
  increaseHubEventReviewsAchievementCounter
} from '../../../components/Achievements'
import {
  EntityFilterConfig,
  getFiltersBody
} from '../../../components/EntityFilters'
import { HubEventReviewBadge } from '../../../components/HubEventReviewBadge'
import {
  isFirstArrayItem,
  isLastArrayItem
} from '../../../helpers/array.helpers'
import { formatQuantity } from '../../../helpers/quantity.helpers'
import { AdminPortalLayout } from '../../../layouts/AdminPortalLayout'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import {
  apiFetchWithDispatch,
  usePaginatedFilteredFetchState,
  usePaginationConfig
} from '../../../lib/fetch'
import { saveExportFile } from '../../../lib/file'
import { MaterialRegistrationUnit } from '../../../services/collected-materials/collected-materials.types'
import { getCountryByCurrentUserLang } from '../../../services/country'
import { HubEventDto } from '../../../services/hub-events'
import { useStateValue } from '../../../state'

import { HubEventDrawer } from './HubEventDrawer'
import { HubEventsFilters } from './HubEventsFilters'

const useStyles = makeStyles<Theme>((theme) => ({
  hubEvents: {
    //
  },
  tableRow: {
    cursor: 'pointer'
  },
  tooltip: {
    width: 800,
    maxWidth: 800,
    height: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  imageThumbnail: {
    width: 40,
    height: 40,
    backgroundSize: 'cover'
  },
  topPaginationWrapper: {
    borderBottom: `1px solid ${theme.palette.border}`
  },
  exportButtonWrapper: {
    float: 'left',
    marginLeft: 10,
    marginTop: 5
  },
  unitIcon: {
    width: 14,
    height: 14,
    opacity: 0.4,
    marginRight: theme.spacing(0.5),
    verticalAlign: 'middle'
  }
}))

export function HubEvents(): FunctionComponentElement<void> {
  const classes = useStyles()
  const [, dispatch] = useStateValue()

  const { page, size, sizeOptions, setPage, setSize } = usePaginationConfig()
  const { t } = useTranslation()

  const [filters, setFilters] = useState<EntityFilterConfig[]>([])
  const [selectedHubEvent, setSelectedHubEvent] = useState<HubEventDto | null>(
    null
  )
  const [showAchievementModal, setShowAchievementModal] = useState<boolean>(
    false
  )
  const [isExportLoading, setExportLoading] = useState<boolean>(false)

  const [
    hubEvents,
    isHubEventsLoading,
    refetchHubEvents
  ] = usePaginatedFilteredFetchState<HubEventDto>(
    '/api/admin/hubEvents',
    page,
    size,
    filters.length ? filters : null
  )

  function getPagination(): ReactNode {
    return (
      <TablePagination
        component="div"
        count={hubEvents?.pagination.total ?? 0}
        onChangePage={(_, page) => setPage(page)}
        onChangeRowsPerPage={(event) => {
          setSize(parseInt(event.target.value, 10))
        }}
        page={page}
        rowsPerPage={size}
        rowsPerPageOptions={sizeOptions}
      />
    )
  }

  function handleHubEventDrawerNextClick(): void {
    if (!hubEvents || !selectedHubEvent) {
      return
    }

    const selectedHubEventIndex = hubEvents.items.indexOf(selectedHubEvent)
    if (selectedHubEventIndex < hubEvents.items.length - 1) {
      setSelectedHubEvent(hubEvents.items[selectedHubEventIndex + 1])
    }
  }

  function handleHubEventDrawerPreviousClick(): void {
    if (!hubEvents || !selectedHubEvent) {
      return
    }

    const selectedHubEventIndex = hubEvents.items.indexOf(selectedHubEvent)
    if (selectedHubEventIndex > 0) {
      setSelectedHubEvent(hubEvents.items[selectedHubEventIndex - 1])
    }
  }

  function handleHubEventReviewSave(): void {
    const selectedHubEventIndex =
      hubEvents?.items.indexOf(selectedHubEvent as HubEventDto) ?? -1
    const nextHubEvent = hubEvents?.items[selectedHubEventIndex + 1]

    if (nextHubEvent) {
      setSelectedHubEvent(nextHubEvent)
    } else {
      setSelectedHubEvent(null)
      refetchHubEvents()
    }

    const hubEventReviewsCount = increaseHubEventReviewsAchievementCounter()
    if (hubEventReviewsCount > 0 && hubEventReviewsCount % 100 === 0) {
      setShowAchievementModal(true)
    }
  }

  function getRegistrationUnitIcon(unit: MaterialRegistrationUnit): ReactNode {
    switch (unit) {
      case MaterialRegistrationUnit.BAG:
        return (
          <Tooltip title="Bag">
            <BagBlackIcon className={classes.unitIcon} />
          </Tooltip>
        )

      case MaterialRegistrationUnit.BALE:
        return (
          <Tooltip title="Bale">
            <PagesIcon className={classes.unitIcon} />
          </Tooltip>
        )

      case MaterialRegistrationUnit.TRUCK_LOAD:
        return (
          <Tooltip title="Truck">
            <LocalShippingIcon className={classes.unitIcon} />
          </Tooltip>
        )
    }

    return <></>
  }

  async function handleExportIconClick() {
    setExportLoading(true)

    const result = await apiFetchWithDispatch(
      dispatch,
      `/api/admin/hubEvents/export`,
      {
        method: 'POST',
        body: getFiltersBody(filters, page, size)
      }
    )

    await saveExportFile(result)

    setExportLoading(false)
  }

  return (
    <AdminPortalLayout title="Hub events">
      <PortalContent>
        <Box className={classes.hubEvents}>
          <Box mb={5}>
            <Paper elevation={5} outlined={false}>
              <Box p={3}>
                <HubEventsFilters
                  onChange={(filters) => {
                    setFilters(filters)
                    setPage(0)
                  }}
                />
              </Box>
            </Paper>
          </Box>

          <Paper elevation={5} outlined={false}>
            <Box p={3}>
              {isHubEventsLoading || !hubEvents ? (
                <Box display="flex" justifyContent="center" p={5}>
                  <CircularProgress />
                </Box>
              ) : (
                <Portlet>
                  <PortletContent noPadding={true}>
                    <Box className={classes.topPaginationWrapper}>
                      <Box className={classes.exportButtonWrapper}>
                        <Tooltip title="Export">
                          {isExportLoading ? (
                            <Box display="flex" justifyContent="left" p={1}>
                              <CircularProgress size={18} />
                            </Box>
                          ) : (
                            <IconButton
                              color="default"
                              style={{
                                border: '1px solid lightgrey',
                                borderRadius: 1
                              }}
                              size="medium"
                              onClick={handleExportIconClick}
                            >
                              <CloudDownloadIcon fontSize="large" />
                            </IconButton>
                          )}
                        </Tooltip>
                      </Box>
                      <Box>{getPagination()}</Box>
                    </Box>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>Amount & Type</TableCell>
                          <TableCell>Assigned hub</TableCell>
                          <TableCell>Date & time</TableCell>
                          <TableCell>Pipeline owner</TableCell>
                          <TableCell>Country</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {hubEvents?.items.map((item) => (
                          <TableRow
                            key={item.uuid}
                            className={classes.tableRow}
                            hover={true}
                            selected={item === selectedHubEvent}
                            onClick={() => setSelectedHubEvent(item)}
                          >
                            <TableCell>
                              {item.picture && (
                                <Tooltip
                                  classes={{
                                    tooltip: classes.tooltip
                                  }}
                                  title={
                                    <>
                                      <img
                                        alt=""
                                        src={item.picture.directLink}
                                      />
                                    </>
                                  }
                                >
                                  <Box
                                    className={classes.imageThumbnail}
                                    style={{
                                      backgroundImage: `url('${item.picture.thumbnailDirectLink}')`
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </TableCell>

                            <TableCell>
                              {getRegistrationUnitIcon(item.registrationUnit)}
                              {formatQuantity(
                                item.weight,
                                item.quantityUnit
                              )}{' '}
                              {t(`materialType_${item.materialType}`)}{' '}
                              {item.productType
                                ? t(`productType_${item.productType}`)
                                : ''}
                            </TableCell>

                            <TableCell>{item.company.name}</TableCell>

                            <TableCell>
                              {moment(item.createdAt).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )}
                            </TableCell>

                            <TableCell>{item.pipelineOwner}</TableCell>

                            <TableCell>
                              {item.company.city}
                              {', '}
                              {getCountryByCurrentUserLang(
                                item.company.country
                              )}
                            </TableCell>

                            <TableCell>
                              {item.review && (
                                <HubEventReviewBadge review={item.review} />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    {getPagination()}
                  </PortletContent>
                </Portlet>
              )}
            </Box>
          </Paper>
        </Box>
      </PortalContent>

      <HubEventDrawer
        hubEvent={selectedHubEvent}
        onClose={() => {
          setSelectedHubEvent(null)
          refetchHubEvents()
        }}
        onNextClick={
          !isLastArrayItem(hubEvents?.items ?? [], selectedHubEvent)
            ? handleHubEventDrawerNextClick
            : undefined
        }
        onPreviousClick={
          !isFirstArrayItem(hubEvents?.items ?? [], selectedHubEvent)
            ? handleHubEventDrawerPreviousClick
            : undefined
        }
        onSave={handleHubEventReviewSave}
      />

      {showAchievementModal && (
        <HubEventsReviewCelebrationModal
          show={showAchievementModal}
          onClose={() => setShowAchievementModal(false)}
        />
      )}
    </AdminPortalLayout>
  )
}
