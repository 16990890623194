import { getCurrentUser } from '../user'

export enum CameraResolution {
  W480_H640 = 'W480_H640',
  W720_H960 = 'W720_H960',
  W960_H1280 = 'W960_H1280',
  W1440_H1920 = 'W1440_H1920'
}

export const CameraWidths = Object.freeze({
  W480_H640: 480,
  W720_H960: 720,
  W960_H1280: 960,
  W1440_H1920: 1440
})

export const CameraHeights = Object.freeze({
  W480_H640: 640,
  W720_H960: 960,
  W960_H1280: 1280,
  W1440_H1920: 1920
})

export const getCameraWidth = (): number => {
  if (!navigator.onLine) {
    return CameraHeights[CameraResolution.W480_H640]
  }

  return CameraHeights[
    getCurrentUser()?.cameraResolution ?? CameraResolution.W480_H640
  ]
}

export const getCameraHeight = (): number => {
  if (!navigator.onLine) {
    return CameraWidths[CameraResolution.W480_H640]
  }

  return CameraWidths[
    getCurrentUser()?.cameraResolution ?? CameraResolution.W480_H640
  ]
}
