import { Box, Button, CircularProgress, TextField } from '@material-ui/core'
import React, { FunctionComponentElement, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete } from '@material-ui/lab'
import MomentUtils from '@date-io/moment'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

import { useStateValue } from '../../../../state'
import { apiFetchWithDispatch, useFetchState } from '../../../../lib/fetch'
import { ClnModal, ClnModalActions, ClnModalContent } from 'components/modal'
import { ClnModalContentProps } from 'components/modal/ClnModalContent'
import { useGeneralStyles } from '../../../../common/styles/general'
import { SelectOption, useForm } from '../../../../lib/form'
import {
  CodeOfConductPrincipleCategory,
  CodeOfConductPrincipleDto
} from '../../../../services/codeofconduct'
import { useFormStyles } from '../../../../common/styles/form'

interface AddAuditActionModalProps {
  hubUuid: string
  show: boolean
  onClose: () => void
}

interface FormData {
  cOcCategory: SelectOption<CodeOfConductPrincipleCategory> | null
  cOcPrinciple: SelectOption<CodeOfConductPrincipleDto> | null
  deadlineDate: Date
  summary: string
  description: string
}

const formSchema = {
  deadlineDate: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  },
  summary: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  },
  description: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  },
  cOcCategory: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  },
  cOcPrinciple: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  }
}

export function AddAuditActionModal({
  hubUuid,
  show,
  onClose
}: AddAuditActionModalProps): FunctionComponentElement<AddAuditActionModalProps> {
  const {
    formState: { values, isValid },
    setValue,
    setValues,
    handleChange
  } = useForm<FormData>(formSchema, {
    cOcCategory: null,
    cOcPrinciple: null,
    deadlineDate: new Date(),
    summary: '',
    description: ''
  })

  const [, dispatch] = useStateValue()

  const { t } = useTranslation()

  const generalClasses = useGeneralStyles()
  const formClasses = useFormStyles()

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [cocPrinciples, isCocPrinciplesLoading] = useFetchState<
    CodeOfConductPrincipleDto[]
  >(`/api/admin/cocPrinciples`)

  const [categoryOptions] = useState<
    SelectOption<CodeOfConductPrincipleCategory>[]
  >(
    Object.values<CodeOfConductPrincipleCategory>(
      CodeOfConductPrincipleCategory
    ).map((value) => ({
      value,
      label: t(`CodeOfConductPrincipleCategory.${value}`)
    }))
  )

  function getCoCPrinciplesOptions(
    categoryOption
  ): SelectOption<CodeOfConductPrincipleDto>[] {
    if (categoryOption && !isCocPrinciplesLoading && cocPrinciples) {
      return cocPrinciples
        .filter((principle) => principle.category === categoryOption.value)
        .map((principle) => ({
          value: principle,
          label: principle.paragraph.toString()
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    }
    return []
  }

  function handleDeadlineDateChange(e) {
    const newValues = { ...values }
    newValues.deadlineDate = e
    setValues(newValues)
  }

  async function handleSave() {
    setIsSaving(true)

    await apiFetchWithDispatch(dispatch, `/api/admin/complianceActions`, {
      method: 'POST',
      body: JSON.stringify({
        name: values.summary,
        description: values.description,
        deadlineDate: values.deadlineDate,
        cocPrincipleUuid: values.cOcPrinciple?.value.uuid,
        companyUuid: hubUuid
      })
    })

    onClose()
  }

  const getContent = (): ReactElement<
    ClnModalContentProps,
    typeof ClnModalContent
  > => {
    if (isSaving) {
      return (
        <ClnModalContent>
          <Box className={generalClasses.progressWrapper}>
            <CircularProgress />
          </Box>
        </ClnModalContent>
      )
    }

    return (
      <ClnModalContent>
        <form autoComplete="off" noValidate>
          <Box>
            <Box mb={2} mt={1}>
              <Autocomplete
                getOptionLabel={(option) => option.label}
                options={categoryOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="CoC Category"
                    variant="outlined"
                  />
                )}
                value={values.cOcCategory}
                onChange={(_, value) => {
                  setValue('cOcCategory', value)
                }}
              />
            </Box>
            <Box mb={2}>
              <Autocomplete
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  option.value.uuid === value.value.uuid
                }
                options={getCoCPrinciplesOptions(values.cOcCategory)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="CoC Principle Paragraph"
                    variant="outlined"
                  />
                )}
                value={values.cOcPrinciple}
                onChange={(_, value) => {
                  setValue('cOcPrinciple', value)
                }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                name="summary"
                label="Summary"
                fullWidth={true}
                value={values.summary}
                variant="outlined"
                onChange={handleChange}
              />
            </Box>
            <Box mb={2}>
              <TextField
                name="description"
                label="Description"
                fullWidth={true}
                multiline={true}
                value={values.description}
                InputProps={{
                  rows: 10
                }}
                variant="outlined"
                onChange={handleChange}
              />
            </Box>
            <Box>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  className={formClasses.dateField}
                  id="mui-pickers-date"
                  name="deadlineDate"
                  label="Deadline Date"
                  value={values.deadlineDate}
                  minDate={new Date()}
                  onChange={handleDeadlineDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>

          <Box mt={8}>
            <ClnModalActions>
              <Button
                size="medium"
                onClick={onClose}
                color="default"
                variant="outlined"
              >
                {t('cancelButton')}
              </Button>

              <Button
                size="medium"
                color="primary"
                variant="contained"
                disabled={!isValid}
                onClick={() => handleSave()}
              >
                Add audit action
              </Button>
            </ClnModalActions>
          </Box>
        </form>
      </ClnModalContent>
    )
  }

  return (
    <Box>
      <ClnModal
        content={getContent()}
        onClose={onClose}
        show={show}
        maxWidth="500px"
        title="Add Audit Action"
      />
    </Box>
  )
}
