import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FunctionComponentElement } from 'react'

import { Theme } from 'common/theme'

interface Props {
  text: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  textDivider: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    position: 'relative',
    textAlign: 'center',

    '&:before': {
      content: '""',
      width: '100%',
      height: 1,
      backgroundColor: theme.palette.common.black,
      position: 'absolute',
      marginTop: 1,
      top: '50%',
      left: 0,
      opacity: 0.12,
      zIndex: 1
    }
  },
  text: {
    backgroundColor: theme.palette.common.white,
    display: 'inline-block',
    padding: theme.spacing(),
    position: 'relative',
    zIndex: 2
  }
}))

function TextDivider({ text }: Props): FunctionComponentElement<Props> {
  const classes = useStyles()

  return (
    <Box className={classes.textDivider}>
      <Box className={classes.text}>
        <Typography variant="caption">{text}</Typography>
      </Box>
    </Box>
  )
}

export default TextDivider
