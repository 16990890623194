import { MinimalCompanyDto } from 'services/company'
import { FileUploadDto } from 'services/fileupload/file-upload.types'
import { MaterialType } from 'services/materialtype/material-type.types'
import { ProductType } from 'services/producttype/product-type.types'
import { MinimalUserDto } from 'services/user/user.types'

import { QuantityUnit } from '../quantityunit'

export enum ProcessingOutputMaterialBagState {
  REGISTERED = 'REGISTERED',
  CLEARED = 'CLEARED'
}

export enum ProcessingUnitState {
  IN_PROGRESS = 'IN_PROGRESS',
  PROCESSING_FINISHED = 'PROCESSING_FINISHED',
  ERROR = 'ERROR'
}

export interface ProcessingUnitDto {
  uuid: string
  createdAt: string
  state: ProcessingUnitState
  originalMaterialType: MaterialType
  quantityUnit: QuantityUnit
  inputTrackedItemsCount: number

  recycler: MinimalCompanyDto
  processingStartedBy: MinimalUserDto
  processingFinishedBy: MinimalUserDto

  outputMaterialBags: ProcessingOutputMaterialBagDto[]

  formattedMaterialTotal: string
}

export interface ProcessingOutputMaterialBagDto {
  uuid: string
  createdAt: string
  state: ProcessingOutputMaterialBagState
  materialType: MaterialType
  productType: ProductType
  weight: number
  quantityUnit: QuantityUnit
  completelyFilled: boolean
  parentWeight: number
  picture: FileUploadDto

  recycler: MinimalCompanyDto
}
