import { useState } from 'react'

import { FileUploadDto } from 'services/fileupload/file-upload.types'

interface CameraState {
  pictureDataUri: string | null
  pictureFileUpload: FileUploadDto | null
  pictureConfirmed: boolean
  showCamera: boolean
}

interface UseCamera extends CameraState {
  handleCameraError: (showCamera: boolean) => void
  handleConfirmPicture: () => void
  handleFileUploadFinished: (pictureFileUpload: string) => void
  handleTakePhotoFinished: (pictureDataUri: string) => void
}

export const useCamera = (): UseCamera => {
  const [
    { pictureDataUri, pictureFileUpload, showCamera, pictureConfirmed },
    setPicture
  ] = useState<CameraState>({
    pictureDataUri: null,
    pictureFileUpload: null,
    showCamera: true,
    pictureConfirmed: false
  })

  function handleTakePhotoFinished(pictureDataUri: string): void {
    setPicture((prevPicture) => ({
      ...prevPicture,
      pictureDataUri
    }))
  }

  function handleFileUploadFinished(pictureFileUpload): void {
    setPicture((prevPicture) => ({
      ...prevPicture,
      pictureFileUpload
    }))
  }

  function handleCameraError(showCamera: boolean): void {
    setPicture((prevPicture) => ({
      ...prevPicture,
      showCamera
    }))
  }

  function handleConfirmPicture(): void {
    setPicture((prevPicture) => ({
      ...prevPicture,
      pictureConfirmed: true
    }))
  }

  return {
    pictureDataUri,
    pictureFileUpload,
    showCamera,
    pictureConfirmed,
    handleTakePhotoFinished,
    handleFileUploadFinished,
    handleConfirmPicture,
    handleCameraError
  }
}
