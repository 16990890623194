import React, { createContext, useContext, useReducer } from 'react'

import { PeriodType } from '../components/PeriodSelector'

// Reducers
import { activePickUpPointReducer } from './reducers/active-pick-up-point.reducer.ts'
import { errorReducer } from './reducers/error.reducer'
import {
  OVERVIEW_PERIOD_TYPE_KEY,
  overviewPeriodReducer
} from './reducers/overview-period.reducer'
import { toastReducer } from './reducers/toast.reducer'

export const StateContext = createContext()
export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)
export const useStateValue = () => useContext(StateContext)

const activePickUpPoint = window.sessionStorage.getItem('activePickUpPoint')
const overviewPeriodType =
  window.localStorage.getItem(OVERVIEW_PERIOD_TYPE_KEY) ?? PeriodType.WEEK

export const initialState = {
  activePickUpPoint: activePickUpPoint ? JSON.parse(activePickUpPoint) : null,
  error: {
    alertBars: [],
    errorMessage: null,
    errorMessageKey: null
  },
  overviewPeriod: {
    endDate: null,
    periodType: overviewPeriodType,
    startDate: null
  },
  toast: {
    message: null,
    variant: null
  }
}

export const mainReducer = (
  { activePickUpPoint, error, overviewPeriod, toast },
  action
) => ({
  activePickUpPoint: activePickUpPointReducer(activePickUpPoint, action),
  error: errorReducer(error, action),
  overviewPeriod: overviewPeriodReducer(overviewPeriod, action),
  toast: toastReducer(toast, action)
})
