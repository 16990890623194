import { useCallback } from 'react'

export type FormatNumber = (
  value: FormatterValue,
  options?: FormatterOptions
) => string

export interface FormatterOptions {
  precision?: number
}

export type FormatterValue = number

export function useFormatNumber(): FormatNumber {
  return useCallback<FormatNumber>((value, options) => {
    return value.toFixed(options?.precision ?? 2)
  }, [])
}
