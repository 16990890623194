import { debounce } from '@material-ui/core'
import { useCallback, useEffect } from 'react'

import {
  EntityFilterConfig,
  getFiltersBody
} from '../../components/EntityFilters'

import { ExtendedRequestInit, useFetchState, UseFetchState } from './fetch.hook'
import { SearchResponse } from './paginated-fetch-state.hook'

export function usePaginatedFilteredFetchState<T>(
  apiUrl: string,
  page: number,
  size: number,
  filters: EntityFilterConfig[] | null
): UseFetchState<SearchResponse<T>> {
  function getFetchConfig(): Partial<ExtendedRequestInit> {
    return {
      body: getFiltersBody(filters, page, size),
      method: 'POST'
    }
  }

  const [response, isLoading, refresh] = useFetchState<SearchResponse<T>>(
    apiUrl,
    null,
    getFetchConfig(),
    {
      initialFetch: false
    }
  )

  const debouncedRefresh = useCallback<typeof refresh>(debounce(refresh, 500), [
    refresh
  ])

  useEffect(() => {
    if (filters) {
      debouncedRefresh(getFetchConfig())
    }
  }, [filters, page, size])

  return [
    response,
    isLoading,
    (refreshOptions) => refresh(refreshOptions ?? getFetchConfig())
  ]
}
