import React from 'react'
import { useHistory } from 'react-router-dom'

// Externals
import classNames from 'classnames'

// Material components
import { Button, Typography, withStyles } from '@material-ui/core'

import { DoneOutline as DoneOutlineIcon } from '@material-ui/icons'

import { SummaryLine } from 'components'

// Component styles
import styles from './styles'
import { useTranslation } from 'react-i18next'

import { getDisplayedQuantityUnit } from 'services/quantityunit'
import moment from 'moment'

function CollectionCenterClearanceUnit(props) {
  const { classes, className, materialClearanceUnit } = props

  const rootClassName = classNames(classes.root, className)

  const { t } = useTranslation()
  const history = useHistory()

  const displayedQuantityUnit = getDisplayedQuantityUnit(
    materialClearanceUnit.quantityUnit
  )
  function handleFinishClearance() {
    redirectToClearMaterialStock()
  }

  function redirectToClearMaterialStock() {
    history.push(
      `/clear-material-stock?materialClearanceUnitUuid=${materialClearanceUnit.uuid}&total=${materialClearanceUnit.weight}&materialTypes=${materialClearanceUnit.materialType}`
    )
  }

  return (
    <div className={rootClassName}>
      <Typography className={classes.title} variant="h3">
        {t('ongoingMaterialClearanceSummary')}
      </Typography>
      <div className={classes.content}>
        <SummaryLine
          title={t('started')}
          value={`${moment(materialClearanceUnit.createdAt).format(
            'DD/MM/YYYY HH:mm'
          )}`}
        />
        {materialClearanceUnit.inputTrackedItemsCount && (
          <SummaryLine
            title={t('inputTrackedItemsCount')}
            value={materialClearanceUnit.inputTrackedItemsCount}
          />
        )}
        {materialClearanceUnit.weight && (
          <SummaryLine
            title={t('weight')}
            value={
              <>
                {materialClearanceUnit.weight} {displayedQuantityUnit}
              </>
            }
          />
        )}

        <Button
          size="large"
          variant="contained"
          className={classes.finishGradingButton}
          onClick={handleFinishClearance}
          startIcon={
            <DoneOutlineIcon
              style={{
                height: 50,
                width: 50,
                fontWeight: 30
              }}
            />
          }
        >
          {t('finishMaterialClearanceButton')}
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(CollectionCenterClearanceUnit)
