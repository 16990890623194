import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  TablePagination,
  Tooltip,
  Typography
} from '@material-ui/core'

import {
  ViewModuleOutlined as ViewModuleOutlinedIcon,
  FileCopy as FileCopyIcon
} from '@material-ui/icons'

import React, {
  Fragment,
  FunctionComponentElement,
  ReactNode,
  useState
} from 'react'
import { match } from 'react-router-dom'

import { Theme } from '../../../common/theme'
import { Image, Paper, Portlet, PortletContent } from '../../../components'
import { EntityFilterConfig } from '../../../components/EntityFilters'
import { AdminPortalLayout } from '../../../layouts/AdminPortalLayout'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import {
  usePaginatedFilteredFetchState,
  usePaginationConfig
} from '../../../lib/fetch'
import { TrackedItemDto, TrackedItemType } from '../../../services/tracked-item'

import { ReactComponent as BagIcon } from 'icons/bag-black.svg'

import { TrackedItemsFilters } from './TrackedItemsFilters'

interface Props {
  match: match<{ hubCompanyUuid: string }>
}

const useStyles = makeStyles<Theme>((theme) => ({
  viewTrackedItems: {
    //
  },
  typeWrapper: {
    width: `calc(100% - ${theme.spacing(6)}px)`,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 3),
    zIndex: 1200
  },
  buttonCopy: {
    minWidth: 20,
    padding: 0
  },
  bottomPaginationWrapper: {
    borderTop: `1px solid ${theme.palette.border}`,

    '@media print': {
      display: 'none'
    }
  },
  filters: {
    '@media print': {
      display: 'none'
    }
  },
  pageBreak: {
    clear: 'both',
    pageBreakAfter: 'always'
  },
  paper: {
    overflow: 'hidden',

    '@media print': {
      boxShadow: 'none'
    }
  },
  qrCodeWrapper: {
    width: '25%',
    float: 'left',
    border: `1px solid ${theme.palette.border}`
  },
  qrCodes: {
    '@media print': {
      display: 'block'
    }
  },
  topPaginationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    borderBottom: `1px solid ${theme.palette.border}`,

    '@media print': {
      display: 'none'
    }
  },
  trackingNumber: {
    zIndex: 1200,

    '@media print': {
      fontSize: 16
    }
  }
}))

export function ViewTrackedItems({
  match
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const {
    params: { hubCompanyUuid }
  } = match

  const { page, size, setPage, setSize } = usePaginationConfig()

  const [filters, setFilters] = useState<EntityFilterConfig[]>([])

  const [
    trackedItems,
    isTrackedItemsLoading
  ] = usePaginatedFilteredFetchState<TrackedItemDto>(
    `/api/admin/trackedItems`,
    page,
    size,
    filters
  )

  function getPagination(): ReactNode {
    return (
      <TablePagination
        component="div"
        count={trackedItems?.pagination.total ?? 0}
        onChangePage={(_, page) => setPage(page)}
        onChangeRowsPerPage={(event) => {
          setSize(parseInt(event.target.value, 10))
        }}
        page={page}
        rowsPerPage={size}
        rowsPerPageOptions={[16, 32, 48]}
      />
    )
  }

  return (
    <AdminPortalLayout title="View Tracked Items">
      <PortalContent>
        <Box className={classes.filters} mb={5}>
          <Paper elevation={5} outlined={false}>
            <Box p={3}>
              <TrackedItemsFilters
                hubUuid={hubCompanyUuid}
                onChange={(filters) => {
                  setFilters(filters)
                  setPage(0)
                }}
              />
            </Box>
          </Paper>
        </Box>

        <Paper className={classes.paper} elevation={5} outlined={false}>
          <Box>
            {isTrackedItemsLoading || !trackedItems ? (
              <Box display="flex" justifyContent="center" p={5}>
                <CircularProgress />
              </Box>
            ) : (
              <Portlet>
                <PortletContent noPadding={true}>
                  <Box className={classes.topPaginationWrapper}>
                    <Box>{getPagination()}</Box>
                  </Box>

                  <Box>
                    <Grid
                      className={classes.qrCodes}
                      container={true}
                      spacing={0}
                    >
                      {trackedItems?.items.map((trackedItem, index) => (
                        <Fragment key={trackedItem.uuid}>
                          <Grid
                            className={classes.qrCodeWrapper}
                            item={true}
                            xs={12}
                            sm={4}
                            lg={3}
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              mt={4}
                              textAlign="center"
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                textAlign="center"
                              >
                                <Box mr={2}>
                                  {trackedItem.type === TrackedItemType.BAG && (
                                    <Tooltip title="Bag">
                                      <BagIcon
                                        style={{ width: 36, height: 36 }}
                                      />
                                    </Tooltip>
                                  )}

                                  {trackedItem.type ===
                                    TrackedItemType.BALE && (
                                    <Tooltip title="Bale">
                                      <ViewModuleOutlinedIcon
                                        style={{ width: 36, height: 36 }}
                                      />
                                    </Tooltip>
                                  )}
                                </Box>

                                <Typography
                                  className={classes.trackingNumber}
                                  variant="h4"
                                >
                                  {trackedItem.trackingNumber}
                                </Typography>

                                <Box ml={2}>
                                  <Tooltip title="Copy Tracking URL">
                                    <Button
                                      className={classes.buttonCopy}
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          trackedItem.trackingUrl
                                        )
                                      }
                                    >
                                      <FileCopyIcon
                                        className={classes.copyIcon}
                                      />
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </Box>

                              {/* without this padding, the image is covering the border of the parent Grid */}
                              <Box padding="1px">
                                <Image
                                  source={trackedItem.qrCode.directLink}
                                  width="100%"
                                />
                              </Box>
                            </Box>
                          </Grid>

                          {(index + 1) % 16 === 0 ? (
                            <Box className={classes.pageBreak} />
                          ) : null}
                        </Fragment>
                      ))}
                    </Grid>
                  </Box>

                  <Box className={classes.bottomPaginationWrapper}>
                    {getPagination()}
                  </Box>
                </PortletContent>
              </Portlet>
            )}
          </Box>
        </Paper>
      </PortalContent>
    </AdminPortalLayout>
  )
}
