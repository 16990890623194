import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FunctionComponentElement, ReactNode } from 'react'

import { Theme } from 'common/theme'

interface EmptyStateProps {
  action?: ReactNode
  heading?: string
  image?: string
  message?: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  emptyComponent: {
    width: '100%'
  },
  emptyStateImage: {
    width: '50%',
    maxHeight: '200px',
    display: 'block',
    margin: `${theme.spacing(3)}px auto ${theme.spacing(5)}px`
  }
}))

export function EmptyState({
  action,
  heading,
  image,
  message
}: EmptyStateProps): FunctionComponentElement<EmptyStateProps> {
  const classes = useStyles()

  return (
    <Box className={classes.emptyComponent} textAlign="center">
      {image && (
        <img className={classes.emptyStateImage} src={image} alt={heading} />
      )}

      {heading && <Typography variant="h3">{heading}</Typography>}

      {message && (
        <Box mt={1}>
          <Typography variant="body1" color="textSecondary">
            {message}
          </Typography>
        </Box>
      )}

      {action && <Box mt={3}>{action}</Box>}
    </Box>
  )
}
