import { Receipt as ReceiptIcon } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'

import { compose } from 'recompose'

// Material components
import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'

// Shared layouts
import { mergeDateAndTime } from '../../../helpers/date-time.helpers'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'

// Component styles
import styles from './styles'
import { useTheme } from '@material-ui/styles'

import { useStateValue } from 'state'
import schema from './schema'

import { useCamera } from 'lib/camera'

import { useTranslation } from 'react-i18next'

import { apiFetchWithDispatch } from 'lib/fetch'
import { useForm } from 'lib/form'

import { ImagePreview, FullScreenCamera } from 'components'

import { QuantityUnits } from 'services/quantityunit'
import LinkButton from '../../../components/LinkButton'

function WeighRecyclerClearanceTransport (props) {
  const { classes, history } = props
  const { clearanceTransportUuid } = props.match.params

  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  const theme = useTheme()

  const [, dispatch] = useStateValue()

  const weighSlipCamera = useCamera()

  const {
    formState: {
      values,
      errors,
      isValid
    },
    setValues,
    handleChange,
    resetForm,
    hasError
  } = useForm(schema, {
    weight: '',
    weighedAt: new Date(),
    weighedAtTime: new Date(),
    quantityUnit: QuantityUnits.KG
  })

  useEffect(() => {
    if (!weighSlipCamera.showCamera) {
      history.push('/hub-overview')
    }
  }, [weighSlipCamera.showCamera])

  function handleWeighedAtChange (e) {
    const newValues = { ...values }
    newValues.weighedAt = e
    setValues(newValues)
  }

  function handleWeighedAtTimeChange (e) {
    const newValues = { ...values }
    newValues.weighedAtTime = e
    setValues(newValues)
  }

  async function handleSave () {
    setIsLoading(true)

    const {
      weight,
      weighedAt,
      weighedAtTime
    } = values

    const response = await apiFetchWithDispatch(dispatch,
      `/api/clearanceTransports/${clearanceTransportUuid}/weigh`, {
        method: 'POST',
        body: JSON.stringify({
          weight,
          weighedAt: mergeDateAndTime(weighedAt, weighedAtTime).toISOString(),
          weighSlipPictureFileUploadUuid: weighSlipCamera.pictureFileUpload
            ? weighSlipCamera.pictureFileUpload.uuid
            : null
        })
      })

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('weighRecyclerClearanceTransportTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!weighSlipCamera.pictureConfirmed) {
    return (
      <FullScreenCamera
        key='weigh-recycler-clearance-picture'
        fileName={`weigh-recycler-clearance-${Date.now()}`}
        headerMessage={t('weighRecyclerClearancePictureExplanation')}
        shutterIcon={<ReceiptIcon />}
        onTakePhotoFinished={weighSlipCamera.handleTakePhotoFinished}
        onFileUploadFinished={weighSlipCamera.handleFileUploadFinished}
        onConfirmPicture={weighSlipCamera.handleConfirmPicture}
        onCameraError={weighSlipCamera.handleCameraError}
      />
    )
  }

  return (
    <SecurePortalLayout title={t('weighRecyclerClearanceTransportTitle')}>
      <PortalContent>
        <div className={classes.root}>
          <div className={classes.content}>
            <form autoComplete='off' noValidate>
              <Paper className={classes.formSection}>
                <Typography
                  className={classes.title}
                  variant='h3'
                >
                  {t('weighRecyclerClearanceTransportTitle')}
                </Typography>

                <FormControl component='fieldset'>
                  <FormLabel component='legend' required>
                    {t('weighSlipPicture')}
                  </FormLabel>
                  <div className={classes.imagePreview}>
                    <ImagePreview
                      dataUri={weighSlipCamera.pictureDataUri}
                      isPictureUploading={!weighSlipCamera.pictureFileUpload}
                    />
                  </div>
                </FormControl>

                <div className={classes.field}>
                  <TextField
                    name='weight'
                    autoFocus
                    className={classes.weightField}
                    type='number'
                    label={`${t('addWeight')}`}
                    margin='dense'
                    required
                    value={values.weight}
                    onChange={handleChange}
                    error={hasError('weight')}
                    helperText={hasError('weight') ? t(errors.weight[0]) : ''}
                  />
                  <TextField
                    name='quantityUnit'
                    className={classes.quantityUnitField}
                    disabled
                    label=' '
                    margin='dense'
                    value={values.quantityUnit}
                    InputProps={{
                      style: {
                        color: theme.palette.text.primary
                      }
                    }}
                  />
                </div>

                <div className={classes.field}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      margin='normal'
                      className={classes.dateField}
                      id='mui-pickers-date'
                      name='weighedAt'
                      label={t('weighedAt')}
                      value={values.weighedAt}
                      onChange={handleWeighedAtChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />

                    <KeyboardTimePicker
                      margin='normal'
                      className={classes.timeField}
                      id='time-picker'
                      label=' '
                      value={values.weighedAtTime}
                      onChange={handleWeighedAtTimeChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change time'
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className={classes.actionButtons}>
                  <LinkButton
                    size='large'
                    className={classes.cancelButton}
                    color='default'
                    variant='outlined'
                    href='/hub-overview'
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size='large'
                    variant='contained'
                    className={classes.weighButton}
                    disabled={!isValid || isLoading || !weighSlipCamera.pictureFileUpload}
                    onClick={handleSave}
                  >
                    {t('addWeighSlipButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </div>
        </div>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default compose(
  withRouter,
  withStyles(styles)
)(WeighRecyclerClearanceTransport)
