import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField
} from '@material-ui/core'
import React, { FunctionComponentElement, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStateValue } from '../../../../state'
import { apiFetchWithDispatch } from '../../../../lib/fetch'
import { ClnModal, ClnModalActions, ClnModalContent } from 'components/modal'
import { ClnModalContentProps } from 'components/modal/ClnModalContent'
import { useGeneralStyles } from '../../../../common/styles/general'
import { useForm } from '../../../../lib/form'

interface AddProjectModalModalProps {
  hubUuid: string
  show: boolean
  onClose: () => void
}

const formSchema = {
  projectId: {
    presence: { allowEmpty: false, message: 'validationRequired' },
    length: { maximum: 256, message: 'validationExceedsMaxLength' }
  }
}

export function AddProjectModal({
  hubUuid,
  show,
  onClose
}: AddProjectModalModalProps): FunctionComponentElement<AddProjectModalModalProps> {
  const {
    formState: { values, errors },
    handleChange,
    hasError
  } = useForm(formSchema, {
    projectId: '',
    cleanHubProject: true
  })

  const [, dispatch] = useStateValue()

  const { t } = useTranslation()

  const generalClasses = useGeneralStyles()

  const [isSaving, setIsSaving] = useState<boolean>(false)

  async function handleSave() {
    setIsSaving(true)

    await apiFetchWithDispatch(
      dispatch,
      `/api/admin/companies/${hubUuid}/projects`,
      {
        method: 'POST',
        body: JSON.stringify({
          externalId: values.projectId,
          isCleanHub: values.cleanHubProject
        })
      }
    )

    onClose()
  }

  const getContent = (): ReactElement<
    ClnModalContentProps,
    typeof ClnModalContent
  > => {
    if (isSaving) {
      return (
        <ClnModalContent>
          <Box className={generalClasses.progressWrapper}>
            <CircularProgress />
          </Box>
        </ClnModalContent>
      )
    }

    return (
      <ClnModalContent>
        <form autoComplete="off" noValidate>
          <Box ml={2}>
            <Box>
              <TextField
                name="projectId"
                type="text"
                label="ID"
                required={true}
                fullWidth={true}
                value={values.projectId}
                onChange={handleChange}
                error={hasError('projectId')}
                helperText={
                  hasError('projectId') ? t(errors.projectId?.[0] ?? '') : ''
                }
              />
            </Box>
            <Box style={{ marginTop: 10 }}>
              <FormControlLabel
                labelPlacement="end"
                control={
                  <Switch
                    name="cleanHubProject"
                    checked={values.cleanHubProject}
                    onChange={(event) => handleChange(event)}
                    value={values.cleanHubProject}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="CleanHub Project"
              />
            </Box>
          </Box>

          <Box mt={8}>
            <ClnModalActions>
              <Button
                size="medium"
                onClick={onClose}
                color="default"
                variant="outlined"
              >
                {t('cancelButton')}
              </Button>

              <Button
                size="medium"
                color="primary"
                variant="contained"
                onClick={() => handleSave()}
              >
                Add project
              </Button>
            </ClnModalActions>
          </Box>
        </form>
      </ClnModalContent>
    )
  }

  return (
    <Box>
      <ClnModal
        content={getContent()}
        onClose={onClose}
        show={show}
        maxWidth="350px"
        title="Add Hub Project"
      />
    </Box>
  )
}
