import {
  Box,
  Tooltip,
  CircularProgress,
  FormControl,
  RadioGroup,
  Typography,
  List,
  ListItem,
  IconButton,
  TextField,
  useTheme
} from '@material-ui/core'
import {
  RestoreFromTrash as RestoreFromTrashIcon,
  InfoOutlined as InfoOutlinedIcon,
  AddCircleOutline as AddCircleOutlineIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, {
  FunctionComponentElement,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { debounce } from 'underscore'

import personalSettingsSvg from '../../../../assets/images/empty-state/personal-settings.svg'
import { Theme } from '../../../../common/theme'
import { EmptyState } from '../../../../components/EmptyState'
import FlowActionButton from '../../../../components/FlowActionButton'
import {
  InputRadioMaterialProductType,
  InputRadioMaterialProductTypeValue
} from '../../../../components/InputRadioMaterialProductType'
import { MaterialTypeBreakdown } from '../../../../components/MaterialTypeBreakdown'
import { apiFetchWithDispatch, useFetchState } from '../../../../lib/fetch'
import { useForm } from '../../../../lib/form'
import {
  MassBalanceAccount,
  MassBalanceOverviewDto
} from '../../../../services/mass-balance'
import { getCurrentUser } from '../../../../services/user'
import { useStateValue } from '../../../../state'
import { Dispatch, State } from '../../../../state/state.types'
import { QuantityUnits } from '../../../../services/quantityunit'

interface MassBalanceProcessingOverviewProps {
  onStartProcessing: () => any
}

interface FormValue {
  materialProduct: InputRadioMaterialProductTypeValue | null
  supplier?: string | null
  originalWeight?: number | null
  rejectedWeight?: number | null
  quantityUnit: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  massBalanceProcessingOverview: {
    margin: theme.spacing(-3, 0, 1)
  },
  radioControl: {
    display: 'block'
  },
  tooltipIconButton: {
    pointerEvents: 'auto',
    transform: 'rotate(45deg)',
    color: 'white',
    position: 'relative',
    left: '268px'
  },
  quantityUnitField: {
    width: 18
  },
  additionalField: {
    width: 275,
    maxWidth: '100%'
  }
}))

const formSchema = {
  materialProduct: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  }
}

export function MassBalanceProcessingOverview({
  onStartProcessing
}: MassBalanceProcessingOverviewProps): FunctionComponentElement<MassBalanceProcessingOverviewProps> {
  const processingInputMaterialTypes =
    getCurrentUser()?.processingInputMaterialTypes ?? []
  const processingFeatureSteps = getCurrentUser()?.processingSteps ?? []

  const classes = useStyles()

  const [{ overviewPeriod }, dispatch]: [State, Dispatch] = useStateValue()
  const { t } = useTranslation()

  const {
    formState: { isValid, values },
    setValue,
    handleChange,
    hasError
  } = useForm<FormValue>(formSchema, {
    materialProduct: null,
    supplier: '',
    originalWeight: null,
    rejectedWeight: null,
    quantityUnit: QuantityUnits.KG
  })

  const [isStarting, setIsStarting] = useState<boolean>()
  const [showTooltip, setShowTooltip] = React.useState(false)

  const theme = useTheme()

  const [
    overview,
    isOverviewLoading,
    refetchOverview
  ] = useFetchState<MassBalanceOverviewDto>(
    '/api/massBalance/overview',
    null,
    {},
    { initialFetch: false }
  )

  const debouncedRefetchOverview = useCallback<typeof refetchOverview>(
    debounce(refetchOverview, 500),
    [refetchOverview]
  )

  async function handleStartProcessing(): Promise<void> {
    setIsStarting(true)

    const { materialProduct, supplier, originalWeight, rejectedWeight } = values

    await apiFetchWithDispatch(dispatch, '/api/processingUnits/start', {
      method: 'POST',
      body: JSON.stringify({
        materialType: materialProduct?.materialType,
        supplier,
        originalWeight,
        rejectedWeight
      })
    })

    setIsStarting(false)

    onStartProcessing()
  }

  useEffect(() => {
    if (overviewPeriod.endDate && overviewPeriod.startDate) {
      debouncedRefetchOverview({
        queryParams: {
          account: MassBalanceAccount.PROCESSING,
          endDate: overviewPeriod.endDate.toISOString(),
          startDate: overviewPeriod.startDate.toISOString()
        }
      })
    }
  }, [overviewPeriod.endDate, overviewPeriod.startDate])

  if (isOverviewLoading || !overview || isStarting) {
    return (
      <Box mb={3} textAlign="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.massBalanceProcessingOverview}>
      <Box mx={-1} mb={4}>
        <MaterialTypeBreakdown
          outputTypeBreakdown={overview.outputTypeBreakdown}
          quantityUnit={overview.quantityUnit}
          totalText={t('totalProcessed')}
        />
      </Box>

      {processingInputMaterialTypes.length > 0 ? (
        <>
          <Typography variant="subtitle1">
            {t('selectMaterialTypeForProcessing')}:
            <Tooltip
              leaveTouchDelay={3000}
              enterTouchDelay={50}
              open={showTooltip}
              disableHoverListener
              onMouseEnter={() => setShowTooltip(true)}
              title={
                <>
                  <Box>
                    <IconButton
                      className={classes.tooltipIconButton}
                      onClick={() => setShowTooltip(false)}
                      color="primary"
                      size="small"
                    >
                      <AddCircleOutlineIcon style={{ width: 25, height: 25 }} />
                    </IconButton>
                  </Box>
                  <Box fontSize={14}>
                    {t('processingStepsTooltipTitle')}:
                    <List>
                      {processingFeatureSteps.map((step, index) => (
                        <ListItem key={index} alignItems="flex-start">
                          {index + 1}. {t(`processingStep_${step}`)}
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </>
              }
              placement="right-start"
            >
              <InfoOutlinedIcon
                style={{ height: 16, width: 16 }}
                color="secondary"
              />
            </Tooltip>
          </Typography>

          <form autoComplete="off" noValidate={true}>
            <FormControl className={classes.radioControl} component="fieldset">
              <Box mt={2}>
                <RadioGroup
                  name="materialProduct"
                  value={values.materialProduct}
                  onChange={(_, value) => {
                    setValue('materialProduct', JSON.parse(value))
                  }}
                >
                  {processingInputMaterialTypes.map((materialType) => (
                    <InputRadioMaterialProductType
                      key={materialType}
                      isSelected={
                        materialType === values.materialProduct?.materialType
                      }
                      materialType={materialType}
                    />
                  ))}
                </RadioGroup>
              </Box>

              {values.materialProduct && (
                <Box>
                  <Box>
                    <TextField
                      name="supplier"
                      className={classes.additionalField}
                      label="Supplier"
                      value={values.supplier}
                      onChange={handleChange}
                      error={hasError('supplier')}
                    />
                  </Box>
                  <Box>
                    <TextField
                      name="originalWeight"
                      className={classes.additionalField}
                      label="Weight"
                      type="number"
                      margin="dense"
                      value={values.originalWeight}
                      onChange={handleChange}
                      error={hasError('originalWeight')}
                    />
                    <TextField
                      name="quantityUnit"
                      className={classes.quantityUnitField}
                      disabled
                      label=" "
                      margin="dense"
                      value={values.quantityUnit}
                      InputProps={{
                        style: {
                          color: theme.palette.text.primary
                        }
                      }}
                    />
                  </Box>
                  <Box>
                    <TextField
                      name="rejectedWeight"
                      className={classes.additionalField}
                      label="Rejected Weight"
                      type="number"
                      margin="dense"
                      value={values.rejectedWeight}
                      onChange={handleChange}
                      error={hasError('rejectedWeight')}
                    />
                    <TextField
                      name="quantityUnit"
                      className={classes.quantityUnitField}
                      disabled
                      label=" "
                      margin="dense"
                      value={values.quantityUnit}
                      InputProps={{
                        style: {
                          color: theme.palette.text.primary
                        }
                      }}
                    />
                  </Box>
                </Box>
              )}
            </FormControl>
          </form>

          <FlowActionButton
            color="secondary"
            disabled={!isValid}
            icon={RestoreFromTrashIcon}
            onClick={handleStartProcessing}
          >
            {t('processMaterialButton')}
          </FlowActionButton>
        </>
      ) : (
        <Box mb={3}>
          <EmptyState
            image={personalSettingsSvg}
            heading={t('noMaterialTypes')}
            message={t('noMaterialTypesProcessingDescription')}
          />
        </Box>
      )}
    </Box>
  )
}
