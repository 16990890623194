import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Typography,
  useTheme,
  withStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { FullScreenCamera, ImagePreview } from '../../../components'
import { DocumentWithoutLocationUpload } from '../../../components/DocumentWithoutLocationUpload'
import LinkButton from '../../../components/LinkButton'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'
import { useCamera } from '../../../lib/camera'
import { apiFetchWithDispatch } from '../../../lib/fetch'
import { useForm } from '../../../lib/form'
import { QuantityUnits } from '../../../services/quantityunit'
import { getCurrentUser } from '../../../services/user'
import { useStateValue } from '../../../state'

import schema from './schema'
import styles from './styles'

function AddRecyclerMaterialCertificate({ classes, history, match }) {
  const { materialUuid } = match.params

  const theme = useTheme()

  const [isLoading, setIsLoading] = useState(false)
  const [documentUpload, setDocumentUpload] = useState(null)

  const { t } = useTranslation()

  const [, dispatch] = useStateValue()

  const { enableDocumentUpload } = getCurrentUser()

  const {
    pictureDataUri,
    pictureFileUpload,
    showCamera,
    pictureConfirmed,
    handleTakePhotoFinished,
    handleFileUploadFinished,
    handleConfirmPicture,
    handleCameraError
  } = useCamera()

  const {
    formState: { values, errors, isValid },
    handleChange,
    resetForm,
    hasError
  } = useForm(schema, {
    certifiedWeight: '',
    calorificValueQuantityUnit: QuantityUnits.CALORIES_PER_GRAM
  })

  useEffect(() => {
    if (!showCamera) {
      history.push('/hub-overview')
    }
  }, [showCamera])

  async function handleSave() {
    setIsLoading(true)

    const { certifiedWeight } = values

    const response = await apiFetchWithDispatch(
      dispatch,
      `/api/collectedMaterials/${materialUuid}/certify`,
      {
        method: 'POST',
        body: JSON.stringify({
          certifiedWeight,
          certificatePictureFileUploadUuid: documentUpload
            ? documentUpload.uuid
            : pictureFileUpload
            ? pictureFileUpload.uuid
            : null
        })
      }
    )

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('addMaterialCertificateTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!enableDocumentUpload && !pictureConfirmed) {
    return (
      <FullScreenCamera
        fileName={`recycler-material-certificate-${Date.now()}`}
        headerMessage={t('addCertificatePictureExplanation')}
        onTakePhotoFinished={handleTakePhotoFinished}
        onFileUploadFinished={handleFileUploadFinished}
        onConfirmPicture={handleConfirmPicture}
        onCameraError={handleCameraError}
      />
    )
  }

  return (
    <SecurePortalLayout title={t('addMaterialCertificateTitle')}>
      <PortalContent>
        <div className={classes.root}>
          <div className={classes.content}>
            <form autoComplete="off" noValidate>
              <Paper className={classes.formSection}>
                <Typography className={classes.title} variant="h3">
                  {t('addMaterialCertificateTitle')}
                </Typography>

                {enableDocumentUpload ? (
                  <DocumentWithoutLocationUpload
                    acceptedFiles={['application/pdf', 'image/*']}
                    dropzoneText={t('addCertificateUploadExplanation')}
                    fileName={`recycler-material-certificate-${Date.now()}`}
                    maxFileSize={5000000}
                    onClear={() => setDocumentUpload(null)}
                    onUpload={setDocumentUpload}
                  />
                ) : (
                  <FormControl component="fieldset">
                    <FormLabel component="legend" required>
                      {t('certificatePicture')}
                    </FormLabel>
                    <div className={classes.imagePreview}>
                      <ImagePreview
                        dataUri={pictureDataUri}
                        isPictureUploading={!pictureFileUpload}
                      />
                    </div>
                  </FormControl>
                )}

                <div className={classes.field}>
                  <TextField
                    name="certifiedWeight"
                    autoFocus
                    className={classes.numericField}
                    type="number"
                    label={t('weight')}
                    margin="dense"
                    required
                    value={values.certifiedWeight}
                    onChange={handleChange}
                    error={hasError('certifiedWeight')}
                    helperText={
                      hasError('certifiedWeight')
                        ? t(errors.certifiedWeight[0])
                        : ''
                    }
                  />
                </div>

                <div className={classes.actionButtons}>
                  <LinkButton
                    size="large"
                    className={classes.cancelButton}
                    color="default"
                    variant="outlined"
                    href="/hub-overview"
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size="large"
                    variant="contained"
                    className={classes.addCertificateButton}
                    disabled={
                      !isValid ||
                      isLoading ||
                      (!documentUpload && !pictureFileUpload)
                    }
                    onClick={handleSave}
                  >
                    {t('addCertificateButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </div>
        </div>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default compose(
  withRouter,
  withStyles(styles)
)(AddRecyclerMaterialCertificate)
