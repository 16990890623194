import {
  Box,
  Button,
  FormControl,
  FormLabel,
  makeStyles,
  Paper,
  TextField,
  Typography
} from '@material-ui/core'
import { LocalShipping as LocalShippingIcon } from '@material-ui/icons'
import React, { FunctionComponentElement, useEffect, useState } from 'react'
import { match, useHistory } from 'react-router-dom'

import { PortalContent } from '../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../layouts/SecurePortalLayout'
import { useForm } from '../../lib/form'
import { useCamera } from '../../lib/camera'
import { useTranslation } from 'react-i18next'
import { apiFetchWithDispatch } from '../../lib/fetch'
import { useGeneralStyles } from '../../common/styles/general'
import { useStateValue } from '../../state'
import { useFormStyles } from '../../common/styles/form'
import LinkButton from 'components/LinkButton'

import { ImagePreview, FullScreenCamera } from 'components'

interface Props {
  match: match<{ materialTypes: string }>
}

const useStyles = makeStyles(() => ({
  clearHandlingCenterMaterialForm: {
    //
  }
}))

const schema = {
  transporterLicensePlate: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  },
  buyer: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  }
}

function ClearHandlingCenterMaterialForm({
  match
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()
  const formClasses = useFormStyles()

  const {
    params: { materialTypes }
  } = match

  const materialTypesArray = materialTypes.split(',')

  const [isLoading, setIsLoading] = useState(false)

  const [, dispatch] = useStateValue()

  const history = useHistory()

  const { t } = useTranslation()

  const {
    formState: { values, isValid },
    getErrorMessage,
    handleChange,
    hasError,
    resetForm
  } = useForm(schema, {
    transporterLicensePlate: '',
    buyer: ''
  })

  const transporterCamera = useCamera()

  useEffect(() => {
    if (!transporterCamera.showCamera) {
      history.push('/hub-overview')
    }
  }, [transporterCamera.showCamera])

  async function handleSave() {
    setIsLoading(true)

    const { transporterLicensePlate, buyer } = values

    const response = await apiFetchWithDispatch(
      dispatch,
      '/api/handlingCenterMaterialClearances',
      {
        method: 'POST',
        body: JSON.stringify({
          materialTypes: materialTypesArray,
          transporterLicensePlate,
          buyer,
          transporterPictureFileUploadUuid: transporterCamera.pictureFileUpload
            ? transporterCamera.pictureFileUpload.uuid
            : null
        })
      }
    )

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('clearHandlingCenterMaterialTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!transporterCamera.pictureConfirmed) {
    return (
      <FullScreenCamera
        key="transporter-picture"
        fileName={`handling-center-=material-clearance-${Date.now()}`}
        headerMessage={t('materialClearancePictureExplanation')}
        shutterIcon={<LocalShippingIcon />}
        onTakePhotoFinished={transporterCamera.handleTakePhotoFinished}
        onFileUploadFinished={transporterCamera.handleFileUploadFinished}
        onConfirmPicture={transporterCamera.handleConfirmPicture}
        onCameraError={transporterCamera.handleCameraError}
      />
    )
  }

  return (
    <Box className={classes.clearHandlingCenterMaterialForm}>
      <SecurePortalLayout title={t('clearHandlingCenterMaterialTitle')}>
        <PortalContent>
          <Box className={generalClasses.content}>
            <form autoComplete="off" noValidate>
              <Paper className={formClasses.formSection}>
                <Typography className={generalClasses.title} variant="h3">
                  {t('clearanceOf')}{' '}
                  {materialTypesArray
                    .map((materialType) => t(`materialType_${materialType}`))
                    .join(', ')}
                </Typography>

                <FormControl component="fieldset">
                  <FormLabel component="legend" required>
                    {t('transporterPicture')}
                  </FormLabel>
                  <div className={generalClasses.imagePreview}>
                    <ImagePreview
                      dataUri={transporterCamera.pictureDataUri}
                      isPictureUploading={!transporterCamera.pictureFileUpload}
                    />
                  </div>
                </FormControl>

                <div className={formClasses.field}>
                  <TextField
                    name="transporterLicensePlate"
                    required
                    label={t('licensePlate')}
                    className={formClasses.textField}
                    value={values.transporterLicensePlate}
                    onChange={handleChange}
                    margin="dense"
                  />
                </div>

                <div className={formClasses.field}>
                  <TextField
                    name="buyer"
                    required
                    label={t('buyer')}
                    className={formClasses.textField}
                    value={values.buyer}
                    onChange={handleChange}
                    margin="dense"
                    error={hasError('buyer')}
                    helperText={t(getErrorMessage('buyer'))}
                  />
                </div>

                <div className={formClasses.actionButtons}>
                  <LinkButton
                    size="large"
                    className={formClasses.cancelButton}
                    color="default"
                    variant="outlined"
                    href="/hub-overview"
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size="large"
                    variant="contained"
                    className={generalClasses.primaryButton}
                    disabled={
                      !isValid ||
                      isLoading ||
                      !transporterCamera.pictureFileUpload
                    }
                    onClick={handleSave}
                  >
                    {t('clearMaterialButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </Box>
        </PortalContent>
      </SecurePortalLayout>
    </Box>
  )
}

export default ClearHandlingCenterMaterialForm
