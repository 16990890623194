import {
  Box,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core'
import JSConfetti from 'js-confetti'
import React, {
  FunctionComponentElement,
  useCallback,
  useEffect,
  useState
} from 'react'

import { Theme } from 'common/theme'

import { getRandomGiphy, GiphyImage } from '../../services/giphy'
import { getCurrentUser } from '../../services/user'

import { ClnModal } from '../modal'

import { getCurrentUserSessionAchievements } from './achievements.service'

export interface HubEventsReviewCelebrationModalProps {
  show: boolean

  onClose: () => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  achievementModal: {
    //
  },
  canvas: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1
  },
  content: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(5)
  },
  image: {
    maxWidth: '100%',
    maxHeight: 300,
    display: 'block',
    margin: '0 auto'
  },
  text: {
    color: theme.palette.common.white,
    textAlign: 'center'
  }
}))

export function HubEventsReviewCelebrationModal({
  show,
  onClose
}: HubEventsReviewCelebrationModalProps): FunctionComponentElement<HubEventsReviewCelebrationModalProps> {
  const classes = useStyles()

  const [giphyImage, setGiphyImage] = useState<GiphyImage | null>(null)

  function generateConfetti(canvas: HTMLCanvasElement): void {
    const jsConfetti = new JSConfetti({ canvas })
    jsConfetti.addConfetti({
      confettiNumber: Math.random() * 1000
    })
  }

  function getHubEventReviewsCounter(): number {
    return getCurrentUserSessionAchievements().hubEventReviews ?? 0
  }

  const canvasRef = useCallback(
    (canvas) => {
      if (canvas && giphyImage) {
        setTimeout(() => {
          generateConfetti(canvas)
        }, 500)

        setTimeout(() => {
          generateConfetti(canvas)
        }, 2500)

        setTimeout(() => {
          generateConfetti(canvas)
        }, 4500)
      }
    },
    [giphyImage]
  )

  useEffect(() => {
    ;(async function () {
      setGiphyImage(await getRandomGiphy('party'))
    })()
  }, [])

  return (
    <Box className={classes.achievementModal}>
      <ClnModal
        append={
          <>
            <canvas ref={canvasRef} className={classes.canvas} />
          </>
        }
        content={
          <Box className={classes.content}>
            {giphyImage ? (
              <>
                <img
                  className={classes.image}
                  alt="Party"
                  src={giphyImage.webp}
                />

                <Box mt={5} mb={2}>
                  <Typography className={classes.text} variant="h2">
                    Wow, {getHubEventReviewsCounter()} hub event reviews in a
                    row!
                  </Typography>
                </Box>

                <Typography className={classes.text}>
                  A bajillion more to go! You’re doing amazing{' '}
                  {getCurrentUser()?.firstName ?? 'unknown person'}, you are
                  officially a Hub Event Hunter! Close your eyes for a second
                  and imagine you’re right at the beach, warm breeze on your
                  skin, you can smell the ocean, there’s a margarita right on
                  your hands... Enjoy it for a bit before you get back at
                  bringing down more Hub Events! You rock 🚀
                </Typography>
              </>
            ) : (
              <Box display="flex" justifyContent="center" p={3}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        }
        contentSpacing={false}
        onClose={onClose}
        show={show}
      />
    </Box>
  )
}
