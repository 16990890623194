import { QuantityUnit, QuantityUnits } from '../quantityunit'

export enum ProductType {
  SCRAP = 'SCRAP',
  PELLET = 'PELLET',
  CHIP = 'CHIP',
  OIL = 'OIL',

  BLOCK_SOLID_6 = 'BLOCK_SOLID_6',
  BLOCK_HOLLOW_6 = 'BLOCK_HOLLOW_6',
  BLOCK_INTERLOCKING_6 = 'BLOCK_INTERLOCKING_6',
  BLOCK_INTERLOCKING_4 = 'BLOCK_INTERLOCKING_4',

  TILE_RECTANGULAR_PAVING = 'TILE_RECTANGULAR_PAVING',
  TILE_ROOFING = 'TILE_ROOFING'
}

export function getQuantityUnitByProductType(
  productType: ProductType | null,
  defaultUnit: QuantityUnit = QuantityUnit.KG
): QuantityUnit {
  return ProductType.OIL === productType ? QuantityUnit.LITER : defaultUnit
}

export function getQuantityUnitSymbolByProductType(
  productType: ProductType | null,
  defaultUnit: string = QuantityUnits.KG
): string {
  return ProductType.OIL === productType ? QuantityUnits.LITER : defaultUnit
}
