import { Box, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { ChangeEvent, FunctionComponentElement } from 'react'

import { Theme } from 'common/theme'
import { SearchInput } from 'components'
import { getSecurityRoleOptions } from '../../../../services/user'

interface UsersToolbarProps {
  onFilterChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => any
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'inline-flex',

    '& .MuiOutlinedInput-input': {
      padding: '11px 15px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-8px',
      left: '19px',
      color: '#ccced2'
    }
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectField: {
    width: 185,
    maxWidth: '100%',
    marginLeft: theme.spacing(),
    paddingLeft: theme.spacing(1)
  },
  searchInputName: {
    display: 'flex',
    alignItems: 'center',
    width: 350
  },
  searchInputCompanyName: {
    display: 'flex',
    alignItems: 'center',
    width: 300,
    marginLeft: 15
  }
}))

export function UsersToolbar({
  onFilterChange
}: UsersToolbarProps): FunctionComponentElement<UsersToolbarProps> {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.row}>
        <Box className={classes.searchInputName}>
          <SearchInput
            placeholder="Search by name, email or phone number ..."
            onChange={(event) => onFilterChange(event, 'nameEmailPhone')}
          />
        </Box>
        <Box className={classes.searchInputCompanyName}>
          <SearchInput
            placeholder="Search by company name ..."
            onChange={(event) => onFilterChange(event, 'company')}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <TextField
            name="searchByRole"
            select
            label="Search by role"
            variant="outlined"
            className={classes.selectField}
            SelectProps={{ native: true }}
            onChange={(event) => onFilterChange(event, 'role')}
          >
            {getSecurityRoleOptions()}
          </TextField>
        </Box>
      </Box>
    </Box>
  )
}
