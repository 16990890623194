export default {
  root: {
    '&$expanded': {
      margin: 0,

      '&:last-child': {
        marginBottom: 0
      }
    }
  }
}
