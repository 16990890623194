import * as currencies from 'iso-country-currency'
import { Currency } from 'iso-country-currency'

export interface CurrencyOption {
  label: string
  value: string
}

function getAllCurrencies(): Currency[] {
  return currencies.getAllISOCodes()
}

function getAllUniqueCurrencies(): string[] {
  const allCurrencies = currencies
    .getAllISOCodes()
    .map((isoCode) => isoCode.currency)

  return Array.from(new Set(allCurrencies))
}

const uniqueCurrencies: string[] = getAllUniqueCurrencies()

export const getCurrencyOptions = (): CurrencyOption[] => {
  return uniqueCurrencies.map((currency) => ({
    value: currency,
    label: currency
  }))
}

export function getCurrencySymbol(currencyValue: string): string | null {
  const currency = getAllCurrencies().find(
    (currency) => currency.currency === currencyValue
  )
  return currency ? currency.symbol : null
}
