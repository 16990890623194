import React, { useState } from 'react'

// Externals
import classNames from 'classnames'

// Material components
import {
  CircularProgress,
  withStyles
} from '@material-ui/core'

import { SegmentedControl } from 'segmented-control'

import {
  LocalShipping as LocalShippingIcon
} from '@material-ui/icons'

// Component styles
import styles from './styles'

import { useTranslation } from 'react-i18next'

import { SummaryLine } from 'components'

import { getDisplayedQuantityUnit } from 'services/quantityunit'
import { useFetchState } from 'lib/fetch'
import { getCurrentUser } from 'services/user'
import LinkButton from 'components/LinkButton'
import { Feature } from '../../../../../services/feature'

function RecyclerRegistration (props) {
  const { classes, className } = props

  const rootClassName = classNames(classes.root, className)

  const [truckLoadsSummary, isLoading] = useFetchState('/api/registeredMaterials/truckLoadsSummary', null)

  const { allowUnregisteredSuppliers } = getCurrentUser()

  const [allowUnregisteredSuppliersChecked, setAllowUnregisteredSuppliersChecked] = useState(false)

  const { features } = getCurrentUser()
  const featureSet = new Set(features)

  const { t } = useTranslation()

  if (isLoading || !truckLoadsSummary) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  const displayedQuantityUnit = getDisplayedQuantityUnit(truckLoadsSummary.quantityUnit)

  return (
    <div className={rootClassName}>
      <div className={classes.content}>
        <SummaryLine
          title={t('truckLoadsCount')}
          value={truckLoadsSummary.truckLoadsCount}
        />
        <SummaryLine
          title={t('registeredTotal')}
          value={<>{truckLoadsSummary.formattedRegisteredTotal} {displayedQuantityUnit}</>}
        />
        {featureSet.has(Feature.MATERIAL_SEGREGATION) && (
          <SummaryLine
            title={t('gradingTotal')}
            value={<>{truckLoadsSummary.formattedGradingTotal} {displayedQuantityUnit}</>}
          />
        )}
        {featureSet.has(Feature.MATERIAL_PROCESSING) && (
          <SummaryLine
            title={t('processingTotal')}
            value={<>{truckLoadsSummary.formattedProcessingTotal} {displayedQuantityUnit}</>}
          />
        )}
        {truckLoadsSummary.materialTypeBreakdown && Object.keys(truckLoadsSummary.materialTypeBreakdown).map(materialType => (
          <SummaryLine
            key={materialType}
            title={t(`materialType_${materialType}`)}
            value={<>{truckLoadsSummary.materialTypeBreakdown[materialType].toFixed(2)} {displayedQuantityUnit}</>}
          />
        ))}

        {allowUnregisteredSuppliers && (
          <SummaryLine
            title={t('registeredSupplier')}
            value={
              <SegmentedControl
                name='allowUnregisteredSuppliers'
                className={classes.allowUnregisteredSuppliersSegmentedControl}
                options={[
                  { label: t('no'), value: false, default: true },
                  { label: t('yes'), value: true }
                ]}
                setValue={setAllowUnregisteredSuppliersChecked}
              />
            }
          />
        )}

        <LinkButton
          size='large'
          variant='contained'
          className={classes.registerTruckLoadButton}
          href={`/register-truck-load?cleanhubSupplier=${!allowUnregisteredSuppliers || allowUnregisteredSuppliersChecked}`}
          startIcon={<LocalShippingIcon
            style={{
              height: 30,
              width: 30,
              fontWeight: 30
            }}
          />}
        >
          {t('registerTruckLoadButton')}
        </LinkButton>
      </div>
    </div>
  )
}

export default withStyles(styles)(RecyclerRegistration)
