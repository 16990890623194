import MomentUtils from '@date-io/moment'
import { Box, makeStyles, TextField } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import React, { FunctionComponentElement } from 'react'

import { Theme } from '../../common/theme'

import {
  EntityFilterField,
  EntityFilterOperator,
  EntityFilterType
} from './entity-filters.types'
import { EntityFilterOperatorSelector } from './EntityFilterOperatorSelector'

export interface EntityFilterDateConfig {
  availableOperators?: EntityFilterDateConfig['operator'][]
  field: EntityFilterField
  label: string
  operator:
    | EntityFilterOperator.EQUALS
    | EntityFilterOperator.GREATER_THAN_OR_EQUAL
    | EntityFilterOperator.LESS_THAN_OR_EQUAL
  type: EntityFilterType.DATE
  value: string | null
  valueProcessor?: (value: string | null) => string | null
}

export interface EntityFilterDateProps {
  config: EntityFilterDateConfig

  onChange: (value: string | null, operator: EntityFilterOperator) => void
}

const useStyles = makeStyles<Theme>(() => ({
  entityFilterDate: {
    //
  }
}))

export function EntityFilterDate({
  config,
  onChange
}: EntityFilterDateProps): FunctionComponentElement<EntityFilterDateProps> {
  const classes = useStyles()

  return (
    <Box className={classes.entityFilterDate}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          clearable={true}
          format="DD/MM/YYYY"
          label={config.label}
          value={config.value}
          InputProps={{ readOnly: true }}
          TextFieldComponent={(props) => (
            <TextField
              {...props}
              fullWidth={true}
              size="small"
              variant="outlined"
              InputProps={{
                ...props.InputProps,
                startAdornment: (
                  <EntityFilterOperatorSelector
                    availableOperators={config.availableOperators}
                    operator={config.operator}
                    onChange={(operator) =>
                      onChange(
                        operator === EntityFilterOperator.IS_EMPTY
                          ? ''
                          : config.value,
                        operator
                      )
                    }
                  />
                )
              }}
            />
          )}
          onChange={(value) => {
            const normalizedValue = value ? value.toISOString() : null

            onChange(
              config.valueProcessor
                ? config.valueProcessor(normalizedValue)
                : normalizedValue,
              config.operator
            )
          }}
        />
      </MuiPickersUtilsProvider>
    </Box>
  )
}
