import { MaterialType } from '../materialtype/material-type.types'
import { ProductType } from '../producttype/product-type.types'
import { QuantityUnit } from '../quantityunit'

export enum MassBalanceAccount {
  REGISTRATION = 'REGISTRATION',
  SEGREGATION = 'SEGREGATION',
  PROCESSING = 'PROCESSING',
  CLEARANCE = 'CLEARANCE',
  CERTIFICATION = 'CERTIFICATION'
}

export interface MassBalanceOverviewDto {
  account: MassBalanceAccount
  startDate: string
  endDate: string

  total: number | null
  outputTypeBreakdown: MassBalanceOutputTypeBreakdownEntry[]
  quantityUnit: QuantityUnit
}

export interface MassBalanceOutputTypeBreakdownEntry {
  materialType: MaterialType
  productType: ProductType | null
  weight: number
}

export interface ClearableMassBalanceOverviewDto {
  clearableTypeBreakdown: MassBalanceOutputTypeBreakdownEntry[]
  quantityUnit: QuantityUnit
}

export interface ClearanceMassBalanceSummary {
  clearedTypeBreakdown: MassBalanceOutputTypeBreakdownEntry[]
  quantityUnit: QuantityUnit
  stockedTypeBreakdown: MassBalanceOutputTypeBreakdownEntry[]
}
