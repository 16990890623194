import { MinimalFileUploadDto } from 'services/fileupload/file-upload.types'

export interface MaterialClearanceDto {
  uuid: string
  createdAt: string
  transporterLicensePlate: string
  buyer: string
  transporterPicture: MinimalFileUploadDto
  weighSlipPicture: MinimalFileUploadDto
}

export enum MaterialClearanceStates {
  LOADED = 'LOADED',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED'
}
