import { USER_ROLES_KEY } from '../../lib/auth0'
import { Auth0Role } from '../../types/auth0-role.enum'

import { getReleaseStage } from '../environment'
import { StorageKey } from '../storage'

export const getHomeScreen = (auth0User) => {
  let roles = auth0User?.[USER_ROLES_KEY] ?? []

  const impersonationRole = localStorage.getItem(StorageKey.IMPERSONATION_ROLE)

  if (impersonationRole && roles.includes(Auth0Role.ROLE_SYSTEM_USER)) {
    roles = []
    roles.push(impersonationRole)
  }

  if (roles.includes(Auth0Role.ROLE_CLEANHUB_ADMIN)) {
    return '/admin/hubs'
  }

  if (roles.includes(Auth0Role.ROLE_HUB_OWNER)) {
    const path = '/hub-portal/pipeline-overview'

    return getReleaseStage() === 'dev' ? `http://localhost:3003${path}` : path
  }

  if (roles.includes(Auth0Role.ROLE_HUB_MANAGER)) {
    return '/hub-overview'
  }

  throw new Error(`No home screen defined for roles: '${roles}'`)
}

export function redirectToHomeScreen(history, auth0User) {
  const homeScreen = getHomeScreen(auth0User)
  if (
    // external links need to be handled outside the SPA router
    homeScreen.startsWith('http') ||
    // in case of /admin routes
    // we need to do a "hard" redirect, not a SPA one using the router
    // because we want to protect /admin/... routes with
    // Cloudflare Zero Trust and CF cannot protect client-side navigation
    homeScreen.startsWith('/admin') ||
    // /hub-portal routes cannot be handled as SPA links either
    // because there are no /hub-portal/... links registered within
    // this React SPA and redirects to such pages end on a 404 page
    homeScreen.startsWith('/hub-portal')
  ) {
    window.location.href = homeScreen
  } else {
    history.push(homeScreen)
  }
}
