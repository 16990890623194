import { Reducer } from 'react'

export type ErrorState = {
  alertBars: number[]
  errorMessage: string | null
  errorMessageKey: string | null
}

export enum ErrorActionType {
  ADD_ERROR = 'ADD_ERROR',
  DEREGISTER_ALERT_BAR = 'DEREGISTER_ALERT_BAR',
  REGISTER_ALERT_BAR = 'REGISTER_ALERT_BAR',
  RESET_ERROR = 'RESET_ERROR',
  SET_SESSION_EXPIRED = 'SET_SESSION_EXPIRED'
}

export interface ErrorActionAddError {
  type: ErrorActionType.ADD_ERROR
  value: {
    errorMessage: string | null
    errorMessageKey: string | null
  }
}

export interface ErrorActionDeregisterAlertBar {
  type: ErrorActionType.DEREGISTER_ALERT_BAR
  value: number
}

export interface ErrorActionRegisterAlertBar {
  type: ErrorActionType.REGISTER_ALERT_BAR
  value: number
}

export interface ErrorActionResetError {
  type: ErrorActionType.RESET_ERROR
}

export interface ErrorActionSetSessionExpired {
  type: ErrorActionType.SET_SESSION_EXPIRED
  value: boolean
}

export type ErrorActions =
  | ErrorActionAddError
  | ErrorActionDeregisterAlertBar
  | ErrorActionRegisterAlertBar
  | ErrorActionResetError
  | ErrorActionSetSessionExpired

export const errorReducer: Reducer<ErrorState, ErrorActions> = (
  state,
  action
) => {
  switch (action.type) {
    case ErrorActionType.ADD_ERROR:
      return {
        ...state,
        errorMessage: action.value.errorMessage,
        errorMessageKey: action.value.errorMessageKey
      }

    case ErrorActionType.DEREGISTER_ALERT_BAR:
      return {
        ...state,
        alertBars: state.alertBars.filter((item) => item !== action.value)
      }

    case ErrorActionType.REGISTER_ALERT_BAR:
      return {
        ...state,
        alertBars: [action.value, ...state.alertBars]
      }

    case ErrorActionType.RESET_ERROR:
      return {
        ...state,
        errorMessage: null,
        errorMessageKey: null
      }

    case ErrorActionType.SET_SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: action.value
      }

    default:
      return state
  }
}
