import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  Radio
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import cx from 'classnames'
import React, { FunctionComponentElement } from 'react'

import { Theme } from '../common/theme'

interface InputRadioProps {
  disabled?: boolean
  label: FormControlLabelProps['label']
  selected: boolean
  value: any
}

const useStyles = makeStyles<Theme>((theme) => ({
  inputRadio: {
    //
  },
  radioOption: {
    display: 'flex',
    margin: theme.spacing(0, 0, 1),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(1),
    transition: 'border-color 300ms'
  },
  radioOptionLabel: {
    width: '100%',
    display: 'block',
    paddingRight: theme.spacing(1)
  },
  radioOptionDisabled: {
    opacity: 0.5,
    cursor: 'default'
  },
  radioOptionSelected: {
    borderColor: theme.palette.primary.main
  }
}))

export function InputRadio({
  disabled,
  label,
  selected,
  value
}: InputRadioProps): FunctionComponentElement<InputRadioProps> {
  const classes = useStyles()

  return (
    <Box className={classes.inputRadio}>
      <FormControlLabel
        className={cx(classes.radioOption, {
          [classes.radioOptionDisabled]: disabled,
          [classes.radioOptionSelected]: selected
        })}
        disabled={disabled}
        value={value}
        control={<Radio checked={selected} color="primary" />}
        label={label}
        classes={{
          label: classes.radioOptionLabel
        }}
      />
    </Box>
  )
}
