import validate from 'validate.js'
import { FormEvent, useEffect, useState } from 'react'

export interface FieldError {
  message: string

  [key: string]: any
}

export type FieldSchema = {
  [key: string]: FieldError | boolean
}

export interface FormState<T> {
  errors: {
    [key in keyof T]?: string[]
  }
  isValid: boolean
  isSubmitted: boolean
  touched: {
    [key in keyof T]?: boolean
  }
  values: T
}

export interface UseForm<T> {
  formState: FormState<T>
  getErrorMessage: (field: keyof T) => string
  handleChange: (event: FormEvent) => void
  handleSubmit: (event: FormEvent, cb: () => Promise<void>) => void
  hasError: (field: keyof T) => boolean
  resetForm: (values?: T) => void
  setValue: <K extends keyof T>(name: K, value: T[K]) => void
  setValues: (values: T) => void
}

export type Schema<T> = {
  [key in keyof T]?: FieldSchema
}

export interface SelectOption<T> {
  label: string
  value: T
}

export const useForm = <T extends { [key: string]: any }>(
  schema: Schema<T>,
  values: T
): UseForm<T> => {
  const touched = {}
  const errors = {}
  const initValues = { ...values }
  const [formState, setFormState] = useState<FormState<T>>({
    isSubmitted: false,
    isValid: false,
    values,
    touched,
    errors
  })

  useEffect(() => {
    const errors =
      validate(formState.values, schema, {
        fullMessages: false
      }) || {}

    setFormState((formState) => ({
      ...formState,
      isValid: Object.entries(errors).length === 0,
      errors
    }))
  }, [schema, formState.values])

  const handleChange = (event) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleSubmit = (event, cb) => {
    event.preventDefault()

    setFormState((formState) => ({
      ...formState,
      isSubmitted: true
    }))

    if (cb && formState.isValid) {
      cb()
    }
  }

  const hasError = (field: keyof T): boolean =>
    !!(
      (formState.touched[field] || formState.isSubmitted) &&
      formState.errors[field]
    )

  const getErrorMessage = (field: keyof T): string => {
    if (hasError(field)) {
      return formState.errors[field][0]
    }
    return ''
  }

  function resetForm(values?: T) {
    setFormState({
      values: values ?? initValues,
      isSubmitted: false,
      isValid: false,
      touched: {},
      errors: {}
    })
  }

  function setValues(values) {
    setFormState((formState) => ({
      ...formState,
      values
    }))
  }

  function setValue(name, value) {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value
      }
    }))
  }

  return {
    formState,
    getErrorMessage,
    handleChange,
    handleSubmit,
    hasError,
    resetForm,
    setValue,
    setValues
  }
}
