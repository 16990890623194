import React, { ReactElement } from 'react'

export enum QuantityUnit {
  KG = 'KG',
  TON = 'TON',
  KM = 'KM',
  LITER = 'LITER',
  CALORIES_PER_GRAM = 'CALORIES_PER_GRAM',
  CUBIC_METER = 'CUBIC_METER'
}

export const QuantityUnits = Object.freeze({
  [QuantityUnit.KG]: 'kg',
  [QuantityUnit.TON]: 't',
  [QuantityUnit.KM]: 'km',
  [QuantityUnit.LITER]: 'l',
  [QuantityUnit.CALORIES_PER_GRAM]: 'cal/gr',
  [QuantityUnit.CUBIC_METER]: 'm³'
})

export const getDisplayedQuantityUnit = (
  quantityUnit: QuantityUnit
): ReactElement => {
  return (
    <>
      {QuantityUnits[quantityUnit] && (
        <span>{QuantityUnits[quantityUnit]}</span>
      )}

      {!QuantityUnits[quantityUnit] && (
        <span style={{ fontWeight: 'bold' }}> &#13221;</span>
      )}
    </>
  )
}
