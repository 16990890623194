import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import React, { FunctionComponentElement, useEffect, useState } from 'react'

import { Theme } from '../../../common/theme'
import {
  EntityFilterConfig,
  EntityFilterField,
  EntityFilterOperator,
  EntityFilterType
} from '../../../components/EntityFilters'
import { EntityFilters } from '../../../components/EntityFilters/EntityFilters'
import { CollectorStates, CollectorType } from '../../../services/collector'

export interface CollectorsFiltersProps {
  hubUuid: string

  onChange: (filters: EntityFilterConfig[]) => void
}

const useStyles = makeStyles<Theme>(() => ({
  collectorsFilters: {
    //
  }
}))

const collectorStateOptions = [
  { label: 'Assigned', value: CollectorStates.DEFINED },
  { label: 'Unassigned', value: CollectorStates.UNDEFINED }
]

const collectorTypeOptions = [
  { label: 'Household', value: CollectorType.HOUSEHOLD },
  { label: 'Individual', value: CollectorType.INDIVIDUAL },
  { label: 'Collection Point', value: CollectorType.COLLECTION_POINT }
]

export function CollectorsFilters({
  hubUuid,
  onChange
}: CollectorsFiltersProps): FunctionComponentElement<CollectorsFiltersProps> {
  const classes = useStyles()

  const [filters, setFilters] = useState<EntityFilterConfig[]>([
    {
      field: EntityFilterField.COLLECTOR_TYPE,
      label: 'Type',
      operator: EntityFilterOperator.EQUALS,
      options: collectorTypeOptions,
      type: EntityFilterType.SELECT,
      value: null
    },
    {
      field: EntityFilterField.COLLECTOR_STATE,
      label: 'State',
      operator: EntityFilterOperator.EQUALS,
      options: collectorStateOptions,
      type: EntityFilterType.SELECT,
      value: null
    },
    {
      field: EntityFilterField.COLLECTOR_TRACKING_NUMBER,
      label: 'Tracking number',
      operator: EntityFilterOperator.CONTAINS,
      type: EntityFilterType.TEXT,
      value: ''
    }
  ])

  useEffect(() => {
    onChange([
      {
        field: EntityFilterField.COLLECTOR_HUB_UUID,
        label: '',
        operator: EntityFilterOperator.EQUALS,
        type: EntityFilterType.TEXT,
        value: hubUuid
      },
      ...filters
    ])
  }, [filters])

  return (
    <Box className={classes.collectorsFilters}>
      {filters.length ? (
        <EntityFilters filters={filters} onChange={setFilters} />
      ) : (
        <Box display="flex" justifyContent="center" p={5}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}
