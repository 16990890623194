import { CircularProgress, Typography, withStyles } from '@material-ui/core'
import { LocalShipping as LocalShippingIcon } from '@material-ui/icons'
import classNames from 'classnames'
import { SegmentedControl } from 'segmented-control'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as BagIcon } from 'icons/bag.svg'
import { ReactComponent as ContainerIcon } from 'icons/container.svg'
import { SummaryLine } from 'components'
import { getDisplayedQuantityUnit } from 'services/quantityunit'
import FlowActionButton from 'components/FlowActionButton'
import { CollectedMaterialsApiService } from 'services/collected-materials/collected-materials-api.service'
import { getCurrentUser } from 'services/user'
import { Feature, hasOnlyOneRegistrationEnabled } from 'services/feature'
import {
  RegistrationUnit,
  getRegistrationUnitOptions
} from 'services/registrationunit'

// Component styles
import styles from './styles'

function CollectionCenterRegistration(props) {
  const { classes, className } = props

  const rootClassName = classNames(classes.root, className)

  const [summary, isLoading] = CollectedMaterialsApiService.getSummary()
  const [selectedRegistrationUnit, setSelectedRegistrationUnit] = useState(
    RegistrationUnit.BAG
  )

  const { allowUnregisteredSuppliers } = getCurrentUser()

  const [
    allowUnregisteredSuppliersChecked,
    setAllowUnregisteredSuppliersChecked
  ] = useState(false)

  const { t } = useTranslation()

  if (isLoading || !summary) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  function handleChangeRegistrationUnit(newValue) {
    setSelectedRegistrationUnit(newValue)
  }

  return (
    <div className={rootClassName}>
      <div className={classes.content}>
        <SummaryLine
          title={t('collectionCenter')}
          value={summary.collectionCenter ? summary.collectionCenter.name : ''}
        />
        {Object.keys(summary.materialTypeBreakdown).map((materialType) => (
          <SummaryLine
            key={materialType}
            title={t(`materialType_${materialType}`)}
            value={
              <>
                {summary.materialTypeBreakdown[materialType].toFixed(2)}{' '}
                {getDisplayedQuantityUnit(summary.totalStockUnit)}
              </>
            }
          />
        ))}
        <SummaryLine
          title={t('totalStock')}
          value={
            <>
              {summary.formattedTotalStock}{' '}
              {getDisplayedQuantityUnit(summary.totalStockUnit)}
            </>
          }
        />

        {!hasOnlyOneRegistrationEnabled() && (
          <div className={classes.registrationUnit}>
            <Typography className={classes.registrationUnitTitle} variant="h5">
              {t('registrationUnit')}:
            </Typography>
            <SegmentedControl
              name="selectedRegistrationUnit"
              className={classes.registrationUnitSegmentedControl}
              options={getRegistrationUnitOptions(t)}
              setValue={handleChangeRegistrationUnit}
            />
          </div>
        )}

        {allowUnregisteredSuppliers &&
          (selectedRegistrationUnit === RegistrationUnit.TRUCK_LOAD ||
            hasOnlyOneRegistrationEnabled(Feature.TRUCK_LOAD_REGISTRATION)) && (
            <SummaryLine
              title={t('registeredSupplier')}
              value={
                <SegmentedControl
                  name="allowUnregisteredSuppliers"
                  className={classes.allowUnregisteredSuppliersSegmentedControl}
                  options={[
                    { label: t('no'), value: false },
                    { label: t('yes'), value: true, default: true }
                  ]}
                  setValue={setAllowUnregisteredSuppliersChecked}
                />
              }
            />
          )}
      </div>

      {(hasOnlyOneRegistrationEnabled(Feature.WASTE_BAG_REGISTRATION) ||
        (!hasOnlyOneRegistrationEnabled() &&
          selectedRegistrationUnit === RegistrationUnit.BAG)) && (
        <FlowActionButton
          color="secondary"
          icon={BagIcon}
          href="/register-material-bag"
        >
          {t('registerMaterialBagButton')}
        </FlowActionButton>
      )}

      {(hasOnlyOneRegistrationEnabled(Feature.TRUCK_LOAD_REGISTRATION) ||
        (!hasOnlyOneRegistrationEnabled() &&
          selectedRegistrationUnit === RegistrationUnit.TRUCK_LOAD)) && (
        <FlowActionButton
          color="secondary"
          icon={LocalShippingIcon}
          href={`/register-material-truck-load?cleanhubSupplier=${
            !allowUnregisteredSuppliers || allowUnregisteredSuppliersChecked
          }`}
        >
          {t('registerTruckLoadButton')}
        </FlowActionButton>
      )}

      {(hasOnlyOneRegistrationEnabled(Feature.CONTAINER_REGISTRATION) ||
        (!hasOnlyOneRegistrationEnabled() &&
          selectedRegistrationUnit === RegistrationUnit.CONTAINER)) && (
        <FlowActionButton
          color="secondary"
          icon={ContainerIcon}
          href="/register-material-container"
        >
          {t('registerContainerButton')}
        </FlowActionButton>
      )}
    </div>
  )
}

export default withStyles(styles)(CollectionCenterRegistration)
