import { Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DropzoneArea } from 'material-ui-dropzone'
import React, { FunctionComponentElement, useState } from 'react'

import { Theme } from 'common/theme'

import { uploadFileWithDispatch } from '../services/fileupload'
import { FileUploadDto } from '../services/fileupload/file-upload.types'
import { useStateValue } from '../state'

import { UploadedFile } from './UploadedFile'

interface DocumentWithoutLocationUploadProps {
  acceptedFiles: string[]
  dropzoneText: string
  maxFileSize: number

  onClear: () => any
  onUpload: (fileUpload: FileUploadDto) => any
}

const useStyles = makeStyles<Theme>((theme) => ({
  documentWithoutLocationUpload: {
    maxWidth: 500,
    marginBottom: theme.spacing(1)
  },
  dropzoneAreaRoot: {
    minHeight: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 2, 2)
  }
}))

export function DocumentWithoutLocationUpload({
  acceptedFiles,
  dropzoneText,
  maxFileSize,
  onClear,
  onUpload
}: DocumentWithoutLocationUploadProps): FunctionComponentElement<DocumentWithoutLocationUploadProps> {
  const classes = useStyles()

  const [, dispatch] = useStateValue()

  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [fileUpload, setFileUpload] = useState<FileUploadDto | null>(null)

  async function handleDropzoneChange(files: File[]): Promise<void> {
    if (files.length !== 1) {
      return
    }

    setIsUploading(true)

    const uploadResult = await uploadFileWithDispatch(dispatch, files[0])

    if (uploadResult) {
      setFileUpload(uploadResult)
      onUpload(uploadResult)
    }

    setIsUploading(false)
  }

  function handleFileUploadClear(): void {
    setFileUpload(null)
    onClear()
  }

  if (fileUpload) {
    return (
      <Box className={classes.documentWithoutLocationUpload}>
        <UploadedFile
          fileUploads={[fileUpload]}
          onClearFile={handleFileUploadClear}
        />
      </Box>
    )
  }

  if (isUploading) {
    return (
      <Box className={classes.documentWithoutLocationUpload}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.documentWithoutLocationUpload}>
      <DropzoneArea
        acceptedFiles={acceptedFiles}
        dropzoneText={dropzoneText}
        maxFileSize={maxFileSize}
        filesLimit={1}
        onChange={handleDropzoneChange}
        showPreviewsInDropzone={false}
        showAlerts={true}
        classes={{
          root: classes.dropzoneAreaRoot
        }}
      />
    </Box>
  )
}
