import { UseFetchState, useFetchState } from 'lib/fetch'
import {
  HandlingCenterMaterialClearanceOverviewDto,
  HandlingCenterMaterialSummaryDto
} from './handling-center-material-types'

export class HandlingCenterMaterialApiService {
  public static readonly BASE_URL = '/api/handlingCenterMaterials'

  public static getSummary(): UseFetchState<HandlingCenterMaterialSummaryDto> {
    return useFetchState(`${HandlingCenterMaterialApiService.BASE_URL}/summary`)
  }

  public static getClearanceOverview(): UseFetchState<HandlingCenterMaterialClearanceOverviewDto> {
    return useFetchState<HandlingCenterMaterialClearanceOverviewDto>(
      `${HandlingCenterMaterialApiService.BASE_URL}/clearanceOverview`,
      null
    )
  }
}
