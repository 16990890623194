import { white, black } from '../common/colors'

export default {
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4'
  },
  primary: {
    50: '#e2f0ff',
    100: '#badbff',
    200: '#88c1f7',
    300: '#5ab1ff',
    400: '#2693ff',
    500: '#1981ff',
    600: '#216eea',
    700: '#255ed8',
    800: '#284ab8',
    900: '#1a3399',
    contrastText: white,
    dark: '#255ed8',
    light: '#5ab1ff',
    main: '#1981ff'
  },
  secondary: {
    contrastText: white,
    dark: '#070c2e',
    light: '#32417d',
    main: '#181f4e'
  },
  orange: {
    50: '#fdf2d2',
    100: '#f6de9a',
    200: '#eccb6a',
    300: '#e2c24f',
    400: '#dcb542',
    500: '#d6a736',
    600: '#d89a2b',
    700: '#d98216',
    800: '#d97621',
    900: '#d96e2b',
    brand: '#fdf2d2',
    dark: '#d98216',
    light: '#e2c24f',
    main: '#d6a736'
  },
  success: {
    contrastText: white,
    main: '#45B880',
    light: '#F1FAF5',
    dark: '#00783E'
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489'
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E'
  },
  danger: {
    contrastText: white,
    main: '#ED4740',
    light: '#FFE4E4',
    dark: '#BF0E08'
  },
  text: {
    primary: '#12161B',
    secondary: '#66788A',
    disabled: '#A6B1BB'
  },
  background: {
    default: '#fafafa',
    dark: '#172B4D',
    paper: white
  },
  dashboard: {
    fill: {
      primary: '#026873',
      inverse: white
    },
    stroke: {
      primary: white,
      inverse: '#026873'
    },
    state: '#686868',
    secondary: '#f2916d',
    border: {
      primary: white,
      inverse: '#026873'
    }
  },
  border: '#DFE3E8',
  divider: '#DFE3E8'
}
