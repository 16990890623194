import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog
} from '@material-ui/core'

import { Transition } from 'components'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    marginTop: theme.spacing(1.5)
  },
  dialogAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default function InstructionDialog (props) {
  const {
    message,
    open,
    onClose,
    onContinue
  } = props

  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          size='large'
          onClick={onContinue}
          color='primary'
          variant='contained'
        >
          {t('continueButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
