import { LocalShipping as LocalShippingIcon } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { compose } from 'recompose'

// Material components
import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Typography
} from '@material-ui/core'

// Component styles
import { useFormStyles } from '../../../common/styles/form';
import { useGeneralStyles } from '../../../common/styles/general';
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'
import { useStyles } from './styles'

import { useStateValue } from 'state'
import schema from './schema'

import { useCamera } from 'lib/camera'

import { useTranslation } from 'react-i18next'

import { apiFetchWithDispatch } from 'lib/fetch'
import { useForm } from 'lib/form'

import { ImagePreview, FullScreenCamera } from 'components'
import LinkButton from '../../../components/LinkButton'

function StartRecyclerClearance (props) {
  const { history } = props

  const classes = useStyles();
  const formClasses = useFormStyles();
  const generalClasses = useGeneralStyles();

  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  const [, dispatch] = useStateValue()

  const transporterCamera = useCamera()

  const {
    formState: {
      values,
      errors,
      isValid
    },
    handleChange,
    resetForm,
    hasError
  } = useForm(schema, {
    transporterLicensePlate: '',
    buyer: ''
  })

  useEffect(() => {
    if (!transporterCamera.showCamera) {
      history.push('/hub-overview')
    }
  }, [transporterCamera.showCamera])

  async function handleSave () {
    setIsLoading(true)

    const { transporterLicensePlate, buyer } = values

    const response = await apiFetchWithDispatch(dispatch, '/api/clearanceTransports', {
      method: 'POST',
      body: JSON.stringify({
        transporterLicensePlate,
        buyer,
        transporterPictureFileUploadUuid: transporterCamera.pictureFileUpload
          ? transporterCamera.pictureFileUpload.uuid
          : null
      })
    })

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('startClearanceTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!transporterCamera.pictureConfirmed) {
    return (
      <FullScreenCamera
        key='transporter-picture'
        fileName={`start-recycler-clearance-transporter-${Date.now()}`}
        headerMessage={t('startClearancePictureExplanation')}
        shutterIcon={<LocalShippingIcon />}
        onTakePhotoFinished={transporterCamera.handleTakePhotoFinished}
        onFileUploadFinished={transporterCamera.handleFileUploadFinished}
        onConfirmPicture={transporterCamera.handleConfirmPicture}
        onCameraError={transporterCamera.handleCameraError}
      />
    )
  }

  return (
    <SecurePortalLayout title={t('startClearanceTitle')}>
      <PortalContent>
        <div className={classes.root}>
          <div className={generalClasses.content}>
            <form autoComplete='off' noValidate>
              <Paper className={formClasses.formSection}>
                <Typography
                  className={generalClasses.title}
                  variant='h3'
                >
                  {t('startClearanceTitle')}
                </Typography>

                <FormControl component='fieldset'>
                  <FormLabel component='legend' required>
                    {t('transporterPicture')}
                  </FormLabel>
                  <div className={generalClasses.imagePreview}>
                    <ImagePreview
                      dataUri={transporterCamera.pictureDataUri}
                      isPictureUploading={!transporterCamera.pictureFileUpload}
                    />
                  </div>
                </FormControl>

                <div className={formClasses.field}>
                  <TextField
                    name='transporterLicensePlate'
                    required
                    label={t('licensePlate')}
                    className={formClasses.textField}
                    value={values.transporterLicensePlate}
                    onChange={handleChange}
                    margin='dense'
                    error={hasError('transporterLicensePlate')}
                    helperText={hasError('transporterLicensePlate') ? t(errors.transporterLicensePlate[0]) : ''}
                  />
                </div>

                <div className={formClasses.field}>
                  <TextField
                    name='buyer'
                    label={t('buyer')}
                    className={formClasses.textField}
                    value={values.buyer}
                    onChange={handleChange}
                    margin='dense'
                    error={hasError('buyer')}
                    helperText={hasError('buyer') ? t(errors.buyer[0]) : ''}
                  />
                </div>

                <div className={formClasses.actionButtons}>
                  <LinkButton
                    size='large'
                    className={formClasses.cancelButton}
                    color='default'
                    variant='outlined'
                    href='/hub-overview'
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size='large'
                    variant='contained'
                    className={generalClasses.primaryButton}
                    disabled={!isValid || isLoading || !transporterCamera.pictureFileUpload}
                    onClick={handleSave}
                  >
                    {t('startClearanceButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </div>
        </div>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default compose(
  withRouter
)(StartRecyclerClearance)
