import React, { useCallback, useEffect, useState } from 'react'

// Material components
import { Box, withStyles } from '@material-ui/core'
import { Paper } from '../../../components'
import LinkButton from '../../../components/LinkButton'

// Shared layouts
import { AdminPortalLayout } from '../../../layouts/AdminPortalLayout'
import { PortalContent } from '../../../layouts/portal/PortalContent'

// Custom components
import { HubsTable, HubsToolbar } from './components'

// Component styles
import styles from './styles'

import { apiFetchWithDispatch } from 'lib/fetch'
import { useStateValue } from '../../../state'
import { CompanyState } from '../../../services/company'

function HubList(props) {
  const { classes } = props

  const [hubs, setHubs] = useState([])
  const [allHubs, setAllHubs] = useState([])
  const [filteredMap] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const [, dispatch] = useStateValue()

  const getHubs = useCallback(async () => {
    setIsLoading(true)

    const hubs = await apiFetchWithDispatch(
      dispatch,
      `/api/admin/companies/hubs?states=${[
        CompanyState.ACTIVE,
        CompanyState.PAUSED
      ]}`
    )

    setHubs(hubs)
    setAllHubs(hubs)
    setIsLoading(false)
  }, [dispatch])

  useEffect(() => {
    getHubs()
  }, [getHubs])

  function onUpdate() {
    getHubs()
  }

  function handleFilterHub(event, field) {
    const searchValue = event.target.value

    if (!searchValue || searchValue.length === 0) {
      delete filteredMap[field]

      if (Object.keys(filteredMap).length === 0) {
        setHubs(allHubs)
      } else {
        applyFilter()
      }
      return
    }

    filteredMap[field] = searchValue
    applyFilter()
  }

  function applyFilter() {
    let filteredList = allHubs
    for (const [field, value] of Object.entries(filteredMap)) {
      switch (field) {
        case 'name':
          filteredList = filteredList.filter((hub) =>
            hub.name.toLowerCase().includes(value.toLowerCase())
          )
          break
        case 'type':
          filteredList = filteredList.filter((hub) => hub.type === value)
          break
        case 'feature':
          filteredList = filteredList.filter((hub) =>
            hub.features.includes(value)
          )
          break
        case 'state':
          filteredList = filteredList.filter((hub) => hub.state === value)
          break
      }

      setHubs(filteredList)
    }
  }

  return (
    <AdminPortalLayout title="Hubs">
      <PortalContent
        loading={isLoading}
        titleActions={
          <LinkButton color="primary" variant="contained" href="/admin/add-hub">
            Add Hub
          </LinkButton>
        }
      >
        <Paper elevation={5} outlined={false}>
          <Box p={3}>
            <HubsToolbar onHubFilter={handleFilterHub} />

            <div className={classes.content}>
              {hubs && <HubsTable hubs={hubs} onUpdate={onUpdate} />}
            </div>
          </Box>
        </Paper>
      </PortalContent>
    </AdminPortalLayout>
  )
}

export default withStyles(styles)(HubList)
