import { init, setUser } from '@sentry/react'

import { getReleaseStage, ReleaseStage } from './environment'
import { IdentityDto } from './user'

export function initSentry(): void {
  init({
    dsn:
      'https://1b114552969b478e9b6497fa7364620d@o4504124090613760.ingest.sentry.io/4505006058962944',
    enabled: getReleaseStage() !== ReleaseStage.DEV,
    environment: getReleaseStage()
  })
}

export function addSentryUserMetadata(identity: IdentityDto | null): void {
  if (identity) {
    return setUser({
      // default properties
      id: identity.uuid,
      email: identity.email,
      username: identity.email,

      // custom properties
      'First Name': identity.firstName,
      'Last Name': identity.lastName,
      'Security Role': identity.securityRole,
      'Impersonated Sign-in': !!identity.impersonatedSignIn
    })
  }

  return setUser(null)
}
