import { Box, Button, CircularProgress, Grid } from '@material-ui/core'
import { Add as AddIcon, Done as DoneIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { apiFetchWithDispatch } from 'lib/fetch'
import React, { FunctionComponentElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LinkButton, SummaryLine } from 'components'

import { Theme } from '../../../../common/theme'
import { MaterialTypeBreakdown } from '../../../../components/MaterialTypeBreakdown'
import { MassBalanceOutputTypeBreakdownEntry } from '../../../../services/mass-balance'
import { ProcessingUnitDto } from '../../../../services/processingunit'
import { useStateValue } from '../../../../state'

interface RecyclerProcessingProps {
  processingUnit: ProcessingUnitDto

  onFinishProcessing: () => void
}

const useStyles = makeStyles<Theme>(() => ({
  recyclerProcessing: {
    //
  }
}))

export function RecyclerProcessing({
  processingUnit,
  onFinishProcessing
}: RecyclerProcessingProps): FunctionComponentElement<RecyclerProcessingProps> {
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()
  const [, dispatch] = useStateValue()

  const units = processingUnit
    ? processingUnit.outputMaterialBags.length > 1
      ? t('units')
      : t('unit')
    : ''

  function getMaterialTypeBreakdown(): MassBalanceOutputTypeBreakdownEntry[] {
    return processingUnit.outputMaterialBags.reduce((sum, item) => {
      const existingMaterialProductEntry = sum.find(
        (x) =>
          x.materialType === item.materialType &&
          x.productType === item.productType
      )
      if (existingMaterialProductEntry) {
        return sum.map((x) => {
          if (x === existingMaterialProductEntry) {
            return {
              ...existingMaterialProductEntry,
              weight: existingMaterialProductEntry.weight + item.weight
            }
          }
          return x
        })
      }

      return [
        ...sum,
        {
          materialType: item.materialType,
          productType: item.productType,
          weight: item.weight
        }
      ]
    }, [] as MassBalanceOutputTypeBreakdownEntry[])
  }

  async function handleFinishButtonClick() {
    setIsLoading(true)

    await apiFetchWithDispatch(
      dispatch,
      `/api/processingUnits/${processingUnit.uuid}/finish`,
      {
        method: 'POST'
      }
    )

    setIsLoading(false)

    onFinishProcessing()
  }

  if (isLoading) {
    return (
      <Box mb={3} textAlign="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.recyclerProcessing}>
      <Box mx={-1} mt={-3}>
        <MaterialTypeBreakdown
          outputTypeBreakdown={getMaterialTypeBreakdown()}
          quantityUnit={processingUnit.quantityUnit}
          totalDescription={`${processingUnit.outputMaterialBags.length} ${units}`}
          totalText={t('currentProcessingTitle')}
        />
        {processingUnit.inputTrackedItemsCount > 0 && (
          <SummaryLine
            title={t('inputTrackedItemsCount')}
            value={processingUnit.inputTrackedItemsCount}
          />
        )}
      </Box>

      <Box my={1}>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12} sm={6}>
            <LinkButton
              color="primary"
              fullWidth={true}
              href={`/processing-unit/${processingUnit.uuid}/register-output-material-bag`}
              size="large"
              startIcon={<AddIcon />}
              variant="outlined"
            >
              {t('registerMaterialButton')}
            </LinkButton>
          </Grid>

          <Grid item={true} xs={12} sm={6}>
            <Button
              color="primary"
              fullWidth={true}
              size="large"
              startIcon={<DoneIcon />}
              variant="contained"
              onClick={handleFinishButtonClick}
            >
              {t('finishProcessingButton')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
