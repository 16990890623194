import { makeStyles } from '@material-ui/core'

import { Theme } from '../../common/theme'

interface StyleProps {
  collapsed: boolean
}

const drawerWidth = 280
const drawerWidthCollapsed = 90

export const usePortalNavigationStyles = makeStyles<Theme, StyleProps>(
  (theme) => ({
    portalNavigation: {
      flexShrink: 0,

      [theme.breakpoints.up('lg')]: {
        width: ({ collapsed }) =>
          collapsed ? drawerWidthCollapsed : drawerWidth,
        transition: 'width 300ms'
      },

      '@media print': {
        display: 'none'
      }
    },
    appBarRoot: {
      backgroundColor: theme.palette.common.white
    },
    drawerPaper: {
      width: ({ collapsed }) =>
        collapsed ? drawerWidthCollapsed : drawerWidth,
      overflow: 'hidden',
      transition: 'width 300ms'
    },
    listItemGutters: {
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4)
    },
    listItemIconRoot: {
      minWidth: 40
    },
    listItemIconRootSelected: {
      color: theme.palette.common.black
    },
    listItemText: {
      whiteSpace: 'nowrap'
    },
    itemNameSelected: {
      color: theme.palette.common.black,
      fontWeight: 600
    },
    menuButton: {
      marginRight: 0
    },
    toggleWrapper: {
      width: 5,
      height: '100vh',
      position: 'absolute',
      top: 0,
      right: 0,

      '&:hover $toggleLine': {
        width: 2,
        opacity: 1
      },

      '&:hover $toggleTrigger': {
        right: 0
      }
    },
    toggleLine: {
      width: 0,
      height: '100%',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      top: 0,
      right: 0,
      opacity: 0,
      transition: 'opacity 200ms, width 200ms'
    },
    toggleTrigger: {
      height: 30,
      backgroundColor: theme.palette.primary.main,
      display: 'inline-flex',
      alignItems: 'center',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      marginTop: -15,
      position: 'absolute',
      top: '50%',
      right: -2,
      color: theme.palette.common.white,
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
      cursor: 'pointer',
      transition: 'right 200ms'
    }
  })
)
