import { Box, Button, Link, makeStyles } from '@material-ui/core'
import { InsertDriveFileOutlined as InsertDriveFileOutlinedIcon } from '@material-ui/icons'
import React, { FunctionComponentElement } from 'react'

import { EmptyState } from '../../../../components/EmptyState'
import {
  ClnModal,
  ClnModalActions,
  ClnModalContent
} from '../../../../components/modal'
import { ComplianceActionDto } from '../../../../services/compliance'

interface ViewUploadedEvidenceModalProps {
  complianceAction: ComplianceActionDto | null
  show: boolean
  onClose: () => void
}

const useStyles = makeStyles((theme) => ({
  uploadedFile: {
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.common.black,

    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  }
}))

export function ViewUploadedEvidenceModal({
  complianceAction,
  show,
  onClose
}: ViewUploadedEvidenceModalProps): FunctionComponentElement<ViewUploadedEvidenceModalProps> {
  const classes = useStyles()

  return (
    <Box>
      <ClnModal
        actions={
          <ClnModalActions>
            <Button color="default" variant="outlined" onClick={onClose}>
              Close
            </Button>
          </ClnModalActions>
        }
        content={
          <ClnModalContent>
            {complianceAction?.documents?.map((item) => (
              <Link
                key={item.uuid}
                className={classes.uploadedFile}
                href={item.fileUpload.directLink}
                target="_blank"
                underline="none"
              >
                <Box display="flex" alignItems="center" mr={1}>
                  <InsertDriveFileOutlinedIcon />
                </Box>

                {item.comment}
              </Link>
            ))}

            {complianceAction?.documents?.length === 0 && (
              <Box mt={3}>
                <EmptyState
                  heading="No evidence documents"
                  message="No evidence documents have been uploaded for this compliance action yet."
                />
              </Box>
            )}
          </ClnModalContent>
        }
        show={show}
        title="View uploaded evidence"
        onClose={onClose}
      />
    </Box>
  )
}
