import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, makeStyles } from '@material-ui/core'
import cx from 'classnames'
import React, { FunctionComponentElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Theme } from '../../common/theme'
import { NewLogo } from '../../components/NewLogo'
import { clearUserStorageData } from '../../services/storage/storage.helpers'

const useStyles = makeStyles<Theme>((theme) => ({
  signIn: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(0, 3)
  },
  signInButton: {
    padding: theme.spacing(0, 5),
    borderRadius: 24
  },
  signInFilledButton: {
    height: 44,
    backgroundColor: theme.palette.common.black,

    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  signInOutlinedButton: {
    height: 40,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderColor: theme.palette.common.black,
    borderWidth: 2,
    borderRadius: 24,

    '&:hover': {
      backgroundColor: theme.palette.border,
      borderColor: theme.palette.common.black,
      borderWidth: 2
    }
  }
}))

export function SignIn(): FunctionComponentElement<void> {
  const classes = useStyles()

  const {
    isAuthenticated,
    isLoading: isAuth0Loading,
    loginWithRedirect
  } = useAuth0()
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    // we want to always start with no legacy session
    clearUserStorageData()
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/auth/callback')
    }
  }, [isAuthenticated])

  return (
    <Box className={classes.signIn}>
      <Box display="flex" justifyContent="center" mb={8}>
        <NewLogo />
      </Box>

      <Button
        className={cx(classes.signInButton, classes.signInFilledButton)}
        color="primary"
        disabled={isAuth0Loading || isAuthenticated}
        disableElevation={true}
        size="large"
        variant="contained"
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              connection: 'sms'
            }
          })
        }
      >
        {t('signInWithPhoneNumberButton')}
      </Button>

      <Box mt={2}>
        <Button
          className={cx(classes.signInButton, classes.signInOutlinedButton)}
          color="primary"
          disabled={isAuth0Loading || isAuthenticated}
          disableElevation={true}
          size="large"
          variant="outlined"
          onClick={() =>
            loginWithRedirect({
              authorizationParams: {
                connection: 'Username-Password-Authentication'
              }
            })
          }
        >
          {t('signInWithEmailButton')}
        </Button>
      </Box>
    </Box>
  )
}
