import { useCallback } from 'react'

import { QuantityUnit, QuantityUnits } from '../services/quantityunit'

import { useFormatNumber } from './format-number.hook'

export type FormatQuantity = (
  value: FormatterValue,
  quantityUnit: QuantityUnit,
  options?: FormatterOptions
) => string

export interface FormatterOptions {
  dynamicOutputQuantityUnit?: boolean
  precision?: number
}

export type FormatterValue = number

export function useFormatQuantity(): FormatQuantity {
  const formatNumber = useFormatNumber()

  const isHighKgValue = useCallback(
    (value: number, quantityUnit: QuantityUnit) => {
      return quantityUnit === QuantityUnit.KG && value > 1000
    },
    []
  )

  const isLowTonValue = useCallback(
    (value: number, quantityUnit: QuantityUnit) => {
      return quantityUnit === QuantityUnit.TON && value < 1
    },
    []
  )

  const getNormalizedValue = useCallback(
    (
      value: number,
      quantityUnit: QuantityUnit,
      dynamicOutputQuantityUnit?: boolean
    ) => {
      if (dynamicOutputQuantityUnit) {
        if (isHighKgValue(value, quantityUnit)) {
          return value / 1000
        }

        if (isLowTonValue(value, quantityUnit)) {
          return value * 1000
        }
      }

      return value
    },
    [isHighKgValue, isLowTonValue]
  )

  const getNormalizedQuantityUnit = useCallback(
    (
      value: number,
      quantityUnit: QuantityUnit,
      dynamicOutputQuantityUnit?: boolean
    ) => {
      if (dynamicOutputQuantityUnit) {
        if (isHighKgValue(value, quantityUnit)) {
          return QuantityUnit.TON
        }

        if (isLowTonValue(value, quantityUnit)) {
          return QuantityUnit.KG
        }
      }

      return quantityUnit
    },
    [isHighKgValue, isLowTonValue]
  )

  return useCallback<FormatQuantity>(
    (value, quantityUnit, options) => {
      const normalizedValue = getNormalizedValue(
        value,
        quantityUnit,
        options?.dynamicOutputQuantityUnit
      )
      const normalizedQuantityUnit = getNormalizedQuantityUnit(
        value,
        quantityUnit,
        options?.dynamicOutputQuantityUnit
      )

      return `${formatNumber(normalizedValue, options)} ${
        QuantityUnits[normalizedQuantityUnit]
      }`
    },
    [formatNumber, getNormalizedQuantityUnit, getNormalizedValue]
  )
}
