import { Box, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import React, { FunctionComponentElement, useState } from 'react'

import { Theme } from 'common/theme'
import { useTranslation } from 'react-i18next'
import { EntityFilterOperator } from './entity-filters.types'

export interface EntityFilterOperatorSelectorProps {
  availableOperators?: EntityFilterOperator[]
  operator: EntityFilterOperator

  onChange: (operator: EntityFilterOperator) => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  entityFilterOperatorSelector: {
    //
  },
  operator: {
    padding: theme.spacing(0, 1),
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer'
  },
  operatorLabel: {
    textTransform: 'lowercase'
  }
}))

export function EntityFilterOperatorSelector({
  availableOperators,
  operator,
  onChange
}: EntityFilterOperatorSelectorProps): FunctionComponentElement<EntityFilterOperatorSelectorProps> {
  const classes = useStyles()

  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null)

  function handleClose(): void {
    setAnchorEl(null)
  }

  return (
    <Box className={classes.entityFilterOperatorSelector}>
      <Typography
        className={classes.operator}
        variant="caption"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {t(`EntityFilterOperatorSymbol.${operator}`)}
      </Typography>

      {availableOperators && (
        <Menu
          anchorEl={anchorEl}
          keepMounted={true}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {availableOperators?.map((item) => (
            <MenuItem
              key={item}
              selected={item === operator}
              onClick={() => {
                onChange(item)
                setAnchorEl(null)
              }}
            >
              {t(`EntityFilterOperatorSymbol.${item}`)}
              <Typography className={classes.operatorLabel} variant="caption">
                &nbsp;({t(`EntityFilterOperator.${item}`)})
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  )
}
