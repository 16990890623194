import mixpanel from 'mixpanel-browser'
import React, { FunctionComponentElement, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import { getReleaseStage, ReleaseStage } from '../../services/environment'
import { IdentityDto, isImpersonatedSignIn } from '../../services/user'

import { TrackingContext } from './tracking.context'

interface TrackingProviderProps {
  children?: any
  mixpanelToken?: string
}

let queuedEvents: (() => void)[] = []

export const TrackingProvider = ({
  children
}: TrackingProviderProps): FunctionComponentElement<TrackingProviderProps> => {
  const [cookies] = useCookies()

  const [mixpanelInitialized, setMixpanelInitialized] = useState<boolean>(false)
  const [mixpanelToken] = useState<string | null>(() => {
    switch (getReleaseStage()) {
      case ReleaseStage.PROD:
        return '6392ff248d4a0639c1e97a8635349d8e'

      case ReleaseStage.DEMO:
        return 'd451c94089608a8e17df705dbc7b0400'

      default:
        return ''
    }
  })

  function processEvent(cb: () => void): void {
    if (mixpanelInitialized) {
      cb()
    } else if (mixpanelToken) {
      queuedEvents = [...queuedEvents, cb]
    }
  }

  function track(eventName: string, metadata?: Record<string, string>): void {
    if (getReleaseStage() !== 'prod') {
      console?.debug(`Tracking :: ${eventName}`, metadata ?? {})
    }

    processEvent(() => {
      mixpanel.track(eventName, {
        ...(metadata ?? {}),
        impersonatedSignIn: isImpersonatedSignIn()
      })
    })
  }

  function trackClick(value: string, metadata?: Record<string, string>): void {
    track(`Click: ${value}`, metadata)
  }

  function trackComplete(value: string): void {
    track(`Complete: ${value}`)
  }

  function trackPageView(value: string): void {
    track(`Page View: ${value}`)
  }

  function trackSelect(value: string): void {
    track(`Select: ${value}`)
  }

  function trackSubmit(value: string): void {
    track(`Submit: ${value}`)
  }

  function trackTabView(value: string): void {
    track(`Tab View: ${value}`)
  }

  function trackView(value: string): void {
    track(`View: ${value}`)
  }

  function trackUserSignIn(userId: string, user: IdentityDto): void {
    processEvent(() => {
      mixpanel.identify(userId)

      if (user) {
        mixpanel.people.set({
          $name: `${user.firstName} ${user.lastName}`,
          $email: user.email,
          $companyName: user.company?.name ?? null,
          $companyType: user.company?.type ?? null,
          $companyState: user.company?.state ?? null,
          $roles: [user.securityRole]
        })
      }
    })
  }

  function trackUserSignOut(): void {
    processEvent(() => {
      mixpanel.reset()
    })
  }

  function mixpanelInit(token: string): void {
    mixpanel.init(token, {
      loaded: () => {
        setMixpanelInitialized(true)
      }
    })
  }

  useEffect(() => {
    if (mixpanelInitialized) {
      queuedEvents.forEach((event) => event())
      queuedEvents = []
    }
  }, [mixpanelInitialized])

  useEffect(() => {
    if (mixpanelToken) {
      mixpanelInit(mixpanelToken)
    }
  }, [cookies])

  return (
    <TrackingContext.Provider
      value={{
        trackClick,
        trackComplete,
        trackPageView,
        trackSelect,
        trackSubmit,
        trackTabView,
        trackUserSignIn,
        trackUserSignOut,
        trackView
      }}
    >
      {children}
    </TrackingContext.Provider>
  )
}
