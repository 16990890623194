import { useEffect, useState } from 'react'
import { LocalStorage, StorageKey } from '../../services/storage'

import { ExtendedRequestInit, useFetchState, UseFetchState } from './fetch.hook'

export type PaginationConfig = {
  size: number
}

export interface PaginationDto {
  page: number
  size: number
  total: number
}

export interface SearchResponse<T> {
  items: T[]
  pagination: PaginationDto
}

export interface UsePaginationConfig {
  page: number
  size: number
  sizeOptions: number[]

  setPage: (value: number) => void
  setSize: (value: number) => void
}

export function usePaginatedFetchState<T>(
  apiUrl: string,
  page: number,
  size: number
): UseFetchState<SearchResponse<T>> {
  function getFetchConfig(): Partial<ExtendedRequestInit> {
    return {
      body: JSON.stringify({
        page,
        size
      }),
      method: 'POST'
    }
  }

  const [response, isLoading, refresh] = useFetchState<SearchResponse<T>>(
    apiUrl,
    null,
    getFetchConfig(),
    {
      initialFetch: false
    }
  )

  useEffect(() => {
    refresh(getFetchConfig())
  }, [page, size])

  return [response, isLoading, refresh]
}

export function usePaginationConfig(): UsePaginationConfig {
  const [page, setPage] = useState<number>(0)
  const [size, setSize] = useState<number>(
    LocalStorage.getItem<PaginationConfig>(StorageKey.PAGINATION_CONFIG)
      ?.size ?? 25
  )

  function handleSizeChange(size: number): void {
    setPage(0)
    setSize(size)

    LocalStorage.setItem(StorageKey.PAGINATION_CONFIG, {
      size
    })
  }

  return {
    page,
    size,
    sizeOptions: [10, 25, 50],
    setPage,
    setSize: handleSizeChange
  }
}
