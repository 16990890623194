/**
 * https://stackoverflow.com/a/12646864/4450893
 */
export function shuffleArray<T = any>(array: T[]): T[] {
  const shuffledArray = [...array]

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
  }

  return shuffledArray
}

export function getLastArrayItem<T = any>(array: T[]): T | null {
  return array.length ? array[array.length - 1] : null
}

export function isFirstArrayItem<T>(array: T[], item: T): boolean {
  if (array.length === 0) {
    return false
  }

  return array[0] === item
}

export function isLastArrayItem<T>(array: T[], item: T): boolean {
  const itemIndex = array.indexOf(item)

  return array.length - 1 === itemIndex
}
