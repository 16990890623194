import {
  Box,
  Button,
  FormControl,
  FormLabel,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core'
import { Receipt as ReceiptIcon } from '@material-ui/icons'
import React, { FunctionComponentElement, useEffect, useState } from 'react'

import { match } from 'react-router-dom'
import { useGeneralStyles } from '../../common/styles/general'
import { useFormStyles } from '../../common/styles/form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { mergeDateAndTime } from '../../helpers/date-time.helpers'
import { PortalContent } from '../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../layouts/SecurePortalLayout'
import { useCamera } from '../../lib/camera'
import { useForm } from '../../lib/form'
import { QuantityUnits } from '../../services/quantityunit'

import FullScreenCamera from 'components/FullScreenCamera'

import { useStateValue } from 'state'
import { apiFetchWithDispatch } from '../../lib/fetch'
import ImagePreview from 'components/ImagePreview'
import LinkButton from 'components/LinkButton'
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

interface Props {
  match: match<{ materialClearanceUuid: string }>
}

const useStyles = makeStyles(() => ({
  weighCollectionCenterMaterialClearance: {
    //
  }
}))

const schema = {
  weight: {
    presence: { allowEmpty: false, message: 'validationRequired' },
    length: { maximum: 10, message: 'validationExceedsMaxLength' }
  }
}

function WeighCollectionCenterMaterialClearance({
  match
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()
  const formClasses = useFormStyles()

  const {
    params: { materialClearanceUuid }
  } = match

  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()
  const theme = useTheme()
  const history = useHistory()

  const [, dispatch] = useStateValue()

  const weighSlipCamera = useCamera()

  const {
    formState: { values, isValid },
    setValues,
    getErrorMessage,
    handleChange,
    hasError,
    resetForm
  } = useForm(schema, {
    weight: '',
    weighedAt: new Date(),
    weighedAtTime: new Date(),
    quantityUnit: QuantityUnits.KG
  })

  function handleWeighedAtChange(e) {
    const newValues = { ...values }
    newValues.weighedAt = e
    setValues(newValues)
  }

  function handleWeighedAtTimeChange(e) {
    const newValues = { ...values }
    newValues.weighedAtTime = e
    setValues(newValues)
  }

  useEffect(() => {
    if (!weighSlipCamera.showCamera) {
      history.push('/hub-overview')
    }
  }, [weighSlipCamera.showCamera])

  async function handleSave() {
    setIsLoading(true)

    const { weight, weighedAt, weighedAtTime } = values

    const response = await apiFetchWithDispatch(
      dispatch,
      `/api/materialClearances/${materialClearanceUuid}/weigh`,
      {
        method: 'POST',
        body: JSON.stringify({
          weight,
          weighedAt: mergeDateAndTime(weighedAt, weighedAtTime).toISOString(),
          weighSlipPictureFileUploadUuid: weighSlipCamera.pictureFileUpload
            ? weighSlipCamera.pictureFileUpload.uuid
            : null
        })
      }
    )

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout
        title={t('weighHandlingCenterMaterialClearanceTitle')}
      >
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!weighSlipCamera.pictureConfirmed) {
    return (
      <FullScreenCamera
        fileName={`weigh-collection-center-material-clearance-${Date.now()}`}
        headerMessage={t(
          'weighCollectionCenterMaterialClearancePictureExplanation'
        )}
        shutterIcon={<ReceiptIcon />}
        onTakePhotoFinished={weighSlipCamera.handleTakePhotoFinished}
        onFileUploadFinished={weighSlipCamera.handleFileUploadFinished}
        onConfirmPicture={weighSlipCamera.handleConfirmPicture}
        onCameraError={weighSlipCamera.handleCameraError}
      />
    )
  }

  return (
    <Box className={classes.weighCollectionCenterMaterialClearance}>
      <SecurePortalLayout
        title={t('weighHandlingCenterMaterialClearanceTitle')}
      >
        <PortalContent>
          <Box className={generalClasses.content}>
            <form autoComplete="off" noValidate>
              <Paper className={formClasses.formSection}>
                <Typography className={generalClasses.title} variant="h3">
                  {t('weighHandlingCenterMaterialClearanceTitle')}
                </Typography>

                <FormControl component="fieldset">
                  <FormLabel component="legend" required>
                    {t('weighSlipPicture')}
                  </FormLabel>
                  <div className={generalClasses.imagePreview}>
                    <ImagePreview
                      dataUri={weighSlipCamera.pictureDataUri}
                      isPictureUploading={!weighSlipCamera.pictureFileUpload}
                    />
                  </div>
                </FormControl>

                <div className={formClasses.field}>
                  <TextField
                    name="weight"
                    autoFocus
                    className={formClasses.weightField}
                    type="number"
                    label={`${t('addWeight')}`}
                    margin="dense"
                    required
                    value={values.weight}
                    onChange={handleChange}
                    error={hasError('weight')}
                    helperText={t(getErrorMessage('weight'))}
                  />
                  <TextField
                    name="quantityUnit"
                    className={formClasses.quantityUnitField}
                    disabled
                    label=" "
                    margin="dense"
                    value={values.quantityUnit}
                    InputProps={{
                      style: {
                        color: theme.palette.text.primary
                      }
                    }}
                  />
                </div>

                <div className={formClasses.field}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      className={formClasses.dateField}
                      id="mui-pickers-date"
                      name="weighedAt"
                      label={t('weighedAt')}
                      value={values.weighedAt}
                      onChange={handleWeighedAtChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />

                    <KeyboardTimePicker
                      margin="normal"
                      className={formClasses.timeField}
                      id="time-picker"
                      label=" "
                      value={values.weighedAtTime}
                      onChange={handleWeighedAtTimeChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change time'
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className={formClasses.actionButtons}>
                  <LinkButton
                    size="large"
                    className={formClasses.cancelButton}
                    color="default"
                    variant="outlined"
                    href="/hub-overview"
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size="large"
                    variant="contained"
                    className={generalClasses.primaryButton}
                    disabled={
                      !isValid ||
                      isLoading ||
                      !weighSlipCamera.pictureFileUpload
                    }
                    onClick={handleSave}
                  >
                    {t('addWeighSlipButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </Box>
        </PortalContent>
      </SecurePortalLayout>
    </Box>
  )
}

export default WeighCollectionCenterMaterialClearance
