import React, { useState } from 'react'

import { Snackbar } from '@material-ui/core'

import { Alert as MuiAlert } from '@material-ui/lab'

import { useStateValue } from 'state'

function Alert (props) {
  return <MuiAlert elevation={6} severity={props.severity} variant='filled' {...props} />
}

export default function Toast () {
  const [open, setOpen] = useState(true)

  const [{ toast }] = useStateValue()

  function handleClose (event, reason) {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  if (!open || !toast || !toast.message) {
    return null
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert
        severity={toast.variant}
        color={toast.variant}
        onClose={handleClose}
      >
        {toast.message}
      </Alert>
    </Snackbar>
  )
}
