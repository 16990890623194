import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core'

import {
  Add as AddIcon,
  Done as DoneIcon
} from '@material-ui/icons'

// Shared layouts
import { Paper, SummaryLine } from 'components'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'

// Component styles
import styles from './styles'
import { useTranslation } from 'react-i18next'

import { apiFetchWithDispatch, useFetchState } from 'lib/fetch'
import { useStateValue } from 'state'

import { getDisplayedQuantityUnit } from 'services/quantityunit'

import { SegregationUnitStates } from 'services/segregationunit'
import LinkButton from '../../../components/LinkButton'

import MaterialBreakdown from 'components/MaterialBreakdown'

function FinishTruckLoadGrading (props) {
  const { classes } = props

  const [isLoading, setIsLoading] = useState(false)

  const [ongoingSegregationUnit, isOngoingSegregationUnitLoading] = useFetchState(
    `/api/segregationUnits?state=${SegregationUnitStates.PROCESSING}`)

  const { t } = useTranslation()
  const [, dispatch] = useStateValue()
  const history = useHistory()

  const bags = ongoingSegregationUnit
    ? ongoingSegregationUnit.outputMaterialBagCount > 1 ? t('bags') : t('bag')
    : ''

  async function handleFinishTruckLoadGrading () {
    setIsLoading(true)

    await apiFetchWithDispatch(dispatch,
      `/api/segregationUnits/${ongoingSegregationUnit.uuid}/finish`, {
        method: 'POST'
      })

    setIsLoading(false)

    history.push('/hub-overview')
  }

  if (isLoading || isOngoingSegregationUnitLoading) {
    return (
      <SecurePortalLayout title={t('finishGradingTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  return (
    <SecurePortalLayout title={t('finishGradingTitle')}>
      <PortalContent>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={8}
            lg={5}
          >
            {!ongoingSegregationUnit ? (
              <Paper className={classes.root}>
                <Typography
                  className={classes.title}
                  variant='h3'
                >
                  {t('finishGradingTitle')}
                </Typography>
                <Typography
                  className={classes.title}
                  variant='body1'
                >
                  {t('noOngoingSegregationUnit')}
                </Typography>
              </Paper>
            ) : (
              <>
                <Paper className={classes.root}>
                  <Typography
                    className={classes.title}
                    variant='h3'
                  >
                    {t('currentGradingTitle')}
                  </Typography>

                  <div className={classes.content}>
                    <SummaryLine
                      title={t('registeredMaterialBagsNumber')}
                      value={<>{ongoingSegregationUnit.outputMaterialBagCount} {bags} <br /> ({ongoingSegregationUnit.formattedMaterialTotal} {getDisplayedQuantityUnit(ongoingSegregationUnit.quantityUnit)})</>}
                    />
                  </div>
                </Paper>
                <Paper className={classes.root}>
                  <Typography
                    className={classes.title}
                    variant='h3'
                  >
                    {t('materialBreakdown')}
                  </Typography>
                  <div className={classes.content}>
                    <MaterialBreakdown materialBreakdown={ongoingSegregationUnit.materialBreakdown} quantityUnit={ongoingSegregationUnit.quantityUnit} />
                  </div>
                </Paper>
                <Paper className={classes.root}>
                  <div className={classes.content}>
                    <Typography
                      className={classes.title}
                      variant='h4'
                    >
                      {t('finishGradingQuestion')}
                    </Typography>
                  </div>
                  <div className={classes.actionButtons}>
                    <LinkButton
                      size='large'
                      variant='outlined'
                      className={classes.weighBagButton}
                      href={`/segregation-unit/${ongoingSegregationUnit.uuid}/register-output-material-bag`}
                      startIcon={<AddIcon />}
                    >
                      <span className={classes.weighBagButtonLabel}>
                        {t('yesWeighBagButton')}
                      </span>
                    </LinkButton>

                    <Button
                      size='large'
                      variant='contained'
                      className={classes.finishShiftButton}
                      onClick={handleFinishTruckLoadGrading}
                      startIcon={<DoneIcon />}
                    >
                      {t('noAllWeighedButton')}
                    </Button>
                  </div>
                </Paper>
              </>
            )}
          </Grid>
        </Grid>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default withStyles(styles)(FinishTruckLoadGrading)
