import React from 'react'

// Externals
import classNames from 'classnames'

// Material components
import { CircularProgress, Typography, withStyles } from '@material-ui/core'

// Component styles
import styles from './styles'
import { useTranslation } from 'react-i18next'

import { SummaryLine } from 'components'

import { getDisplayedQuantityUnit } from 'services/quantityunit'
import { LocalShipping as LocalShippingIcon } from '@material-ui/icons'

import { useFetchState } from 'lib/fetch'
import LinkButton from 'components/LinkButton'

function CoProcessingFacilityRegistration (props) {
  const { classes, className } = props

  const rootClassName = classNames(classes.root, className)

  const [summary, isLoading] = useFetchState('/api/coProcessingMaterials/summary')

  const { t } = useTranslation()

  if (isLoading || !summary) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={rootClassName}>
      <div className={classes.content}>
        <SummaryLine
          title={t('coProcessingFacility')}
          value={summary.coProcessingFacilityName}
        />
        <SummaryLine
          title={t('truckLoadsCount')}
          value={<>{summary.truckLoadsCount}</>}
        />
        <SummaryLine
          title={t('totalStock')}
          value={<>{summary.totalStock.toFixed(2)} {getDisplayedQuantityUnit(summary.totalStockUnit)}</>}
        />
        <SummaryLine
          title={t('ongoingTransports')}
          value={<>{summary.ongoingTransportsCount}</>}
        />
      </div>

      {summary.ongoingTransportsCount === 0 ? (
        <>
          <Typography
            className={classes.noTruckLoadToBeRegisteredMessage}
            variant='subtitle1'
          >
            {t('noTruckLoadToBeRegisteredMessage')}
          </Typography>
        </>
      ) : (
        <LinkButton
          size='large'
          variant='contained'
          className={classes.registerMaterialButton}
          href='/register-co-processing-material-truck-load'
          startIcon={<LocalShippingIcon
            style={{
              height: 50,
              width: 50,
              fontWeight: 30
            }}
          />}
        >
          {t('registerTruckLoadButton')}
        </LinkButton>
      )}
    </div>
  )
}

export default withStyles(styles)(CoProcessingFacilityRegistration)
