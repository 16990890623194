import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { FunctionComponentElement } from 'react'
import { HandlingCenterMaterialClearanceDto } from 'services/handling-center-material/handling-center-material-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Receipt as ReceiptIcon } from '@material-ui/icons'
import { useGeneralStyles } from '../../../../common/styles/general'
import FlowActionButton from '../../../../components/FlowActionButton'

import { SummaryLine } from 'components'

interface Props {
  handlingCenterMaterialClearance: HandlingCenterMaterialClearanceDto
}

const useStyles = makeStyles(() => ({
  loadedHandlingCenterMaterialClearance: {
    //
  }
}))

function LoadedHandlingCenterMaterialClearance({
  handlingCenterMaterialClearance
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()

  const { t } = useTranslation()

  return (
    <Box className={classes.loadedHandlingCenterMaterialClearance}>
      <Typography className={generalClasses.title} variant="h3">
        {t('ongoingHandlingCenterMaterialClearanceTitle')}
      </Typography>
      <Box className={generalClasses.content}>
        <SummaryLine
          title={t('loadedAt')}
          value={`${moment(handlingCenterMaterialClearance.createdAt).format(
            'DD/MM/YYYY HH:mm'
          )}`}
        />
        <SummaryLine
          title={t('licensePlate')}
          value={handlingCenterMaterialClearance.transporterLicensePlate}
        />

        <FlowActionButton
          color="secondary"
          href={`/weigh-handling-center-material-clearance/${handlingCenterMaterialClearance.uuid}`}
          icon={ReceiptIcon}
        >
          {t('addWeighSlipButton')}
        </FlowActionButton>
      </Box>
    </Box>
  )
}

export default LoadedHandlingCenterMaterialClearance
