import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// Externals
import classNames from 'classnames'

// Material components
import { Button, CircularProgress, Typography, withStyles } from '@material-ui/core'

import { DoneOutline as DoneOutlineIcon } from '@material-ui/icons'

import { SummaryLine, InstructionDialog } from 'components'

// Component styles
import styles from './styles'
import { useTranslation } from 'react-i18next'

import { getDisplayedQuantityUnit } from 'services/quantityunit'
import moment from 'moment'
import { apiFetchWithDispatch, useFetchState } from 'lib/fetch'
import { UserInstructions } from 'services/userinstruction'

import { useStateValue } from 'state'

import { MaterialType } from 'services/materialtype/material-type.types'

function RecyclerGrading (props) {
  const { classes, className, segregationUnit } = props

  const [finishGradingInstructionView, isFinishGradingInstructionViewLoading] = useFetchState(
    `/api/userInstructionViews?instruction=${UserInstructions.FINISH_TRUCK_LOAD_GRADING}`)

  const [openUserInstructionDialog, setOpenUserInstructionDialog] = useState(false)

  const rootClassName = classNames(classes.root, className)

  const [, dispatch] = useStateValue()
  const { t } = useTranslation()
  const history = useHistory()

  const displayedQuantityUnit = getDisplayedQuantityUnit(segregationUnit.quantityUnit)

  const registeredMaterial = segregationUnit.registeredMaterials
    ? segregationUnit.registeredMaterials[0]
    : null
  const materialType = registeredMaterial.materialType ? registeredMaterial.materialType : MaterialType.MIXED

  function handleFinishGrading () {
    if (finishGradingInstructionView.viewsCount > 3) {
      redirectToRegisterOutputMaterialBag()
      return
    }

    setOpenUserInstructionDialog(true)
  }

  function handleContinueAfterUserInstruction () {
    setOpenUserInstructionDialog(false)

    apiFetchWithDispatch(dispatch, '/api/userInstructionViews', {
      method: 'POST',
      body: JSON.stringify({
        instruction: UserInstructions.FINISH_TRUCK_LOAD_GRADING
      })
    })

    redirectToRegisterOutputMaterialBag()
  }

  function redirectToRegisterOutputMaterialBag () {
    history.push(`/segregation-unit/${segregationUnit.uuid}/register-output-material-bag`)
  }

  function handleCloseUserInstruction () {
    setOpenUserInstructionDialog(false)
  }

  if (isFinishGradingInstructionViewLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={rootClassName}>
      <Typography
        className={classes.title}
        variant='h3'
      >
        {t('ongoingTruckLoadGradingSummary')}
      </Typography>
      <div className={classes.content}>
        <SummaryLine
          title={t('weighedAt')}
          value={`${moment(registeredMaterial.weighedAt).format('DD/MM/YYYY HH:mm')}`}
        />
        <SummaryLine
          title={t('weight')}
          value={<>{segregationUnit.formattedWeight} {displayedQuantityUnit}</>}
        />
        <SummaryLine
          title={t('materialType')}
          value={t(`materialType_${materialType}`)}
        />
        <SummaryLine
          title={t('supplier')}
          value={registeredMaterial.supplier ? registeredMaterial.supplier : '-'}
        />
        <SummaryLine
          title={t('licensePlate')}
          value={registeredMaterial.licensePlate ? registeredMaterial.licensePlate : '-'}
        />

        <Button
          size='large'
          variant='contained'
          className={classes.finishGradingButton}
          onClick={handleFinishGrading}
          startIcon={<DoneOutlineIcon
            style={{
              height: 50,
              width: 50,
              fontWeight: 30
            }}
          />}
        >
          {t('finishGradingButton')}
        </Button>
      </div>

      <InstructionDialog
        message={t(`userInstruction_${UserInstructions.FINISH_TRUCK_LOAD_GRADING}`)}
        open={openUserInstructionDialog}
        onClose={handleCloseUserInstruction}
        onContinue={handleContinueAfterUserInstruction}
      />
    </div>
  )
}

export default withStyles(styles)(RecyclerGrading)
