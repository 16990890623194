import React from 'react'

export const Languages = {
  EN: 'EN',
  DE: 'DE'
}

export function getLanguageOptions () {
  return (
    <>
      <option key='' value='' />
      {Object.keys(Languages).map(language => (
        <option key={language} value={language}>
          {language}
        </option>
      ))}
    </>
  )
}
