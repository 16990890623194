import React from 'react'

import { useHistory } from 'react-router-dom'

import { Box, IconButton } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import { ArrowBackIos as ArrowBackIcon } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 0,
    height: 100,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    zIndex: 1
  },
  content: {
    display: 'flex',
    height: 90,
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    fontSize: 20,
    color: theme.palette.common.white,
    opacity: 0.8,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing()
  },
  message: {
    fontSize: 18,
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(2),
    paddingTop: 5,
    paddingBottom: 5
  }
}))

export default function CameraHeader (props) {
  const { message, onGoBack } = props

  const classes = useStyles()

  const history = useHistory()

  function handleBackButton () {
    onGoBack ? onGoBack() : history.goBack()
  }

  return (
    <Box className={classes.root}>
      <div className={classes.content}>
        <IconButton
          className={classes.icon}
          key='close'
          color='default'
          size='medium'
          onClick={handleBackButton}
        >
          <ArrowBackIcon />
        </IconButton>
        <div className={classes.message}>
          {message}
        </div>
      </div>
    </Box>
  )
}
