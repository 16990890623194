import { Auth0Provider } from '@auth0/auth0-react'
import { ThemeProvider } from '@material-ui/styles'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { createBrowserHistory } from 'history'
import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { pdfjs } from 'react-pdf'
import { Router } from 'react-router-dom'

import { initialState, mainReducer, StateProvider } from 'state'

import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_REDIRECT_URL
} from './lib/auth0'
import { TrackingProvider } from './lib/tracking'
import Routes from './Routes'
import { getReleaseStage } from './services/environment'
import { addSentryUserMetadata } from './services/sentry'
import { getCurrentUser } from './services/user'
import theme from './theme'

// Browser history
const browserHistory = createBrowserHistory()

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

window.addEventListener('appinstalled', async () => {
  await navigator.storage.persist()
})

function UserMetadataResolver({ children }) {
  const userIdentity = getCurrentUser()

  addSentryUserMetadata(userIdentity)

  return children
}

const App = () => {
  return (
    <Auth0Provider
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      authorizationParams={{
        audience: AUTH0_AUDIENCE,
        redirect_uri: AUTH0_REDIRECT_URL
      }}
    >
      <SentryErrorBoundary>
        <Suspense fallback="">
          <StateProvider initialState={initialState} reducer={mainReducer}>
            <TrackingProvider>
              <UserMetadataResolver>
                <ThemeProvider theme={theme}>
                  <Router history={browserHistory}>
                    <Helmet>
                      {/* Hotjar Tracking Code */}
                      {getReleaseStage() === 'prod' && (
                        <script type="text/javascript">
                          {`
                          (function(h,o,t,j,a,r){
                            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                            h._hjSettings={hjid:3298041,hjsv:6};
                            a=o.getElementsByTagName('head')[0];
                            r=o.createElement('script');r.async=1;
                            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                            a.appendChild(r);
                          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                        `}
                        </script>
                      )}
                    </Helmet>

                    <Routes />
                  </Router>
                </ThemeProvider>
              </UserMetadataResolver>
            </TrackingProvider>
          </StateProvider>
        </Suspense>
      </SentryErrorBoundary>
    </Auth0Provider>
  )
}

export default App
