import React from 'react'
import { useTranslation } from 'react-i18next'
// Material components
import { Grid, Typography, withStyles } from '@material-ui/core'

// Component styles
import styles from './styles'

function Error (props) {
  const { classes } = props
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Grid
        container
        justify='center'
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant='h1'>
              {t('genericErrorMessage')}
            </Typography>
            <img
              alt='Under development'
              className={classes.image}
              src='/images/not_found.png'
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(Error)
