import { useAuth0 } from '@auth0/auth0-react'
import React, { Component, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useTracking } from './lib/tracking'
import AddHub from './views/admin/AddHub'
import AdminManageAuditActions from './views/admin/AdminManageAuditActions'
import AdminManageProjects from './views/admin/AdminManageProjects'
import AdminRegisterCollectedMaterials from './views/admin/AdminRegisterCollectedMaterials'
import AdminStartMaterialClearance from './views/admin/AdminStartMaterialClearance'
import { ViewCollectors } from './views/admin/Collectors/ViewCollectors'
import { HubEvents } from './views/admin/HubEvents/HubEvents'
import HubList from './views/admin/HubList'
import { HubStock } from './views/admin/HubStock/HubStock'
import { ViewTrackedItems } from './views/admin/TrackedItems/ViewTrackedItems'
import UserList from './views/admin/UserList'
import { Home } from './views/Home'
import { PipelineOverview } from './views/hubowner/pipeline-overview/PipelineOverview'
import TrackedItemIdentity from './views/identity/TrackedItemIdentity'
import CollectorIdentity from './views/identity/CollectorIdentity'
import HubIdentity from './views/identity/HubIdentity'
import { AuthCallback } from './views/public/auth/callback/AuthCallback'
import Error from './views/public/Error'
import NotFound from './views/public/NotFound'
import { SignIn } from './views/public/SignIn'
import AddCoProcessingMaterialCertificate from './views/secure/AddCoProcessingMaterialCertificate'
import AddRecyclerMaterialCertificate from './views/secure/AddRecyclerMaterialCertificate'
import ClearHandlingCenterMaterialForm from './views/secure/ClearHandlingCenterMaterialForm'
import ClearMaterialStockForm from './views/secure/ClearMaterialStockForm'
import DefineCollector from './views/secure/DefineCollector'
import DispatchCarrierMaterial from './views/secure/DispatchCarrierMaterial'
import FinishCollection from './views/secure/FinishCollection'
import FinishTruckLoadGrading from './views/secure/FinishTruckLoadGrading'
import HubOverview from './views/secure/HubOverview'
import CollectionCenterActiveCollector from './views/secure/HubOverview/components/CollectionCenterActiveCollector'
import PickUpPointCheckIn from './views/secure/PickUpPointCheckIn'
import RegisterAdditionalMaterial from './views/secure/RegisterAdditionalMaterial'
import RegisterCarrierMaterial from './views/secure/RegisterCarrierMaterial'
import RegisterCollectedMaterialBag from './views/secure/RegisterCollectedMaterialBag'
import RegisterCollectedMaterialContainer from './views/secure/RegisterCollectedMaterialContainer'
import RegisterCollectedMaterialTruckLoad from './views/secure/RegisterCollectedMaterialTruckLoad'
import RegisterCoProcessingMaterialTruckLoad from './views/secure/RegisterCoProcessingMaterialTruckLoad'
import RegisterHandlingCenterMaterial from './views/secure/RegisterHandlingCenterMaterial'
import RegisterProcessingOutputMaterialBag from './views/secure/RegisterProcessingOutputMaterialBag'
import RegisterSegregationOutputMaterialBag from './views/secure/RegisterSegregationOutputMaterialBag'
import RegisterTruckLoad from './views/secure/RegisterTruckLoad'
import StartCollection from './views/secure/StartCollection'
import StartRecyclerClearance from './views/secure/StartRecyclerClearance'
import WeighCollectionCenterMaterialClearance from './views/secure/WeighCollectionCenterMaterialClearance'
import WeighHandlingCenterMaterialClearance from './views/secure/WeighHandlingCenterMaterialClearance'
import WeighRecyclerClearanceTransport from './views/secure/WeighRecyclerClearanceTransport'

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route component={Home} exact from="/" />
        <Route component={SignIn} exact path="/sign-in" />
        <Route component={AuthCallback} exact path="/auth/callback" />
        <Route component={NotFound} exact path="/not-found" />
        <Route component={Error} exact path="/error" />

        <SecureRoute
          component={HubOverview}
          exact
          pageName="Hub Overview"
          path="/hub-overview"
        />

        <SecureRoute
          component={RegisterCollectedMaterialBag}
          exact
          pageName="Register Material Bag"
          path="/register-material-bag/:supplierUuid?"
        />

        <SecureRoute
          component={RegisterCollectedMaterialTruckLoad}
          exact
          pageName="Register Material Truckload"
          path="/register-material-truck-load"
        />

        <SecureRoute
          component={RegisterCollectedMaterialContainer}
          exact
          pageName="Register Material Container"
          path="/register-material-container"
        />

        <SecureRoute
          component={CollectionCenterActiveCollector}
          exact
          pageName="Collection Center Active Collector"
          path="/active-collector/:collectorUuid"
        />

        <SecureRoute
          component={ClearMaterialStockForm}
          exact
          pageName="Clear Material Stock"
          path="/clear-material-stock"
        />

        <SecureRoute
          component={FinishTruckLoadGrading}
          exact
          pageName="Finish Grading"
          path="/finish-grading"
        />

        <SecureRoute
          component={RegisterSegregationOutputMaterialBag}
          exact
          pageName="Segregation - Register Output Material Bag"
          path="/segregation-unit/:segregationUnitUuid/register-output-material-bag/"
        />

        <SecureRoute
          component={RegisterProcessingOutputMaterialBag}
          exact
          pageName="Processing - Register Output Material Bag"
          path="/processing-unit/:processingUnitUuid/register-output-material-bag/"
        />

        <SecureRoute
          component={RegisterTruckLoad}
          exact
          pageName="Register Truckload"
          path="/register-truck-load"
        />

        <SecureRoute
          component={RegisterAdditionalMaterial}
          exact
          pageName="Register Additional Material"
          path="/register-additional-material/:ongoingTransportUuid"
        />

        <SecureRoute
          component={StartRecyclerClearance}
          exact
          pageName="Start Clearance"
          path="/start-recycler-clearance"
        />

        <SecureRoute
          component={WeighRecyclerClearanceTransport}
          exact
          pageName="Weigh Clearance Transport"
          path="/weigh-recycler-clearance-transport/:clearanceTransportUuid"
        />

        <SecureRoute
          component={WeighCollectionCenterMaterialClearance}
          exact
          pageName="Weigh Material Clearance"
          path="/weigh-collection-center-material-clearance/:materialClearanceUuid"
        />

        <SecureRoute
          component={RegisterCoProcessingMaterialTruckLoad}
          exact
          pageName="Register Material Truckload"
          path="/register-co-processing-material-truck-load"
        />

        <SecureRoute
          component={AddCoProcessingMaterialCertificate}
          exact
          pageName="Add Certificate"
          path="/co-processing-material/:coProcessingMaterialUuid/add-certificate"
        />

        <SecureRoute
          component={AddRecyclerMaterialCertificate}
          exact
          pageName="Add Certificate"
          path="/recycler-material/:materialUuid/add-certificate"
        />

        {/* Hub Owner - Start */}

        <SecureRoute
          component={PipelineOverview}
          exact
          pageName="Pipeline Overview"
          path="/hub-owner/pipeline-overview"
        />

        {/* Hub Owner - End */}

        <SecureRoute
          component={HubList}
          exact
          pageName="Admin - Hubs"
          path="/admin/hubs"
        />

        <SecureRoute
          component={AddHub}
          exact
          pageName="Admin - Hubs - Add Hub"
          path="/admin/add-hub/:hubCompanyUuid?"
        />

        <SecureRoute
          component={AdminRegisterCollectedMaterials}
          exact
          pageName="Admin - Hubs - Register Collected Materials"
          path="/admin/hubs/:hubCompanyUuid/register-collected-materials"
        />

        <SecureRoute
          component={AdminStartMaterialClearance}
          exact
          pageName="Admin - Hubs - Start Material Clearance"
          path="/admin/hubs/:hubCompanyUuid/start-material-clearance"
        />

        <SecureRoute
          component={ViewCollectors}
          exact
          pageName="Admin - Hubs - Collectors"
          path="/admin/hubs/:hubCompanyUuid/collectors"
        />

        <SecureRoute
          component={ViewTrackedItems}
          exact
          pageName="Admin - Hubs - Tracked Items"
          path="/admin/hubs/:hubCompanyUuid/tracked-items"
        />

        <SecureRoute
          component={AdminManageAuditActions}
          exact
          pageName="Admin - Hubs - Manage Audit Actions"
          path="/admin/hubs/:hubCompanyUuid/manage-audit-actions"
        />

        <SecureRoute
          component={AdminManageProjects}
          exact
          pageName="Admin - Hubs - Manage Projects"
          path="/admin/hubs/:hubCompanyUuid/manage-projects"
        />

        <SecureRoute
          component={UserList}
          exact
          pageName="Admin - Users"
          path="/admin/users"
        />

        <SecureRoute
          component={HubEvents}
          exact
          pageName="Admin - Hub events"
          path="/admin/hub-events"
        />

        <SecureRoute
          component={HubStock}
          exact
          pageName="Admin - Hub Stock"
          path="/admin/hub-stock"
        />

        <SecureRoute
          component={HubIdentity}
          exact
          pageName="Identity - Hub"
          path="/identity/hub/:hubUuid"
        />

        <SecureRoute
          component={TrackedItemIdentity}
          exact
          pageName="Identity - Tracked Item"
          path="/identity/tracked-item/:trackedItemUuid"
        />

        <SecureRoute
          component={CollectorIdentity}
          exact
          pageName="Identity - Collector"
          path="/identity/collector/:collectorUuid"
        />

        <SecureRoute
          component={DefineCollector}
          exact
          pageName="Define Collector"
          path="/define-collector/:collectorUuid"
        />

        <SecureRoute
          component={StartCollection}
          exact
          pageName="Start Collection"
          path="/start-collection"
        />

        <SecureRoute
          component={PickUpPointCheckIn}
          exact
          pageName="Check-in"
          path="/check-in"
        />

        <SecureRoute
          component={FinishCollection}
          exact
          pageName="Finish Collection"
          path="/collection-units/:collectionUnitUuid/finish"
        />

        <SecureRoute
          component={RegisterCarrierMaterial}
          exact
          pageName="Register Material"
          path="/register-carrier-material"
        />

        <SecureRoute
          component={DispatchCarrierMaterial}
          exact
          pageName="Dispatch Material"
          path="/dispatch-carrier-material"
        />
        <SecureRoute
          component={RegisterHandlingCenterMaterial}
          exact
          pageName="Register Material"
          path="/register-handling-center-material"
        />
        <SecureRoute
          component={ClearHandlingCenterMaterialForm}
          exact
          pageName="Clear Material"
          path="/clear-handling-center-material/:materialTypes"
        />
        <SecureRoute
          component={WeighHandlingCenterMaterialClearance}
          exact
          pageName="Weigh Material Clearance"
          path="/weigh-handling-center-material-clearance/:handlingCenterMaterialClearanceUuid"
        />

        <Redirect to="/not-found" />
      </Switch>
    )
  }
}

function SecureRoute({ component: Component, pageName, ...rest }) {
  const { trackPageView } = useTracking()
  const { isAuthenticated, isLoading } = useAuth0()

  useEffect(() => {
    if (pageName) {
      trackPageView(pageName)
    }
  }, [pageName])

  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoading ? (
          <></>
        ) : isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: props.location }
            }}
          />
        )
      }}
    />
  )
}
