import { Box, CircularProgress, Typography } from '@material-ui/core'
import { LocalShipping as LocalShippingIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, {
  FunctionComponentElement,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentedControl } from 'segmented-control'
import { debounce } from 'underscore'

import { Theme } from '../../../../common/theme'
import { SummaryLine } from '../../../../components'
import FlowActionButton from '../../../../components/FlowActionButton'
import { MaterialTypeBreakdown } from '../../../../components/MaterialTypeBreakdown'
import { ReactComponent as BagIcon } from '../../../../icons/bag.svg'
import { ReactComponent as ContainerIcon } from '../../../../icons/container.svg'
import {
  Feature,
  hasOnlyOneRegistrationEnabled
} from '../../../../services/feature'
import { MassBalanceAccount } from '../../../../services/mass-balance'
import { MassBalanceApiService } from '../../../../services/mass-balance/mass-balance-api.service'
import {
  getRegistrationUnitOptions,
  RegistrationUnit
} from '../../../../services/registrationunit'
import { getCurrentUser } from '../../../../services/user'
import { useStateValue } from '../../../../state'
import { Dispatch, State } from '../../../../state/state.types'

const useStyles = makeStyles<Theme>((theme) => ({
  massBalanceRegistrationOverview: {
    margin: theme.spacing(-3, 0, 1)
  },
  registrationUnit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0.5, 1)
  },
  registrationUnitTitle: {
    fontWeight: 400
  },
  segmentedControl: {
    width: '100%',
    color: theme.palette.primary.main,
    fontWeight: 500
  }
}))

export function MassBalanceRegistrationOverview(): FunctionComponentElement<void> {
  const allowUnregisteredSuppliers =
    getCurrentUser()?.allowUnregisteredSuppliers ?? false

  const classes = useStyles()

  const [{ overviewPeriod }]: [State, Dispatch] = useStateValue()
  const { t } = useTranslation()

  const [
    allowUnregisteredSuppliersChecked,
    setAllowUnregisteredSuppliersChecked
  ] = useState<boolean>(false)
  const [
    selectedRegistrationUnit,
    setSelectedRegistrationUnit
  ] = useState<RegistrationUnit>(RegistrationUnit.BAG)

  const [
    overview,
    isOverviewLoading,
    refetchOverview
  ] = MassBalanceApiService.getRegistrationOverview()

  const debouncedRefetchOverview = useCallback<typeof refetchOverview>(
    debounce(refetchOverview, 500),
    [refetchOverview]
  )

  useEffect(() => {
    if (overviewPeriod.endDate && overviewPeriod.startDate) {
      debouncedRefetchOverview({
        queryParams: {
          account: MassBalanceAccount.REGISTRATION,
          endDate: overviewPeriod.endDate.toISOString(),
          startDate: overviewPeriod.startDate.toISOString()
        }
      })
    }
  }, [overviewPeriod.endDate, overviewPeriod.startDate])

  if (isOverviewLoading || !overview) {
    return (
      <Box mb={3} textAlign="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.massBalanceRegistrationOverview}>
      <Box mx={-1} mb={4}>
        <MaterialTypeBreakdown
          outputTypeBreakdown={overview.outputTypeBreakdown}
          quantityUnit={overview.quantityUnit}
          total={overview.total ?? 0}
          totalText={t('totalRegistered')}
        />
      </Box>

      {!hasOnlyOneRegistrationEnabled() && (
        <div className={classes.registrationUnit}>
          <Typography className={classes.registrationUnitTitle} variant="h5">
            {t('registrationUnit')}:
          </Typography>

          <SegmentedControl
            className={classes.segmentedControl}
            name="selectedRegistrationUnit"
            options={getRegistrationUnitOptions(t)}
            setValue={(value) => setSelectedRegistrationUnit(value)}
          />
        </div>
      )}

      {allowUnregisteredSuppliers &&
        (selectedRegistrationUnit === RegistrationUnit.TRUCK_LOAD ||
          hasOnlyOneRegistrationEnabled(Feature.TRUCK_LOAD_REGISTRATION)) && (
          <SummaryLine
            title={t('registeredSupplier')}
            value={
              <SegmentedControl
                className={classes.segmentedControl}
                name="allowUnregisteredSuppliers"
                options={[
                  { label: t('no'), value: false },
                  { label: t('yes'), value: true, default: true }
                ]}
                setValue={setAllowUnregisteredSuppliersChecked}
              />
            }
          />
        )}

      {(hasOnlyOneRegistrationEnabled(Feature.WASTE_BAG_REGISTRATION) ||
        (!hasOnlyOneRegistrationEnabled() &&
          selectedRegistrationUnit === RegistrationUnit.BAG)) && (
        <FlowActionButton
          color="secondary"
          icon={BagIcon as any}
          href="/register-material-bag"
        >
          {t('registerMaterialBagButton')}
        </FlowActionButton>
      )}

      {(hasOnlyOneRegistrationEnabled(Feature.TRUCK_LOAD_REGISTRATION) ||
        (!hasOnlyOneRegistrationEnabled() &&
          selectedRegistrationUnit === RegistrationUnit.TRUCK_LOAD)) && (
        <FlowActionButton
          color="secondary"
          icon={LocalShippingIcon}
          href={`/register-material-truck-load?cleanhubSupplier=${
            !allowUnregisteredSuppliers || allowUnregisteredSuppliersChecked
          }`}
        >
          {t('registerTruckLoadButton')}
        </FlowActionButton>
      )}

      {(hasOnlyOneRegistrationEnabled(Feature.CONTAINER_REGISTRATION) ||
        (!hasOnlyOneRegistrationEnabled() &&
          selectedRegistrationUnit === RegistrationUnit.CONTAINER)) && (
        <FlowActionButton
          color="secondary"
          icon={ContainerIcon as any}
          href="/register-material-container"
        >
          {t('registerContainerButton')}
        </FlowActionButton>
      )}
    </Box>
  )
}
