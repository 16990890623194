import {
  Box,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core'
import { LocalShipping as LocalShippingIcon } from '@material-ui/icons'
import cx from 'classnames'
import React, { FunctionComponentElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useGeneralStyles } from 'common/styles/general'
import FlowActionButton from 'components/FlowActionButton'
import SummaryLine from 'components/SummaryLine'
import { CarrierMaterialApiService } from 'services/carrier-material/carrier-material-api.service'

const useStyles = makeStyles((theme) => ({
  carrierRegistration: {
    marginBottom: theme.spacing()
  }
}))

function CarrierRegistration(): FunctionComponentElement<void> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()
  const titleClasses = cx([generalClasses.title, generalClasses.sectionTitle])

  const { t } = useTranslation()

  const [summary, isSummaryLoading] = CarrierMaterialApiService.getSummary()

  if (isSummaryLoading || !summary) {
    return (
      <div className={generalClasses.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <Box className={classes.carrierRegistration}>
      <Typography className={titleClasses} variant="h3">
        {t('carrierOverview')}
      </Typography>

      <Box className={generalClasses.content}>
        <SummaryLine title={t('carrierName')} value={summary.carrierName} />
        <SummaryLine
          title={t('storedUnitsCount')}
          value={summary.storedUnitsCount}
        />

        <SummaryLine
          title={t('ongoingTransports')}
          value={summary.ongoingTransportsCount}
        />

        <FlowActionButton
          color="secondary"
          href="/register-carrier-material"
          icon={LocalShippingIcon}
        >
          {t('registerMaterialButton')}
        </FlowActionButton>
      </Box>
    </Box>
  )
}

export default CarrierRegistration
