import { MinimalFileUploadDto } from 'services/fileupload/file-upload.types'

export enum TrackedItemType {
  BAG = 'BAG',
  BALE = 'BALE',
  SACK = 'SACK'
}

export enum TrackedItemState {
  UNASSIGNED = 'UNASSIGNED',
  COLLECTED = 'COLLECTED',
  REGISTERED = 'REGISTERED',
  IN_SORTING = 'IN_SORTING',
  SORTED = 'SORTED',
  IN_PROCESSING = 'IN_PROCESSING',
  PROCESSED = 'PROCESSED',
  IN_TRANSIT = 'IN_TRANSIT'
}

export interface TrackedItemDto {
  uuid: string
  createdAt: string
  state: TrackedItemState
  trackingNumber: string
  trackingUrl: string
  type: TrackedItemType
  qrCode: MinimalFileUploadDto
}
