import {
  Box,
  IconButton,
  makeStyles,
  Modal,
  Typography
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import cx from 'classnames'
import React, { FunctionComponentElement, ReactElement, ReactNode } from 'react'

import { Theme } from '../../common/theme'

import { ClnModalActions, ClnModalActionsProps } from './ClnModalActions'
import { ClnModalContent, ClnModalContentProps } from './ClnModalContent'

interface Props {
  actions?: ReactElement<ClnModalActionsProps, typeof ClnModalActions>
  append?: ReactNode
  content: ReactElement<ClnModalContentProps, typeof ClnModalContent>
  contentSpacing?: boolean
  onClose: any
  show: boolean
  title?: string | null
  maxWidth?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  clnModal: {
    width: `calc(100vw - ${theme.spacing(4)}px)`,
    maxWidth: ({ maxWidth }: Props) => maxWidth || '960px',
    maxHeight: `calc(100vh - ${theme.spacing(2)}px)`,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    left: '50%',
    top: theme.spacing(2),
    borderRadius: theme.spacing(),
    boxShadow: theme.shadows[5],
    outline: 'none',
    overflow: 'hidden',
    transform: 'translate(-50%, 0)'
  },
  actions: {
    margin: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2)
    }
  },
  closeButton: {
    border: 'none'
  },
  closeButtonWrapper: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 5
  },
  content: {
    overflowY: 'auto'
  },
  contentWithSpacing: {
    margin: theme.spacing(4)
  },
  header: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(4)
  }
}))

export function ClnModal({
  actions,
  append,
  content,
  contentSpacing = true,
  show,
  title,
  maxWidth,
  onClose
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles({
    actions,
    content,
    contentSpacing,
    onClose,
    show,
    title,
    maxWidth
  })
  const contentClasses = cx(classes.content, {
    [classes.contentWithSpacing]: contentSpacing
  })

  return (
    <Modal open={show} onClose={onClose}>
      <>
        <Box className={classes.clnModal} display="flex" flexDirection="column">
          <Box position="absolute" className={classes.closeButtonWrapper}>
            <IconButton
              className={classes.closeButton}
              key="close"
              color="default"
              onClick={onClose}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>

          {title && (
            <Box className={classes.header}>
              <Typography variant="h3">{title}</Typography>
            </Box>
          )}

          <Box className={contentClasses} flexGrow={1} flexShrink={1}>
            {content}
          </Box>

          {actions && <Box className={classes.actions}>{actions}</Box>}
        </Box>

        {append}
      </>
    </Modal>
  )
}
