import React from 'react'

import { useTheme } from '@material-ui/styles'

// Material components
import { Chip } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'


function Tag (props) {
  const { value, color } = props

  const theme = useTheme()

  const StyledChip = withStyles({
    root: {
      color: color,
      borderColor: color,
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      marginRight: theme.spacing(),

      '&:hover': {
        backgroundColor: theme.palette.common.white
      },
    }
  })(Chip)

  return (
    <StyledChip
      label={value}
      variant='outlined'
    />
  )
}

export default Tag
