export default {
  moisturePercentage: {
    presence: {allowEmpty: false, message: 'validationRequired'},
    numericality: {
      greaterThan: 0,
      lessThan: 100
    }
  },
  calorificValue: {
    presence: {allowEmpty: false, message: 'validationRequired'},
    numericality: {
      greaterThan: 0
    }
  }
}
