import {
  Box,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons'
import React, { FunctionComponentElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from '../../../common/theme'
import { Paper } from '../../../components'
import { EmptyState } from '../../../components/EmptyState'
import {
  EntityFilterConfig,
  getSingleFilterValue
} from '../../../components/EntityFilters'
import { NoValue } from '../../../components/NoValue'
import { useFormatPrice } from '../../../hooks/format-price.hook'
import { useFormatQuantity } from '../../../hooks/format-quantity.hook'
import { AdminPortalLayout } from '../../../layouts/AdminPortalLayout'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { apiFetchWithDispatch, useFetchState } from '../../../lib/fetch'
import { saveExportFile } from '../../../lib/file'
import { useStateValue } from '../../../state'
import { MaterialHubStockDto } from '../../../types/material-hub-stock.dto'

import { HubStockFilters } from './HubStockFilters'

const useStyles = makeStyles<Theme>((theme) => ({
  hubStock: {
    //
  },
  topPaginationWrapper: {
    padding: theme.spacing(0.5, 1.5),
    borderBottom: `1px solid ${theme.palette.border}`
  }
}))

export function HubStock(): FunctionComponentElement<void> {
  const classes = useStyles()

  const formatPrice = useFormatPrice()
  const formatQuantity = useFormatQuantity()
  const [, dispatch] = useStateValue()
  const { t } = useTranslation()

  const [filters, setFilters] = useState<EntityFilterConfig[]>([])
  const [isExportLoading, setExportLoading] = useState<boolean>(false)

  const selectedHubUuid = getSingleFilterValue(filters)
  const hubUuidUrlParam = selectedHubUuid ? `?hubUuid=${selectedHubUuid}` : ''

  const [stockMaterials, isStockMaterialsLoading] = useFetchState<
    MaterialHubStockDto[]
  >(`/api/admin/hubs/stock${hubUuidUrlParam}`)

  async function handleExportIconClick() {
    setExportLoading(true)

    const result = await apiFetchWithDispatch(
      dispatch,
      `/api/admin/hubs/stock/export${hubUuidUrlParam}`,
      {
        method: 'POST'
      }
    )

    await saveExportFile(result)

    setExportLoading(false)
  }

  return (
    <AdminPortalLayout title="Hub stock">
      <PortalContent>
        <Box className={classes.hubStock}>
          <Box mb={5}>
            <Paper elevation={5} outlined={false}>
              <Box p={3}>
                <HubStockFilters
                  onChange={(filters) => {
                    setFilters(filters)
                  }}
                />
              </Box>
            </Paper>
          </Box>

          <Paper elevation={5} outlined={false}>
            {isStockMaterialsLoading ? (
              <Box display="flex" justifyContent="center" p={5}>
                <CircularProgress />
              </Box>
            ) : stockMaterials?.length ? (
              <>
                <Box className={classes.topPaginationWrapper}>
                  <Box className={classes.exportButtonWrapper}>
                    <Tooltip title="Export">
                      {isExportLoading ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="left"
                          height={42}
                          px={1}
                        >
                          <CircularProgress size={16} />
                        </Box>
                      ) : (
                        <IconButton
                          color="default"
                          size="medium"
                          onClick={handleExportIconClick}
                        >
                          <CloudDownloadIcon fontSize="large" />
                        </IconButton>
                      )}
                    </Tooltip>
                  </Box>
                </Box>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Material type</TableCell>
                      <TableCell>
                        <TableSortLabel active={true} direction="desc">
                          Stock
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Bale count</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {stockMaterials?.map((stockMaterial) => (
                      <TableRow key={stockMaterial.materialType} hover={true}>
                        <TableCell>
                          {t(`materialType_${stockMaterial.materialType}`)}
                        </TableCell>
                        <TableCell>
                          {stockMaterial.quantity ? (
                            formatQuantity(
                              stockMaterial.quantity,
                              stockMaterial.quantityUnit
                            )
                          ) : (
                            <NoValue />
                          )}
                        </TableCell>
                        <TableCell>
                          {stockMaterial.price ? (
                            formatPrice(
                              stockMaterial.price,
                              stockMaterial.currency
                            )
                          ) : (
                            <NoValue />
                          )}
                        </TableCell>
                        <TableCell>
                          {stockMaterial.baleCount ? (
                            stockMaterial.baleCount
                          ) : (
                            <NoValue />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            ) : (
              <Box p={5}>
                <EmptyState
                  heading="No stock"
                  message="There is no stock data currently available given your applied filters"
                />
              </Box>
            )}
          </Paper>
        </Box>
      </PortalContent>
    </AdminPortalLayout>
  )
}
