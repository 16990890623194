import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import React, { FunctionComponentElement, useEffect, useState } from 'react'

import { Theme } from 'common/theme'

import {
  EntityFilterConfig,
  EntityFilterField,
  EntityFilterOperator,
  EntityFilterType
} from '../../../components/EntityFilters'
import { EntityFilters } from '../../../components/EntityFilters/EntityFilters'
import { useFetchState } from '../../../lib/fetch'
import { CompanyType } from '../../../services/company'
import { getCountryByCurrentUserLang } from '../../../services/country'

export interface HubEventsFiltersProps {
  onChange: (filters: EntityFilterConfig[]) => void
}

const useStyles = makeStyles<Theme>(() => ({
  hubEventsFilters: {
    //
  }
}))

export function HubStockFilters({
  onChange
}: HubEventsFiltersProps): FunctionComponentElement<HubEventsFiltersProps> {
  const classes = useStyles()

  const [filters, setFilters] = useState<EntityFilterConfig[]>([])

  const [hubs] = useFetchState('/api/admin/companies/hubs')

  useEffect(() => {
    onChange(filters)
  }, [filters])

  useEffect(() => {
    if (hubs) {
      const hubOptions = hubs
        .filter(
          (hub) =>
            hub.pipelineOwner && hub.type === CompanyType.COLLECTION_CENTER
        )
        .sort((a, b) => a.country.localeCompare(b.country))
        .map((item) => ({
          data: item,
          label: `${item.name} (${item.state})`,
          value: item.uuid
        }))

      setFilters([
        {
          field: EntityFilterField.HUB_EVENT_HUB_UUID,
          groupBy: (option) =>
            option.data?.country
              ? getCountryByCurrentUserLang(option.data.country)
              : '',
          label: 'Hub',
          multiple: false,
          operator: EntityFilterOperator.IN,
          options: hubOptions,
          type: EntityFilterType.SELECT,
          value: null
        }
      ])
    }
  }, [hubs])

  return (
    <Box className={classes.hubEventsFilters}>
      {filters.length ? (
        <EntityFilters filters={filters} onChange={setFilters} />
      ) : (
        <Box display="flex" justifyContent="center" p={5}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}
