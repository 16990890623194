import {
  Box,
  CircularProgress,
  Container,
  Hidden,
  makeStyles,
  Typography
} from '@material-ui/core'
import cx from 'classnames'
import React, {
  FunctionComponentElement,
  ReactFragment,
  ReactNode
} from 'react'

import { AlertBar, Toast } from 'components'

import { Theme } from '../../common/theme'

import { usePortalLayout } from './portal-layout.hook'

interface PortalContentProps {
  children?: ReactNode
  loading?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  padding?: boolean
  showTitle?: boolean
  title?: string
  titleActions?: ReactNode
}

const useStyles = makeStyles<Theme>((theme) => ({
  portalContent: {
    width: 'calc(100% - 280px)',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexGrow: 1,

    '@media print': {
      backgroundColor: theme.palette.common.white
    }
  },
  container: {
    display: 'flex',
    flexGrow: 1,

    [theme.breakpoints.up('lg')]: {
      marginLeft: 0
    }
  },
  title: {
    lineHeight: '48px'
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5)
    }
  },
  content: {
    width: '100%'
  },
  contentWithPadding: {
    padding: theme.spacing(5),
    paddingRight: theme.spacing(7),
    paddingLeft: theme.spacing(7),

    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(20)
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(8)
    },

    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(7)
    },

    '@media print': {
      paddingTop: theme.spacing(0)
    }
  },
  alertBar: {
    marginBottom: theme.spacing(3)
  }
}))

export function PortalContent({
  children,
  loading,
  maxWidth,
  padding,
  showTitle,
  titleActions
}: PortalContentProps): FunctionComponentElement<PortalContentProps> {
  const classes = useStyles()
  const { title } = usePortalLayout()

  const getContent = (): ReactFragment => {
    return (
      <>
        {loading ? (
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            className={cx(classes.content, {
              [classes.contentWithPadding]: padding !== false
            })}
          >
            {((title && showTitle !== false) || titleActions) && (
              <Hidden mdDown={!titleActions}>
                <Box className={classes.titleWrapper}>
                  <Typography className={classes.title} variant="h2">
                    <Hidden mdDown={true}>{title}</Hidden>
                  </Typography>

                  {titleActions && <Box>{titleActions}</Box>}
                </Box>
              </Hidden>
            )}

            <AlertBar className={classes.alertBar} />

            <Toast />

            {children}
          </Box>
        )}
      </>
    )
  }

  return (
    <Box className={classes.portalContent}>
      {maxWidth ? (
        <Container className={classes.container} maxWidth={maxWidth}>
          {getContent()}
        </Container>
      ) : (
        getContent()
      )}
    </Box>
  )
}
