import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog
} from '@material-ui/core'

import { Transition } from 'components'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    borderBottom: `1px solid ${theme.palette.common.neutral}`,

    '& h2': {
      fontSize: 16
    }
  },
  dialogContent: {
    marginTop: theme.spacing(1.5)
  },
  dialogActions: {
    marginRight: theme.spacing()
  }
}))

export default function ConfirmDialog (props) {
  const {
    title,
    message,
    open,
    onClose,
    onCancel,
    onConfirm
  } = props

  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          size='large'
          onClick={onCancel}
          color='default'
          variant='outlined'
        >
          {t('cancelButton')}
        </Button>
        <Button
          size='large'
          onClick={onConfirm}
          color='primary'
          variant='contained'
        >
          {t('confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
