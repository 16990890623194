import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// Externals
import classNames from 'classnames'

// Material components
import {
  Button,
  CircularProgress,
  Typography,
  withStyles
} from '@material-ui/core'

import { DoneOutline as DoneOutlineIcon } from '@material-ui/icons'

import { SummaryLine, InstructionDialog } from 'components'

// Component styles
import styles from './styles'
import { useTranslation } from 'react-i18next'

import { getDisplayedQuantityUnit } from 'services/quantityunit'
import moment from 'moment'
import { apiFetchWithDispatch, useFetchState } from 'lib/fetch'
import { UserInstructions } from 'services/userinstruction'

import { useStateValue } from 'state'

function CollectionCenterGrading(props) {
  const { classes, className, segregationUnit } = props

  const [
    finishGradingInstructionView,
    isFinishGradingInstructionViewLoading
  ] = useFetchState(
    `/api/userInstructionViews?instruction=${UserInstructions.FINISH_MATERIAL_GRADING}`
  )

  const [openUserInstructionDialog, setOpenUserInstructionDialog] = useState(
    false
  )

  const rootClassName = classNames(classes.root, className)

  const [, dispatch] = useStateValue()
  const { t } = useTranslation()
  const history = useHistory()

  const displayedQuantityUnit = getDisplayedQuantityUnit(
    segregationUnit.quantityUnit
  )

  const gradingUnit =
    segregationUnit.collectedMaterials &&
    segregationUnit.collectedMaterials.length > 0
      ? segregationUnit.collectedMaterials[0].registrationUnit
      : null

  function handleFinishGrading() {
    if (finishGradingInstructionView.viewsCount > 3) {
      redirectToRegisterWasteBag()
      return
    }

    setOpenUserInstructionDialog(true)
  }

  function handleContinueAfterUserInstruction() {
    setOpenUserInstructionDialog(false)

    apiFetchWithDispatch(dispatch, '/api/userInstructionViews', {
      method: 'POST',
      body: JSON.stringify({
        instruction: UserInstructions.FINISH_TRUCK_LOAD_GRADING
      })
    })

    redirectToRegisterWasteBag()
  }

  function redirectToRegisterWasteBag() {
    history.push(
      `/segregation-unit/${segregationUnit.uuid}/register-output-material-bag`
    )
  }

  function handleCloseUserInstruction() {
    setOpenUserInstructionDialog(false)
  }

  if (isFinishGradingInstructionViewLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={rootClassName}>
      <Typography className={classes.title} variant="h3">
        {t('ongoingMaterialGradingSummary')}
      </Typography>
      <div className={classes.content}>
        <SummaryLine
          title={t('started')}
          value={`${moment(segregationUnit.createdAt).format(
            'DD/MM/YYYY HH:mm'
          )}`}
        />
        {segregationUnit.originalMaterialType && (
          <SummaryLine
            title={t('originalMaterialType')}
            value={t(`materialType_${segregationUnit.originalMaterialType}`)}
          />
        )}
        {segregationUnit.inputTrackedItemsCount && (
          <SummaryLine
            title={t('inputTrackedItemsCount')}
            value={segregationUnit.inputTrackedItemsCount}
          />
        )}
        {segregationUnit.formattedWeight && (
          <SummaryLine
            title={t('weight')}
            value={
              <>
                {segregationUnit.formattedWeight} {displayedQuantityUnit}
              </>
            }
          />
        )}
        {gradingUnit && (
          <SummaryLine
            title={t('gradingUnit')}
            value={t(`registrationUnit_${gradingUnit}`)}
          />
        )}

        <Button
          size="large"
          variant="contained"
          className={classes.finishGradingButton}
          onClick={handleFinishGrading}
          startIcon={
            <DoneOutlineIcon
              style={{
                height: 50,
                width: 50,
                fontWeight: 30
              }}
            />
          }
        >
          {t('finishGradingButton')}
        </Button>
      </div>

      <InstructionDialog
        message={t(
          `userInstruction_${UserInstructions.FINISH_MATERIAL_GRADING}`
        )}
        open={openUserInstructionDialog}
        onClose={handleCloseUserInstruction}
        onContinue={handleContinueAfterUserInstruction}
      />
    </div>
  )
}

export default withStyles(styles)(CollectionCenterGrading)
