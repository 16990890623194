import React, { useState } from 'react'

// Material components
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
  withStyles
} from '@material-ui/core'

// Component styles
import styles from './styles'
import { useTranslation } from 'react-i18next'

import {
  LocalShipping as LocalShippingIcon
} from '@material-ui/icons'

import { getDisplayedQuantityUnit } from 'services/quantityunit'
import LinkButton from 'components/LinkButton'
import { MaterialClearancesApiService } from 'services/materialclearance/material-clearances-api.service'
import { getCurrentUser } from 'services/user';
import { MaterialType } from 'services/materialtype/material-type.types'

function CollectionCenterClearance (props) {
  const { classes } = props

  const { t } = useTranslation()

  const [clearableMaterials, isLoading] = MaterialClearancesApiService.getSummary()

  const [checkedMaterialTypes, setCheckedMaterialTypes] = useState(
    Object
      .values(MaterialType)
      .reduce((sum, item) => ({
        ...sum,
        [item]: false
      }), {})
  )

  const { showClearanceWeight } = getCurrentUser()

  function getCheckedMaterialTypesArray () {
    return Object
      .entries(checkedMaterialTypes)
      .filter(([, value]) => value === true)
      .map(([key]) => key)
  }

  function handleChangeMaterialType (event) {
    event.persist()

    setCheckedMaterialTypes({ ...checkedMaterialTypes, [event.target.name]: event.target.checked })
  }

  if (isLoading || !clearableMaterials) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  if (clearableMaterials.totalStock === 0) {
    return (
      <>
        <Typography
          className={classes.inactiveFormMessage}
          variant='subtitle1'
        >
          {t('noMaterialForClearance')}
        </Typography>
      </>
    )
  }

  return (
    <div>
      <form autoComplete='off' noValidate>
        <div className={classes.radioGroupField}>
          <FormControl component='fieldset'>
            <FormLabel
              component='legend'
              className={classes.radioGroupLabel}
            >
              {t('selectMaterialTypesForClearance')}:
            </FormLabel>
              {
                Object.keys(clearableMaterials.materialBreakdown)
                  .map(materialType => (
                    <FormControlLabel
                      key={materialType}
                      control={
                        <Checkbox
                          key={materialType}
                          name={materialType}
                          checked={checkedMaterialTypes[materialType]}
                          onChange={handleChangeMaterialType}
                          color="secondary"
                        />
                      }
                      label={
                        <>
                          {t(`materialType_${materialType}`)}
                          {showClearanceWeight && (
                            <>
                              &nbsp;-
                              &nbsp;{clearableMaterials.materialBreakdown[materialType].toFixed(3)}
                              &nbsp;{getDisplayedQuantityUnit(clearableMaterials.totalStockUnit)}
                            </>
                          )}
                        </>
                      }
                    />
                  ))
              }
          </FormControl>
        </div>

        <LinkButton
          size='large'
          variant='outlined'
          disabled={getCheckedMaterialTypesArray().length === 0}
          className={classes.clearMaterialStockButton}
          href={`/clear-material-stock?materialTypes=${getCheckedMaterialTypesArray().join()}`}
          startIcon={<LocalShippingIcon style={{ height: 30 }} />}
        >
          {t('clearMaterialStockButton')}
        </LinkButton>
      </form>
    </div>
  )
}

export default withStyles(styles)(CollectionCenterClearance)
