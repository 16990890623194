export default theme => ({
  root: {
    paddingTop: theme.spacing(),
    padding: theme.spacing(2)
  },
  title: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(3),
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  formSection: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),

    '& h5': {
      marginBottom: theme.spacing()
    }
  },
  field: {
    width: 300
  },
  radioGroupField: {
    width: 300,
    marginTop: theme.spacing(2)
  },
  radioGroupLabel: {
    marginBottom: theme.spacing()
  },
  content: {
    marginTop: theme.spacing(2)
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  textField: {
    width: 280,
    maxWidth: '100%',
    marginTop: theme.spacing()
  },
  weightField: {
    width: 190,
    maxWidth: '100%',
    marginTop: theme.spacing()
  },
  quantityUnitField: {
    width: 30,
    maxWidth: '100%',
    marginTop: theme.spacing()
  },
  selectField: {
    width: 240,
    maxWidth: '100%',
    marginTop: theme.spacing(2)
  },
  imagePreview: {
    width: 300,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  actionButtons: {
    marginTop: theme.spacing(3)
  },
  cancelButton: {
    marginRight: theme.spacing()
  },
  saveRegistrationButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing()
  }
})
