import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Typography
} from '@material-ui/core'
import React, { FunctionComponentElement, useState } from 'react'
import { Theme } from '../../../../common/theme'
import { useGeneralStyles } from '../../../../common/styles/general'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { HandlingCenterMaterialApiService } from '../../../../services/handling-center-material/handling-center-material-api.service'
import FlowActionButton from '../../../../components/FlowActionButton'
import { LocalShipping as LocalShippingIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  materialHandlingCenterClearance: {
    marginBottom: theme.spacing()
  },
  radioGroupLabel: {
    fontSize: 18,
    marginBottom: theme.spacing(3)
  },
  inactiveFormMessage: {
    paddingLeft: theme.spacing(),
    paddingBottom: theme.spacing()
  }
}))

function MaterialHandlingCenterClearance(): FunctionComponentElement<void> {
  const classes = useStyles()

  const generalClasses = useGeneralStyles()
  const titleClasses = cx([generalClasses.title, generalClasses.sectionTitle])

  const { t } = useTranslation()

  const [
    clearanceOverview,
    isClearanceOverviewLoading
  ] = HandlingCenterMaterialApiService.getClearanceOverview()

  const [checkedMaterialTypes, setCheckedMaterialTypes] = useState({})

  function getCheckedMaterialTypesArray() {
    return checkedMaterialTypes ? Object.keys(checkedMaterialTypes) : []
  }

  function handleChangeMaterialType(event) {
    event.persist()

    setCheckedMaterialTypes({
      ...checkedMaterialTypes,
      [event.target.name]: event.target.checked
    })
  }

  if (isClearanceOverviewLoading || !clearanceOverview) {
    return (
      <div className={generalClasses.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  if (clearanceOverview.registeredUnitsCount === 0) {
    return (
      <>
        <Typography className={classes.inactiveFormMessage} variant="subtitle1">
          {t('noMaterialForClearance')}
        </Typography>
      </>
    )
  }

  return (
    <Box className={classes.materialHandlingCenterClearance}>
      <Typography className={titleClasses} variant="h3">
        {t('materialHandlingCenterClearanceOverview')}
      </Typography>

      <Box className={generalClasses.content} px={1}>
        <FormControl component="fieldset">
          <FormLabel component="legend" className={classes.radioGroupLabel}>
            {t('selectMaterialTypesForClearance')}:
          </FormLabel>
          {clearanceOverview &&
            clearanceOverview.materialTypes &&
            clearanceOverview.materialTypes.map((materialType) => (
              <>
                <FormControlLabel
                  key={materialType}
                  control={
                    <Checkbox
                      key={materialType}
                      name={materialType}
                      checked={checkedMaterialTypes[materialType]}
                      onChange={handleChangeMaterialType}
                      color="secondary"
                    />
                  }
                  label={<>{t(`materialType_${materialType}`)}</>}
                />
              </>
            ))}
        </FormControl>

        <FlowActionButton
          color="secondary"
          href={`/clear-handling-center-material/${getCheckedMaterialTypesArray().join()}`}
          icon={LocalShippingIcon}
          disabled={getCheckedMaterialTypesArray().length === 0}
        >
          {t('clearMaterialButton')}
        </FlowActionButton>
      </Box>
    </Box>
  )
}

export default MaterialHandlingCenterClearance
