export default {
  weight: {
    presence: { allowEmpty: false, message: 'validationRequired' },
    length: { maximum: 10, message: 'validationExceedsMaxLength' },
    numericality: {
      greaterThanOrEqualTo: 100
    }
  },
  selectedOngoingTransport: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  }
}
