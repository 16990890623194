import React, { FunctionComponentElement, ReactNode, useEffect } from 'react'

import { useTracking } from '../lib/tracking'

interface TabContentProps {
  children?: ReactNode
  tabName?: string
}

export function TabContent({
  children,
  tabName
}: TabContentProps): FunctionComponentElement<TabContentProps> {
  const { trackTabView } = useTracking()

  useEffect(() => {
    if (tabName) {
      trackTabView(tabName)
    }
  }, [tabName])

  return <>{children}</>
}
