import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  TextField
} from '@material-ui/core'
import React, { Fragment, FunctionComponentElement, useState } from 'react'

import { match } from 'react-router-dom'
import { Theme } from '../../common/theme'

import { AdminPortalLayout } from '../../layouts/AdminPortalLayout'
import { PortalContent } from '../../layouts/portal/PortalContent'
import { apiFetchWithDispatch, useFetchState } from '../../lib/fetch'
import { useGeneralStyles } from '../../common/styles/general'
import { useFormStyles } from '../../common/styles/form'
import { DropzoneArea } from 'material-ui-dropzone'
import { uploadFileByAdminWithDispatch } from '../../services/fileupload'
import { useStateValue } from '../../state'
import {
  FileUploadDto,
  FileUploadPostAnalysisDto
} from '../../services/fileupload/file-upload.types'

import { Image, Paper } from '../../components'
import { useForm } from '../../lib/form'

interface Props {
  match: match<{ hubCompanyUuid: string }>
}

interface CollectedMaterialRequest {
  fileUpload: FileUploadDto
  weight?: number | null
  saved: boolean
}

const useStyles = makeStyles<Theme>(() => ({
  adminRegisterCollectedMaterials: {
    width: '50%'
  },
  weightField: {
    width: 190,
    maxWidth: '100%'
  }
}))

const schema = {
  materialType: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  },
  latitude: {
    presence: { allowEmpty: false, message: 'validationRequired' },
    numericality: {
      greaterThan: -90,
      lessThan: 90,
      message: 'validationInvalid'
    }
  },
  longitude: {
    presence: { allowEmpty: false, message: 'validationRequired' },
    numericality: {
      greaterThan: -180,
      lessThan: 180,
      message: 'validationInvalid'
    }
  }
}

function AdminRegisterCollectedMaterials({
  match
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()
  const formClasses = useFormStyles()

  const [, dispatch] = useStateValue()

  const {
    params: { hubCompanyUuid }
  } = match

  const [hub, isHubLoading] = useFetchState(
    `/api/admin/companies/hubs/${hubCompanyUuid}`
  )

  const {
    formState: { values, isValid },
    handleChange,
    hasError
  } = useForm(schema, {
    materialType: '',
    latitude: '',
    longitude: ''
  })

  const [collectedMaterials, setCollectedMaterials] = useState<
    CollectedMaterialRequest[]
  >([])
  const [pendingFileUploadCount, setPendingFileUploadCount] = useState(0)

  function filterWeightValue(value: string): string {
    return value
      .toUpperCase()
      .replace(' ', '')
      .replace('O', '0')
      .replace('I', '1')
      .replace('S', '5')
      .replace('G', '6')
      .trim()
  }

  function parseWeight(
    postAnalysisResults: FileUploadPostAnalysisDto[]
  ): number | null {
    if (!postAnalysisResults || !postAnalysisResults[0]) {
      return null
    }

    const firstResult = postAnalysisResults[0]

    if (!firstResult.analysisResults) {
      return null
    }

    const parsedWeight = firstResult.analysisResults
      .map((value) => filterWeightValue(value))
      .find((value) =>
        /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(value)
      )

    const containsDotOrComma = parsedWeight
      ? parsedWeight.includes('.') || parsedWeight.includes(',')
      : false

    return parsedWeight
      ? Number(parsedWeight) / (containsDotOrComma ? 1 : 1000)
      : null
  }

  async function handleFilesUpload(files) {
    if (files.length === 0) {
      return
    }

    setPendingFileUploadCount(files.length)

    const { latitude, longitude } = values

    for (const file of files) {
      const fileUpload = await uploadFileByAdminWithDispatch(
        dispatch,
        file,
        hubCompanyUuid,
        latitude,
        longitude
      )

      setPendingFileUploadCount((prevPendingCount) => prevPendingCount - 1)

      if (fileUpload) {
        setCollectedMaterials(
          (prevCollectedMaterials: CollectedMaterialRequest[]) => [
            ...prevCollectedMaterials,
            {
              fileUpload,
              file,
              saved: false,
              weight: parseWeight(fileUpload.postAnalysisResults)
            }
          ]
        )
      }
    }
  }

  function setWeight(event: any, index: number) {
    const collectedMaterial = collectedMaterials[index]
    collectedMaterial.weight = event.target.value

    const currentCollectedMaterials = [...collectedMaterials]
    currentCollectedMaterials[index] = collectedMaterial
    setCollectedMaterials(currentCollectedMaterials)
  }

  async function handleSave(index: number) {
    const collectedMaterial = collectedMaterials[index]

    const { fileUpload, weight } = collectedMaterial

    await apiFetchWithDispatch(dispatch, '/api/admin/collectedMaterials', {
      method: 'POST',
      body: JSON.stringify({
        weight,
        materialType: values.materialType,
        pictureFileUploadUuid: fileUpload ? fileUpload.uuid : null,
        hubCompanyUuid
      })
    })

    collectedMaterial.saved = true
    const currentCollectedMaterials = [...collectedMaterials]
    currentCollectedMaterials[index] = collectedMaterial
    setCollectedMaterials(currentCollectedMaterials)
  }

  if (isHubLoading || !hub) {
    return (
      <AdminPortalLayout title="Register Collected Materials">
        <PortalContent loading={true} />
      </AdminPortalLayout>
    )
  }

  return (
    <AdminPortalLayout title={`Register Collected Materials for ${hub.name}`}>
      <PortalContent>
        <Box className={classes.adminRegisterCollectedMaterials}>
          <Paper elevation={5} outlined={false}>
            <Box p={3}>
              <Box>
                <TextField
                  name="materialType"
                  select
                  required
                  label="Select material type"
                  className={formClasses.selectField}
                  value={values.materialType}
                  onChange={handleChange}
                  error={hasError('materialType')}
                  SelectProps={{ native: true }}
                  margin="dense"
                >
                  <>
                    <option key="" value="" />
                    {hub.collectedMaterialTypes.map((materialType) => (
                      <option key={materialType} value={materialType}>
                        {materialType}
                      </option>
                    ))}
                  </>
                </TextField>
              </Box>

              <Box my={2}>
                <TextField
                  name="latitude"
                  required
                  label="Latitude"
                  className={formClasses.weightField}
                  value={values.latitude}
                  onChange={handleChange}
                  error={hasError('latitude')}
                />

                <TextField
                  name="longitude"
                  required
                  label="Longitude"
                  className={formClasses.weightField}
                  value={values.longitude}
                  onChange={handleChange}
                  error={hasError('longitude')}
                />
              </Box>

              <Box mt={2}>
                {pendingFileUploadCount > 0 ? (
                  <Box
                    width="100%"
                    height={200}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText="Upload collected material pictures"
                    maxFileSize={3000000}
                    filesLimit={100}
                    onChange={handleFilesUpload}
                    showPreviewsInDropzone={false}
                    showAlerts={false}
                  />
                )}
              </Box>
            </Box>
          </Paper>

          <Box mt={2}>
            {collectedMaterials.map(
              (collectedMaterial: CollectedMaterialRequest, index) => (
                <Fragment key={collectedMaterial.fileUpload.uuid}>
                  {!collectedMaterial.saved && (
                    <Paper elevation={5} outlined={false}>
                      <Box p={3}>
                        <Box
                          key={index}
                          display="flex"
                          alignItems="flex-end"
                          width="100%"
                          py={2}
                        >
                          <Image
                            source={collectedMaterial.fileUpload.directLink}
                            width="50%"
                          />
                          <Box pl={1} alignItems="flex-end">
                            <TextField
                              name="weight"
                              autoFocus
                              className={classes.weightField}
                              type="number"
                              label={'Weight'}
                              margin="dense"
                              required
                              value={collectedMaterial.weight}
                              onChange={(event) => setWeight(event, index)}
                            />
                            <Box pt={2}>
                              <Button
                                size="large"
                                variant="contained"
                                className={generalClasses.primaryButton}
                                disabled={!collectedMaterial.weight || !isValid}
                                onClick={() => handleSave(index)}
                              >
                                Save
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  )}
                </Fragment>
              )
            )}
          </Box>
        </Box>
      </PortalContent>
    </AdminPortalLayout>
  )
}

export default AdminRegisterCollectedMaterials
