const GIPHY_API_KEY = 'Mocv5nwq02JLbeywYPuoMUhGw3CEjLlp'

export interface GiphyImage {
  frames: string
  hash: string
  height: string
  mp4: string
  size: string
  url: string
  webp: string
  width: string
}

export async function getRandomGiphy(tag: string): Promise<GiphyImage | null> {
  const response = await window.fetch(
    `https://api.giphy.com/v1/gifs/random?rating=r&api_key=${GIPHY_API_KEY}&tag=${tag}`
  )
  const responseBody = await response.json()

  return responseBody?.data.images.fixed_height ?? null
}
