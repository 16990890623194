import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { SvgIconComponent } from '@material-ui/icons'
import cx from 'classnames'
import React, { createElement, FunctionComponentElement } from 'react'
import { useHistory } from 'react-router-dom'

import { Theme } from '../../common/theme'

import { usePortalNavigationStyles } from './portal-navigation-styles.hook'

export interface NavigationItem {
  icon: SvgIconComponent
  link?: string
  name: string
  onClick?: (navigationItem: NavigationItem) => void
}

export interface PortalNavigationItemProps {
  collapsibleNavigation?: boolean
  desktopDrawerCollapsed: boolean
  item: NavigationItem
  showText: boolean
}

export function PortalNavigationItem({
  collapsibleNavigation,
  desktopDrawerCollapsed,
  item,
  showText
}: PortalNavigationItemProps): FunctionComponentElement<PortalNavigationItemProps> {
  const theme = useTheme<Theme>()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const classes = usePortalNavigationStyles({
    collapsed: !isMobile && desktopDrawerCollapsed && !!collapsibleNavigation
  })

  const history = useHistory()

  function handleNavigationItemClick(item: NavigationItem): void {
    if (item.link) {
      history.push(item.link)
    }

    if (item.onClick) {
      item.onClick(item)
    }
  }

  function isSelected(item: NavigationItem): boolean {
    return history.location.pathname === item.link
  }

  return (
    <ListItem
      button={true}
      selected={isSelected(item)}
      onClick={() => handleNavigationItemClick(item)}
      classes={{
        gutters: classes.listItemGutters,
        selected: classes.listItemSelected
      }}
    >
      <ListItemIcon
        classes={{
          root: cx(classes.listItemIconRoot, {
            [classes.listItemIconRootSelected]: isSelected(item)
          })
        }}
      >
        {createElement(item.icon)}
      </ListItemIcon>

      {showText && (
        <ListItemText className={classes.listItemText}>
          <Typography
            className={cx({
              [classes.itemNameSelected]: isSelected(item)
            })}
            variant="body1"
          >
            {item.name}
          </Typography>
        </ListItemText>
      )}
    </ListItem>
  )
}
