import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { compose } from 'recompose'

import { EmptyState } from '../../../../../components/EmptyState'
import Image from '../../../../../components/Image'
import LinkButton from '../../../../../components/LinkButton'
import { apiFetchWithDispatch, useFetchState } from '../../../../../lib/fetch'
import { useForm } from '../../../../../lib/form'
import { CameraResolution } from '../../../../../services/cameraresolution'
import {
  CompanyState,
  CompanyType,
  DataExportReportFrequency,
  getHubCompanyTypeOptions,
  HubCompanyTypes
} from '../../../../../services/company'
import { getCountryOptions } from '../../../../../services/country'
import { getCurrencyOptions } from '../../../../../services/currency'
import { Feature, getFeatureOptions } from '../../../../../services/feature'
import { getGenderOptions } from '../../../../../services/gender'
import { getSupportedHubEventReviewTags } from '../../../../../services/hub-events'
import { getLanguageOptions } from '../../../../../services/language'
import {
  getAllCollectedMaterialTypeOptions,
  getAllMaterialTypeOptions
} from '../../../../../services/materialtype'
import { getAllProcessingStepOptions } from '../../../../../services/processingstep'
import { getAllProductTypesOptions } from '../../../../../services/producttype'
import {
  getUserAuthTypesOptions,
  UserAuthType
} from '../../../../../services/user'
import { useStateValue } from '../../../../../state'

import styles from './styles'

function getFormSchema(features) {
  const schema = {
    name: {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 100, message: 'validationExceedsMaxLength' }
    },
    type: {
      presence: { allowEmpty: false, message: 'validationRequired' }
    },
    cameraResolution: {
      presence: { allowEmpty: false, message: 'validationRequired' }
    },
    currency: {
      presence: { allowEmpty: false, message: 'validationRequired' }
    },
    street: {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 64, message: 'validationExceedsMaxLength' }
    },
    zipCode: {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 10, message: 'validationExceedsMaxLength' }
    },
    city: {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 60, message: 'validationExceedsMaxLength' }
    },
    country: {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 2, message: 'validationExceedsMaxLength' }
    },
    allowUnregisteredSuppliers: {
      type: 'boolean',
      presence: { allowEmpty: false, message: 'validationRequired' }
    }
  }

  if (features.includes(Feature.MATERIAL_SEGREGATION)) {
    schema.segregationOutputMaterialTypes = {
      presence: { allowEmpty: false, message: 'validationRequired' }
    }

    if (features.includes(Feature.MASS_BALANCE_TRACKING)) {
      schema.segregationInputMaterialTypes = {
        presence: { allowEmpty: false, message: 'validationRequired' }
      }
    }
  }

  if (features.includes(Feature.MATERIAL_PROCESSING)) {
    schema.processingInputMaterialTypes = {
      presence: { allowEmpty: false, message: 'validationRequired' }
    }

    schema.processingOutputMaterialTypes = {
      presence: { allowEmpty: false, message: 'validationRequired' }
    }

    schema.processingOutputProductTypes = {
      presence: { allowEmpty: false, message: 'validationRequired' }
    }
  }

  return schema
}

function getUserFormSchema(authType) {
  const schema = {
    gender: {
      presence: { allowEmpty: false, message: 'validationRequired' }
    },
    firstName: {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 64, message: 'validationExceedsMaxLength' }
    },
    lastName: {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 64, message: 'validationExceedsMaxLength' }
    },
    authType: {
      presence: { allowEmpty: false, message: 'validationRequired' }
    },
    language: {
      presence: { allowEmpty: false, message: 'validationRequired' }
    },
    email: {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 255, message: 'validationExceedsMaxLength' }
    }
  }

  if (authType === UserAuthType.SMS) {
    schema.phoneNumber = {
      presence: { allowEmpty: false, message: 'validationRequired' },
      format: {
        pattern: /^\+\d{1,15}$/,
        message: 'validationPhoneNumberPattern'
      },
      length: { minimum: 6, message: 'validationPhoneNumberMinLength' }
    }
  }

  return schema
}

function AddHubForm(props) {
  const { classes, history, hub } = props

  const [availableSuppliers, setAvailableSuppliers] = useState([])

  const [availableCompanies, setAvailableCompanies] = useState([])

  const [formSchema, setFormSchema] = useState({})

  const [userFormSchema, setUserFormSchema] = useState({})

  const [
    isAvailableCompaniesLoading,
    setIsAvailableCompaniesLoading
  ] = useState(false)
  const [
    isAvailableSuppliersLoading,
    setIsAvailableSuppliersLoading
  ] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const { t } = useTranslation()

  const [, dispatch] = useStateValue()

  const animatedComponents = makeAnimated()
  const isLoading =
    isAvailableCompaniesLoading || isAvailableSuppliersLoading || isSaving

  const {
    formState: { values, errors, isValid },
    setValue,
    handleChange,
    resetForm,
    hasError
  } = useForm(
    formSchema,
    hub
      ? {
          ...hub,
          organizationUuid: hub.organization?.uuid ?? null,
          assignedCompanyUuids:
            hub.assignedCompanies?.map((ac) => ac.company.uuid) ?? []
        }
      : {
          name: '',
          type: '',
          features: [],
          cameraResolution: CameraResolution.W720_H960,
          collectedMaterialTypes: [],
          street: '',
          zipCode: '',
          city: '',
          region: '',
          country: '',
          currency: null,
          allowUnregisteredSuppliers: false,
          showClearanceWeight: true,
          showMaterialClearanceBuyer: true,
          showMaterialClearanceTransport: false,
          weighMaterialClearance: false,
          weighMaterialCollection: true,
          enableDocumentUpload: false,
          pipelineOwner: false,
          autoPublishEvents: false,
          enableBaleSegregationOutput: false,
          generateSustainabilityDeclaration: false,
          enableRegistrationTruckPictureUpload: false,
          enablePlasticCredits: false,
          collectorSurveyLink: null,
          dataExportReportFrequency: DataExportReportFrequency.WEEKLY,
          segregationInputMaterialTypes: [],
          segregationOutputMaterialTypes: [],
          processingInputMaterialTypes: [],
          processingOutputMaterialTypes: [],
          processingOutputProductTypes: [],
          processingSteps: [],
          eventAutoReviewTags: [],
          eventAppReviewTags: [],
          organizationUuid: null,
          assignedCompanyUuids: []
        }
  )

  const {
    formState: { values: userValues, errors: userErrors, isValid: isUserValid },
    handleChange: handleUserChange,
    resetForm: resetUserForm,
    hasError: hasUserError
  } = useForm(userFormSchema, {
    gender: '',
    firstName: '',
    lastName: '',
    email: null,
    phoneNumber: null,
    language: '',
    authType: ''
  })

  const [organizationHubs] = useFetchState(
    `/api/admin/companies/hubs?type=${CompanyType.ORGANIZATION}`
  )

  const organizationHubOptions = useMemo(() => {
    return (organizationHubs ?? []).map((organization) => ({
      label: organization.name,
      value: organization.uuid
    }))
  }, [organizationHubs])

  const selectedOrganizationHubOption = useMemo(() => {
    return (
      (organizationHubOptions ?? []).find(
        (organization) => organization.value === values.organizationUuid
      ) ?? null
    )
  }, [organizationHubOptions, values.organizationUuid])

  const getAvailableSuppliers = useCallback(async () => {
    if (!values.type) {
      return
    }

    setIsAvailableSuppliersLoading(true)

    const availableSuppliers = await apiFetchWithDispatch(
      dispatch,
      `/api/admin/companies/hubs/suppliers?type=${values.type}`
    )

    setAvailableSuppliers(
      hub
        ? availableSuppliers.filter((supplier) => supplier.uuid !== hub.uuid)
        : availableSuppliers
    )

    setIsAvailableSuppliersLoading(false)
  }, [dispatch, values.type, hub])

  useEffect(() => {
    getAvailableSuppliers()
  }, [getAvailableSuppliers])

  useEffect(() => {
    setFormSchema(getFormSchema(values.features))
    setUserFormSchema(getUserFormSchema(values.authType))
  }, [values])

  function getSupplierValueOptions() {
    if (!values.supplierUuids) {
      return null
    }

    return values.supplierUuids.map((supplierUuid) => ({
      value: supplierUuid,
      label: getSupplierLabel(supplierUuid)
    }))
  }

  function getSupplierLabel(supplierUuid) {
    if (!availableSuppliers) {
      return ''
    }

    const supplier = availableSuppliers.find(
      (supplier) => supplier.uuid === supplierUuid
    )
    return supplier ? supplier.name : ''
  }

  const loadOrganizationUnassignedCompanies = useCallback(async () => {
    if (values.type !== CompanyType.ORGANIZATION) {
      return
    }

    setIsAvailableCompaniesLoading(true)

    const availableCompanies = await apiFetchWithDispatch(
      dispatch,
      `/api/admin/companies/hubs?states=${CompanyState.ACTIVE}`
    )

    setAvailableCompanies(
      hub
        ? availableCompanies.filter((company) => company.uuid !== hub.uuid)
        : availableCompanies
    )

    setIsAvailableCompaniesLoading(false)
  }, [dispatch, values.type, hub])

  useEffect(() => {
    loadOrganizationUnassignedCompanies()
  }, [loadOrganizationUnassignedCompanies])

  const getSelectedAvailableCompanies = () => {
    if (!values.assignedCompanyUuids) {
      return null
    }

    return values.assignedCompanyUuids.map((assignedCompanyUuid) => ({
      value: assignedCompanyUuid,
      label:
        availableCompanies.find(
          (company) => company.uuid === assignedCompanyUuid
        )?.name ?? ''
    }))
  }

  const getAvailableCompaniesOptions = () => {
    if (!availableCompanies) {
      return []
    }

    return availableCompanies.map((company) => ({
      label: company.name,
      value: company.uuid
    }))
  }

  function handleTypeChange(event) {
    setValue('type', event.target.value)
    setValue('supplierUuids', [])

    if (event.target.value === CompanyType.ORGANIZATION) {
      setValue('assignedCompanyUuids', null)
      setValue('organizationUuid', null)
    }
  }

  function handleOrganizationUuidChange(newValue) {
    setValue('organizationUuid', newValue?.value ?? null)
  }

  function handleAssignedCompanyUuidChange(newValues) {
    setValue(
      'assignedCompanyUuids',
      newValues ? [...newValues].map((company) => company.value) : []
    )
  }

  function handleSupplierSelectChange(newValue) {
    setValue(
      'supplierUuids',
      newValue ? [...newValue].map((supplier) => supplier.value) : []
    )
  }

  function getSupplierOptions() {
    if (!availableSuppliers) {
      return []
    }

    return availableSuppliers.map((supplier) => ({
      value: supplier.uuid,
      label: supplier.name
    }))
  }

  function getFeatureValueOptions() {
    if (!values.features) {
      return []
    }

    return values.features.map((featureValue) => ({
      value: featureValue,
      label: featureValue
    }))
  }

  function handleFeatureSelectChange(newValue) {
    const newFeatures = newValue
      ? [...newValue].map((feature) => feature.value)
      : []
    if (!newFeatures.includes(Feature.EVENT_REVIEW)) {
      setValue('autoPublishEvents', false)
    }
    if (!newFeatures.includes(Feature.MATERIAL_SEGREGATION)) {
      setValue('enableBaleSegregationOutput', false)
    }

    if (
      newFeatures.includes(Feature.COLLECTION_CENTER_WEEKLY_DATA_EXPORT) &&
      !values.dataExportReportFrequency
    ) {
      setValue('dataExportReportFrequency', DataExportReportFrequency.WEEKLY)
    }

    setValue('features', newFeatures)
  }

  function getSegregationInputMaterialTypeValueOptions() {
    if (!values.segregationInputMaterialTypes) {
      return []
    }

    return values.segregationInputMaterialTypes.map(
      (segregationInputMaterialType) => ({
        value: segregationInputMaterialType,
        label: segregationInputMaterialType
      })
    )
  }

  function handleSegregationInputMaterialTypeSelectChange(newValue) {
    setValue(
      'segregationInputMaterialTypes',
      newValue ? [...newValue].map((mt) => mt.value) : []
    )
  }

  function getSegregationOutputMaterialTypeValueOptions() {
    if (!values.segregationOutputMaterialTypes) {
      return []
    }

    return values.segregationOutputMaterialTypes.map(
      (segregationOutputMaterialType) => ({
        value: segregationOutputMaterialType,
        label: segregationOutputMaterialType
      })
    )
  }

  function handleSegregationOutputMaterialTypeSelectChange(newValue) {
    setValue(
      'segregationOutputMaterialTypes',
      newValue ? [...newValue].map((mt) => mt.value) : []
    )
  }

  function getCollectedMaterialTypeValueOptions() {
    if (!values.collectedMaterialTypes) {
      return []
    }

    return values.collectedMaterialTypes.map((collectedMaterialType) => ({
      value: collectedMaterialType,
      label: collectedMaterialType
    }))
  }

  function handleCollectedMaterialTypeSelectChange(newValue) {
    setValue(
      'collectedMaterialTypes',
      newValue ? [...newValue].map((materialType) => materialType.value) : []
    )
  }

  function getProcessingInputMaterialTypeValueOptions() {
    if (!values.processingInputMaterialTypes) {
      return []
    }

    return values.processingInputMaterialTypes.map(
      (processingInputMaterialType) => ({
        value: processingInputMaterialType,
        label: processingInputMaterialType
      })
    )
  }

  function handleProcessingInputMaterialTypeSelectChange(newValue) {
    setValue(
      'processingInputMaterialTypes',
      newValue ? [...newValue].map((mt) => mt.value) : []
    )
  }

  function getProcessingOutputMaterialTypeValueOptions() {
    if (!values.processingOutputMaterialTypes) {
      return []
    }

    return values.processingOutputMaterialTypes.map(
      (processingOutputMaterialType) => ({
        value: processingOutputMaterialType,
        label: processingOutputMaterialType
      })
    )
  }

  function handleProcessingOutputMaterialTypeSelectChange(newValue) {
    setValue(
      'processingOutputMaterialTypes',
      newValue ? [...newValue].map((mt) => mt.value) : []
    )
  }

  function getProcessingOutputProductTypeValueOptions() {
    if (!values.processingOutputProductTypes) {
      return []
    }

    return values.processingOutputProductTypes.map(
      (processingOutputProductType) => ({
        value: processingOutputProductType,
        label: processingOutputProductType
      })
    )
  }

  function handleProcessingOutputProductTypeSelectChange(newValue) {
    setValue(
      'processingOutputProductTypes',
      newValue ? [...newValue].map((pt) => pt.value) : []
    )
  }

  function getProcessingStepValueOptions() {
    if (!values.processingSteps) {
      return []
    }

    return values.processingSteps.map((processingStep) => ({
      value: processingStep,
      label: processingStep
    }))
  }

  function getEventAutoReviewTagsOptions() {
    if (!values.eventAutoReviewTags) {
      return []
    }

    return values.eventAutoReviewTags.map((autoReviewTag) => ({
      value: autoReviewTag,
      label: autoReviewTag
    }))
  }

  function handleEventAutoReviewTagsSelectChange(newValue) {
    setValue(
      'eventAutoReviewTags',
      newValue ? [...newValue].map((tag) => tag.value) : []
    )
  }

  function getEventAppReviewTagsOptions() {
    if (!values.eventAppReviewTags) {
      return []
    }

    return values.eventAppReviewTags.map((appReviewTag) => ({
      value: appReviewTag,
      label: appReviewTag
    }))
  }

  function handleEventAppReviewTagsSelectChange(newValue) {
    setValue(
      'eventAppReviewTags',
      newValue ? [...newValue].map((tag) => tag.value) : []
    )
  }

  function handleProcessingStepSelectChange(newValue) {
    setValue(
      'processingSteps',
      newValue ? [...newValue].map((step) => step.value) : []
    )
  }

  function handleCurrencySelectChange(newValue) {
    setValue('currency', newValue ? newValue.value : newValue)
  }

  function getCurrencyValueOptions() {
    if (!values.currency) {
      return ''
    }

    return {
      value: values.currency,
      label: values.currency
    }
  }

  async function handleSave() {
    setIsSaving(true)

    const {
      name,
      type,
      features,
      cameraResolution,
      collectedMaterialTypes,
      supplierUuids,
      allowUnregisteredSuppliers,
      showClearanceWeight,
      showMaterialClearanceBuyer,
      showMaterialClearanceTransport,
      weighMaterialClearance,
      weighMaterialCollection,
      enableDocumentUpload,
      pipelineOwner,
      autoPublishEvents,
      enableBaleSegregationOutput,
      generateSustainabilityDeclaration,
      enableRegistrationTruckPictureUpload,
      enablePlasticCredits,
      collectorSurveyLink,
      dataExportReportFrequency,
      segregationInputMaterialTypes,
      segregationOutputMaterialTypes,
      processingInputMaterialTypes,
      processingOutputMaterialTypes,
      processingOutputProductTypes,
      processingSteps,
      street,
      zipCode,
      city,
      region,
      country,
      currency,
      eventAutoReviewTags,
      eventAppReviewTags,
      organizationUuid,
      assignedCompanyUuids
    } = values

    const baseApiUrl = '/api/admin/companies/hubs'
    const apiUrl = hub ? `${baseApiUrl}/${hub.uuid}` : baseApiUrl

    const response = await apiFetchWithDispatch(dispatch, apiUrl, {
      method: 'POST',
      body: JSON.stringify({
        name,
        type,
        features,
        cameraResolution,
        collectedMaterialTypes,
        supplierUuids,
        allowUnregisteredSuppliers,
        showClearanceWeight,
        showMaterialClearanceBuyer,
        showMaterialClearanceTransport,
        weighMaterialClearance,
        weighMaterialCollection,
        enableDocumentUpload,
        pipelineOwner,
        autoPublishEvents,
        enableBaleSegregationOutput,
        generateSustainabilityDeclaration,
        enableRegistrationTruckPictureUpload,
        enablePlasticCredits,
        collectorSurveyLink,
        dataExportReportFrequency,
        segregationInputMaterialTypes,
        segregationOutputMaterialTypes,
        processingInputMaterialTypes,
        processingOutputMaterialTypes,
        processingOutputProductTypes,
        processingSteps,
        street,
        zipCode,
        city,
        region,
        country,
        currency,
        eventAutoReviewTags,
        eventAppReviewTags,
        organizationUuid,
        assignedCompanyUuids,
        firstUser: !hub
          ? {
              ...userValues,
              email: userValues.email.trim()
            }
          : null
      })
    })

    setIsSaving(false)

    if (response) {
      resetForm()
      resetUserForm()
      history.push('/admin/hubs')
    }
  }

  if (isLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <form autoComplete="off" noValidate>
      <Paper className={classes.formSection}>
        <Typography variant="h5" component="h5">
          HUB INFO
        </Typography>

        <div className={classes.field}>
          <TextField
            name="name"
            className={classes.textField}
            type="text"
            label="Name"
            margin="dense"
            required
            value={values.name}
            onChange={(event) => handleChange(event)}
            error={hasError('name')}
            helperText={hasError('name') ? t(errors.name[0]) : ''}
          />
        </div>

        <div className={classes.field}>
          <TextField
            name="type"
            select
            required
            label="Select type"
            className={classes.selectField}
            value={values.type}
            onChange={handleTypeChange}
            SelectProps={{ native: true }}
            margin="dense"
          >
            {getHubCompanyTypeOptions()}
          </TextField>
        </div>

        {values.type && values.type !== CompanyType.ORGANIZATION && (
          <div className={classes.suppliersSelectField}>
            <Typography variant="body1" className={classes.suppliersLabel}>
              Organization
            </Typography>

            <Select
              name="organizationUuid"
              isClearable={true}
              components={animatedComponents}
              options={organizationHubOptions}
              value={selectedOrganizationHubOption}
              onChange={handleOrganizationUuidChange}
            />
          </div>
        )}

        {values.type && values.type === CompanyType.ORGANIZATION && (
          <div className={classes.suppliersSelectField}>
            <Typography variant="body1" className={classes.suppliersLabel}>
              Assigned Hubs
            </Typography>
            <Select
              name="assignedCompanyUuids"
              isClearable
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={getSelectedAvailableCompanies()}
              isMulti
              options={getAvailableCompaniesOptions()}
              onChange={handleAssignedCompanyUuidChange}
            />
          </div>
        )}

        {availableSuppliers && availableSuppliers.length > 0 && (
          <div className={classes.suppliersSelectField}>
            <Typography variant="body1" className={classes.suppliersLabel}>
              Suppliers
            </Typography>
            <Select
              name="suppliers"
              isClearable
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={getSupplierValueOptions()}
              isMulti
              options={getSupplierOptions()}
              onChange={handleSupplierSelectChange}
            />
          </div>
        )}

        <div className={classes.field}>
          <FormControlLabel
            labelPlacement="start"
            className={classes.switchField}
            control={
              <Switch
                name="allowUnregisteredSuppliers"
                checked={values.allowUnregisteredSuppliers}
                onChange={(event) => handleChange(event)}
                value={values.allowUnregisteredSuppliers}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Allow unregistered suppliers"
          />
        </div>

        {values.features.includes(Feature.MATERIAL_COLLECTION) && (
          <div className={classes.field}>
            <FormControlLabel
              labelPlacement="start"
              className={classes.switchField}
              control={
                <Switch
                  name="weighMaterialCollection"
                  checked={values.weighMaterialCollection}
                  onChange={(event) => handleChange(event)}
                  value={values.weighMaterialCollection}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Weigh material collection"
            />
          </div>
        )}

        {values.features.includes(Feature.TRUCK_LOAD_REGISTRATION) && (
          <div className={classes.field}>
            <FormControlLabel
              labelPlacement="start"
              className={classes.switchField}
              control={
                <Switch
                  name="enableRegistrationTruckPictureUpload"
                  checked={values.enableRegistrationTruckPictureUpload}
                  onChange={(event) => handleChange(event)}
                  value={values.enableRegistrationTruckPictureUpload}
                  disabled={values.type !== HubCompanyTypes.COLLECTION_CENTER}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Enable registration truck picture upload"
            />
          </div>
        )}

        <div className={classes.field}>
          <FormControlLabel
            labelPlacement="start"
            className={classes.switchField}
            control={
              <Switch
                name="showClearanceWeight"
                checked={values.showClearanceWeight}
                onChange={(event) => handleChange(event)}
                value={values.showClearanceWeight}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Show clearance weight"
          />
        </div>

        <div className={classes.field}>
          <FormControlLabel
            labelPlacement="start"
            className={classes.switchField}
            control={
              <Switch
                name="showMaterialClearanceBuyer"
                checked={values.showMaterialClearanceBuyer}
                onChange={(event) => handleChange(event)}
                value={values.showMaterialClearanceBuyer}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Show material clearance buyer"
          />
        </div>

        <div className={classes.field}>
          <FormControlLabel
            labelPlacement="start"
            className={classes.switchField}
            control={
              <Switch
                name="showMaterialClearanceTransport"
                checked={values.showMaterialClearanceTransport}
                onChange={(event) => handleChange(event)}
                value={values.showMaterialClearanceTransport}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Show material clearance transport"
          />
        </div>

        <div className={classes.field}>
          <FormControlLabel
            labelPlacement="start"
            className={classes.switchField}
            control={
              <Switch
                name="weighMaterialClearance"
                checked={values.weighMaterialClearance}
                onChange={(event) => handleChange(event)}
                value={values.weighMaterialClearance}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Weigh material clearance"
          />
        </div>

        <div className={classes.field}>
          <FormControlLabel
            labelPlacement="start"
            className={classes.switchField}
            control={
              <Switch
                name="enableDocumentUpload"
                checked={values.enableDocumentUpload}
                onChange={(event) => handleChange(event)}
                value={values.enableDocumentUpload}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Enable document upload"
          />
        </div>

        <div className={classes.field}>
          <FormControlLabel
            labelPlacement="start"
            className={classes.switchField}
            control={
              <Switch
                name="pipelineOwner"
                checked={values.pipelineOwner}
                onChange={(event) => handleChange(event)}
                value={values.pipelineOwner}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Pipeline Owner"
          />
        </div>

        <div className={classes.field}>
          <FormControlLabel
            labelPlacement="start"
            className={classes.switchField}
            control={
              <Switch
                name="enablePlasticCredits"
                checked={values.enablePlasticCredits}
                onChange={(event) => handleChange(event)}
                value={values.enablePlasticCredits}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Enable plastic credits"
          />
        </div>

        <div className={classes.field}>
          <Tooltip
            title="Auto-publish is possible only if Feature EVENT_REVIEW is enabled for hub"
            arrow
          >
            <FormControlLabel
              labelPlacement="start"
              className={classes.switchField}
              control={
                <Switch
                  name="autoPublishEvents"
                  checked={values.autoPublishEvents}
                  onChange={(event) => handleChange(event)}
                  value={values.autoPublishEvents}
                  disabled={!values.features.includes(Feature.EVENT_REVIEW)}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Auto-publish events"
            />
          </Tooltip>
        </div>

        <div className={classes.field}>
          <Tooltip
            title="Sustainability declaration is available only for recyclers"
            arrow
          >
            <FormControlLabel
              labelPlacement="start"
              className={classes.switchField}
              control={
                <Switch
                  name="generateSustainabilityDeclaration"
                  checked={values.generateSustainabilityDeclaration}
                  onChange={(event) => handleChange(event)}
                  value={values.generateSustainabilityDeclaration}
                  disabled={values.type !== HubCompanyTypes.RECYCLER}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Generate sustainability declaration"
            />
          </Tooltip>
        </div>

        <div className={classes.field}>
          <TextField
            name="collectorSurveyLink"
            className={classes.textField}
            type="text"
            label="Collector survey link"
            margin="dense"
            value={values.collectorSurveyLink}
            onChange={(event) => handleChange(event)}
            error={hasError('collectorSurveyLink')}
            helperText={
              hasError('collectorSurveyLink')
                ? t(errors.collectorSurveyLink[0])
                : ''
            }
          />
        </div>

        <div className={classes.multiSelectField}>
          <Typography variant="body1" className={classes.multiSelectFieldLabel}>
            Collected Material Types
          </Typography>
          <Select
            name="collectedMaterialTypes"
            isClearable
            closeMenuOnSelect={false}
            components={animatedComponents}
            value={getCollectedMaterialTypeValueOptions()}
            isMulti
            options={getAllCollectedMaterialTypeOptions()}
            onChange={handleCollectedMaterialTypeSelectChange}
          />
        </div>

        <div className={classes.multiSelectField}>
          <Typography variant="body1" className={classes.multiSelectFieldLabel}>
            Features
          </Typography>
          <Select
            name="features"
            isClearable
            closeMenuOnSelect={false}
            components={animatedComponents}
            value={getFeatureValueOptions()}
            isMulti
            options={getFeatureOptions()}
            onChange={handleFeatureSelectChange}
          />
        </div>

        {values.features.includes(Feature.MATERIAL_SEGREGATION) &&
          values.features.includes(Feature.MASS_BALANCE_TRACKING) && (
            <div className={classes.multiSelectField}>
              <Typography
                variant="body1"
                className={classes.multiSelectFieldLabel}
              >
                Segregation Input Material Types
              </Typography>
              <Select
                name="segregationInputMaterialTypes"
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={getSegregationInputMaterialTypeValueOptions()}
                isMulti
                onChange={handleSegregationInputMaterialTypeSelectChange}
                options={getCollectedMaterialTypeValueOptions()}
              />
            </div>
          )}

        {values.features.includes(Feature.MATERIAL_SEGREGATION) && (
          <div className={classes.multiSelectField}>
            <Typography
              variant="body1"
              className={classes.multiSelectFieldLabel}
            >
              Segregation Output Material Types
            </Typography>
            <Select
              name="segregationOutputMaterialTypes"
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={getSegregationOutputMaterialTypeValueOptions()}
              isMulti
              onChange={handleSegregationOutputMaterialTypeSelectChange}
              options={getAllMaterialTypeOptions()}
            />
          </div>
        )}

        {values.features.includes(Feature.MATERIAL_SEGREGATION) && (
          <div className={classes.field}>
            <FormControlLabel
              labelPlacement="start"
              className={classes.switchField}
              control={
                <Switch
                  name="enableBaleSegregationOutput"
                  checked={values.enableBaleSegregationOutput}
                  onChange={(event) => handleChange(event)}
                  value={values.enableBaleSegregationOutput}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Enable Bale Segregation Output"
            />
          </div>
        )}

        {values.features.includes(Feature.MATERIAL_PROCESSING) && (
          <div className={classes.multiSelectField}>
            <Typography
              variant="body1"
              className={classes.multiSelectFieldLabel}
            >
              Processing Input Material Types
            </Typography>
            <Select
              name="processingInputMaterialTypes"
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={getProcessingInputMaterialTypeValueOptions()}
              isMulti
              onChange={handleProcessingInputMaterialTypeSelectChange}
              options={getAllMaterialTypeOptions()}
            />
          </div>
        )}

        {values.features.includes(Feature.MATERIAL_PROCESSING) && (
          <div className={classes.multiSelectField}>
            <Typography
              variant="body1"
              className={classes.multiSelectFieldLabel}
            >
              Processing Output Material Types
            </Typography>
            <Select
              name="processingOutputMaterialTypes"
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={getProcessingOutputMaterialTypeValueOptions()}
              isMulti
              onChange={handleProcessingOutputMaterialTypeSelectChange}
              options={getAllMaterialTypeOptions()}
            />
          </div>
        )}

        {values.features.includes(Feature.MATERIAL_PROCESSING) && (
          <div className={classes.multiSelectField}>
            <Typography
              variant="body1"
              className={classes.multiSelectFieldLabel}
            >
              Processing Output Product Types
            </Typography>
            <Select
              name="processingOutputProductTypes"
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={getProcessingOutputProductTypeValueOptions()}
              isMulti
              onChange={handleProcessingOutputProductTypeSelectChange}
              options={getAllProductTypesOptions()}
            />
          </div>
        )}

        {values.features.includes(Feature.MATERIAL_PROCESSING) && (
          <div className={classes.multiSelectField}>
            <Typography
              variant="body1"
              className={classes.multiSelectFieldLabel}
            >
              Processing Steps
            </Typography>
            <Select
              name="processingSteps"
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={getProcessingStepValueOptions()}
              isMulti
              onChange={handleProcessingStepSelectChange}
              options={getAllProcessingStepOptions()}
            />
          </div>
        )}

        {values.features.includes(Feature.EVENT_REVIEW) && (
          <div className={classes.multiSelectField}>
            <Typography
              variant="body1"
              className={classes.multiSelectFieldLabel}
            >
              Event Auto Review Tags
            </Typography>
            <Select
              name="eventAutoReviewTags"
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={getEventAutoReviewTagsOptions()}
              isMulti
              onChange={handleEventAutoReviewTagsSelectChange}
              options={getSupportedHubEventReviewTags()}
            />
          </div>
        )}

        {values.features.includes(Feature.EVENT_REVIEW) && (
          <div className={classes.multiSelectField}>
            <Typography
              variant="body1"
              className={classes.multiSelectFieldLabel}
            >
              Event In-App Review Tags
            </Typography>
            <Select
              name="eventAppReviewTags"
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={getEventAppReviewTagsOptions()}
              isMulti
              onChange={handleEventAppReviewTagsSelectChange}
              options={getSupportedHubEventReviewTags()}
            />
          </div>
        )}

        {values.features.includes(
          Feature.COLLECTION_CENTER_WEEKLY_DATA_EXPORT
        ) && (
          <div className={classes.field}>
            <TextField
              name="dataExportReportFrequency"
              select
              label="Frequency of data report"
              className={classes.selectField}
              value={values.dataExportReportFrequency}
              onChange={(event) => handleChange(event)}
              SelectProps={{ native: true }}
              margin="dense"
            >
              {Object.keys(DataExportReportFrequency).map((reportFrequency) => (
                <option key={reportFrequency} value={reportFrequency}>
                  {reportFrequency}
                </option>
              ))}
            </TextField>
          </div>
        )}

        <div className={classes.field}>
          <TextField
            name="cameraResolution"
            select
            required
            label="Camera resolution"
            className={classes.selectField}
            value={values.cameraResolution}
            onChange={(event) => handleChange(event)}
            SelectProps={{ native: true }}
            margin="dense"
          >
            {Object.keys(CameraResolution).map((cameraResolution) => (
              <option key={cameraResolution} value={cameraResolution}>
                {cameraResolution}
              </option>
            ))}
          </TextField>
        </div>
      </Paper>

      <Paper className={classes.formSection}>
        <Typography variant="h5" component="h5">
          EXPERIMENTS
        </Typography>

        <EmptyState
          heading="No experiments"
          message="There are currently no ongoing experiments"
        />
      </Paper>

      <Paper className={classes.formSection}>
        <Typography variant="h5" component="h5">
          ADDRESS
        </Typography>

        <div className={classes.field}>
          <TextField
            name="street"
            className={classes.textField}
            type="text"
            label="Street & No"
            margin="dense"
            required
            value={values.street}
            onChange={(event) => handleChange(event)}
            error={hasError('street')}
            helperText={hasError('street') ? t(errors.street[0]) : ''}
          />
        </div>

        <div className={classes.field}>
          <TextField
            name="zipCode"
            className={classes.zipCodeField}
            type="number"
            label="Zip Code"
            margin="dense"
            required
            value={values.zipCode}
            onChange={(event) => handleChange(event)}
            error={hasError('zipCode')}
            helperText={hasError('zipCode') ? t(errors.zipCode[0]) : ''}
          />
          <TextField
            name="city"
            className={classes.cityTextField}
            type="text"
            label="City"
            margin="dense"
            required
            value={values.city}
            onChange={(event) => handleChange(event)}
            error={hasError('city')}
            helperText={hasError('city') ? t(errors.city[0]) : ''}
          />
        </div>

        <div className={classes.field}>
          <TextField
            name="region"
            className={classes.textField}
            type="text"
            label="Region"
            margin="dense"
            value={values.region}
            onChange={(event) => handleChange(event)}
          />
        </div>

        <div className={classes.field}>
          <TextField
            name="country"
            className={classes.textField}
            label="Country"
            margin="dense"
            required
            select
            SelectProps={{ native: true }}
            value={values.country}
            onChange={(event) => handleChange(event)}
            error={hasError('country')}
            helperText={hasError('country') ? t(errors.country[0]) : ''}
          >
            {getCountryOptions()}
          </TextField>
        </div>

        <div className={classes.multiSelectField}>
          <Typography variant="body1" className={classes.multiSelectFieldLabel}>
            Currency *
          </Typography>
          <Select
            name="currency"
            isClearable
            closeMenuOnSelect={true}
            components={animatedComponents}
            value={getCurrencyValueOptions()}
            options={getCurrencyOptions()}
            onChange={handleCurrencySelectChange}
          />
        </div>
      </Paper>

      {hub && hub.qrCode && (
        <Paper className={classes.formSection}>
          <Typography variant="h5" component="h5">
            HUB IDENTIFIER
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mt={4}
          >
            <Box
              display="flex"
              alignItems="space-between"
              mb={-6}
              zIndex={1200}
            >
              <Typography variant="h4">{hub.name}</Typography>
            </Box>
            <Image source={hub.qrCode.directLink} />
          </Box>
        </Paper>
      )}

      {!hub && (
        <Paper className={classes.formSection}>
          <Typography variant="h5" component="h5">
            FIRST HUB OWNER
          </Typography>

          <div className={classes.field}>
            <TextField
              name="firstName"
              className={classes.nameField}
              type="text"
              label="First Name"
              margin="dense"
              required
              value={userValues.firstName}
              onChange={(event) => handleUserChange(event)}
              error={hasUserError('firstName')}
              helperText={
                hasUserError('firstName') ? t(userErrors.firstName[0]) : ''
              }
            />
            <TextField
              name="lastName"
              className={classes.nameField}
              type="text"
              label="Last Name"
              margin="dense"
              required
              value={userValues.lastName}
              onChange={(event) => handleUserChange(event)}
              error={hasUserError('lastName')}
              helperText={
                hasUserError('lastName') ? t(userErrors.lastName[0]) : ''
              }
            />
          </div>

          <div className={classes.field}>
            <TextField
              name="gender"
              className={classes.selectField}
              label="Gender"
              margin="dense"
              required
              select
              SelectProps={{ native: true }}
              value={userValues.gender}
              onChange={(event) => handleUserChange(event)}
              error={hasUserError('gender')}
              helperText={hasUserError('gender') ? t(userErrors.gender[0]) : ''}
            >
              {getGenderOptions()}
            </TextField>

            <TextField
              name="language"
              className={classes.selectField}
              label="Language"
              margin="dense"
              required
              select
              SelectProps={{ native: true }}
              value={userValues.language}
              onChange={(event) => handleUserChange(event)}
              error={hasUserError('language')}
              helperText={
                hasUserError('language') ? t(userErrors.language[0]) : ''
              }
            >
              {getLanguageOptions()}
            </TextField>
          </div>

          <TextField
            name="authType"
            className={classes.selectField}
            label="Authentication Method"
            margin="dense"
            required
            select
            SelectProps={{ native: true }}
            value={userValues.authType}
            onChange={(event) => handleUserChange(event)}
            error={hasUserError('authType')}
            helperText={
              hasUserError('authType') ? t(userErrors.authType[0]) : ''
            }
          >
            {getUserAuthTypesOptions()}
          </TextField>

          {userValues.authType === UserAuthType.SMS && (
            <div className={classes.field}>
              <TextField
                name="phoneNumber"
                className={classes.nameField}
                type="text"
                label="Phone Number"
                margin="dense"
                required
                value={userValues.phoneNumber}
                onChange={(event) => handleUserChange(event)}
                error={hasUserError('phoneNumber')}
                helperText={
                  hasUserError('phoneNumber')
                    ? t(userErrors.phoneNumber[0])
                    : ''
                }
              />
            </div>
          )}

          <div className={classes.field}>
            <TextField
              name="email"
              className={classes.nameField}
              type="text"
              label="Email"
              margin="dense"
              required
              value={userValues.email}
              onChange={(event) => handleUserChange(event)}
              error={hasUserError('email')}
              helperText={hasUserError('email') ? t(userErrors.email[0]) : ''}
            />
          </div>
        </Paper>
      )}

      <div className={classes.actionButtons}>
        <LinkButton
          className={classes.cancelButton}
          color="default"
          variant="outlined"
          href="/admin/hubs"
        >
          {t('cancelButton')}
        </LinkButton>
        <Button
          disabled={!isValid || (!hub && !isUserValid) || isLoading}
          color="primary"
          variant="contained"
          onClick={handleSave}
        >
          {hub ? t('updateButton') : t('createButton')}
        </Button>
      </div>
    </form>
  )
}

export default compose(withRouter, withStyles(styles))(AddHubForm)
