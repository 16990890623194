import { Receipt as ReceiptIcon } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'

import { compose } from 'recompose'

// Material components
import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'

// Component styles
import { useFormStyles } from 'common/styles/form';
import { useGeneralStyles } from 'common/styles/general';
import { mergeDateAndTime } from '../../../helpers/date-time.helpers'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'
import { OngoingTransportsApiService } from '../../../services/ongoing-transport/ongoing-transports-api.service'
import { useStyles } from './styles'
import { useTheme } from '@material-ui/styles';

import { useStateValue } from 'state'

import { useCamera } from 'lib/camera'

import { useTranslation } from 'react-i18next'

import { apiFetchWithDispatch } from 'lib/fetch'
import { useForm } from 'lib/form'

import { ImagePreview, FullScreenCamera } from 'components'

import { QuantityUnits } from 'services/quantityunit'

import AwesomeDebouncePromise from 'awesome-debounce-promise'
import AsyncSelect from 'react-select/async/dist/react-select.esm'
import queryString from 'query-string'
import LinkButton from '../../../components/LinkButton'

import { getCurrentUser } from 'services/user'
import { getCollectedMaterialTypeOptions } from 'services/materialtype'
import { getMaterialTypeOptions } from '../../../services/materialtype'

function getFormSchema(cleanhubSupplier, collectedMaterialTypes) {
  const schema = {
    weight: {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 10, message: 'validationExceedsMaxLength' }
    }
  }

  if (!cleanhubSupplier) {
    schema.licensePlate = {
      presence: { allowEmpty: false, message: 'validationRequired' }
    }

    schema.supplier = {
      presence: { allowEmpty: false, message: 'validationRequired' }
    }
  }

  if (!cleanhubSupplier && collectedMaterialTypes.length !== 1) {
    schema.materialType = {
      presence: { allowEmpty: false, message: 'validationRequired' }
    }
  }

  return schema
}

function RegisterTruckLoad (props) {
  const { history } = props
  const queryParams = queryString.parse(props.location.search)
  const cleanhubSupplier = queryParams.cleanhubSupplier === 'true'

  const classes = useStyles();
  const formClasses = useFormStyles();
  const generalClasses = useGeneralStyles();

  const [formSchema, setFormSchema] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const theme = useTheme()
  const [, dispatch] = useStateValue()

  const collectedMaterialTypes = getCurrentUser().collectedMaterialTypes || []

  useEffect(() => {
    setFormSchema(getFormSchema(cleanhubSupplier, collectedMaterialTypes))
  }, [props.location])

  const {
    pictureDataUri,
    pictureFileUpload,
    showCamera,
    pictureConfirmed,
    handleTakePhotoFinished,
    handleFileUploadFinished,
    handleConfirmPicture,
    handleCameraError
  } = useCamera()

  const {
    formState: {
      values,
      errors,
      isValid
    },
    setValue,
    setValues,
    handleChange,
    resetForm,
    hasError
  } = useForm(formSchema, {
    weight: '',
    licensePlate: '',
    supplier: '',
    internalId: '',
    weighedAt: new Date(),
    weighedAtTime: new Date(),
    selectedOngoingTransport: {
      label: '',
      licensePlate: '',
      materialTypes: [],
      value: ''
    },
    materialType: null,
    quantityUnit: QuantityUnits.KG
  })

  useEffect(() => {
    if (!showCamera) {
      history.push('/hub-overview')
    }
  }, [showCamera])

  function handleWeighedAtChange (e) {
    const newValues = { ...values }
    newValues.weighedAt = e
    setValues(newValues)
  }

  function handleWeighedAtTimeChange (e) {
    const newValues = { ...values }
    newValues.weighedAtTime = e
    setValues(newValues)
  }

  function handleSelectOngoingTransport (event) {
    setValue('selectedOngoingTransport', event)
  }

  const ongoingTransportSearchResponseOptions = (inputLicensePlate) => {
    handleSelectOngoingTransport({
      label: '',
      licensePlate: '',
      materialTypes: [],
      value: ''
    })

    return OngoingTransportsApiService.search(dispatch, t, inputLicensePlate)
  }

  function hasMultipleMaterialTypes ()  {
    return values.selectedOngoingTransport &&
      values.selectedOngoingTransport.materialTypes &&
      values.selectedOngoingTransport.materialTypes.length > 1
  }

  function getDefaultMaterialType () {
    if (!cleanhubSupplier) {
      return collectedMaterialTypes[0]
    }

    return values.selectedOngoingTransport.materialTypes[0]
  }

  const debouncedOngoingTransportSearchResponseOptions = AwesomeDebouncePromise(ongoingTransportSearchResponseOptions, 500)

  async function handleSave () {
    setIsLoading(true)

    const {
      weight,
      licensePlate,
      supplier,
      internalId,
      selectedOngoingTransport,
      weighedAt,
      weighedAtTime,
      materialType
    } = values

    const response = await apiFetchWithDispatch(dispatch, '/api/registeredMaterials/', {
      method: 'POST',
      body: JSON.stringify({
        weight,
        licensePlate: selectedOngoingTransport.licensePlate || licensePlate,
        supplier,
        internalId,
        transportOwner: selectedOngoingTransport.value || null,
        weighedAt: mergeDateAndTime(weighedAt, weighedAtTime).toISOString(),
        materialType: materialType || getDefaultMaterialType(),
        pictureFileUploadUuid: pictureFileUpload ? pictureFileUpload.uuid : null
      })
    })

    setIsLoading(false)

    if (response) {
      resetForm()

      if (hasMultipleMaterialTypes()) {
        history.push(`/register-additional-material/${values.selectedOngoingTransport.value.uuid}`)
      } else {
        history.push('/hub-overview')
      }
    }
  }

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      boxShadow: 'none'
    }),
    noOptionsMessage: () => ({
      display: 'none'
    })
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('registerTruckLoadTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  return (
    <div>
      {
        pictureConfirmed ? (
          <SecurePortalLayout title={t('registerTruckLoadTitle')}>
            <PortalContent>
              <div className={classes.root}>
                <div className={generalClasses.content}>
                  <form autoComplete='off' noValidate>
                    <Paper className={formClasses.formSection}>
                      <Typography
                        className={generalClasses.title}
                        variant='h3'
                      >
                        {t('registerTruckLoadTitle')}
                      </Typography>

                      <FormControl component='fieldset'>
                        <FormLabel component='legend' required>
                          {t('weighSlipPicture')}
                        </FormLabel>
                        <div className={generalClasses.imagePreview}>
                          <ImagePreview
                            dataUri={pictureDataUri}
                            isPictureUploading={!pictureFileUpload}
                          />
                        </div>
                      </FormControl>

                      <div className={formClasses.field}>
                        <TextField
                          name='weight'
                          autoFocus
                          className={formClasses.weightField}
                          type='number'
                          label={`${t('addWeight')}`}
                          margin='dense'
                          required
                          value={values.weight}
                          onChange={handleChange}
                          error={hasError('weight')}
                          helperText={hasError('weight') ? t(errors.weight[0]) : ''}
                        />
                        <TextField
                          name='quantityUnit'
                          className={formClasses.quantityUnitField}
                          disabled
                          label=' '
                          margin='dense'
                          value={values.quantityUnit}
                          InputProps={{
                            style: {
                              color: theme.palette.text.primary
                            }
                          }}
                        />
                      </div>

                      <div className={formClasses.field}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            margin='normal'
                            className={formClasses.dateField}
                            id='mui-pickers-date'
                            name='weighedAt'
                            label={t('weighedAt')}
                            value={values.weighedAt}
                            onChange={handleWeighedAtChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                          />

                          <KeyboardTimePicker
                            margin='normal'
                            className={formClasses.timeField}
                            id='time-picker'
                            label=' '
                            value={values.weighedAtTime}
                            onChange={handleWeighedAtTimeChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change time'
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                      {!cleanhubSupplier && (
                        <>
                          <div className={formClasses.field}>
                            <TextField
                              name='licensePlate'
                              required
                              label={t('licensePlate')}
                              className={formClasses.textField}
                              value={values.licensePlate}
                              onChange={handleChange}
                              margin='dense'
                              error={hasError('licensePlate')}
                              helperText={hasError('licensePlate') ? t(errors.licensePlate[0]) : ''}
                            />
                          </div>

                          <div className={formClasses.field}>
                            <TextField
                              name='supplier'
                              label={t('supplier')}
                              required
                              className={formClasses.textField}
                              value={values.supplier}
                              onChange={handleChange}
                              margin='dense'
                              error={hasError('supplier')}
                              helperText={hasError('supplier') ? t(errors.supplier[0]) : ''}
                            />
                          </div>
                        </>
                      )}

                      {cleanhubSupplier && (
                        <div className={formClasses.textField}>
                          <Typography variant='body1' className={classes.transporterLicensePlateLabel}>
                            {t('licensePlate')}
                          </Typography>
                          <AsyncSelect
                            name='selectedOngoingTransport'
                            classNamePrefix='selectedOngoingTransport'
                            styles={customSelectStyles}
                            required
                            label={t('selectedOngoingTransport')}
                            value={values.selectedOngoingTransport}
                            onChange={handleSelectOngoingTransport}
                            cacheOptions
                            defaultOptions
                            loadOptions={debouncedOngoingTransportSearchResponseOptions}
                          />
                        </div>
                      )}

                      {hasMultipleMaterialTypes() && (
                        <div className={formClasses.radioGroupField}>
                          <FormControl component='fieldset'>
                            <FormLabel
                              component='legend'
                              required
                              className={formClasses.radioGroupLabel}
                            >
                              {t('selectMaterial')}
                            </FormLabel>
                            <RadioGroup
                              name='materialType'
                              value={values.materialType}
                              onChange={handleChange}
                            >
                              {getMaterialTypeOptions(values.selectedOngoingTransport.materialTypes, t)}
                            </RadioGroup>
                          </FormControl>
                        </div>
                      )}

                      <div className={formClasses.field}>
                        <TextField
                          name='internalId'
                          label={t('internalId')}
                          className={formClasses.textField}
                          value={values.internalId}
                          onChange={handleChange}
                          margin='dense'
                        />
                      </div>

                      {!cleanhubSupplier && collectedMaterialTypes.length !== 1 && (
                        <div className={formClasses.radioGroupField}>
                          <FormControl component='fieldset'>
                            <FormLabel
                              component='legend'
                              required
                              className={formClasses.radioGroupLabel}
                            >
                              {t('selectMaterial')}
                            </FormLabel>
                            <RadioGroup
                              name='materialType'
                              value={values.materialType}
                              onChange={handleChange}
                            >
                              {getCollectedMaterialTypeOptions(t)}
                            </RadioGroup>
                          </FormControl>
                        </div>
                      )}

                      <div className={formClasses.actionButtons}>
                        <LinkButton
                          size='large'
                          className={formClasses.cancelButton}
                          color='default'
                          variant='outlined'
                          href='/hub-overview'
                        >
                          {t('cancelButton')}
                        </LinkButton>
                        <Button
                          size='large'
                          variant='contained'
                          className={generalClasses.primaryButton}
                          disabled={!isValid || isLoading || !pictureFileUpload || (cleanhubSupplier
                             && !values.selectedOngoingTransport.value )}
                          onClick={handleSave}
                        >
                          {hasMultipleMaterialTypes() ? t('saveAndContinueRegistrationButton') : t('addTruckLoadButton')}
                        </Button>
                      </div>
                    </Paper>
                  </form>
                </div>
              </div>
            </PortalContent>
          </SecurePortalLayout>
        ) : (
          showCamera && (
            <FullScreenCamera
              fileName={`register-truck-load-${Date.now()}`}
              headerMessage={t('registerTruckLoadPictureExplanation')}
              shutterIcon={<ReceiptIcon />}
              onTakePhotoFinished={handleTakePhotoFinished}
              onFileUploadFinished={handleFileUploadFinished}
              onConfirmPicture={handleConfirmPicture}
              onCameraError={handleCameraError}
            />
          )
        )
      }
    </div>
  )
}

export default compose(
  withRouter
)(RegisterTruckLoad)
