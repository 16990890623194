import {
  Box,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core'
import React, { FunctionComponentElement } from 'react'
import { Theme } from '../../../../common/theme'
import { useGeneralStyles } from '../../../../common/styles/general'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { HandlingCenterMaterialApiService } from '../../../../services/handling-center-material/handling-center-material-api.service'
import SummaryLine from 'components/SummaryLine'
import FlowActionButton from '../../../../components/FlowActionButton'
import { LocalShipping as LocalShippingIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  materialHandlingCenterRegistration: {
    marginBottom: theme.spacing()
  }
}))

function MaterialHandlingCenterRegistration(): FunctionComponentElement<void> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()
  const titleClasses = cx([generalClasses.title, generalClasses.sectionTitle])

  const { t } = useTranslation()

  const [
    summary,
    isSummaryLoading
  ] = HandlingCenterMaterialApiService.getSummary()

  if (isSummaryLoading || !summary) {
    return (
      <div className={generalClasses.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <Box className={classes.materialHandlingCenterRegistration}>
      <Typography className={titleClasses} variant="h3">
        {t('materialHandlingCenterOverview')}
      </Typography>

      <Box className={generalClasses.content}>
        <SummaryLine
          title={t('handlingCenterName')}
          value={summary.handlingCenterName}
        />
        <SummaryLine
          title={t('registeredUnitsCount')}
          value={summary.registeredUnitsCount}
        />

        <SummaryLine
          title={t('ongoingTransports')}
          value={summary.ongoingTransportsCount}
        />

        <FlowActionButton
          color="secondary"
          href="/register-handling-center-material"
          icon={LocalShippingIcon}
        >
          {t('registerMaterialButton')}
        </FlowActionButton>
      </Box>
    </Box>
  )
}

export default MaterialHandlingCenterRegistration
