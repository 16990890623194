// Palette
import palette from '../palette'

export default {
  root: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 8,
    marginBottom: 8,

    '&:focus': {
      backgroundColor: palette.common.white
    }
  },
  content: {
    border: `1px solid ${palette.text.disabled}`,
    paddingLeft: 4,
    paddingRight: 8,
    paddingBottom: 8,
    paddingTop: 8,

    '&:hover': {
      border: `1px solid ${palette.primary.main}`,
      backgroundColor: palette.common.white
    }
  },

  label: {
    paddingLeft: 0,
    paddingRight: 0
  }
}
