import {
  Box,
  Checkbox,
  ListItem,
  ListItemText,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import React, { FunctionComponentElement, useEffect, useState } from 'react'

import { Theme } from '../../common/theme'

import {
  EntityFilterField,
  EntityFilterOperator,
  EntityFilterType
} from './entity-filters.types'
import { EntityFilterOperatorSelector } from './EntityFilterOperatorSelector'

export interface EntityFilterSelectOption {
  data?: Record<string, any>
  disabled?: boolean
  label: string
  value: string
}

export interface EntityFilterSelectConfig {
  availableOperators?: EntityFilterSelectConfig['operator'][]
  clearable?: boolean
  field: EntityFilterField
  groupBy?: (option: EntityFilterSelectOption) => string
  label: string
  multiple?: boolean
  operator:
    | EntityFilterOperator.EQUALS
    | EntityFilterOperator.IN
    | EntityFilterOperator.IS_EMPTY
  options: EntityFilterSelectOption[]
  type: EntityFilterType.SELECT
  value: EntityFilterSelectOption | EntityFilterSelectOption[] | null
}

export interface EntityFilterSelectProps {
  config: EntityFilterSelectConfig

  onChange: (
    value: EntityFilterSelectConfig['value'],
    operator: EntityFilterOperator
  ) => void
}

const useStyles = makeStyles<Theme>(() => ({
  entityFilterSelect: {
    //
  }
}))

export function EntityFilterSelect({
  config,
  onChange
}: EntityFilterSelectProps): FunctionComponentElement<EntityFilterSelectProps> {
  const classes = useStyles()

  const [groupedOptions, setGroupedOptions] = useState<
    Record<string, EntityFilterSelectOption[]>
  >({})

  function toggleGroupItems(event, params) {
    const groupItems = groupedOptions[params.group]
    const currentItems = config.value as EntityFilterSelectOption[]

    function getNewItems() {
      if (currentItems.includes(groupItems[0])) {
        return currentItems.filter((item) => !groupItems.includes(item))
      }

      return [
        ...currentItems,
        ...groupItems.filter((item) => !currentItems.includes(item))
      ]
    }

    if (groupItems) {
      onChange(getNewItems(), config.operator)
    }
  }

  function groupOptions(
    options: EntityFilterSelectOption[],
    groupBy?: EntityFilterSelectConfig['groupBy']
  ): Record<string, EntityFilterSelectOption[]> {
    if (!groupBy) {
      return {}
    }

    return config.options
      .filter((item) => !item.disabled)
      .reduce((grouped, item) => {
        const groupKey = config.groupBy?.(item) ?? ''
        const existingGroupItems = grouped[groupKey]

        return {
          ...grouped,
          [groupKey]: existingGroupItems
            ? [...existingGroupItems, item]
            : [item]
        }
      }, {})
  }

  useEffect(() => {
    setGroupedOptions(groupOptions(config.options, config.groupBy))
  }, [config.groupBy, config.options])

  return (
    <Box className={classes.entityFilterSelect}>
      <Autocomplete
        disabled={config.operator === EntityFilterOperator.IS_EMPTY}
        disableCloseOnSelect={config.multiple}
        disableClearable={config.clearable === false}
        getOptionDisabled={(option) => option.disabled === true}
        getOptionLabel={(option) => option.label}
        groupBy={config.groupBy}
        multiple={config.multiple}
        options={config.options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={config.label}
            size="small"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <EntityFilterOperatorSelector
                    availableOperators={config.availableOperators}
                    operator={config.operator}
                    onChange={(operator) => {
                      const newValue =
                        operator === EntityFilterOperator.IS_EMPTY
                          ? config.multiple
                            ? []
                            : null
                          : config.value

                      onChange(newValue as typeof config.value, operator)
                    }}
                  />
                  {params.InputProps.startAdornment}
                </>
              )
            }}
          />
        )}
        renderGroup={
          config.multiple
            ? (params) => (
                <Box {...params}>
                  <ListItem
                    button={true}
                    onClick={(event) => toggleGroupItems(event, params)}
                  >
                    <ListItemText>
                      <Typography variant="h5">{params.group}</Typography>
                    </ListItemText>
                  </ListItem>

                  <Box>{params.children}</Box>
                </Box>
              )
            : undefined
        }
        renderOption={(option, { selected }) =>
          config.multiple ? (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlankOutlinedIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon color="primary" fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </>
          ) : (
            <>{option.label}</>
          )
        }
        value={config.value}
        ChipProps={{
          variant: 'outlined'
        }}
        onChange={(_, value) => {
          onChange(value, config.operator)
        }}
      />
    </Box>
  )
}
