import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  MenuItem,
  Menu,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  withStyles,
  Box,
  CircularProgress
} from '@material-ui/core'

import {
  MoreVert as MoreVertIcon
} from '@material-ui/icons'

import { AddHubManagerModal } from '../AddHubManagerModal'
import { AddHubOwnerModal } from '../AddHubOwnerModal'

import { CompanyType } from 'services/company/index.tsx'

// Shared components
import { Portlet, PortletContent, Tag, Transition } from 'components'
import GenerateTrackedItemsModal from '../GenerateTrackedItemsModal'

import styles from './styles'
import classNames from 'classnames'

import { getCountryByCurrentUserLang } from 'services/country'

import { apiFetchWithDispatch, usePaginationConfig } from 'lib/fetch'
import { useStateValue } from 'state'
import GenerateCollectorsModal from '../GenerateCollectorsModal'
import { CompanyState } from '../../../../../services/company'

function HubsTable(props) {
  const { classes, className, hubs, onUpdate } = props
  const rootClassName = classNames(classes.root, className)

  const [, dispatch] = useStateValue()
  const { page, size, sizeOptions, setPage, setSize } = usePaginationConfig()

  const [paginatedHubs, setPaginatedHubs] = useState([])

  const [anchorEl, setAnchorEl] = useState(null)
  const [actionMenuOpen, setActionMenuOpen] = useState(false)

  const [openArchiveConfirmation, setOpenArchiveConfirmation] = useState(false)
  const [openPauseConfirmation, setOpenPauseConfirmation] = useState(false)
  const [openReactivateConfirmation, setOpenReactivateConfirmation] = useState(false)
  const [selectedHub, setSelectedHub] = useState(null)

  const [showAddHubManagerModal, setShowAddHubManagerModal] = useState(false)
  const [showAddHubOwnerModal, setShowAddHubOwnerModal] = useState(false)
  const [
    showGenerateCollectorsModal,
    setShowGenerateCollectorsModal
  ] = useState(false)

  const [
    showGenerateTrackedItemsModal,
    setShowGenerateTrackedItemsModal
  ] = useState(false)

  const [isActionInProgress, setActionInProgress] = useState(false)

  const history = useHistory()

  useEffect(() => {
    setPage(0)
  }, [hubs])

  useEffect(() => {
    setPaginatedHubs(hubs.slice(page * size, page * size + size))
  }, [hubs, page, size])

  function handleOpenActionMenu(event, selectedHub) {
    setAnchorEl(event.currentTarget)
    setActionMenuOpen(true)
    setSelectedHub(selectedHub)
  }

  function handleCloseActionMenu() {
    setAnchorEl(null)
    setActionMenuOpen(false)
  }

  function handleClickOpenArchiveConfirmation() {
    setOpenArchiveConfirmation(true)
    setActionMenuOpen(false)
  }

  function handleCloseArchiveConfirmation() {
    setOpenArchiveConfirmation(false)
  }

  function handleClickOpenPauseConfirmation() {
    setOpenPauseConfirmation(true)
    setActionMenuOpen(false)
  }

  function handleClosePauseConfirmation() {
    setOpenPauseConfirmation(false)
  }

  function handleClickOpenReactivateConfirmation() {
    setOpenReactivateConfirmation(true)
    setActionMenuOpen(false)
  }

  function handleCloseReactivateConfirmation() {
    setOpenReactivateConfirmation(false)
  }

  function handleClickEditHub() {
    history.push(`/admin/add-hub/${selectedHub.uuid}`)
  }

  function handleClickViewCollectors() {
    history.push(`/admin/hubs/${selectedHub.uuid}/collectors`)
  }

  function handleClickViewTrackedItems() {
    history.push(`/admin/hubs/${selectedHub.uuid}/tracked-items`)
  }

  async function handleClickSyncMarketplace() {
    await apiFetchWithDispatch(
      dispatch,
      `/api/admin/companies/hubs/${selectedHub.uuid}/syncMarketplace`,
      {
        method: 'POST'
      }
    )

    setActionMenuOpen(false)
  }

  function handleClickAddHubManager() {
    setShowAddHubManagerModal(true)

    setActionMenuOpen(false)
  }

  function handleClickAddHubOwner() {
    setShowAddHubOwnerModal(true)

    setActionMenuOpen(false)
  }

  function handleClickGenerateCollectors() {
    setShowGenerateCollectorsModal(true)

    setActionMenuOpen(false)
  }

  function handleClickGenerateTrackedItems() {
    setShowGenerateTrackedItemsModal(true)

    setActionMenuOpen(false)
  }

  function handleClickManageAuditActions() {
    history.push(`/admin/hubs/${selectedHub.uuid}/manage-audit-actions`)
  }

  function handleClickManageProjects() {
    history.push(`/admin/hubs/${selectedHub.uuid}/manage-projects`)
  }

  function handleCloseAddHubOwnerModal() {
    setShowAddHubOwnerModal(false)
  }

  function handleGenerateCollectors() {
    setShowGenerateCollectorsModal(false)
    history.push(`/admin/hubs/${selectedHub.uuid}/collectors`)
  }

  function handleCloseGenerateCollectorsModal() {
    setShowGenerateCollectorsModal(false)
  }

  function handleGenerateTrackedItems() {
    setShowGenerateTrackedItemsModal(false)
    history.push(`/admin/hubs/${selectedHub.uuid}/tracked-items`)
  }

  function handleCloseGenerateTrackedItemsModal() {
    setShowGenerateTrackedItemsModal(false)
  }

  function companyCanHaveHubOwners() {
    return (
      selectedHub &&
      (selectedHub.pipelineOwner ||
        selectedHub.type === CompanyType.COLLECTION_CENTER ||
        selectedHub.type === CompanyType.RECYCLER ||
        selectedHub.type === CompanyType.CO_PROCESSING_FACILITY)
    )
  }

  async function handleArchive() {
    await apiFetchWithDispatch(
      dispatch,
      `/api/admin/companies/hubs/${selectedHub.uuid}/archive`,
      {
        method: 'POST'
      }
    )

    onUpdate()

    setOpenArchiveConfirmation(false)
    setSelectedHub(null)
  }

  async function handlePause() {
    setActionInProgress(true)

    await apiFetchWithDispatch(
      dispatch,
      `/api/admin/companies/hubs/${selectedHub.uuid}/pause`,
      {
        method: 'POST'
      }
    )

    onUpdate()

    setActionInProgress(false)
    setOpenPauseConfirmation(false)
    setSelectedHub(null)
  }

  async function handleReactivate() {
    setActionInProgress(true)

    await apiFetchWithDispatch(
      dispatch,
      `/api/admin/companies/hubs/${selectedHub.uuid}/reactivate`,
      {
        method: 'POST'
      }
    )

    onUpdate()

    setActionInProgress(false)
    setOpenReactivateConfirmation(false)
    setSelectedHub(null)
  }

  if (!hubs || hubs.length === 0) {
    return (
      <Typography variant="h6">
        There are currently no hubs matching this name.
      </Typography>
    )
  }

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">State</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Pipeline Owner</TableCell>
              <TableCell align="left">Country</TableCell>
              <TableCell align="left">Features</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedHubs.map((hub) => (
              <TableRow className={classes.tableRow} hover key={hub.uuid}>
                <TableCell className={classes.tableCell}>{hub.state}</TableCell>
                <TableCell className={classes.tableCell}>{hub.name}</TableCell>
                <TableCell className={classes.tableCell}>{hub.type}</TableCell>
                <TableCell className={classes.tableCell}>
                  {hub.pipelineOwner ? 'Yes' : 'No'}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {getCountryByCurrentUserLang(hub.country)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {hub.features.map((feature) => (
                    <Tag key={feature} value={feature} />
                  ))}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  <IconButton
                    size="medium"
                    aria-label="More"
                    aria-controls="actionMenu"
                    aria-haspopup="true"
                    onClick={(event) => handleOpenActionMenu(event, hub)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="actionMenu"
                    anchorEl={anchorEl}
                    className={classes.actionMenu}
                    keepMounted
                    open={actionMenuOpen}
                    onClose={handleCloseActionMenu}
                    PaperProps={{
                      className: classes.actionMenuPaper
                    }}
                  >
                    <MenuItem
                      key="Edit"
                      color="default"
                      onClick={handleClickEditHub}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      key="GenerateCollectors"
                      color="default"
                      onClick={handleClickGenerateCollectors}
                    >
                      Generate Collectors
                    </MenuItem>
                    <MenuItem
                      key="ViewCollectors"
                      color="default"
                      onClick={handleClickViewCollectors}
                    >
                      View Collectors
                    </MenuItem>
                    <MenuItem
                      key="GenerateTrackedItems"
                      color="default"
                      onClick={handleClickGenerateTrackedItems}
                    >
                      Generate Tracked Items
                    </MenuItem>
                    <MenuItem
                      key="ViewTrackedItems"
                      color="default"
                      onClick={handleClickViewTrackedItems}
                    >
                      View Tracked Items
                    </MenuItem>
                    {companyCanHaveHubOwners() && (
                      <MenuItem
                        key="AddHubOwner"
                        color="default"
                        onClick={handleClickAddHubOwner}
                      >
                        Add Hub Owner
                      </MenuItem>
                    )}
                    <MenuItem
                      key="AddHubManager"
                      color="default"
                      onClick={handleClickAddHubManager}
                    >
                      Add Hub Manager
                    </MenuItem>

                    <MenuItem
                      key="ManageAuditActions"
                      color="default"
                      onClick={handleClickManageAuditActions}
                    >
                      Manage Audit Actions
                    </MenuItem>
                    <MenuItem
                      key="ManageProjects"
                      color="default"
                      onClick={handleClickManageProjects}
                    >
                      Manage Projects
                    </MenuItem>
                    <MenuItem
                      key="SyncMarketplace"
                      color="default"
                      onClick={handleClickSyncMarketplace}
                    >
                      Sync marketplace
                    </MenuItem>
                    {selectedHub && selectedHub.state === CompanyState.ACTIVE && (
                      <MenuItem
                        key="Pause"
                        color="default"
                        onClick={handleClickOpenPauseConfirmation}
                      >
                        Pause
                      </MenuItem>
                    )}
                    {selectedHub && selectedHub.state === CompanyState.PAUSED && (
                      <MenuItem
                        key="Reactivate"
                        color="default"
                        onClick={handleClickOpenReactivateConfirmation}
                      >
                        Reactivate
                      </MenuItem>
                    )}
                    <MenuItem
                      key="Archive"
                      className={classes.archiveAction}
                      onClick={handleClickOpenArchiveConfirmation}
                    >
                      Archive
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          component="div"
          count={hubs.length}
          onChangePage={(_, page) => setPage(page)}
          onChangeRowsPerPage={(event) => {
            setSize(parseInt(event.target.value, 10))
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          page={page}
          rowsPerPage={size}
          rowsPerPageOptions={sizeOptions}
        />
      </PortletContent>

      <Dialog
        open={openArchiveConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseArchiveConfirmation}
      >
        <DialogTitle>Archive Hub</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to archive the selected hub? The archive
            operation cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseArchiveConfirmation}
            color="default"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button onClick={handleArchive} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPauseConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosePauseConfirmation}
      >
        <DialogTitle>Pause Hub</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Hub will be paused and all hub users will be suspended. Are you sure
            that you want to pause the selected hub?
          </DialogContentText>
        </DialogContent>

        {isActionInProgress ? (
          <DialogActions>
            <Box display="flex" justifyContent="left" p={1}>
              <CircularProgress size={18} />
            </Box>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={handleClosePauseConfirmation}
              color="default"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={handlePause} color="primary" variant="contained">
              Confirm
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        open={openReactivateConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseReactivateConfirmation}
      >
        <DialogTitle>Reactivate Hub</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Hub and all hub users will be reactivated. Are you sure that you want to reactivate the selected hub?
          </DialogContentText>
        </DialogContent>

        {isActionInProgress ? (
          <DialogActions>
            <Box display="flex" justifyContent="left" p={1}>
              <CircularProgress size={18} />
            </Box>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={handleCloseReactivateConfirmation}
              color="default"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={handleReactivate} color="primary" variant="contained">
              Confirm
            </Button>
          </DialogActions>
        )}
      </Dialog>

      {showAddHubManagerModal && (
        <AddHubManagerModal
          hub={selectedHub}
          show={showAddHubManagerModal}
          onClose={() => setShowAddHubManagerModal(false)}
        />
      )}

      {showAddHubOwnerModal && (
        <AddHubOwnerModal
          show={showAddHubOwnerModal}
          hub={selectedHub}
          onClose={handleCloseAddHubOwnerModal}
        />
      )}

      {showGenerateCollectorsModal && (
        <GenerateCollectorsModal
          showGenerateCollectorsModal={showGenerateCollectorsModal}
          hub={selectedHub}
          onGenerateCollectors={handleGenerateCollectors}
          onCloseGenerateCollectorsModal={handleCloseGenerateCollectorsModal}
        />
      )}

      {showGenerateTrackedItemsModal && (
        <GenerateTrackedItemsModal
          showGenerateTrackedItemsModal={showGenerateTrackedItemsModal}
          hub={selectedHub}
          onGenerateTrackedItems={handleGenerateTrackedItems}
          onCloseGenerateTrackedItemsModal={
            handleCloseGenerateTrackedItemsModal
          }
        />
      )}
    </Portlet>
  )
}

export default withStyles(styles)(HubsTable)
