import { makeStyles, Typography } from '@material-ui/core'
import React, { FunctionComponentElement } from 'react'

import { Theme } from 'common/theme'

const useStyles = makeStyles<Theme>((theme) => ({
  noValue: {
    color: theme.palette.text.disabled
  }
}))

export function NoValue(): FunctionComponentElement<void> {
  const classes = useStyles()

  return (
    <Typography className={classes.noValue} variant="body2">
      &mdash;
    </Typography>
  )
}
