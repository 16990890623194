import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { compose } from 'recompose'

// Material components
import {
  Box,
  CircularProgress,
  withStyles
} from '@material-ui/core'

// Shared layouts
import { AdminPortalLayout } from '../../../layouts/AdminPortalLayout'
import { PortalContent } from '../../../layouts/portal/PortalContent'

// Component styles
import styles from './styles'

import { AddHubForm } from './components'

import { apiFetchWithDispatch } from 'lib/fetch'

import { useStateValue } from 'state'

function AddHub (props) {
  const { match: { params: { hubCompanyUuid } } } = props

  const [hub, setHub] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [, dispatch] = useStateValue()

  const getHub = useCallback(async () => {
    if (!hubCompanyUuid) {
      return
    }

    setIsLoading(true)

    const hub = await apiFetchWithDispatch(dispatch, `/api/admin/companies/hubs/${hubCompanyUuid}`)

    setHub(hub)
    setIsLoading(false)
  }, [dispatch, hubCompanyUuid])

  useEffect(() => {
    getHub()
  }, [getHub])

  function getTitle () {
    const action = hubCompanyUuid ? 'Edit' : 'Add'
    return `${action} hub`
  }

  return (
    <AdminPortalLayout title={getTitle()}>
      <PortalContent loading={isLoading}>
        <AddHubForm hub={hub} />
      </PortalContent>
    </AdminPortalLayout>
  )
}

export default compose(
  withRouter,
  withStyles(styles)
)(AddHub)
