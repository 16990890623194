import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { FunctionComponentElement, useState } from 'react'
import { useGeneralStyles } from '../../common/styles/general'
import { useFormStyles } from '../../common/styles/form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import LinkButton from '../../components/LinkButton'
import { PortalContent } from '../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../layouts/SecurePortalLayout'
import { OngoingTransportsApiService } from '../../services/ongoing-transport/ongoing-transports-api.service'
import { useStateValue } from '../../state'
import { useForm } from '../../lib/form'
import schema from './RegisterCoProcessingMaterialTruckLoad/schema'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { apiFetchWithDispatch } from '../../lib/fetch'
import AsyncSelect from 'react-select/async/dist/react-select.esm'
import { Theme } from '../../common/theme'

const useStyles = makeStyles((theme: Theme) => ({
  registerHandlingCenterMaterial: {
    //
  },
  transporterIdentifierLabel: {
    marginBottom: theme.spacing()
  }
}))

function RegisterHandlingCenterMaterial(): FunctionComponentElement<void> {
  const classes = useStyles()

  const generalClasses = useGeneralStyles()
  const formClasses = useFormStyles()

  const [, dispatch] = useStateValue()

  const { t } = useTranslation()

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)

  const {
    formState: { values },
    setValue,
    resetForm
  } = useForm(schema, {
    selectedOngoingTransport: {
      label: '',
      licensePlate: '',
      materialTypes: [],
      value: ''
    }
  })

  function handleSelectOngoingTransport(event) {
    setValue('selectedOngoingTransport', event)
  }

  const ongoingTransportSearchResponseOptions = (inputLicensePlate) => {
    handleSelectOngoingTransport({
      label: '',
      licensePlate: '',
      materialTypes: [],
      value: ''
    })

    return OngoingTransportsApiService.search(dispatch, t, inputLicensePlate)
  }

  const debouncedOngoingTransportSearchResponseOptions = AwesomeDebouncePromise(
    ongoingTransportSearchResponseOptions,
    500
  )

  async function handleSave() {
    setIsLoading(true)

    const { selectedOngoingTransport } = values

    const response = await apiFetchWithDispatch(
      dispatch,
      '/api/handlingCenterMaterials',
      {
        method: 'POST',
        body: JSON.stringify({
          transportOwner: selectedOngoingTransport.value
        })
      }
    )

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      boxShadow: 'none'
    }),
    noOptionsMessage: () => ({
      display: 'none'
    })
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('registerHandlingCenterMaterialTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  return (
    <Box className={classes.registerHandlingCenterMaterial}>
      <SecurePortalLayout title={t('registerHandlingCenterMaterialTitle')}>
        <PortalContent>
          <div className={generalClasses.content}>
            <form autoComplete="off" noValidate>
              <Paper className={formClasses.formSection}>
                <Typography className={generalClasses.title} variant="h3">
                  {t('registerHandlingCenterMaterialTitle')}
                </Typography>

                <div className={formClasses.textField}>
                  <Typography
                    variant="body1"
                    className={classes.transporterIdentifierLabel}
                  >
                    {t('identifier')}
                  </Typography>
                  <AsyncSelect
                    name="selectedOngoingTransport"
                    classNamePrefix="selectedOngoingTransport"
                    styles={customSelectStyles}
                    required
                    label={t('selectedOngoingTransport')}
                    value={values.selectedOngoingTransport}
                    onChange={handleSelectOngoingTransport}
                    cacheOptions
                    defaultOptions
                    loadOptions={debouncedOngoingTransportSearchResponseOptions}
                  />
                </div>

                <div className={formClasses.actionButtons}>
                  <LinkButton
                    size="large"
                    className={formClasses.cancelButton}
                    color="default"
                    variant="outlined"
                    href="/hub-overview"
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size="large"
                    variant="contained"
                    className={generalClasses.primaryButton}
                    disabled={
                      isLoading || !values.selectedOngoingTransport.value
                    }
                    onClick={handleSave}
                  >
                    {t('registerButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </div>
        </PortalContent>
      </SecurePortalLayout>
    </Box>
  )
}

export default RegisterHandlingCenterMaterial
