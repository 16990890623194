export default (theme) => ({
  '@global': {
    '.MuiTab-root': {
      width: '50%'
    }
  },

  container: {
    marginBottom: 60
  },
  root: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),

    [theme.breakpoints.up('md')]: {
      margin: theme.spacing()
    }
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    marginLeft: theme.spacing(),
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.common.white,

    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 281px)',
      left: 281
    }
  },
  tab: {
    width: 'auto !important',
    minWidth: 'auto !important',
    flexGrow: 1,
    flexShrink: 1,

    [theme.breakpoints.down('sm')]: {
      fontSize: '60%',
      textTransform: 'capitalize'
    }
  },
  periodSelectorWrapper: {
    padding: theme.spacing(1),
    border: 'none',
    borderBottom: `1px solid ${theme.palette.border}`,
    borderRadius: 0,

    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 1, 3),
      border: `1px solid ${theme.palette.border}`,
      borderRadius: theme.spacing(1)
    }
  }
})
