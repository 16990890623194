import React, { useState } from 'react'

// Material components
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles
} from '@material-ui/core'

// Component styles
import styles from './styles'
import { useTranslation } from 'react-i18next'

import {
  RestoreFromTrash as RestoreFromTrashIcon
} from '@material-ui/icons'

import schema from './schema'

import { useForm } from 'lib/form'
import { apiFetchWithDispatch, useFetchState } from 'lib/fetch'

import { useStateValue } from 'state'
import { getRegisteredMaterialLabel } from '../../../../../services/registeredmaterial'

function RecyclerGradeTruckLoadForm (props) {
  const { classes, onStartGrading } = props

  const [registeredMaterials, isRegisteredMaterialLoading] = useFetchState('/api/registeredMaterials', [])

  const [isLoading, setIsLoading] = useState(false)

  const [, dispatch] = useStateValue()

  const { t } = useTranslation()

  const {
    formState: {
      values,
      isValid
    },
    handleChange
  } = useForm(schema, {
    registeredMaterialUuid: ''
  })

  async function handleStartGrading () {
    setIsLoading(true)

    await apiFetchWithDispatch(dispatch, `/api/registeredMaterials/${values.registeredMaterialUuid}/grade`, {
      method: 'POST'
    })

    setIsLoading(false)

    onStartGrading()
  }

  if (isLoading || isRegisteredMaterialLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  if (registeredMaterials.length === 0) {
    return (
      <>
        <Typography
          className={classes.inactiveFormMessage}
          variant='subtitle1'
        >
          {t('noMaterialSourceForGrading')}
        </Typography>
      </>
    )
  }

  return (
    <div>
      <form autoComplete='off' noValidate>
        <div className={classes.radioGroupField}>
          <FormControl component='fieldset'>
            <FormLabel
              component='legend'
              className={classes.radioGroupLabel}
            >
              {t('selectTruckLoadForGrading')}:
            </FormLabel>
            <RadioGroup
              name='registeredMaterialUuid'
              value={values.registeredMaterialUuid}
              onChange={handleChange}
            >
              {
                registeredMaterials
                  .map(registeredMaterial => (
                    <FormControlLabel
                      className={classes.radioFieldLabel}
                      key={registeredMaterial.uuid}
                      value={registeredMaterial.uuid}
                      control={<Radio />}
                      label={getRegisteredMaterialLabel(registeredMaterial, t)}
                    />
                  ))
              }
            </RadioGroup>
          </FormControl>
        </div>

        <Button
          size='large'
          variant='outlined'
          disabled={!isValid || isLoading}
          className={classes.startGradingButton}
          onClick={handleStartGrading}
          startIcon={<RestoreFromTrashIcon style={{ height: 30 }} />}
        >
          {t('gradeTruckLoadButton')}
        </Button>
      </form>
    </div>
  )
}

export default withStyles(styles)(RecyclerGradeTruckLoadForm)
