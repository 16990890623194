import { Box, makeStyles } from '@material-ui/core'
import React, { FunctionComponentElement, ReactNode } from 'react'

export interface ClnModalContentProps {
  children?: ReactNode
}

const useStyles = makeStyles(() => ({
  clnModalContent: {
    //
  }
}))

export function ClnModalContent({
  children
}: ClnModalContentProps): FunctionComponentElement<ClnModalContentProps> {
  const classes = useStyles()

  return <Box className={classes.clnModalContent}>{children}</Box>
}
