import { Box, makeStyles } from '@material-ui/core'
import { captureException } from '@sentry/react'
import React, { useEffect, useState } from 'react'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import { useTranslation } from 'react-i18next'
import { usePosition } from 'use-position'

import { FullScreenImagePreview } from '../../components'
import { dataUriToFile } from '../../lib/file'
import {
  getCameraHeight,
  getCameraWidth
} from '../../services/cameraresolution'
import { uploadFileWithDispatch } from '../../services/fileupload'
import { useStateValue } from '../../state'
import { ToastActionType } from '../../state/reducers/toast.reducer'

import Toast from '../Toast'

import { CameraFooter, CameraHeader } from './components'

const MIN_RESOLUTION = {
  width: 640,
  height: 480
}

const MAX_RESOLUTION = {
  width: 640,
  height: 480
}

const useStyles = makeStyles(() => ({
  '@global': {
    '#container-circles': {
      height: 75,
      position: 'fixed',
      bottom: 100
    },
    '#inner-circle, #inner-circle.is-clicked': {
      height: 55,
      width: 55,
      marginTop: -27,
      marginLeft: -27,
      top: 37
    },
    '#outer-circle': {
      pointerEvents: ({ latitude, longitude }) =>
        !latitude || !longitude ? 'none' : 'auto',
      display: ({ latitude, longitude }) =>
        !latitude || !longitude ? 'none' : 'block'
    }
  },
  shutterIconWrapper: {
    position: 'fixed',
    bottom: 122,
    left: '50%',
    marginLeft: -10,
    opacity: 0.54,
    pointerEvents: 'none',
    zIndex: 1
  }
}))

export default function FullScreenCamera(props) {
  // we need to use the "watchPosition" instead of "getCurrentPosition"
  // because the latter cannot be cancelled when we remove this component from the DOM
  const {
    latitude: currentLatitude,
    longitude: currentLongitude,
    accuracy: currentAccuracy,
    error
  } = usePosition(true, {
    enableHighAccuracy: true
  })

  const {
    fileName,
    headerMessage,
    onTakePhotoFinished,
    onFileUploadFinished,
    onConfirmPicture,
    onCameraError,
    onGoBack,
    shutterIcon
  } = props

  const [, dispatch] = useStateValue()

  const { t } = useTranslation()

  const [pictureDataUri, setPictureDataUri] = useState(null)
  const [localError, setLocalError] = useState(null)
  const [cameraState, setCameraState] = useState('initializing')
  const [cameraResolution] = useState({
    width: {
      min: MIN_RESOLUTION.width,
      ideal: getCameraWidth() || MAX_RESOLUTION.width
    },
    height: {
      min: MIN_RESOLUTION.height,
      ideal: getCameraHeight() || MAX_RESOLUTION.height
    }
  })
  const [resolvedAccuracy, setResolvedAccuracy] = useState(null)
  const [resolvedLatitude, setResolvedLatitude] = useState(null)
  const [resolvedLongitude, setResolvedLongitude] = useState(null)

  useEffect(() => {
    if (error && cameraState !== 'initializing') {
      dispatch({
        type: ToastActionType.ADD_TOAST,
        value: {
          message: `${t('locationDeniedErrorMessage')} (${error})`,
          variant: 'error'
        }
      })

      setLocalError(error)
      onCameraError(false)
    }
  }, [error, cameraState])

  useEffect(() => {
    if (currentAccuracy) {
      setResolvedAccuracy(currentAccuracy)
    }

    if (currentLatitude) {
      setResolvedLatitude(currentLatitude)
    }

    if (currentLongitude) {
      setResolvedLongitude(currentLongitude)
    }
  }, [currentAccuracy, currentLatitude, currentLongitude])

  async function handleTakePhotoAnimationDone(pictureDataUri) {
    setPictureDataUri(pictureDataUri)
    onTakePhotoFinished(pictureDataUri)

    if (!currentLatitude || !currentLongitude) {
      captureException('Current lat/lng is not set', {
        extra: {
          currentAccuracy,
          currentLatitude,
          currentLongitude,
          resolvedAccuracy,
          resolvedLatitude,
          resolvedLongitude
        }
      })
    }

    const pictureFileUpload = await handlePictureUpload(
      dataUriToFile(pictureDataUri, fileName),
      {
        accuracy: resolvedAccuracy,
        latitude: resolvedLatitude,
        longitude: resolvedLongitude
      }
    )
    onFileUploadFinished(pictureFileUpload)
  }

  function handleCameraError(error) {
    dispatch({
      type: ToastActionType.ADD_TOAST,
      value: {
        message: t('cameraNotSupportedErrorMessage'),
        variant: 'error'
      }
    })

    setCameraState('error')
    onCameraError(false)
    setLocalError(error)
  }

  function handleCameraStart() {
    setCameraState('started')
  }

  async function handlePictureUpload(pictureDataFile, geolocation) {
    return await uploadFileWithDispatch(dispatch, pictureDataFile, geolocation)
  }

  function handleRetakePhoto() {
    setPictureDataUri(null)
    onTakePhotoFinished(null)
  }

  const classes = useStyles({
    latitude: resolvedLatitude,
    longitude: resolvedLongitude
  })

  if (pictureDataUri) {
    return (
      <FullScreenImagePreview
        dataUri={pictureDataUri}
        onRetakePhoto={handleRetakePhoto}
        onConfirmPhoto={onConfirmPicture}
      />
    )
  }

  return (
    <>
      <CameraHeader message={headerMessage} onGoBack={onGoBack} />
      {!localError && (
        <>
          <Camera
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            idealResolution={cameraResolution}
            isSilentMode
            isFullscreen
            isImageMirror={false}
            isDisplayStartCameraError={false}
            onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
            onCameraError={handleCameraError}
            onCameraStart={handleCameraStart}
            imageType={IMAGE_TYPES.JPG}
          />

          {shutterIcon && resolvedLatitude && resolvedLongitude && (
            <Box className={classes.shutterIconWrapper}>{shutterIcon}</Box>
          )}
        </>
      )}
      <CameraFooter latitude={resolvedLatitude} longitude={resolvedLongitude} />

      {/*
       * We need to add the toast here as well
       * in case the FullScreenCamera is not removed from the DOM
       * when an error occurs.
       *
       * In that case, the toast would not be shown
       */}
      <Toast />
    </>
  )
}
