import { createContext } from 'react'

import { IdentityDto } from '../../services/user'

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <TrackingProvider>.')
}

export interface TrackingContextInterface {
  trackClick: (value: string, metadata?: Record<string, string>) => void
  trackComplete: (value: string) => void
  trackPageView: (value: string) => void
  trackSelect: (value: string) => void
  trackSubmit: (value: string) => void
  trackTabView: (value: string) => void
  trackUserSignIn: (userId: string, userSession: IdentityDto) => void
  trackUserSignOut: () => void
  trackView: (value: string) => void
}

export const TrackingContext = createContext<TrackingContextInterface>({
  trackClick: stub,
  trackComplete: stub,
  trackPageView: stub,
  trackSelect: stub,
  trackSubmit: stub,
  trackTabView: stub,
  trackUserSignIn: stub,
  trackUserSignOut: stub,
  trackView: stub
})
