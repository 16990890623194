import React from 'react'

// Externals
import classNames from 'classnames'

// Material components
import { TextField, withStyles } from '@material-ui/core'

// Component styles
import styles from './styles'
import Box from '@material-ui/core/Box'
import { SearchInput } from '../../../../../components'
import {
  getActiveAndPausedCompanyStateOptions,
  getHubCompanyFeatureOptions,
  getHubCompanyTypeOptions
} from "../../../../../services/company";

function HubsToolbar(props) {
  const { classes, className, onHubFilter } = props

  const rootClassName = classNames(classes.root, className)

  return (
    <div className={rootClassName}>
      <div className={classes.row}>
        <Box display="flex" alignItems="center">
          <SearchInput
            placeholder="Search hub by name"
            onChange={(event) => onHubFilter(event, 'name')}
          />
        </Box>

        <Box display="flex" alignItems="center">
          <TextField
            name="searchByHubType"
            select
            label="Search hub by type"
            variant="outlined"
            className={classes.selectField}
            InputProps={{ disableUnderline: true }}
            SelectProps={{ native: true }}
            onChange={(event) => onHubFilter(event, 'type')}
          >
            {getHubCompanyTypeOptions()}
          </TextField>
        </Box>

        <Box display="flex" alignItems="center">
          <TextField
            name="searchByFeature"
            select
            label="Search hub by feature"
            variant="outlined"
            className={classes.selectField}
            InputProps={{ disableUnderline: true }}
            SelectProps={{ native: true }}
            onChange={(event) => onHubFilter(event, 'feature')}
          >
            {getHubCompanyFeatureOptions()}
          </TextField>
        </Box>

        <Box display="flex" alignItems="center">
          <TextField
            name="searchByState"
            select
            label="Search hub by state"
            variant="outlined"
            className={classes.selectField}
            InputProps={{ disableUnderline: true }}
            SelectProps={{ native: true }}
            onChange={(event) => onHubFilter(event, 'state')}
          >
            {getActiveAndPausedCompanyStateOptions()}
          </TextField>
        </Box>
      </div>
    </div>
  )
}

export default withStyles(styles)(HubsToolbar)
