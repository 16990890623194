import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import {
  ChevronLeftOutlined as ChevronLeftOutlinedIcon,
  ChevronRightOutlined as ChevronRightOutlinedIcon,
  ExitToAppOutlined as ExitToAppOutlinedIcon,
  FaceOutlined as FaceOutlinedIcon,
  Menu as MenuIcon
} from '@material-ui/icons'
import React, {
  Fragment,
  FunctionComponentElement,
  ReactNode,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from '../../common/theme'
import { NewLogo } from '../../components/NewLogo'
import { auth0Client } from '../../lib/auth0'
import { useTracking } from '../../lib/tracking'
import { LocalStorage, StorageKey } from '../../services/storage'
import { clearUserStorageData } from '../../services/storage/storage.helpers'
import { getCurrentUser } from '../../services/user'

import { usePortalLayout } from './portal-layout.hook'
import { usePortalNavigationStyles } from './portal-navigation-styles.hook'
import { NavigationItem, PortalNavigationItem } from './PortalNavigationItem'

export interface NavigationGroup {
  id: string
  items: NavigationItem[]
}

interface PortalNavigationProps {
  children?: ReactNode
  collapsibleNavigation?: boolean
  groups: NavigationGroup[]
  showTitle?: boolean
  userItems?: NavigationItem[]
}

export function PortalNavigation({
  collapsibleNavigation,
  groups,
  showTitle = true,
  userItems
}: PortalNavigationProps): FunctionComponentElement<PortalNavigationProps> {
  const theme = useTheme<Theme>()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { trackUserSignOut } = useTracking()
  const { t } = useTranslation()
  const { title } = usePortalLayout()

  const [desktopDrawerCollapsed, setDesktopDrawerCollapsed] = useState<boolean>(
    () => {
      return (
        LocalStorage.getItem<boolean>(
          StorageKey.PORTAL_NAVIGATION_COLLAPSED
        ) === true
      )
    }
  )
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState<boolean>(false)

  const currentUser = getCurrentUser()
  const classes = usePortalNavigationStyles({
    collapsed: !isMobile && desktopDrawerCollapsed && !!collapsibleNavigation
  })

  function showLogoWave(): boolean {
    return (
      !!collapsibleNavigation && desktopDrawerCollapsed && !mobileDrawerOpen
    )
  }

  function showListItemText(): boolean {
    return isMobile || !collapsibleNavigation || !desktopDrawerCollapsed
  }

  function toggleDesktopDrawer(): void {
    LocalStorage.setItemRaw(
      StorageKey.PORTAL_NAVIGATION_COLLAPSED,
      !desktopDrawerCollapsed
    )

    setDesktopDrawerCollapsed((value) => !value)
  }

  const handleSignOut = async () => {
    trackUserSignOut()
    clearUserStorageData()

    await auth0Client.logout()
  }

  const generateNavigation = (): ReactNode => {
    return (
      <Box height="100%" display="flex" flexDirection="column">
        <Box
          width={showLogoWave() ? 44 : 240}
          height={44}
          mt={5}
          mb={2}
          ml={showLogoWave() ? 3 : 4}
        >
          <NewLogo variant={showLogoWave() ? 'wave' : 'text'} />
        </Box>

        <Box flexGrow={1} overflow="auto">
          {groups.map((group, index) => (
            <Fragment key={group.id}>
              {index !== 0 && (
                <Box my={1}>
                  <Divider />
                </Box>
              )}

              <List>
                {group.items.map((item) => (
                  <PortalNavigationItem
                    key={item.name}
                    collapsibleNavigation={collapsibleNavigation}
                    desktopDrawerCollapsed={desktopDrawerCollapsed}
                    item={item}
                    showText={showListItemText()}
                  />
                ))}
              </List>
            </Fragment>
          ))}
        </Box>

        {currentUser && (
          <>
            {showListItemText() && (
              <>
                <List>
                  <ListItem
                    classes={{
                      gutters: classes.listItemGutters
                    }}
                  >
                    <ListItemIcon
                      classes={{
                        root: classes.listItemIconRoot
                      }}
                    >
                      <FaceOutlinedIcon />
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemText}
                      primary={
                        <Typography variant="body1">
                          {currentUser.firstName} {currentUser.lastName}
                        </Typography>
                      }
                      secondary={
                        currentUser.company ? (
                          <Typography color="textSecondary" variant="caption">
                            {currentUser.company.name}
                          </Typography>
                        ) : null
                      }
                    />
                  </ListItem>

                  {(userItems ?? []).map((item) => (
                    <PortalNavigationItem
                      key={item.name}
                      collapsibleNavigation={collapsibleNavigation}
                      desktopDrawerCollapsed={desktopDrawerCollapsed}
                      item={item}
                      showText={showListItemText()}
                    />
                  ))}
                </List>

                <Divider />
              </>
            )}

            <List>
              <ListItem
                button={true}
                onClick={handleSignOut}
                classes={{
                  gutters: classes.listItemGutters
                }}
              >
                <ListItemIcon
                  classes={{
                    root: classes.listItemIconRoot
                  }}
                >
                  <ExitToAppOutlinedIcon />
                </ListItemIcon>

                {showListItemText() && (
                  <ListItemText className={classes.listItemText}>
                    <Typography variant="body1">
                      {t('signOutButton')}
                    </Typography>
                  </ListItemText>
                )}
              </ListItem>
            </List>
          </>
        )}
      </Box>
    )
  }

  return (
    <Box className={classes.portalNavigation}>
      <Hidden mdDown={true}>
        <Drawer
          variant="permanent"
          open={true}
          classes={{ paper: classes.drawerPaper }}
        >
          {generateNavigation()}

          {collapsibleNavigation && (
            <Box className={classes.toggleWrapper}>
              <Box className={classes.toggleLine}>
                <Box
                  className={classes.toggleTrigger}
                  onClick={toggleDesktopDrawer}
                >
                  {desktopDrawerCollapsed ? (
                    <ChevronRightOutlinedIcon style={{ fontSize: 16 }} />
                  ) : (
                    <ChevronLeftOutlinedIcon style={{ fontSize: 16 }} />
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Drawer>
      </Hidden>

      <Hidden lgUp={true}>
        <AppBar
          color="transparent"
          position="fixed"
          classes={{ root: classes.appBarRoot }}
        >
          <Toolbar>
            <Box
              display="flex"
              flexGrow={1}
              alignItems="center"
              justifyContent="start"
            >
              <Box height={30}>
                <NewLogo variant="mark" />
              </Box>

              {title && showTitle && (
                <Box ml={2}>
                  <Typography variant="h4" noWrap>
                    {title}
                  </Typography>
                </Box>
              )}
            </Box>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={() => setMobileDrawerOpen(true)}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="temporary"
          open={mobileDrawerOpen}
          onClose={() => setMobileDrawerOpen(false)}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {generateNavigation()}
        </Drawer>
      </Hidden>
    </Box>
  )
}
