export default theme => ({
  root: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.danger.main,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '4rem',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: '4rem'
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '2rem',
    height: '2rem',
    width: '2rem'
  },
  registerMaterialButton: {
    width: '100%',
    fontSize: 18,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),

    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  noTruckLoadToBeRegisteredMessage: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing()
  }
})
