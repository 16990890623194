import {
  AppBar,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  withStyles
} from '@material-ui/core'
import {
  CallSplitOutlined as CallSplitOutlinedIcon,
  ControlPointDuplicateOutlined as ControlPointDuplicateOutlinedIcon,
  HomeWorkOutlined as HomeWorkOutlinedIcon,
  LocalShippingOutlined as LocalShippingOutlinedIcon,
  RotateLeftOutlined as RotateLeftOutlinedIcon,
  SendOutlined as SendOutlinedIcon,
  VerifiedUserOutlined as VerifiedUserOutlinedIcon
} from '@material-ui/icons'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Paper } from '../../../components'
import { PeriodSelector } from '../../../components/PeriodSelector'
import { TabContent } from '../../../components/TabContent'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'
import { apiFetchWithDispatch, useFetchState } from '../../../lib/fetch'
import { ClearanceTransportStates } from '../../../services/clearancetransport'
import { HubCompanyTypes } from '../../../services/company'
import { Feature } from '../../../services/feature'
import { MaterialClearanceStates } from '../../../services/material-clearance/material-clearance-types'
import { ProcessingUnitState } from '../../../services/processingunit'
import { SegregationUnitStates } from '../../../services/segregationunit'
import { getCurrentUser } from '../../../services/user'
import { useStateValue } from '../../../state'
import { OverviewPeriodActionType } from '../../../state/reducers/overview-period.reducer'

import {
  CollectionCenterClearance,
  CollectionCenterGradeForm,
  CollectionCenterGrading,
  CollectionCenterClearanceUnit,
  CollectionCenterRegistration,
  CoProcessingFacilityCertificationForm,
  CoProcessingFacilityRegistration,
  OngoingRecyclerClearance,
  RecyclerClearance,
  RecyclerGradeTruckLoadForm,
  RecyclerGrading,
  RecyclerRegistration
} from './components'
import CarrierDispatch from './components/CarrierDispatch'
import CarrierRegistration from './components/CarrierRegistration'
import CollectionCenterActivePickUp from './components/CollectionCenterActivePickUp'
import CollectionCenterCollectionsOverview from './components/CollectionCenterCollectionsOverview'
import LoadedHandlingCenterMaterialClearance from './components/LoadedHandlingCenterMaterialClearance'
import { MassBalanceClearanceOverview } from './components/MassBalanceClearanceOverview'
import { MassBalanceGradingOverview } from './components/MassBalanceGradingOverview'
import { MassBalanceProcessingOverview } from './components/MassBalanceProcessingOverview'
import { MassBalanceRegistrationOverview } from './components/MassBalanceRegistrationOverview'
import MaterialHandlingCenterClearance from './components/MaterialHandlingCenterClearance'
import MaterialHandlingCenterRegistration from './components/MaterialHandlingCenterRegistration'
import OngoingClearance from './components/OngoingClearance'
import RecyclerCertificationForm from './components/RecyclerCertificationForm'
import { RecyclerProcessing } from './components/RecyclerProcessing'
import styles from './styles'
import { MaterialClearanceUnitState } from '../../../services/materialclearanceunit'

function HubOverview(props) {
  const { classes } = props

  const OverviewTabs = {
    COLLECTION: 0,
    REGISTRATION: 1,
    GRADING: 2,
    PROCESSING: 3,
    CLEARANCE: 4,
    CERTIFICATION: 5,
    DISPATCH: 6
  }

  const [isLoading, setIsLoading] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(
    OverviewTabs.REGISTRATION
  )

  const { t } = useTranslation()

  const { company, features } = getCurrentUser()

  const featureSet = new Set(features)
  const hubType = company.type

  const [ongoingSegregationUnit, setOngoingSegregationUnit] = useState(null)
  const [ongoingProcessingUnit, setOngoingProcessingUnit] = useState(null)
  const [
    ongoingMaterialClearanceUnit,
    setOngoingMaterialClearanceUnit
  ] = useState(null)
  const [ongoingClearanceTransport, setOngoingClearanceTransport] = useState(
    null
  )
  const [ongoingMaterialClearance, setOngoingMaterialClearance] = useState(null)
  const [
    loadedHandlingCenterMaterialClearance,
    setLoadedHandlingCenterMaterialClearance
  ] = useState(null)

  const [clearancesOverview, isClearancesOverviewLoading] = useFetchState(
    '/api/clearances/overview'
  )

  const [{ activePickUpPoint, overviewPeriod }, dispatch] = useStateValue()

  const hasCollectionTab = featureSet.has(Feature.MATERIAL_COLLECTION)
  const hasRegistrationTab =
    featureSet.has(Feature.TRUCK_LOAD_REGISTRATION) ||
    featureSet.has(Feature.WASTE_BAG_REGISTRATION) ||
    featureSet.has(Feature.CONTAINER_REGISTRATION) ||
    featureSet.has(Feature.MATERIAL_REGISTRATION)
  const hasGradingTab = featureSet.has(Feature.MATERIAL_SEGREGATION)
  const hasProcessingTab = featureSet.has(Feature.MATERIAL_PROCESSING)
  const hasClearanceTab = featureSet.has(Feature.MATERIAL_CLEARANCE)
  const hasCertificationTab = featureSet.has(Feature.MATERIAL_CERTIFICATION)
  const hasDispatchTab = featureSet.has(Feature.MATERIAL_DISPATCH)

  const tabsCount =
    (hasCollectionTab ? 1 : 0) +
    (hasRegistrationTab ? 1 : 0) +
    (hasGradingTab ? 1 : 0) +
    (hasProcessingTab ? 1 : 0) +
    (hasClearanceTab ? 1 : 0) +
    (hasCertificationTab ? 1 : 0) +
    (hasDispatchTab ? 1 : 0)

  const collectionTabIndex = hasCollectionTab ? 0 : -1
  const registrationTabIndex = hasRegistrationTab
    ? hasCollectionTab
      ? 1
      : 0
    : -1
  const gradingTabIndex = hasGradingTab
    ? (hasCollectionTab ? 1 : 0) + (hasRegistrationTab ? 1 : 0)
    : -1

  const processingTabIndex = hasProcessingTab
    ? (hasCollectionTab ? 1 : 0) +
      (hasRegistrationTab ? 1 : 0) +
      (hasGradingTab ? 1 : 0)
    : -1

  const clearanceTabIndex = hasClearanceTab
    ? (hasCollectionTab ? 1 : 0) +
      (hasRegistrationTab ? 1 : 0) +
      (hasGradingTab ? 1 : 0) +
      (hasProcessingTab ? 1 : 0)
    : -1

  const certificationTabIndex = hasCertificationTab ? tabsCount - 1 : -1

  const dispatchTabIndex = hasDispatchTab ? tabsCount - 1 : -1

  function handleChangeTab(event, newValue) {
    setActiveTabIndex(newValue)
  }

  const getOngoing = useCallback(async () => {
    setIsLoading(true)

    const ongoingSegregationUnit = await apiFetchWithDispatch(
      dispatch,
      `/api/segregationUnits?state=${SegregationUnitStates.PROCESSING}`
    )
    setOngoingSegregationUnit(ongoingSegregationUnit)

    const ongoingProcessingUnit = await apiFetchWithDispatch(
      dispatch,
      `/api/processingUnits?state=${ProcessingUnitState.IN_PROGRESS}`
    )
    setOngoingProcessingUnit(ongoingProcessingUnit)

    const ongoingMaterialClearanceUnit = await apiFetchWithDispatch(
      dispatch,
      `/api/materialClearanceUnits?state=${MaterialClearanceUnitState.IN_PROGRESS}`
    )
    setOngoingMaterialClearanceUnit(ongoingMaterialClearanceUnit)

    const ongoingClearanceTransport = await apiFetchWithDispatch(
      dispatch,
      `/api/clearanceTransports?state=${ClearanceTransportStates.LOADED}`
    )
    setOngoingClearanceTransport(ongoingClearanceTransport)

    const ongoingMaterialClearance = await apiFetchWithDispatch(
      dispatch,
      `/api/materialClearances?state=${MaterialClearanceStates.LOADED}`
    )
    setOngoingMaterialClearance(ongoingMaterialClearance)

    const loadedHandlingCenterMaterialClearance = await apiFetchWithDispatch(
      dispatch,
      '/api/handlingCenterMaterialClearances/loaded'
    )
    setLoadedHandlingCenterMaterialClearance(
      loadedHandlingCenterMaterialClearance
    )

    setActiveTabIndex(
      ongoingClearanceTransport ||
        ongoingMaterialClearance ||
        loadedHandlingCenterMaterialClearance ||
        ongoingMaterialClearanceUnit
        ? clearanceTabIndex
        : ongoingSegregationUnit && !ongoingProcessingUnit
        ? gradingTabIndex
        : ongoingSegregationUnit &&
          ongoingProcessingUnit &&
          moment(ongoingSegregationUnit.createdAt).toDate() >
            moment(ongoingProcessingUnit.createdAt).toDate()
        ? gradingTabIndex
        : ongoingProcessingUnit
        ? processingTabIndex
        : 0
    )

    setIsLoading(false)
  }, [dispatch, clearanceTabIndex, gradingTabIndex, processingTabIndex])

  useEffect(() => {
    if ('Notification' in window) {
      Notification.requestPermission()
    }
  }, [])

  useEffect(() => {
    getOngoing()
  }, [getOngoing])

  function handleStartGrading() {
    getOngoing()
  }

  function handleFinishProcessing() {
    getOngoing()
  }

  function handleStartProcessing() {
    getOngoing()
  }

  function showPeriodSelector() {
    const isCorrectTab =
      activeTabIndex === registrationTabIndex ||
      (activeTabIndex === gradingTabIndex && !ongoingSegregationUnit) ||
      (activeTabIndex === processingTabIndex && !ongoingProcessingUnit) ||
      (activeTabIndex === clearanceTabIndex &&
        !ongoingMaterialClearance &&
        !ongoingMaterialClearanceUnit)
    const isCorrectHubType =
      hubType === HubCompanyTypes.COLLECTION_CENTER ||
      hubType === HubCompanyTypes.RECYCLER
    const isEligible =
      isCorrectHubType && featureSet.has(Feature.MASS_BALANCE_TRACKING)

    return isCorrectTab && isEligible && !!overviewPeriod.periodType
  }

  const useStyles = makeStyles(() => ({
    '@global': {
      '.MuiTab-root': {
        width: tabsCount === 4 ? '20%' : tabsCount === 3 ? '33%' : '50%'
      }
    }
  }))

  useStyles()

  if (isLoading || isClearancesOverviewLoading) {
    return (
      <SecurePortalLayout title={t('registeredTruckLoadsTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  return (
    <SecurePortalLayout title={t('registeredTruckLoadsTitle')}>
      <PortalContent showTitle={false}>
        {showPeriodSelector() && (
          <Paper className={classes.periodSelectorWrapper}>
            <PeriodSelector
              periodType={overviewPeriod.periodType}
              onDatesChange={(startDate, endDate) =>
                dispatch({
                  type: OverviewPeriodActionType.SET_DATES,
                  value: { endDate, startDate }
                })
              }
              onPeriodTypeChange={(periodType) =>
                dispatch({
                  type: OverviewPeriodActionType.SET_PERIOD_TYPE,
                  value: periodType
                })
              }
            />
          </Paper>
        )}

        <Grid className={classes.container} container>
          <Grid item xs={12} sm={8} lg={5}>
            <Paper className={classes.root}>
              {activeTabIndex === collectionTabIndex && (
                <TabContent tabName="Collection">
                  {hubType === HubCompanyTypes.COLLECTION_CENTER && (
                    <>
                      {!!activePickUpPoint && <CollectionCenterActivePickUp />}

                      {!activePickUpPoint && (
                        <CollectionCenterCollectionsOverview />
                      )}
                    </>
                  )}
                </TabContent>
              )}

              {activeTabIndex === registrationTabIndex && (
                <TabContent tabName="Registration">
                  {hubType === HubCompanyTypes.COLLECTION_CENTER && (
                    <>
                      {featureSet.has(Feature.MASS_BALANCE_TRACKING) ? (
                        <MassBalanceRegistrationOverview />
                      ) : (
                        <CollectionCenterRegistration />
                      )}
                    </>
                  )}

                  {hubType === HubCompanyTypes.RECYCLER && (
                    <>
                      {featureSet.has(Feature.MASS_BALANCE_TRACKING) ? (
                        <MassBalanceRegistrationOverview />
                      ) : (
                        <RecyclerRegistration />
                      )}
                    </>
                  )}

                  {hubType === HubCompanyTypes.CARRIER && (
                    <CarrierRegistration />
                  )}

                  {hubType === HubCompanyTypes.MATERIAL_HANDLING_CENTER && (
                    <MaterialHandlingCenterRegistration />
                  )}

                  {hubType === HubCompanyTypes.CO_PROCESSING_FACILITY && (
                    <CoProcessingFacilityRegistration />
                  )}
                </TabContent>
              )}

              {activeTabIndex === gradingTabIndex && (
                <TabContent tabName="Grading">
                  {hubType === HubCompanyTypes.COLLECTION_CENTER && (
                    <>
                      {ongoingSegregationUnit ? (
                        <CollectionCenterGrading
                          segregationUnit={ongoingSegregationUnit}
                        />
                      ) : featureSet.has(Feature.MASS_BALANCE_TRACKING) ? (
                        <MassBalanceGradingOverview
                          onStartGrading={handleStartGrading}
                        />
                      ) : (
                        <CollectionCenterGradeForm
                          onStartGrading={handleStartGrading}
                        />
                      )}
                    </>
                  )}

                  {hubType === HubCompanyTypes.RECYCLER &&
                    !featureSet.has(Feature.MASS_BALANCE_TRACKING) && (
                      <>
                        {ongoingSegregationUnit ? (
                          <RecyclerGrading
                            segregationUnit={ongoingSegregationUnit}
                          />
                        ) : (
                          <RecyclerGradeTruckLoadForm
                            onStartGrading={handleStartGrading}
                          />
                        )}
                      </>
                    )}

                  {hubType === HubCompanyTypes.RECYCLER &&
                    featureSet.has(Feature.MASS_BALANCE_TRACKING) && (
                      <>
                        {ongoingSegregationUnit ? (
                          <CollectionCenterGrading
                            segregationUnit={ongoingSegregationUnit}
                          />
                        ) : (
                          <MassBalanceGradingOverview
                            onStartGrading={handleStartGrading}
                          />
                        )}
                      </>
                    )}
                </TabContent>
              )}

              {activeTabIndex === processingTabIndex && (
                <TabContent tabName="Processing">
                  {hubType === HubCompanyTypes.RECYCLER && (
                    <>
                      {ongoingProcessingUnit ? (
                        <RecyclerProcessing
                          processingUnit={ongoingProcessingUnit}
                          onFinishProcessing={handleFinishProcessing}
                        />
                      ) : (
                        <MassBalanceProcessingOverview
                          onStartProcessing={handleStartProcessing}
                        />
                      )}
                    </>
                  )}
                </TabContent>
              )}

              {activeTabIndex === clearanceTabIndex && (
                <TabContent tabName="Clearance">
                  {hubType === HubCompanyTypes.COLLECTION_CENTER && (
                    <>
                      {ongoingMaterialClearance ? (
                        <OngoingClearance
                          materialClearance={ongoingMaterialClearance}
                        />
                      ) : (
                        <>
                          {ongoingMaterialClearanceUnit ? (
                            <CollectionCenterClearanceUnit
                              materialClearanceUnit={
                                ongoingMaterialClearanceUnit
                              }
                            />
                          ) : (
                            <>
                              {featureSet.has(Feature.MASS_BALANCE_TRACKING) ? (
                                <MassBalanceClearanceOverview />
                              ) : (
                                <CollectionCenterClearance />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {hubType === HubCompanyTypes.RECYCLER && (
                    <>
                      {(ongoingClearanceTransport &&
                        !featureSet.has(Feature.MASS_BALANCE_TRACKING)) ||
                      (ongoingMaterialClearance &&
                        featureSet.has(Feature.MASS_BALANCE_TRACKING)) ? (
                        <>
                          {featureSet.has(Feature.MASS_BALANCE_TRACKING) ? (
                            <OngoingClearance
                              materialClearance={ongoingMaterialClearance}
                            />
                          ) : (
                            <OngoingRecyclerClearance
                              clearanceTransport={ongoingClearanceTransport}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {featureSet.has(Feature.MASS_BALANCE_TRACKING) ? (
                            <MassBalanceClearanceOverview />
                          ) : (
                            <RecyclerClearance overview={clearancesOverview} />
                          )}
                        </>
                      )}
                    </>
                  )}

                  {hubType === HubCompanyTypes.MATERIAL_HANDLING_CENTER && (
                    <>
                      {loadedHandlingCenterMaterialClearance ? (
                        <LoadedHandlingCenterMaterialClearance
                          handlingCenterMaterialClearance={
                            loadedHandlingCenterMaterialClearance
                          }
                        />
                      ) : (
                        <MaterialHandlingCenterClearance />
                      )}
                    </>
                  )}
                </TabContent>
              )}

              {activeTabIndex === certificationTabIndex && (
                <TabContent tabName="Certification">
                  {hubType === HubCompanyTypes.CO_PROCESSING_FACILITY && (
                    <CoProcessingFacilityCertificationForm />
                  )}

                  {hubType === HubCompanyTypes.RECYCLER && (
                    <RecyclerCertificationForm />
                  )}
                </TabContent>
              )}

              {activeTabIndex === dispatchTabIndex && (
                <TabContent tabName="Dispatch">
                  {hubType === HubCompanyTypes.CARRIER && <CarrierDispatch />}
                </TabContent>
              )}
            </Paper>

            {tabsCount > 1 && (
              <AppBar
                position="fixed"
                color="primary"
                className={classes.appBar}
              >
                <Tabs value={activeTabIndex} onChange={handleChangeTab}>
                  {hasCollectionTab && (
                    <Tab
                      className={classes.tab}
                      icon={<HomeWorkOutlinedIcon />}
                      label={t('collectionTab')}
                    />
                  )}

                  {hasRegistrationTab && (
                    <Tab
                      className={classes.tab}
                      icon={<ControlPointDuplicateOutlinedIcon />}
                      label={t('registrationTab')}
                    />
                  )}

                  {hasGradingTab && (
                    <Tab
                      className={classes.tab}
                      icon={<CallSplitOutlinedIcon />}
                      label={t('gradingTab')}
                    />
                  )}

                  {hasProcessingTab && (
                    <Tab
                      className={classes.tab}
                      icon={<RotateLeftOutlinedIcon />}
                      label={t('processingTab')}
                    />
                  )}

                  {hasClearanceTab && (
                    <Tab
                      className={classes.tab}
                      icon={<LocalShippingOutlinedIcon />}
                      label={t('clearanceTab')}
                    />
                  )}

                  {hasCertificationTab && (
                    <Tab
                      className={classes.tab}
                      icon={<VerifiedUserOutlinedIcon />}
                      label={t('certificationTab')}
                    />
                  )}

                  {hasDispatchTab && (
                    <Tab
                      className={classes.tab}
                      icon={<SendOutlinedIcon />}
                      label={t('dispatchTab')}
                    />
                  )}
                </Tabs>
              </AppBar>
            )}
          </Grid>
        </Grid>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default withStyles(styles)(HubOverview)
