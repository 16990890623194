import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles
} from '@material-ui/core'
import { Receipt as ReceiptIcon } from '@material-ui/icons'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useFetchState } from '../../../../../lib/fetch'
import { useForm } from '../../../../../lib/form'
import { QuantityUnits } from '../../../../../services/quantityunit'

import styles from './styles'

function RecyclerCertificationForm({ classes }) {
  const [materials, isLoading] = useFetchState(
    '/api/collectedMaterials/readyForCertification',
    []
  )

  const { t } = useTranslation()

  const history = useHistory()

  const {
    formState: { values },
    handleChange,
    setValue
  } = useForm(null, {
    materialUuid: ''
  })

  useEffect(() => {
    if (materials.length === 1 && values.materialUuid === '') {
      setValue('materialUuid', materials[0].uuid)
    }
  }, [materials, values.materialUuid, setValue])

  function getMaterialLabel(material) {
    const displayedQuantityUnit = QuantityUnits[material.quantityUnit]

    return `${moment(material.weighedAt).format(
      'DD/MM/YYYY HH:mm'
    )} - ${material.weight.toFixed(2)} ${displayedQuantityUnit}, ${
      material.licensePlate
    }`
  }

  function handleAddCertificate() {
    history.push(`/recycler-material/${values.materialUuid}/add-certificate`)
  }

  if (isLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress color="inherit" />
      </div>
    )
  }

  if (materials.length === 0) {
    return (
      <>
        <Typography className={classes.inactiveFormMessage} variant="subtitle1">
          {t('noMaterialForCertification')}
        </Typography>
      </>
    )
  }

  return (
    <div>
      <form autoComplete="off" noValidate>
        <div className={classes.radioGroupField}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.radioGroupLabel}>
              {t('selectTruckLoadForCertification')}:
            </FormLabel>
            <RadioGroup
              name="materialUuid"
              value={values.collectedMaterialUuid}
              onChange={handleChange}
            >
              <>
                {materials.map((material) => (
                  <FormControlLabel
                    className={classes.radioFieldLabel}
                    key={material.uuid}
                    value={material.uuid}
                    control={<Radio />}
                    label={getMaterialLabel(material)}
                  />
                ))}
              </>
            </RadioGroup>
          </FormControl>
        </div>
      </form>

      <Button
        size="large"
        variant="outlined"
        className={classes.addCertificateButton}
        disabled={!values.materialUuid}
        onClick={handleAddCertificate}
        startIcon={<ReceiptIcon style={{ height: 30 }} />}
      >
        {t('addCertificateButton')}
      </Button>
    </div>
  )
}

export default withStyles(styles)(RecyclerCertificationForm)
