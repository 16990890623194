// Palette
import palette from '../palette'

export default {
  root: {
    borderBottom: `1px solid ${palette.divider}`,
    padding: '12px 24px 12px 20px'
  },
  head: {
    fontSize: '14px',
    color: palette.text.primary
  }
}
