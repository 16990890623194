import { TFunction } from 'i18next'

import { getCurrentUser } from '../user'
import { Feature } from '../feature'

export enum RegistrationUnit {
  TRUCK_LOAD = 'TRUCK_LOAD',
  BAG = 'BAG',
  BALE = 'BALE',
  CONTAINER = 'CONTAINER'
}

export interface RegistrationUnitOption {
  default: boolean
  label: string
  value: RegistrationUnit
}

export function getRegistrationUnitOptions(
  t: TFunction
): RegistrationUnitOption[] {
  const options: RegistrationUnitOption[] = []
  const featureSet = new Set(getCurrentUser()?.features)

  if (featureSet.has(Feature.WASTE_BAG_REGISTRATION)) {
    options.push({
      label: t('bagRegistration'),
      value: RegistrationUnit.BAG,
      default: true
    })
  }

  if (featureSet.has(Feature.TRUCK_LOAD_REGISTRATION)) {
    options.push({
      label: t('truckLoadRegistration'),
      value: RegistrationUnit.TRUCK_LOAD,
      default: options.length === 0
    })
  }

  if (featureSet.has(Feature.CONTAINER_REGISTRATION)) {
    options.push({
      label: t('containerRegistration'),
      value: RegistrationUnit.CONTAINER,
      default: options.length === 0
    })
  }

  return options
}
