import { MinimalCompanyDto } from '../company'
import { MinimalFileUploadDto } from '../fileupload/file-upload.types'
import {
  CodeOfConductPrincipleCategory,
  CodeOfConductPrincipleDto
} from '../codeofconduct'

export enum ComplianceActionType {
  REQUEST_EVIDENCE = 'REQUEST_EVIDENCE'
}

export enum ComplianceActionStatus {
  CREATED = 'CREATED',
  OWNER_NOTIFIED = 'OWNER_NOTIFIED',
  IN_REVIEW = 'IN_REVIEW',
  REVIEWED = 'REVIEWED'
}

export interface ComplianceActionDto {
  uuid: string
  createdAt: string
  lastModifiedAt: string
  type: ComplianceActionType
  status: ComplianceActionStatus
  name: string
  description: string
  deadlineDate: string
  cocPrinciple: CodeOfConductPrincipleDto
  company: MinimalCompanyDto
  documents: ComplianceActionDocumentDto[] | null
}

export interface ComplianceActionDocumentDto {
  uuid: string
  comment: string
  fileUpload: MinimalFileUploadDto
}

export interface MinimalComplianceActionDto {
  uuid: string
  createdAt: string
  lastModifiedAt: string
  type: ComplianceActionType
  status: ComplianceActionStatus
  name: string
  deadlineDate: string
  cocPrincipleCategory: CodeOfConductPrincipleCategory
}
