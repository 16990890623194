import * as countries from 'i18n-iso-countries'
import React from 'react'

import { getCurrentUser } from '../user'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))

function getAllCountryCodes () {
  return countries.getAlpha2Codes()
}

function getCountryName (countryCode, language) {
  return countries.getName(countryCode, language)
}

export function getCountryByCurrentUserLang (countryCode) {
  const currentUser = getCurrentUser()
  const lang = currentUser ? currentUser.language.toLowerCase() : 'en'

  return countries.getName(countryCode, lang)
}

export function getCountryOptions () {
  const countryOptions = []

  const currentUser = getCurrentUser()
  const lang = currentUser ? currentUser.language.toLowerCase() : 'en'

  countryOptions.push(<option key='' value='' />)
  countryOptions.push(Object.keys(getAllCountryCodes()).map(countryCode => (
    <option
      key={countryCode}
      value={countryCode}
    >
      {getCountryName(countryCode, lang)}
    </option>
  )))

  return countryOptions
}
