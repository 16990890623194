import React from 'react'

// Externals
import classNames from 'classnames'

// Material components
import { Typography, withStyles } from '@material-ui/core'

import { Receipt as ReceiptIcon } from '@material-ui/icons'

import { SummaryLine } from 'components'

// Component styles
import styles from './styles'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import LinkButton from 'components/LinkButton'

function OngoingRecyclerClearance (props) {
  const { classes, className, clearanceTransport } = props

  const rootClassName = classNames(classes.root, className)

  const { t } = useTranslation()

  return (
    <div className={rootClassName}>
      <Typography
        className={classes.title}
        variant='h3'
      >
        {t('ongoingRecyclerClearanceTransportSummary')}
      </Typography>
      <div className={classes.content}>
        <SummaryLine
          title={t('loadedAt')}
          value={`${moment(clearanceTransport.createdAt).format('DD/MM/YYYY HH:mm')}`}
        />
        <SummaryLine
          title={t('licensePlate')}
          value={clearanceTransport.transporterLicensePlate}
        />

        <LinkButton
          size='large'
          variant='contained'
          className={classes.addWeighSlipButton}
          href={`/weigh-recycler-clearance-transport/${clearanceTransport.uuid}`}
          startIcon={<ReceiptIcon
            style={{
              height: 50,
              width: 50,
              fontWeight: 30
            }}
          />}
        >
          {t('addWeighSlipButton')}
        </LinkButton>
      </div>
    </div>
  )
}

export default withStyles(styles)(OngoingRecyclerClearance)
