import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import enTranslation from './locales/en/translation.json'

i18next.use(initReactI18next).init({
  defaultNS: 'translation',
  lng: 'en',
  interpolation: {
    escapeValue: false
  },
  resources: {
    en: {
      translation: enTranslation
    }
  }
})

export default i18next
