import React from 'react'

import { makeStyles } from '@material-ui/core'

function Image ({ source, width }) {
  const useStyles = makeStyles(theme => ({
    root: {
      position: 'relative',
      textAlign: 'center',
      width: width || '80%',

      '& img': {
        width: width || '100%'
      },

      [theme.breakpoints.up('md')]: {
        width: '100%'
      }
    }
  }))

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img
        src={source}
        alt=''
      />
    </div>
  )
}

export default Image
