import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  TablePagination,
  Tooltip,
  Typography
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import {
  CloudDownload as CloudDownloadIcon,
  HouseOutlined as HouseIcon,
  PersonOutlined as PersonIcon,
  PinDropOutlined as PinDropOutlinedIcon
} from '@material-ui/icons'
import { saveAs } from 'file-saver'
import { unparse } from 'papaparse'
import React, {
  Fragment,
  FunctionComponentElement,
  ReactNode,
  useState
} from 'react'
import { match } from 'react-router-dom'

import { Theme } from '../../../common/theme'
import { Image, Paper, Portlet, PortletContent } from '../../../components'
import { EntityFilterConfig } from '../../../components/EntityFilters'
import { AdminPortalLayout } from '../../../layouts/AdminPortalLayout'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import {
  usePaginatedFilteredFetchState,
  usePaginationConfig
} from '../../../lib/fetch'
import { CollectorDto, CollectorType } from '../../../services/collector'

import { CollectorsFilters } from './CollectorsFilters'

interface Props {
  match: match<{ hubCompanyUuid: string }>
}

const useStyles = makeStyles<Theme>((theme) => ({
  viewCollectors: {
    //
  },
  assignedName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  assignedNameWrapper: {
    width: `calc(100% - ${theme.spacing(6)}px)`,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 3),
    zIndex: 1200
  },
  bottomPaginationWrapper: {
    borderTop: `1px solid ${theme.palette.border}`,

    '@media print': {
      display: 'none'
    }
  },
  filters: {
    '@media print': {
      display: 'none'
    }
  },
  pageBreak: {
    clear: 'both',
    pageBreakAfter: 'always'
  },
  paper: {
    overflow: 'hidden',

    '@media print': {
      boxShadow: 'none'
    }
  },
  qrCodeWrapper: {
    width: '25%',
    float: 'left',
    border: `1px solid ${theme.palette.border}`
  },
  qrCodes: {
    '@media print': {
      display: 'block'
    }
  },
  topPaginationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    borderBottom: `1px solid ${theme.palette.border}`,

    '@media print': {
      display: 'none'
    }
  },
  trackingNumber: {
    marginLeft: theme.spacing(1),
    zIndex: 1200,

    '@media print': {
      fontSize: 16
    }
  },
  trackingNumberWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 3),
    zIndex: 1200
  }
}))

export function ViewCollectors({
  match
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const {
    params: { hubCompanyUuid }
  } = match

  const { page, size, setPage, setSize } = usePaginationConfig()

  const [filters, setFilters] = useState<EntityFilterConfig[]>([])

  const [
    collectors,
    isCollectorsLoading
  ] = usePaginatedFilteredFetchState<CollectorDto>(
    `/api/admin/collectors`,
    page,
    size,
    filters
  )

  function getPagination(): ReactNode {
    return (
      <TablePagination
        component="div"
        count={collectors?.pagination.total ?? 0}
        onChangePage={(_, page) => setPage(page)}
        onChangeRowsPerPage={(event) => {
          setSize(parseInt(event.target.value, 10))
        }}
        page={page}
        rowsPerPage={size}
        rowsPerPageOptions={[16, 32, 48]}
      />
    )
  }

  function exportCollectorTrackingNumbers() {
    const data = [
      ['Tracking number'],
      ...(collectors?.items.map((item) => [item.trackingNumber]) ?? [])
    ]
    const csv = unparse(data, {
      header: false
    })
    const blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    })

    saveAs(blob, `collectors-${hubCompanyUuid}.csv`)
  }

  return (
    <AdminPortalLayout title="View Collectors">
      <PortalContent>
        <Box className={classes.filters} mb={5}>
          <Paper elevation={5} outlined={false}>
            <Box p={3}>
              <CollectorsFilters
                hubUuid={hubCompanyUuid}
                onChange={(filters) => {
                  setFilters(filters)
                  setPage(0)
                }}
              />
            </Box>
          </Paper>
        </Box>

        <Paper className={classes.paper} elevation={5} outlined={false}>
          <Box>
            {isCollectorsLoading || !collectors ? (
              <Box display="flex" justifyContent="center" p={5}>
                <CircularProgress />
              </Box>
            ) : (
              <Portlet>
                <PortletContent noPadding={true}>
                  <Box className={classes.topPaginationWrapper}>
                    <Tooltip title="Export tracking numbers">
                      <IconButton
                        color="default"
                        size="medium"
                        onClick={exportCollectorTrackingNumbers}
                      >
                        <CloudDownloadIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>

                    <Box>{getPagination()}</Box>
                  </Box>

                  <Box className={classes.viewCollectors}>
                    <Grid
                      className={classes.qrCodes}
                      container={true}
                      spacing={0}
                    >
                      {collectors?.items.map((collector, index) => (
                        <Fragment key={collector.uuid}>
                          <Grid
                            className={classes.qrCodeWrapper}
                            item={true}
                            xs={12}
                            sm={4}
                            lg={3}
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              mt={4}
                              textAlign="center"
                            >
                              <Box className={classes.trackingNumberWrapper}>
                                {collector.type ===
                                  CollectorType.COLLECTION_POINT && (
                                  <PinDropOutlinedIcon
                                    className={classes.icon}
                                  />
                                )}

                                {collector.type ===
                                  CollectorType.INDIVIDUAL && (
                                  <PersonIcon className={classes.icon} />
                                )}

                                {collector.type === CollectorType.HOUSEHOLD && (
                                  <HouseIcon className={classes.icon} />
                                )}

                                <Typography
                                  className={classes.trackingNumber}
                                  variant="h4"
                                >
                                  {collector.trackingNumber}
                                </Typography>
                              </Box>

                              <Box className={classes.assignedNameWrapper}>
                                <Box className={classes.assignedName}>
                                  {collector.name ?? <>&nbsp;</>}
                                </Box>
                              </Box>

                              {/* without this padding, the image is covering the border of the parent Grid */}
                              <Box padding="1px">
                                <Image
                                  source={collector.qrCode.directLink}
                                  width="100%"
                                />
                              </Box>
                            </Box>
                          </Grid>

                          {(index + 1) % 16 === 0 ? (
                            <Box className={classes.pageBreak} />
                          ) : null}
                        </Fragment>
                      ))}
                    </Grid>
                  </Box>

                  <Box className={classes.bottomPaginationWrapper}>
                    {getPagination()}
                  </Box>
                </PortletContent>
              </Portlet>
            )}
          </Box>
        </Paper>
      </PortalContent>
    </AdminPortalLayout>
  )
}
