import React, { FunctionComponentElement } from 'react'

import logoFullBlack from '../assets/images/logo/cleanhub-logo-full-black.svg'
import logoFullWhite from '../assets/images/logo/cleanhub-logo-full-white.svg'
import logoMarkBlack from '../assets/images/logo/cleanhub-logo-mark-black.svg'
import logoMarkWhite from '../assets/images/logo/cleanhub-logo-mark-white.svg'
import logoTaglineBlack from '../assets/images/logo/cleanhub-logo-tagline-black.svg'
import logoTaglineWhite from '../assets/images/logo/cleanhub-logo-tagline-white.svg'
import logoTextBlack from '../assets/images/logo/cleanhub-logo-text-black.svg'
import logoTextWhite from '../assets/images/logo/cleanhub-logo-text-white.svg'
import logoWaveBlack from '../assets/images/logo/cleanhub-logo-wave-black.svg'
import logoWaveWhite from '../assets/images/logo/cleanhub-logo-wave-white.svg'

type LogoColor = 'black' | 'white'
type LogoVariant = 'full' | 'mark' | 'tagline' | 'text' | 'wave'

export interface NewLogoProps {
  color?: LogoColor
  variant?: LogoVariant
}

const logoMap: Record<LogoVariant, Record<LogoColor, string>> = {
  full: {
    black: logoFullBlack,
    white: logoFullWhite
  },
  mark: {
    black: logoMarkBlack,
    white: logoMarkWhite
  },
  tagline: {
    black: logoTaglineBlack,
    white: logoTaglineWhite
  },
  text: {
    black: logoTextBlack,
    white: logoTextWhite
  },
  wave: {
    black: logoWaveBlack,
    white: logoWaveWhite
  }
}

export function NewLogo({
  color = 'black',
  variant = 'full'
}: NewLogoProps): FunctionComponentElement<NewLogoProps> {
  return (
    <>
      <img
        alt="CleanHub"
        src={logoMap[variant][color]}
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          display: 'block'
        }}
      />
    </>
  )
}
