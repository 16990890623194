import React, { useCallback, useEffect, useState } from 'react'

// Material components
import { Box, withStyles } from '@material-ui/core'

// Shared layouts
import { Paper } from '../../../components'
import { AdminPortalLayout } from '../../../layouts/AdminPortalLayout'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { apiFetchWithDispatch } from '../../../lib/fetch'
import { useStateValue } from '../../../state'

// Custom components
import { UsersTable } from './components'
import { UsersToolbar } from './components/UsersToolbar'

// Component styles
import styles from './styles'

function UserList(props) {
  const { classes } = props

  const [users, setUsers] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [filteredMap] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const [, dispatch] = useStateValue()

  const getUsers = useCallback(async () => {
    setIsLoading(true)

    const users = await apiFetchWithDispatch(dispatch, '/api/admin/users')

    setUsers(users)
    setAllUsers(users)
    setIsLoading(false)
  }, [dispatch])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  function handleRefresh() {
    getUsers()
  }

  function handleFilterChange(event, field) {
    event.persist()

    const searchValue = (event.target.value ?? '').toLowerCase()

    if (!searchValue || searchValue.length === 0) {
      delete filteredMap[field]

      if (Object.keys(filteredMap).length === 0) {
        setUsers(allUsers)
      } else {
        applyFilter()
      }
      return
    }

    filteredMap[field] = searchValue
    applyFilter()
  }

  function applyFilter() {
    let filteredList = allUsers
    for (const [field, value] of Object.entries(filteredMap)) {
      if (field === 'nameEmailPhone') {
        filteredList = filteredList.filter((user) => {
          const email = user.email?.toLowerCase() ?? ''
          const fullName = user.fullName.toLowerCase()
          const phoneNumber = user.phoneNumber ?? ''

          return (
            email.includes(value) ||
            fullName.includes(value) ||
            phoneNumber.includes(value)
          )
        })
      } else if (field === 'company') {
        filteredList = filteredList.filter((user) =>
          (user.company?.name.toLowerCase() ?? '').includes(value)
        )
      } else if (field === 'role') {
        filteredList = filteredList.filter(
          (user) => value === user.role.toLowerCase()
        )
      }

      setUsers(filteredList)
    }
  }

  return (
    <AdminPortalLayout title="Users">
      <PortalContent loading={isLoading}>
        <Paper elevation={5} outlined={false}>
          <Box p={3}>
            <UsersToolbar onFilterChange={handleFilterChange} />

            <div className={classes.content}>
              {users && <UsersTable users={users} onEditUser={handleRefresh} />}
            </div>
          </Box>
        </Paper>
      </PortalContent>
    </AdminPortalLayout>
  )
}

export default withStyles(styles)(UserList)
