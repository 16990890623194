import React from 'react'

// Externals
import classNames from 'classnames'

// Material components
import { withStyles } from '@material-ui/core'

import {
  LocalShipping as LocalShippingIcon
} from '@material-ui/icons'

// Component styles
import styles from './styles'

import { useTranslation } from 'react-i18next'

import { SummaryLine } from 'components'

import { getDisplayedQuantityUnit } from 'services/quantityunit'
import LinkButton from 'components/LinkButton'
import { getCurrentUser } from '../../../../../services/user'

function RecyclerClearance({ classes, className, overview }) {
  const rootClassName = classNames(classes.root, className)
  const { segregationOutputMaterialTypes } = getCurrentUser()
  const hasMultipleOutputMaterialTypes = segregationOutputMaterialTypes.length > 1

  const { t } = useTranslation()

  const displayedQuantityUnit = getDisplayedQuantityUnit(overview.quantityUnit)

  return (
    <div className={rootClassName}>
      <div className={classes.content}>
        <SummaryLine
          title={t('materialBagsCount', {
            materialType: hasMultipleOutputMaterialTypes ? undefined : t(`materialType_${segregationOutputMaterialTypes[0]}`),
            context: hasMultipleOutputMaterialTypes ? 'multipleOutputMaterialTypes' : undefined
          })}
          value={overview.segregationOutputMaterialBagsCount}
        />
        <SummaryLine
          title={t('materialTotal', {
            materialType: hasMultipleOutputMaterialTypes ? undefined : t(`materialType_${segregationOutputMaterialTypes[0]}`),
            context: hasMultipleOutputMaterialTypes ? 'multipleOutputMaterialTypes' : undefined
          })}
          value={<>{overview.formattedMaterialTotal} {displayedQuantityUnit}</>}
        />
        
        <LinkButton
          size='large'
          variant='contained'
          className={classes.startClearanceButton}
          disabled={overview.segregationOutputMaterialBagsCount === 0}
          href='/start-recycler-clearance'
          startIcon={<LocalShippingIcon
            style={{
              height: 30,
              width: 30,
              fontWeight: 30
            }}
          />}
        >
          {t('startClearanceButton')}
        </LinkButton>
      </div>
    </div>
  )
}

export default withStyles(styles)(RecyclerClearance)
