import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import React, { FunctionComponentElement } from 'react'
import { match, Redirect } from 'react-router-dom'

import { useFetchState } from '../../lib/fetch'
import { CollectorStates } from '../../services/collector'
import { useGeneralStyles } from '../../common/styles/general'
import { getCompany } from '../../services/company'

interface Props {
  match: match<{ collectorUuid: string }>
}

const useStyles = makeStyles(() => ({
  collectorIdentity: {
    //
  }
}))

function CollectorIdentity({ match }: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()

  const {
    params: { collectorUuid }
  } = match

  const [collector, isLoading] = useFetchState(
    `/api/collectors/${collectorUuid}`
  )

  const currentCompany = getCompany()

  if (isLoading || !collector) {
    return (
      <Box className={generalClasses.progressWrapper}>
        <CircularProgress />
      </Box>
    )
  }

  if (collector && collector.company?.uuid !== currentCompany?.uuid) {
    return <Redirect to={`/error`} />
  }

  if (collector && collector.state === CollectorStates.UNDEFINED) {
    return (
      <Box className={classes.collectorIdentity}>
        <Redirect to={`/define-collector/${collectorUuid}`} />
      </Box>
    )
  }

  return (
    <Box className={classes.collectorIdentity}>
      <Redirect to={`/active-collector/${collectorUuid}`} />
    </Box>
  )
}

export default CollectorIdentity
