import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  TextField
} from '@material-ui/core'
import React, { Fragment, FunctionComponentElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { match } from 'react-router-dom'
import { DropzoneArea } from 'material-ui-dropzone'

import { Theme } from '../../common/theme'

import { AdminPortalLayout } from '../../layouts/AdminPortalLayout'
import { PortalContent } from '../../layouts/portal/PortalContent'
import { apiFetchWithDispatch, useFetchState } from '../../lib/fetch'
import { useFormStyles } from '../../common/styles/form'
import { uploadFileByAdminWithDispatch } from '../../services/fileupload'
import { useStateValue } from '../../state'
import { FileUploadDto } from '../../services/fileupload/file-upload.types'

import { Image, Paper } from '../../components'

interface Props {
  match: match<{ hubCompanyUuid: string }>
}

interface CollectedMaterialRequest {
  fileUpload: FileUploadDto
  buyer?: string
  transporterLicensePlate?: string
  saved: boolean
}

const useStyles = makeStyles<Theme>(() => ({
  adminStartMaterialClearance: {
    width: '50%'
  }
}))

function AdminStartMaterialClearance({
  match
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const formClasses = useFormStyles()

  const [, dispatch] = useStateValue()
  const { t } = useTranslation()

  const {
    params: { hubCompanyUuid }
  } = match

  const [hub, isHubLoading] = useFetchState(
    `/api/admin/companies/hubs/${hubCompanyUuid}`
  )

  const [collectedMaterials, setCollectedMaterials] = useState<
    CollectedMaterialRequest[]
  >([])
  const [pendingFileUploadCount, setPendingFileUploadCCount] = useState(0)

  async function handleFilesUpload(files) {
    if (files.length === 0) {
      return
    }

    setPendingFileUploadCCount(files.length)

    for (const file of files) {
      const fileUpload = await uploadFileByAdminWithDispatch(
        dispatch,
        file,
        hubCompanyUuid
      )

      setPendingFileUploadCCount((prevPendingCount) => prevPendingCount - 1)

      if (fileUpload) {
        setCollectedMaterials(
          (prevCollectedMaterials: CollectedMaterialRequest[]) => [
            ...prevCollectedMaterials,
            {
              fileUpload,
              file,
              saved: false,
              buyer: '',
              transporterLicensePlate: ''
            }
          ]
        )
      }
    }
  }

  function setBuyer(event: any, index: number) {
    const collectedMaterial = collectedMaterials[index]
    collectedMaterial.buyer = event.target.value

    const currentCollectedMaterials = [...collectedMaterials]
    currentCollectedMaterials[index] = collectedMaterial
    setCollectedMaterials(currentCollectedMaterials)
  }

  function setTransporterLicensePlate(event: any, index: number) {
    const collectedMaterial = collectedMaterials[index]
    collectedMaterial.transporterLicensePlate = event.target.value

    const currentCollectedMaterials = [...collectedMaterials]
    currentCollectedMaterials[index] = collectedMaterial
    setCollectedMaterials(currentCollectedMaterials)
  }

  async function handleSave(index: number) {
    const collectedMaterial = collectedMaterials[index]

    const { fileUpload, buyer, transporterLicensePlate } = collectedMaterial

    await apiFetchWithDispatch(dispatch, '/api/admin/materialClearances', {
      method: 'POST',
      body: JSON.stringify({
        buyer,
        transporterLicensePlate,
        pictureFileUploadUuid: fileUpload ? fileUpload.uuid : null,
        hubCompanyUuid
      })
    })

    collectedMaterial.saved = true
    const currentCollectedMaterials = [...collectedMaterials]
    currentCollectedMaterials[index] = collectedMaterial
    setCollectedMaterials(currentCollectedMaterials)
  }

  if (isHubLoading || !hub) {
    return (
      <AdminPortalLayout title="Start Material Clearance">
        <PortalContent loading={true} />
      </AdminPortalLayout>
    )
  }

  return (
    <AdminPortalLayout title={`Start Material Clearance for ${hub.name}`}>
      <PortalContent>
        <Box className={classes.adminStartMaterialClearance}>
          <Paper elevation={5} outlined={false}>
            <Box p={3}>
              {pendingFileUploadCount > 0 ? (
                <Box
                  width="100%"
                  height={200}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText="Upload a material clearance picture"
                  maxFileSize={3000000}
                  filesLimit={1}
                  onChange={handleFilesUpload}
                  showPreviewsInDropzone={false}
                  showAlerts={false}
                />
              )}
            </Box>
          </Paper>

          <Box mt={2}>
            {collectedMaterials.map(
              (collectedMaterial: CollectedMaterialRequest, index) => (
                <Fragment key={collectedMaterial.fileUpload.uuid}>
                  {!collectedMaterial.saved && (
                    <Paper elevation={5} outlined={false}>
                      <Box p={3}>
                        <Box style={{ maxWidth: 300 }}>
                          <Image
                            width="100%"
                            source={collectedMaterial.fileUpload.directLink}
                          />
                        </Box>

                        <Box className={formClasses.field}>
                          <TextField
                            name="transporterLicensePlate"
                            autoFocus
                            className={formClasses.textField}
                            type="text"
                            label={t('licensePlate')}
                            margin="dense"
                            required
                            value={collectedMaterial.transporterLicensePlate}
                            onChange={(event) =>
                              setTransporterLicensePlate(event, index)
                            }
                          />
                        </Box>

                        <Box className={formClasses.field}>
                          <TextField
                            name="buyer"
                            className={formClasses.textField}
                            type="text"
                            label={'Buyer'}
                            margin="dense"
                            required
                            value={collectedMaterial.buyer}
                            onChange={(event) => setBuyer(event, index)}
                          />
                        </Box>

                        <Box pt={2}>
                          <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            disabled={
                              !collectedMaterial.buyer ||
                              !collectedMaterial.transporterLicensePlate
                            }
                            onClick={() => handleSave(index)}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Paper>
                  )}
                </Fragment>
              )
            )}
          </Box>
        </Box>
      </PortalContent>
    </AdminPortalLayout>
  )
}

export default AdminStartMaterialClearance
