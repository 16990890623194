import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import classNames from 'classnames'
import { generate } from 'generate-password'
import React, { useEffect, useState } from 'react'

import { Portlet, PortletContent } from '../../../../../components'
import {
  ClnModal,
  ClnModalActions,
  ClnModalContent
} from '../../../../../components/modal'
import { auth, AUTH0_REDIRECT_URL } from '../../../../../lib/auth0'
import {
  apiFetchWithDispatch,
  usePaginationConfig
} from '../../../../../lib/fetch'
import { StorageKey } from '../../../../../services/storage'
import { SecurityRole, UserAuthType } from '../../../../../services/user'
import { useStateValue } from '../../../../../state'

import { EditUserModal } from '../EditUserModal'

import styles from './styles'

function UsersTable(props) {
  const { classes, className, users, onEditUser } = props
  const rootClassName = classNames(classes.root, className)

  const { page, size, sizeOptions, setPage, setSize } = usePaginationConfig()

  const [paginatedUsers, setPaginatedUsers] = useState([])

  const [anchorEl, setAnchorEl] = useState(null)
  const [actionMenuOpen, setActionMenuOpen] = useState(false)

  const [selectedUser, setSelectedUser] = useState(null)

  const [showEditUserModal, setShowEditUserModal] = useState(false)

  const [
    openSuspendUserConfirmation,
    setOpenSuspendUserConfirmation
  ] = useState(false)

  const [, dispatch] = useStateValue()

  useEffect(() => {
    setPage(0)
  }, [users])

  useEffect(() => {
    setPaginatedUsers(users.slice(page * size, page * size + size))
  }, [users, page, size])

  function handleClickSuspendUser() {
    setOpenSuspendUserConfirmation(true)

    setActionMenuOpen(false)
  }

  function handleCloseSuspendUserConfirmation() {
    setOpenSuspendUserConfirmation(false)
  }

  function handleCloseEditUserModal() {
    setShowEditUserModal(false)

    onEditUser()
  }

  function handleClickEditUser() {
    setShowEditUserModal(true)

    setActionMenuOpen(false)
  }

  async function handleSuspendUser() {
    await apiFetchWithDispatch(
      dispatch,
      `/api/admin/users/${selectedUser.uuid}/suspend`,
      {
        method: 'POST'
      }
    )

    onEditUser()
    setOpenSuspendUserConfirmation(false)
    setSelectedUser(null)
  }

  function handleOpenActionMenu(event, selectedUser) {
    setAnchorEl(event.currentTarget)
    setActionMenuOpen(true)
    setSelectedUser(selectedUser)
  }

  function handleCloseActionMenu() {
    setAnchorEl(null)
    setActionMenuOpen(false)
  }

  if (!users || users.length === 0) {
    return (
      <Typography variant="h6">
        There are currently no users matching this name.
      </Typography>
    )
  }

  function userCanBeEdited() {
    return (
      selectedUser &&
      (selectedUser.role === SecurityRole.ROLE_HUB_MANAGER ||
        selectedUser.role === SecurityRole.ROLE_HUB_OWNER)
    )
  }

  function isSystemUser() {
    return selectedUser && selectedUser.role === SecurityRole.ROLE_SYSTEM_USER
  }

  async function handleImpersonateMenuItemClick(user, role) {
    handleCloseActionMenu()

    localStorage.setItem(StorageKey.IMPERSONATION_ROLE, role)

    const newPassword = generate({
      length: 30,
      lowercase: true,
      numbers: true,
      strict: true,
      symbols: true,
      uppercase: true
    })

    const response = await apiFetchWithDispatch(
      dispatch,
      `/api/admin/users/${user.uuid}/changePassword`,
      {
        method: 'POST',
        body: JSON.stringify({
          password: newPassword
        })
      }
    )

    if (response) {
      await auth.login(
        {
          password: newPassword,
          username: selectedUser.email,
          realm: 'Username-Password-Authentication',
          onRedirecting: () => {
            window.location.href = AUTH0_REDIRECT_URL
          }
        },
        (error, authResult) => {
          if (error) {
            throw error
          }
          return authResult
        }
      )
    }
  }

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Auth Method</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Phone Number</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Company</TableCell>
              <TableCell align="left">Role</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((user) => (
              <TableRow className={classes.tableRow} hover key={user.uuid}>
                <TableCell className={classes.tableCellSmall}>
                  {user.authType === UserAuthType.SMS
                    ? 'Phone'
                    : user.authType === UserAuthType.EMAIL_PASSWORD
                    ? 'Email'
                    : '-'}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {user.email}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {user.phoneNumber}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {user.fullName}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {user.company?.name ?? ''}
                </TableCell>
                <TableCell className={classes.tableCell}>{user.role}</TableCell>
                <TableCell className={classes.tableCell} align="right">
                  <IconButton
                    size="medium"
                    aria-label="More"
                    aria-controls="actionMenu"
                    aria-haspopup="true"
                    onClick={(event) => handleOpenActionMenu(event, user)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="actionMenu"
                    anchorEl={anchorEl}
                    className={classes.actionMenu}
                    keepMounted
                    open={actionMenuOpen}
                    onClose={handleCloseActionMenu}
                    PaperProps={{
                      className: classes.actionMenuPaper
                    }}
                  >
                    {isSystemUser() && (
                      <MenuItem
                        key="impersonateAsManager"
                        className={classes.signInAction}
                        onClick={() =>
                          handleImpersonateMenuItemClick(
                            selectedUser,
                            SecurityRole.ROLE_HUB_MANAGER
                          )
                        }
                      >
                        Impersonate as Manager
                      </MenuItem>
                    )}
                    {isSystemUser() && (
                      <MenuItem
                        key="impersonateAsOwner"
                        className={classes.signInAction}
                        onClick={() =>
                          handleImpersonateMenuItemClick(
                            selectedUser,
                            SecurityRole.ROLE_HUB_OWNER
                          )
                        }
                      >
                        Impersonate as Owner
                      </MenuItem>
                    )}
                    {userCanBeEdited() && (
                      <MenuItem
                        key="reset"
                        color="default"
                        onClick={handleClickEditUser}
                      >
                        Edit
                      </MenuItem>
                    )}
                    {userCanBeEdited() && (
                      <MenuItem
                        key="suspend"
                        className={classes.suspendAction}
                        onClick={handleClickSuspendUser}
                      >
                        Suspend
                      </MenuItem>
                    )}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          component="div"
          count={users.length}
          onChangePage={(_, page) => setPage(page)}
          onChangeRowsPerPage={(event) => {
            setSize(parseInt(event.target.value, 10))
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          page={page}
          rowsPerPage={size}
          rowsPerPageOptions={sizeOptions}
        />
      </PortletContent>

      {openSuspendUserConfirmation && (
        <ClnModal
          content={
            <ClnModalContent>
              <Box>
                <Typography>
                  Are you sure that you want to suspend the selected user{' '}
                  <Box fontWeight="fontWeightMedium" display="inline">
                    {selectedUser.email}
                  </Box>
                  ?
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography>The suspend operation cannot be undone.</Typography>
              </Box>
            </ClnModalContent>
          }
          actions={
            <ClnModalActions>
              <Box display="flex" justifyContent="space-between">
                <Button
                  color="black"
                  variant="outlined"
                  onClick={handleCloseSuspendUserConfirmation}
                >
                  Cancel
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSuspendUser}
                >
                  Confirm
                </Button>
              </Box>
            </ClnModalActions>
          }
          show={openSuspendUserConfirmation}
          title="Suspend User"
          onClose={handleCloseSuspendUserConfirmation}
          maxWidth="400px"
        />
      )}

      {showEditUserModal && (
        <EditUserModal
          show={showEditUserModal}
          user={selectedUser}
          onClose={handleCloseEditUserModal}
        />
      )}
    </Portlet>
  )
}

export default withStyles(styles)(UsersTable)
