// Material components
import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  RadioGroup,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { FullScreenCamera, ImagePreview } from 'components'

import { ReactComponent as BagIcon } from 'icons/bag-black.svg'

import { useCamera } from 'lib/camera'

import { apiFetchWithDispatch } from 'lib/fetch'
import { useForm } from 'lib/form'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import { compose } from 'recompose'

import { getCollectedMaterialTypeOptions } from 'services/materialtype'

import { QuantityUnits } from 'services/quantityunit'
import { RegistrationUnit } from 'services/registrationunit'
import { getCurrentUser } from 'services/user'

import { useStateValue } from 'state'
import LinkButton from '../../../components/LinkButton'

// Shared layouts
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'

// Component styles
import styles from './styles'

const schema = {
  weight: {
    presence: { allowEmpty: false, message: 'validationRequired' },
    length: { maximum: 10, message: 'validationExceedsMaxLength' }
  }
}

const schemaWithMaterialType = {
  weight: {
    presence: { allowEmpty: false, message: 'validationRequired' },
    length: { maximum: 10, message: 'validationExceedsMaxLength' }
  },
  materialType: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  }
}

function RegisterCollectedMaterialBag(props) {
  const {
    classes,
    history,
    match: {
      params: { supplierUuid }
    },
    location
  } = props
  const queryParams = queryString.parse(location.search)

  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  const theme = useTheme()

  const [, dispatch] = useStateValue()

  const collectedMaterialTypes = getCurrentUser().collectedMaterialTypes || []

  const {
    pictureDataUri,
    pictureFileUpload,
    showCamera,
    pictureConfirmed,
    handleTakePhotoFinished,
    handleFileUploadFinished,
    handleConfirmPicture,
    handleCameraError
  } = useCamera()

  const {
    formState: { values, errors, isValid },
    handleChange,
    resetForm,
    hasError
  } = useForm(
    collectedMaterialTypes.length !== 1 ? schemaWithMaterialType : schema,
    {
      weight: '',
      quantityUnit: QuantityUnits.KG,
      materialType: '',
      supplierName: ''
    }
  )

  useEffect(() => {
    if (!showCamera) {
      history.push('/hub-overview')
    }
  }, [showCamera])

  async function handleSave() {
    setIsLoading(true)

    const { weight, materialType, supplierName } = values

    const response = await apiFetchWithDispatch(
      dispatch,
      '/api/collectedMaterials',
      {
        method: 'POST',
        body: {
          registrationUnit: RegistrationUnit.BAG,
          weight,
          materialType:
            collectedMaterialTypes.length === 1
              ? collectedMaterialTypes[0]
              : materialType,
          pictureFileUploadUuid: pictureFileUpload
            ? pictureFileUpload.uuid
            : null,
          supplierName,
          supplierUuid,
          collectorUuid: queryParams.collectorUuid,
          collectionUnitUuid: queryParams.collectionUnitUuid,
          trackedItemUuid: queryParams.trackedItemUuid
        }
      }
    )

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push(
        queryParams.collectorUuid
          ? `/active-collector/${queryParams.collectorUuid}`
          : '/hub-overview'
      )
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('registerMaterialBag')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!pictureConfirmed) {
    return (
      <FullScreenCamera
        fileName={`collected-material-bag-${Date.now()}`}
        headerMessage={t('registerMaterialBagPictureExplanation')}
        shutterIcon={<BagIcon />}
        onTakePhotoFinished={handleTakePhotoFinished}
        onFileUploadFinished={handleFileUploadFinished}
        onConfirmPicture={handleConfirmPicture}
        onCameraError={handleCameraError}
      />
    )
  }

  return (
    <SecurePortalLayout title={t('registerMaterialBag')}>
      <PortalContent>
        <div className={classes.root}>
          <div className={classes.content}>
            <form autoComplete="off" noValidate>
              <Paper className={classes.formSection}>
                <Typography className={classes.title} variant="h3">
                  {t('registerMaterialBag')}
                </Typography>

                <FormControl component="fieldset">
                  <FormLabel component="legend" required>
                    {t('materialBagPicture')}
                  </FormLabel>
                  <div className={classes.imagePreview}>
                    <ImagePreview
                      dataUri={pictureDataUri}
                      isPictureUploading={!pictureFileUpload}
                    />
                  </div>
                </FormControl>

                <div className={classes.field}>
                  <div className={classes.field}>
                    <TextField
                      name="weight"
                      autoFocus
                      className={classes.weightField}
                      type="number"
                      label={`${t('addWeight')}`}
                      margin="dense"
                      required
                      value={values.weight}
                      onChange={handleChange}
                      error={hasError('weight')}
                      helperText={hasError('weight') ? t(errors.weight[0]) : ''}
                    />
                    <TextField
                      name="quantityUnit"
                      className={classes.quantityUnitField}
                      disabled
                      label=" "
                      margin="dense"
                      value={values.quantityUnit}
                      InputProps={{
                        style: {
                          color: theme.palette.text.primary
                        }
                      }}
                    />
                  </div>
                </div>

                {collectedMaterialTypes.length !== 1 && (
                  <div className={classes.radioGroupField}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        required
                        className={classes.radioGroupLabel}
                      >
                        {t('selectMaterial')}
                      </FormLabel>
                      <RadioGroup
                        name="materialType"
                        value={values.materialType}
                        onChange={handleChange}
                      >
                        {getCollectedMaterialTypeOptions(t)}
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}

                {!supplierUuid &&
                  typeof queryParams.collectorUuid === 'undefined' && (
                    <div className={classes.field}>
                      <TextField
                        name="supplierName"
                        label={t('supplier')}
                        className={classes.textField}
                        value={values.supplierName}
                        onChange={handleChange}
                        margin="dense"
                        error={hasError('supplierName')}
                        helperText={
                          hasError('supplierName')
                            ? t(errors.supplierName[0])
                            : ''
                        }
                      />
                    </div>
                  )}

                <div className={classes.actionButtons}>
                  <LinkButton
                    size="large"
                    className={classes.cancelButton}
                    color="default"
                    variant="outlined"
                    href="/hub-overview"
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size="large"
                    variant="contained"
                    className={classes.saveRegistrationButton}
                    disabled={!isValid || isLoading || !pictureFileUpload}
                    onClick={handleSave}
                  >
                    {t('registerButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </div>
        </div>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default compose(
  withRouter,
  withStyles(styles)
)(RegisterCollectedMaterialBag)
