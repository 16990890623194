// Palette
import palette from '../palette'

export default {
  root: {
    margin: 8,
    fontSize: 18,
    color: palette.primary.main,
    border: `1px solid ${palette.primary.main}`,

    '&$selected': {
      backgroundColor: palette.primary.main,
      color: palette.common.white,

      '&:hover': {
        backgroundColor: palette.primary.main
      },

      '& + &': {
        marginLeft: 8,
        borderLeft: `1px solid ${palette.primary.main}`
      }
    },

    '&($selected + $selected)': {
      marginLeft: 8
    },

    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}
