import { QuantityUnit, QuantityUnits } from '../services/quantityunit'

import { formatNumber } from './number.helpers'

const KG_IN_ONE_LITER_OF_OIL = 0.7

export function formatQuantity(
  quantity: number,
  quantityUnit: QuantityUnit,
  precision = 3
): string {
  return `${formatNumber(quantity, precision)} ${QuantityUnits[quantityUnit]}`
}

export function convertOilLitersToKilograms(quantity: number): number {
  return KG_IN_ONE_LITER_OF_OIL * quantity
}

export function convertKilogramsToOilLiters(quantity: number): number {
  return quantity / KG_IN_ONE_LITER_OF_OIL
}
