import { Backdrop, Box, fade, IconButton, makeStyles } from '@material-ui/core'
import {
  ArrowBackIosOutlined as ArrowBackIosOutlinedIcon,
  ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
  CloseOutlined as CloseOutlinedIcon,
  RotateLeftOutlined as RotateLeftOutlinedIcon,
  RotateRightOutlined as RotateRightOutlinedIcon,
  SaveAltOutlined as SaveAltOutlinedIcon,
  ZoomInOutlined as ZoomInOutlinedIcon,
  ZoomOutOutlined as ZoomOutOutlinedIcon
} from '@material-ui/icons'
import React, { FunctionComponentElement, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Document, Page } from 'react-pdf'

import { Theme } from 'common/theme'

import { MinimalFileUploadDto } from '../services/fileupload/file-upload.types'

export interface PdfViewerProps {
  file: MinimalFileUploadDto

  onClose: () => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  pdfViewer: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    overflow: 'auto'
  },
  actions: {
    backgroundColor: fade(theme.palette.common.black, 0.8),
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    position: 'fixed',
    bottom: theme.spacing(3),
    left: '50%',
    borderRadius: theme.spacing(1),
    zIndex: 2,
    transform: 'translateX(-50%)'
  },
  actionButton: {
    color: theme.palette.common.white,

    '&.Mui-disabled': {
      color: fade(theme.palette.common.white, 0.5)
    }
  },
  backdrop: {
    zIndex: 9999
  },
  divider: {
    width: '1px',
    height: 20,
    backgroundColor: fade(theme.palette.common.white, 0.3),
    margin: theme.spacing(0, 1)
  },
  document: {
    width: '100vw',
    height: '100vh',
    position: 'relative',
    display: 'table-cell',
    verticalAlign: 'middle',
    overflow: 'auto',

    '& canvas': {
      margin: '0 auto'
    }
  }
}))

export function PdfViewer({
  file,
  onClose
}: PdfViewerProps): FunctionComponentElement<PdfViewerProps> {
  const classes = useStyles()

  const [numPages, setNumPages] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [rotate, setRotate] = useState<number>(0)
  const [scale, setScale] = useState<number>(1)

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'initial'
    }
  })

  return createPortal(
    <Backdrop className={classes.backdrop} open={true}>
      <Box className={classes.pdfViewer}>
        <Document
          className={classes.document}
          file={file.directLink}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          <Page
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            rotate={rotate}
            scale={scale}
          />
        </Document>

        <Box className={classes.actions}>
          <IconButton
            className={classes.actionButton}
            disabled={pageNumber === 1}
            onClick={() => setPageNumber((value) => value - 1)}
          >
            <ArrowBackIosOutlinedIcon />
          </IconButton>

          <IconButton
            className={classes.actionButton}
            disabled={pageNumber === numPages}
            onClick={() => setPageNumber((value) => value + 1)}
          >
            <ArrowForwardIosOutlinedIcon />
          </IconButton>

          <Box className={classes.divider} />

          <IconButton
            className={classes.actionButton}
            onClick={() => setScale((value) => value + 0.2)}
          >
            <ZoomInOutlinedIcon />
          </IconButton>

          <IconButton
            className={classes.actionButton}
            onClick={() => setScale((value) => value - 0.2)}
          >
            <ZoomOutOutlinedIcon fontSize="large" />
          </IconButton>

          <Box className={classes.divider} />

          <IconButton
            className={classes.actionButton}
            onClick={() =>
              setRotate((value) => (value <= -270 ? 0 : value - 90))
            }
          >
            <RotateLeftOutlinedIcon />
          </IconButton>

          <IconButton
            className={classes.actionButton}
            onClick={() =>
              setRotate((value) => (value >= 270 ? 0 : value + 90))
            }
          >
            <RotateRightOutlinedIcon />
          </IconButton>

          <Box className={classes.divider} />

          <IconButton
            className={classes.actionButton}
            href={file.directLink}
            target="_blank"
          >
            <SaveAltOutlinedIcon />
          </IconButton>

          <Box className={classes.divider} />

          <IconButton className={classes.actionButton} onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
    </Backdrop>,
    document.body
  )
}
