import { apiFetchWithDispatch, UseFetchState, useFetchState } from 'lib/fetch'

import { CompanyDto, MinimalCompanyDto } from '.'

export class CompaniesApiService {
  public static readonly BASE_URL = '/api/companies'

  public static getDetails(
    companyUuid: string
  ): UseFetchState<MinimalCompanyDto> {
    return useFetchState(`${CompaniesApiService.BASE_URL}/${companyUuid}`)
  }

  public static searchPickupPoints(
    dispatch: () => any,
    searchQuery: string
  ): Promise<CompanyDto[] | null> {
    if (!searchQuery) {
      return Promise.resolve([])
    }

    return apiFetchWithDispatch(
      dispatch,
      `${CompaniesApiService.BASE_URL}/pickupPoints/search`,
      {
        method: 'POST',
        body: JSON.stringify({
          name: searchQuery
        })
      }
    )
  }
}
