export default theme => ({
  root: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.danger.main,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '4rem',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: '4rem'
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '2rem',
    height: '2rem',
    width: '2rem'
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  registrationUnit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  },
  registrationUnitTitle: {
    color: theme.palette.text.main,
    fontWeight: 400
  },
  registrationUnitSegmentedControl: {
    width: '100%',
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  allowUnregisteredSuppliersSegmentedControl: {
    width: '100%',
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  buttonDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing()
  }
})
