import React from 'react'

import { Box, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 0,
    bottom: '25%',
    height: 30,
    width: '100%',
    zIndex: 1
  },
  content: {
    display: 'flex',
    height: 20,
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  message: {
    fontSize: 14,
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(2),
    paddingTop: 5,
    paddingBottom: 5
  },
  waitForLocationMessage: {
    fontSize: 18,
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(2),
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: 'center'
  }
}))

export default function CameraFooter (props) {
  const { latitude, longitude } = props

  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Box className={classes.root}>
      <div className={classes.content}>
        <div className={classes.message}>
          {(!latitude || !longitude) && (
            <Typography variant='body1' className={classes.waitForLocationMessage}>
              {t('waitForLocationMessage')}
            </Typography>
          )}
        </div>
      </div>
    </Box>
  )
}
