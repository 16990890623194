import { Box, IconButton, Link } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { FunctionComponentElement } from 'react'
import NumberFormat from 'react-number-format'

import { Theme } from '../common/theme'
import { FileUploadDto } from '../services/fileupload/file-upload.types'

interface UploadedFileProps {
  allowClear?: boolean
  fileUploads: FileUploadDto[]

  onClearFile?: (fileUpload: FileUploadDto) => any
}

// Component styles
const useStyles = makeStyles<Theme>((theme) => ({
  uploadedFile: {
    display: 'inline-block'
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(0.5)
  },
  fileName: {
    color: theme.palette.primary.dark,
    fontWeight: 500
  },
  fileSize: {
    fontSize: 13
  },
  clearFileButton: {
    marginLeft: theme.spacing(2),
    border: 'none',
    padding: 0
  }
}))

export function UploadedFile({
  allowClear = true,
  fileUploads,
  onClearFile
}: UploadedFileProps): FunctionComponentElement<UploadedFileProps> {
  const classes = useStyles()

  function getDisplayedFileName(fileName) {
    if (fileName.length <= 22) {
      return fileName
    }

    return fileName.substring(0, 22) + '...'
  }

  return (
    <Box className={classes.uploadedFile}>
      {fileUploads.map((fileUpload, i) => (
        <Box key={i} className={classes.file}>
          <Box>
            <Link target="_blank" href={fileUpload.directLink}>
              <span className={classes.fileName}>
                {getDisplayedFileName(fileUpload.fileName)}
              </span>
            </Link>

            <NumberFormat
              value={fileUpload.size / 1024}
              displayType="text"
              decimalScale={1}
              prefix=" ("
              thousandSeparator
              suffix="K) "
              className={classes.fileSize}
            />
          </Box>

          {allowClear && onClearFile && (
            <IconButton
              className={classes.clearFileButton}
              key="close"
              color="default"
              size="small"
              onClick={() => onClearFile(fileUpload)}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      ))}
    </Box>
  )
}
