import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, {
  FunctionComponentElement,
  ReactElement,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useGeneralStyles } from '../../../../common/styles/general'

import { Theme } from '../../../../common/theme'
import { ClnModal, ClnModalContent } from '../../../../components/modal'
import { ClnModalContentProps } from '../../../../components/modal/ClnModalContent'
import { apiFetchWithDispatch } from '../../../../lib/fetch'
import { useForm } from '../../../../lib/form'
import { getGenderOptions } from '../../../../services/gender'
import { HubCompanyDto } from '../../../../services/hub'
import { useStateValue } from '../../../../state'
import {
  getUserAuthTypesOptions,
  SecurityRole,
  UserAuthType
} from '../../../../services/user'

interface AddHubOwnerModalProps {
  hub: HubCompanyDto
  show: boolean
  onClose: () => any
}

const useStyles = makeStyles<Theme>(() => ({
  addHubOwnerModal: {
    //
  }
}))

function getUserFormSchema(authType) {
  const schema = {
    gender: {
      presence: { allowEmpty: false, message: 'validationRequired' }
    },
    firstName: {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 128, message: 'validationExceedsMaxLength' }
    },
    lastName: {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 128, message: 'validationExceedsMaxLength' }
    },
    authType: {
      presence: { allowEmpty: false, message: 'validationRequired' }
    },
    phoneNumber: {},
    email: {}
  }

  if (authType === UserAuthType.SMS) {
    schema.phoneNumber = {
      presence: { allowEmpty: false, message: 'validationRequired' },
      format: {
        pattern: /^\+\d{1,15}$/,
        message: 'validationPhoneNumberPattern'
      },
      length: { minimum: 6, message: 'validationPhoneNumberMinLength' }
    }
  }

  if (authType === UserAuthType.EMAIL_PASSWORD) {
    schema.email = {
      presence: { allowEmpty: false, message: 'validationRequired' },
      length: { maximum: 255, message: 'validationExceedsMaxLength' }
    }
  }

  return schema
}

export function AddHubOwnerModal({
  hub,
  show,
  onClose
}: AddHubOwnerModalProps): FunctionComponentElement<AddHubOwnerModalProps> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()

  const [, dispatch] = useStateValue()
  const { t } = useTranslation()

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [userFormSchema, setUserFormSchema] = useState({})

  const {
    formState: { values, errors, isValid },
    handleChange,
    hasError
  } = useForm(userFormSchema, {
    firstName: '',
    lastName: '',
    gender: '',
    email: null,
    phoneNumber: null,
    authType: '',
    role: SecurityRole.ROLE_HUB_OWNER
  })

  useEffect(() => {
    setUserFormSchema(getUserFormSchema(values.authType))
  }, [values])

  const handleSave = async (): Promise<void> => {
    setIsSaving(true)

    await apiFetchWithDispatch<HubCompanyDto>(
      dispatch,
      `/api/admin/companies/hubs/${hub.uuid}/addHubOwner`,
      {
        method: 'POST',
        body: JSON.stringify(values)
      }
    )

    onClose()
  }

  const getContent = (): ReactElement<
    ClnModalContentProps,
    typeof ClnModalContent
  > => {
    if (isSaving) {
      return (
        <ClnModalContent>
          <Box className={generalClasses.progressWrapper}>
            <CircularProgress />
          </Box>
        </ClnModalContent>
      )
    }

    return (
      <ClnModalContent>
        <form autoComplete="off" noValidate>
          <Box mb={3}>
            <Grid spacing={2} container>
              <Grid lg={4} md={4} sm={12} xs={12} item>
                <TextField
                  name="firstName"
                  className={classes.nameField}
                  type="text"
                  label="First Name"
                  fullWidth={true}
                  required={true}
                  value={values.firstName}
                  onChange={handleChange}
                  error={hasError('firstName')}
                  helperText={
                    hasError('firstName') ? t(errors.firstName?.[0] ?? '') : ''
                  }
                />
              </Grid>

              <Grid lg={4} md={4} sm={12} xs={12} item>
                <TextField
                  name="lastName"
                  className={classes.nameField}
                  type="text"
                  label="Last Name"
                  fullWidth={true}
                  required={true}
                  value={values.lastName}
                  onChange={handleChange}
                  error={hasError('lastName')}
                  helperText={
                    hasError('lastName') ? t(errors.lastName?.[0] ?? '') : ''
                  }
                />
              </Grid>

              <Grid lg={4} md={4} sm={12} xs={12} item>
                <TextField
                  name="gender"
                  className={classes.selectField}
                  label="Gender"
                  fullWidth={true}
                  required={true}
                  select
                  SelectProps={{ native: true }}
                  value={values.gender}
                  onChange={handleChange}
                  error={hasError('gender')}
                  helperText={
                    hasError('gender') ? t(errors.gender?.[0] ?? '') : ''
                  }
                >
                  {getGenderOptions()}
                </TextField>
              </Grid>
            </Grid>
          </Box>

          <Box mb={3}>
            <Grid spacing={2} container>
              <Grid lg={4} md={4} sm={12} xs={12} item>
                <TextField
                  name="authType"
                  className={classes.selectField}
                  label="Authentication Method"
                  required
                  fullWidth={true}
                  select
                  SelectProps={{ native: true }}
                  value={values.authType}
                  onChange={handleChange}
                  error={hasError('authType')}
                  helperText={
                    hasError('authType') ? t(errors.authType?.[0] ?? '') : ''
                  }
                >
                  {getUserAuthTypesOptions()}
                </TextField>
              </Grid>
              {values.authType === UserAuthType.SMS && (
                <Grid lg={4} md={4} sm={12} xs={12} item>
                  <TextField
                    name="phoneNumber"
                    type="text"
                    className={classes.nameField}
                    label="Phone Number"
                    fullWidth={true}
                    required
                    autoComplete="off"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    error={hasError('phoneNumber')}
                    helperText={
                      hasError('phoneNumber')
                        ? t(errors.phoneNumber?.[0] ?? '')
                        : ''
                    }
                  />
                </Grid>
              )}
              {!!values.authType && (
                <Grid lg={4} md={4} sm={12} xs={12} item>
                  <TextField
                    name="email"
                    className={classes.nameField}
                    type="text"
                    label={
                      values.authType === UserAuthType.SMS
                        ? 'Email for reporting'
                        : 'Email'
                    }
                    fullWidth={true}
                    required
                    value={values.email}
                    onChange={handleChange}
                    error={hasError('email')}
                    helperText={
                      hasError('email') ? t(errors.email?.[0] ?? '') : ''
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={8}>
            <Button
              size="large"
              color="default"
              variant="outlined"
              onClick={onClose}
            >
              {t('cancelButton')}
            </Button>

            <Box ml={1}>
              <Button
                size="large"
                color="primary"
                variant="contained"
                disabled={!isValid}
                onClick={handleSave}
              >
                {t('saveButton')}
              </Button>
            </Box>
          </Box>
        </form>
      </ClnModalContent>
    )
  }

  return (
    <Box className={classes.addHubOwnerModal}>
      <ClnModal
        content={getContent()}
        onClose={onClose}
        show={show}
        title="Add Hub Owner"
      />
    </Box>
  )
}
