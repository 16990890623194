export default theme => ({
  root: {
    display: 'inline-flex',

    "& .MuiOutlinedInput-input": {
      padding: '11px 15px'
    },
    "& .MuiInputLabel-formControl":{
      top: "-8px",
      left: "19px",
      color: theme.palette.common.neutral
    }
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  spacer: {
    flexGrow: 1
  },
  deleteButton: {
    color: theme.palette.danger.main,
    marginRight: theme.spacing()
  },
  searchInput: {
    marginRight: theme.spacing()
  },
  selectField: {
    width: 185,
    maxWidth: '100%',
    marginLeft: theme.spacing(),
    paddingLeft: theme.spacing(1)
  },
  formControl:{
    marginTop: theme.spacing()
  }
})
