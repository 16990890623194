import { Box, makeStyles } from '@material-ui/core'
import { green, red, yellow } from '@material-ui/core/colors'
import {
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  FlagOutlined as FlagOutlinedIcon,
  WarningOutlined as WarningOutlinedIcon
} from '@material-ui/icons'
import cx from 'classnames'
import React, { FunctionComponentElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from '../common/theme'
import { HubEventReviewDto, HubEventReviewStatus } from '../services/hub-events'

export interface HubEventReviewBadgeProps {
  review: HubEventReviewDto
}

const useStyles = makeStyles<Theme>((theme) => ({
  hubEventReviewBadge: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1),
    fontSize: 12,
    borderRadius: 24,
    whiteSpace: 'nowrap',

    '&.green': {
      backgroundColor: green[50],
      color: green[800]
    },

    '&.red': {
      backgroundColor: red[50],
      color: red[800]
    },

    '&.yellow': {
      backgroundColor: yellow[50],
      color: yellow[800]
    }
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(0.5)
  }
}))

export function HubEventReviewBadge({
  review
}: HubEventReviewBadgeProps): FunctionComponentElement<HubEventReviewBadgeProps> {
  const classes = useStyles()

  const { t } = useTranslation()

  function getIcon(): ReactNode {
    switch (review.status) {
      case HubEventReviewStatus.APPROVED:
      case HubEventReviewStatus.AUTO_APPROVED:
        return <CheckCircleOutlinedIcon className={classes.icon} />

      case HubEventReviewStatus.FLAGGED:
        return <FlagOutlinedIcon className={classes.icon} />

      case HubEventReviewStatus.AUTO_FLAGGED:
        return <WarningOutlinedIcon className={classes.icon} />
    }

    return <></>
  }

  return (
    <Box
      className={cx(classes.hubEventReviewBadge, {
        green: [
          HubEventReviewStatus.APPROVED,
          HubEventReviewStatus.AUTO_APPROVED
        ].includes(review.status),
        red: review.status === HubEventReviewStatus.FLAGGED,
        yellow: review.status === HubEventReviewStatus.AUTO_FLAGGED
      })}
    >
      {getIcon()}
      {t(`HubEventReviewStatus.${review.status}`)}
    </Box>
  )
}
