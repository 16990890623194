import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog
} from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import React, { FunctionComponentElement, useState } from 'react'
import { match } from 'react-router-dom'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'

import { apiFetchWithDispatch, useFetchState } from '../../lib/fetch'
import { AdminPortalLayout } from '../../layouts/AdminPortalLayout'
import { PortalContent } from '../../layouts/portal/PortalContent'
import { Paper, Portlet, PortletContent } from '../../components'
import {
  ComplianceActionDto,
  ComplianceActionStatus
} from '../../services/compliance'

import { MarkAuditActionAsReviewedModal } from './HubList/components/MarkAuditActionAsReviewedModal'
import { ViewUploadedEvidenceModal } from './HubList/components/ViewUploadedEvidenceModal'
import { AddAuditActionModal } from './HubList/components/AddAuditActionModal'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../common/theme'
import { useStateValue } from '../../state'

interface AdminManageAuditActionsProps {
  match: match<{ hubCompanyUuid: string }>
}

const useStyles = makeStyles<Theme>(() => ({
  longTextTableCell: {
    minWidth: 200,
    maxWidth: 350,
    whiteSpace: 'pre-wrap'
  }
}))

function AdminManageAuditActions({
  match
}: AdminManageAuditActionsProps): FunctionComponentElement<AdminManageAuditActionsProps> {
  const {
    params: { hubCompanyUuid }
  } = match

  const [
    complianceActions,
    isComplianceActionsLoading,
    refreshComplianceActions
  ] = useFetchState<ComplianceActionDto[]>(
    `/api/admin/complianceActions?companyUuid=${hubCompanyUuid}`
  )

  const { t } = useTranslation()
  const classes = useStyles()

  const [, dispatch] = useStateValue()

  const [anchorEl, setAnchorEl] = useState(null)
  const [actionMenuOpen, setActionMenuOpen] = useState(false)
  const [
    selectedComplianceAction,
    setSelectedComplianceAction
  ] = useState<ComplianceActionDto | null>(null)

  const [showMarkAsReviewedModal, setMarkAsReviewedModal] = useState(false)
  const [showReopenModal, setShowReopenModal] = useState(false)
  const [
    showViewUploadedEvidenceModal,
    setShowViewUploadedEvidenceModal
  ] = useState(false)

  const [showAddAuditActionModal, setShowAddAuditActionModal] = useState(false)

  function handleOpenActionMenu(event, selectedComplianceAction) {
    setAnchorEl(event.currentTarget)
    setActionMenuOpen(true)
    setSelectedComplianceAction(selectedComplianceAction)
  }

  function handleCloseActionMenu() {
    setAnchorEl(null)
    setActionMenuOpen(false)
  }

  function handleClickMarkAsReviewed() {
    setMarkAsReviewedModal(true)

    setActionMenuOpen(false)
  }

  function handleClickReopen() {
    setShowReopenModal(true)

    setActionMenuOpen(false)
  }

  function handleClickViewUploadedEvidence() {
    setShowViewUploadedEvidenceModal(true)

    setActionMenuOpen(false)
  }

  function handleCloseMarkAsReviewedModal() {
    setMarkAsReviewedModal(false)

    refreshComplianceActions()
  }

  function handleCloseReopenModal() {
    setShowReopenModal(false)

    refreshComplianceActions()
  }

  function handleClickAddAuditAction() {
    setShowAddAuditActionModal(true)
  }

  function handleCloseAddAuditActionModal() {
    setShowAddAuditActionModal(false)

    refreshComplianceActions()
  }

  function actionCanBeReopened() {
    return (
      selectedComplianceAction &&
      (selectedComplianceAction.status === ComplianceActionStatus.CREATED ||
        selectedComplianceAction.status ===
          ComplianceActionStatus.OWNER_NOTIFIED)
    )
  }

  async function handleReopen() {
    await apiFetchWithDispatch(
      dispatch,
      `/api/admin/complianceActions/${selectedComplianceAction?.uuid}/reopen`,
      {
        method: 'POST'
      }
    )

    handleCloseReopenModal()
  }

  return (
    <AdminPortalLayout title="Manage Audit Actions">
      <PortalContent
        titleActions={
          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={() => handleClickAddAuditAction()}
          >
            Add Audit Action
          </Button>
        }
      >
        <Box>
          <Paper elevation={5} outlined={false}>
            <Box p={3}>
              {isComplianceActionsLoading || !complianceActions ? (
                <Box display="flex" justifyContent="center" p={5}>
                  <CircularProgress />
                </Box>
              ) : (
                <Portlet>
                  <PortletContent noPadding={true}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>CoC principle</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Deadline until</TableCell>
                          <TableCell>Created at</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Reviewed at</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {complianceActions.map((action) => (
                          <TableRow key={action.uuid}>
                            <TableCell>
                              {t(
                                `CodeOfConductPrincipleCategory.${action.cocPrinciple.category}`
                              )}{' '}
                              ({action.cocPrinciple.paragraph})
                            </TableCell>
                            <TableCell className={classes.longTextTableCell}>
                              {action.name}
                            </TableCell>
                            <TableCell className={classes.longTextTableCell}>
                              {action.description}
                            </TableCell>
                            <TableCell>
                              {moment(action.deadlineDate).format('YYYY-MM-DD')}
                            </TableCell>
                            <TableCell>
                              {moment(action.createdAt).format('YYYY-MM-DD')}
                            </TableCell>
                            <TableCell>{action.status}</TableCell>
                            <TableCell>
                              {action.status === ComplianceActionStatus.REVIEWED
                                ? moment(action.lastModifiedAt).format(
                                    'YYYY-MM-DD'
                                  )
                                : '-'}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                size="medium"
                                aria-label="More"
                                aria-controls="actionMenu"
                                aria-haspopup="true"
                                onClick={(event) =>
                                  handleOpenActionMenu(event, action)
                                }
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="actionMenu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={
                                  actionMenuOpen &&
                                  selectedComplianceAction === action
                                }
                                onClose={handleCloseActionMenu}
                              >
                                <MenuItem
                                  color="default"
                                  onClick={handleClickViewUploadedEvidence}
                                >
                                  View uploaded evidence
                                </MenuItem>

                                <MenuItem
                                  color="default"
                                  disabled={
                                    !!(
                                      selectedComplianceAction &&
                                      selectedComplianceAction.status ===
                                        ComplianceActionStatus.REVIEWED
                                    )
                                  }
                                  onClick={handleClickMarkAsReviewed}
                                >
                                  Mark as reviewed
                                </MenuItem>

                                <MenuItem
                                  color="default"
                                  disabled={!!actionCanBeReopened()}
                                  onClick={handleClickReopen}
                                >
                                  Reopen for more evidence
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </PortletContent>
                </Portlet>
              )}
            </Box>
          </Paper>
        </Box>
      </PortalContent>

      <Dialog
        open={showReopenModal}
        keepMounted
        onClose={handleCloseReopenModal}
      >
        <DialogTitle>Reopen Audit Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to reopen the selected audit action ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseReopenModal}
            color="default"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button onClick={handleReopen} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {showMarkAsReviewedModal && (
        <MarkAuditActionAsReviewedModal
          hubUuid={hubCompanyUuid}
          complianceAction={selectedComplianceAction}
          show={showMarkAsReviewedModal}
          onClose={handleCloseMarkAsReviewedModal}
        />
      )}

      {showViewUploadedEvidenceModal && (
        <ViewUploadedEvidenceModal
          complianceAction={selectedComplianceAction}
          show={showViewUploadedEvidenceModal}
          onClose={() => setShowViewUploadedEvidenceModal(false)}
        />
      )}

      {showAddAuditActionModal && (
        <AddAuditActionModal
          hubUuid={hubCompanyUuid}
          show={showAddAuditActionModal}
          onClose={handleCloseAddAuditActionModal}
        />
      )}
    </AdminPortalLayout>
  )
}

export default AdminManageAuditActions
