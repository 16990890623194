export default theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  title: {
    marginLeft: theme.spacing(),
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.danger.main,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '4rem',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: '4rem'
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '2rem',
    height: '2rem',
    width: '2rem'
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  addWeighSlipButton: {
    width: '100%',
    fontSize: 18,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
})
