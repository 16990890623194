/* global localStorage */

import { CameraResolution } from '../cameraresolution'
import { MinimalCompanyDto } from '../company'
import { Feature } from '../feature'
import { Gender } from '../gender/gender.types'
import { CleanhubLanguage } from '../language/language.types'
import { MaterialType } from '../materialtype/material-type.types'
import { ProductType } from '../producttype/product-type.types'
import { ProcessingFeatureStep } from '../processingunit/feature-step.types'
import React, { ReactElement } from 'react'

export enum SecurityRole {
  ROLE_SYSTEM_USER = 'ROLE_SYSTEM_USER',
  ROLE_CLEANHUB_ADMIN = 'ROLE_CLEANHUB_ADMIN',
  ROLE_HUB_MANAGER = 'ROLE_HUB_MANAGER',
  ROLE_HUB_OWNER = 'ROLE_HUB_OWNER'
}

export enum UserAuthType {
  EMAIL_PASSWORD = 'EMAIL_PASSWORD',
  SMS = 'SMS'
}

export interface IdentityDto {
  uuid: string
  createdAt: string
  gender: Gender
  firstName: string
  lastName: string
  fullName: string
  email: string
  phoneNumber: string
  authType: UserAuthType
  securityRole: SecurityRole
  language: CleanhubLanguage
  features: Feature[]
  cameraResolution: CameraResolution
  collectedMaterialTypes: MaterialType[]
  allowUnregisteredSuppliers: boolean
  showClearanceWeight: boolean
  showMaterialClearanceBuyer: boolean
  enableDocumentUpload: boolean
  weighMaterialCollection: boolean
  // this is set by FE on the impersonated user's Identity in the local storage
  impersonatedSignIn?: boolean

  company: MinimalCompanyDto

  pipelineOwner: boolean

  processingInputMaterialTypes: MaterialType[]
  processingOutputMaterialTypes: MaterialType[]
  processingOutputProductTypes: ProductType[]
  processingSteps: ProcessingFeatureStep[]

  segregationInputMaterialTypes: MaterialType[]
  segregationOutputMaterialTypes: MaterialType[]
}

export function getCurrentUser(): IdentityDto | null {
  return localStorage.getItem('userIdentity')
    ? JSON.parse(localStorage.getItem('userIdentity') ?? '')
    : null
}

export function getCurrentUserSecurityRole(): SecurityRole | null {
  return getCurrentUser()?.securityRole ?? null
}

export function isCleanhubAdmin(): boolean {
  return getCurrentUser()?.securityRole === SecurityRole.ROLE_CLEANHUB_ADMIN
}

export function isHubManager(): boolean {
  return getCurrentUser()?.securityRole === SecurityRole.ROLE_HUB_MANAGER
}

export function isCompanyPipelineOwner(): boolean {
  return getCurrentUser()?.pipelineOwner === true
}

export function isImpersonatedSignIn(): boolean {
  return getCurrentUser()?.impersonatedSignIn === true
}

export function companyHasFeature(feature: Feature): boolean | null {
  return getCurrentUser()?.features.includes(feature) ?? null
}

export const getUserAuthTypesOptions = (): ReactElement => {
  return (
    <>
      <option key="" value="" />

      {Object.keys(UserAuthType).map((type) => (
        <option key={type} value={type}>
          {type}
        </option>
      ))}
    </>
  )
}

export const getSecurityRoleOptions = (): ReactElement => {
  return (
    <>
      <option key="" value="" />

      {Object.keys(SecurityRole).map((role) => (
        <option key={role} value={role}>
          {role}
        </option>
      ))}
    </>
  )
}
