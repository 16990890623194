import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { FunctionComponentElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from 'common/theme'

import { EntityFilterConfig, EntityFilterType } from './entity-filters.types'
import { EntityFilter } from './EntityFilter'

export interface EntityFiltersProps {
  filters: EntityFilterConfig[]

  onChange?: (filters: EntityFilterConfig[]) => void
}

const useStyles = makeStyles<Theme>(() => ({
  entityFilters: {
    //
  }
}))

export function EntityFilters({
  filters,
  onChange
}: EntityFiltersProps): FunctionComponentElement<EntityFiltersProps> {
  const classes = useStyles()

  const { t } = useTranslation()

  function isMultiSelectFilter(filter: EntityFilterConfig): boolean {
    return filter.type === EntityFilterType.SELECT && filter.multiple === true
  }

  return (
    <Box className={classes.entityFilters}>
      <Box mb={3}>
        <Typography variant="h4">{t('filters')}</Typography>
      </Box>

      <Grid container={true} spacing={2}>
        {filters.map((filter) => (
          <Grid
            key={`${filter.field}_${filter.label}`}
            item={true}
            xs={12}
            sm={isMultiSelectFilter(filter) ? 12 : 6}
            md={isMultiSelectFilter(filter) ? 12 : 4}
            lg={isMultiSelectFilter(filter) ? 12 : 3}
          >
            <EntityFilter
              config={filter}
              onChange={(value, operator) => {
                onChange?.(
                  filters.map((item) =>
                    item === filter ? { ...item, operator, value } : item
                  ) as EntityFilterConfig[]
                )
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
