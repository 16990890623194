import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Typography
} from '@material-ui/core'
import { LocalShipping as LocalShippingIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { FunctionComponentElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Theme } from 'common/theme'
import { useFormStyles } from 'common/styles/form'
import { useGeneralStyles } from 'common/styles/general'
import { useCamera } from 'lib/camera'
import { useStateValue } from 'state'
import FullScreenCamera from 'components/FullScreenCamera'
import ImagePreview from 'components/ImagePreview'
import { useForm } from 'lib/form'
import LinkButton from 'components/LinkButton'
import { apiFetchWithDispatch } from 'lib/fetch'
import { PortalContent } from '../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../layouts/SecurePortalLayout'

const useStyles = makeStyles<Theme>(() => ({
  startCollection: {
    //
  }
}))

const formScheme = {
  transporterLicensePlate: {
    presence: { allowEmpty: false, message: 'validationRequired' },
    length: { maximum: 100, message: 'validationExceedsMaxLength' }
  }
}

function StartCollection(): FunctionComponentElement<void> {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const generalClasses = useGeneralStyles()

  const history = useHistory()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [, dispatch] = useStateValue()
  const transporterCamera = useCamera()

  const {
    formState: { values, isValid },
    getErrorMessage,
    handleChange,
    hasError,
    resetForm
  } = useForm(formScheme, {
    transporterLicensePlate: ''
  })

  async function handleSave() {
    setIsLoading(true)

    const { transporterLicensePlate } = values

    const response = await apiFetchWithDispatch(
      dispatch,
      '/api/collectionUnits',
      {
        method: 'POST',
        body: JSON.stringify({
          transporterLicensePlate,
          transporterPictureFileUploadUuid:
            transporterCamera.pictureFileUpload?.uuid ?? null
        })
      }
    )

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('startCollectionTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!transporterCamera.pictureConfirmed) {
    return (
      <FullScreenCamera
        key="transporter-picture"
        fileName={`start-recycler-clearance-transporter-${Date.now()}`}
        headerMessage={t('startClearancePictureExplanation')}
        shutterIcon={<LocalShippingIcon />}
        onTakePhotoFinished={transporterCamera.handleTakePhotoFinished}
        onFileUploadFinished={transporterCamera.handleFileUploadFinished}
        onConfirmPicture={transporterCamera.handleConfirmPicture}
        onCameraError={transporterCamera.handleCameraError}
      />
    )
  }

  return (
    <SecurePortalLayout title={t('startCollectionTitle')}>
      <PortalContent>
        <div className={classes.root}>
          <div className={generalClasses.content}>
            <form autoComplete="off" noValidate>
              <Paper className={formClasses.formSection}>
                <Typography className={generalClasses.title} variant="h3">
                  {t('startCollectionTitle')}
                </Typography>

                <FormControl component="fieldset">
                  <FormLabel component="legend" required>
                    {t('transporterPicture')}
                  </FormLabel>
                  <div className={generalClasses.imagePreview}>
                    <ImagePreview
                      dataUri={transporterCamera.pictureDataUri}
                      isPictureUploading={!transporterCamera.pictureFileUpload}
                    />
                  </div>
                </FormControl>

                <div className={formClasses.field}>
                  <TextField
                    name="transporterLicensePlate"
                    required
                    label={t('licensePlate')}
                    className={formClasses.textField}
                    value={values.transporterLicensePlate}
                    onChange={handleChange}
                    margin="dense"
                    error={hasError('transporterLicensePlate')}
                    helperText={t(getErrorMessage('transporterLicensePlate'))}
                  />
                </div>

                <div className={formClasses.actionButtons}>
                  <LinkButton
                    size="large"
                    className={formClasses.cancelButton}
                    color="default"
                    variant="outlined"
                    href="/hub-overview"
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size="large"
                    variant="contained"
                    className={generalClasses.primaryButton}
                    disabled={
                      !isValid ||
                      isLoading ||
                      !transporterCamera.pictureFileUpload
                    }
                    onClick={handleSave}
                  >
                    {t('startCollectionButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </div>
        </div>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default StartCollection
