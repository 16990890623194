import { makeStyles } from '@material-ui/styles'

import { Theme } from '../theme'

export const useGeneralStyles = makeStyles<Theme>((theme) => ({
  content: {
    marginTop: theme.spacing(2)
  },
  title: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(3),
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  sectionTitle: {
    '&$title': {
      paddingBottom: theme.spacing(2),
      marginLeft: theme.spacing()
    }
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  imagePreview: {
    width: 300,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  primaryButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  }
}))
