// Palette
import palette from '../palette'

export default {
  root: {
    height: 60,
    width: '33%',
    backgroundColor: palette.common.white,
    color: palette.text.primary,
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: '14px',

    '@media (min-width: 960px)': {
      minWidth: '100px'
    },

    '&$selected': {
      backgroundColor: palette.common.white,
      color: palette.primary.main
    }
  },
  textColorInherit: {
    color: palette.text.primary
  },
  textColorPrimary: {
    color: palette.text.secondary
  }
}
