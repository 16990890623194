import React from 'react'

export const Genders = {
  FEMALE: 'FEMALE',
  MALE: 'MALE',
  UNKNOWN: 'UNKNOWN'
}

export function getGenderOptions () {
  return (
    <>
      <option key='' value='' />
      {Object.keys(Genders).map(gender => (
        <option key={gender} value={gender}>
          {gender}
        </option>
      ))}
    </>
  )
}
