import { Reducer } from 'react'

export type ActivePickUpPointState = {
  uuid: string
}

export enum ActivePickUpPointActionType {
  CLEAR = 'CLEAR',
  SET = 'SET'
}

export interface ActivePickUpPointActionClear {
  type: ActivePickUpPointActionType.CLEAR
}

export interface ActivePickUpPointActionSet {
  type: ActivePickUpPointActionType.SET
  value: ActivePickUpPointState
}

export type ActivePickUpPointActions =
  | ActivePickUpPointActionClear
  | ActivePickUpPointActionSet

export const activePickUpPointReducer: Reducer<
  ActivePickUpPointState | null,
  ActivePickUpPointActions
> = (state, action) => {
  switch (action.type) {
    case ActivePickUpPointActionType.CLEAR: {
      window.sessionStorage.removeItem('activePickUpPoint')

      return null
    }

    case ActivePickUpPointActionType.SET: {
      window.sessionStorage.setItem(
        'activePickUpPoint',
        JSON.stringify(action.value)
      )

      return {
        uuid: action.value.uuid
      }
    }

    default:
      return state
  }
}
