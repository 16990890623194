import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import React, {
  FunctionComponentElement,
  useCallback,
  useEffect,
  useState
} from 'react'
import { match, Redirect } from 'react-router-dom'

import { apiFetchWithDispatch, useFetchState } from 'lib/fetch'
import { useGeneralStyles } from '../../common/styles/general'
// import { getCompany } from '../../services/company'
import { SegregationUnitStates } from '../../services/segregationunit'
import { TrackedItemDto, TrackedItemState } from '../../services/tracked-item'
import { useStateValue } from 'state'
import { ProcessingUnitState } from '../../services/processingunit'

interface Props {
  match: match<{ trackedItemUuid: string }>
}

const useStyles = makeStyles(() => ({
  trackedItemIdentity: {
    //
  }
}))

function TrackedItemIdentity({
  match
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()

  const {
    params: { trackedItemUuid }
  } = match

  const [trackedItem, isTrackedItemLoading] = useFetchState(
    `/api/trackedItems/${trackedItemUuid}`
  )

  const [
    ongoingSegregationUnit,
    isOngoingSegregationUnitLoading
  ] = useFetchState(
    `/api/segregationUnits?state=${SegregationUnitStates.PROCESSING}`
  )

  const [ongoingProcessingUnit, isOngoingProcessingUnitLoading] = useFetchState(
    `/api/processingUnits?state=${ProcessingUnitState.IN_PROGRESS}`
  )

  const [
    updatedTrackedItem,
    setUpdatedTrackedItem
  ] = useState<TrackedItemDto | null>(null)

  const [isLoading, setIsLoading] = useState(false)

  const [, dispatch] = useStateValue()

  // const currentCompany = getCompany()

  const updateTrackedItem = useCallback(async () => {
    if (trackedItem && trackedItem.state !== TrackedItemState.UNASSIGNED) {
      setIsLoading(true)

      const updatedTrackedItem = await apiFetchWithDispatch(
        dispatch,
        `/api/trackedItems/${trackedItemUuid}`,
        {
          method: 'POST'
        }
      )
      setUpdatedTrackedItem(updatedTrackedItem)

      setIsLoading(false)
    }
  }, [dispatch, trackedItem])

  useEffect(() => {
    updateTrackedItem()
  }, [updateTrackedItem])

  if (
    isLoading ||
    isTrackedItemLoading ||
    isOngoingSegregationUnitLoading ||
    isOngoingProcessingUnitLoading ||
    !trackedItem
  ) {
    return (
      <Box className={generalClasses.progressWrapper}>
        <CircularProgress />
      </Box>
    )
  }

  // TODO enable back when registration is enabled on recycler
  // if (trackedItem && trackedItem.company?.uuid !== currentCompany?.uuid) {
  //  return <Redirect to={`/error`} />
  // }

  if (
    trackedItem &&
    trackedItem.state === TrackedItemState.UNASSIGNED &&
    ongoingProcessingUnit
  ) {
    return (
      <Box className={classes.trackedItemIdentity}>
        <Redirect
          to={`/processing-unit/${ongoingProcessingUnit.uuid}/register-output-material-bag?trackedItemUuid=${trackedItemUuid}`}
        />
      </Box>
    )
  }

  if (
    trackedItem &&
    trackedItem.state === TrackedItemState.UNASSIGNED &&
    !isOngoingSegregationUnitLoading &&
    !ongoingSegregationUnit
  ) {
    return (
      <Box className={classes.trackedItemIdentity}>
        <Redirect
          to={`/register-material-bag?trackedItemUuid=${trackedItemUuid}`}
        />
      </Box>
    )
  }

  if (
    trackedItem &&
    trackedItem.state === TrackedItemState.UNASSIGNED &&
    ongoingSegregationUnit
  ) {
    return (
      <Box className={classes.trackedItemIdentity}>
        <Redirect
          to={`/segregation-unit/${ongoingSegregationUnit.uuid}/register-output-material-bag?trackedItemUuid=${trackedItemUuid}`}
        />
      </Box>
    )
  }

  if (
    updatedTrackedItem &&
    (updatedTrackedItem.state === TrackedItemState.IN_SORTING ||
      updatedTrackedItem.state === TrackedItemState.IN_PROCESSING ||
      updatedTrackedItem.state === TrackedItemState.IN_TRANSIT)
  ) {
    return (
      <Box className={classes.trackedItemIdentity}>
        <Redirect to={`/hub-overview`} />
      </Box>
    )
  }

  return (
    <Box className={classes.trackedItemIdentity}>
      //todo handle the other states of a tracked item
      <Redirect
        to={`/register-material-bag?trackedItemUuid=${trackedItemUuid}`}
      />
    </Box>
  )
}

export default TrackedItemIdentity
