import { useCallback, useEffect, useState } from 'react'

import { clearUserStorageData } from '../../services/storage/storage.helpers'
import { useStateValue } from '../../state'
import { ErrorActionType } from '../../state/reducers/error.reducer'
import { Dispatch } from '../../state/state.types'

import { auth0Client } from '../auth0'

export interface ExtendedRequestInit extends RequestInit {
  queryParams?: Record<string, string>
}

export interface FetchConfig {
  initialFetch?: boolean
  multipart?: boolean
}

export type UseFetchState<T> = [
  T | null,
  boolean,
  (refreshOptions?: Partial<ExtendedRequestInit>) => Promise<T | null>
]

class ApiError extends Error {
  body?: { errorMessage: string } | { errorMessageKey: string }
}

function getUrlWithQueryParams(
  url: string,
  params?: Record<string, string>
): string {
  if (params) {
    return `${url}?${new URLSearchParams(params)}`
  }

  return url
}

export const apiFetch = <T = any>(
  url: string,
  options?: Partial<ExtendedRequestInit>,
  config: FetchConfig = {}
): Promise<T> => {
  return auth0Client.getTokenSilently().then((accessToken) => {
    const defaultOptions: { headers: Record<string, string> } = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    defaultOptions.headers[
      'x-time-zone'
    ] = Intl.DateTimeFormat().resolvedOptions().timeZone

    const xAuthToken = window.localStorage.getItem('x-auth-token')
    if (xAuthToken) {
      defaultOptions.headers['x-auth-token'] = xAuthToken
    } else if (accessToken) {
      defaultOptions.headers.Authorization = `Bearer ${accessToken}`
    }

    const opts = { ...defaultOptions, ...(options || {}) }
    opts.credentials = opts.credentials || 'same-origin'

    if (config.multipart) {
      delete opts.headers['Content-Type']

      const formData = new FormData()
      Object.entries(options?.body ?? {}).forEach(([key, value]) => {
        formData.append(key, value)
      })

      opts.body = formData
    } else if (opts.body && typeof opts.body !== 'string') {
      opts.body = JSON.stringify(opts.body)
    }

    return window
      .fetch(getUrlWithQueryParams(url, options?.queryParams), opts)
      .then((res) => {
        if (res.status === 401) {
          clearUserStorageData()

          return auth0Client.logout()
        }

        if (res.status >= 400) {
          const err = new ApiError(res.status.toString())

          return res
            .json()
            .catch(() => ({ errorMessageKey: 'genericErrorMessage' }))
            .then((body) => {
              err.body =
                body && (body.errorMessage || body.errorMessageKey)
                  ? body
                  : { errorMessageKey: 'genericErrorMessage' }
              throw err
            })
        }

        if (res.status === 204) {
          return
        }

        if (
          res.headers.has('content-type') &&
          res.headers.get('content-type') !== 'application/json'
        ) {
          return res
        }

        return res.json()
      })
  })
}

export const apiFetchWithDispatch = async <T = any>(
  dispatch: Dispatch,
  url: string,
  options?: Partial<ExtendedRequestInit>,
  config: FetchConfig = {}
): Promise<T | null> => {
  try {
    dispatch({ type: ErrorActionType.RESET_ERROR })

    return await apiFetch<T>(url, options, config)
  } catch (error) {
    if (error.message === 'Unexpected end of JSON input') {
      return null
    }

    if (!error.body) {
      return null
    }

    if (error.message === '401') {
      dispatch({ type: ErrorActionType.SET_SESSION_EXPIRED, value: true })
    } else {
      dispatch({
        type: ErrorActionType.ADD_ERROR,
        value: {
          errorMessage: error.body.errorMessage,
          errorMessageKey: error.body.errorMessageKey
        }
      })
    }

    return null
  }
}

export const apiFetchWithContext = async <T = any>(
  context: [any, Dispatch],
  url: string,
  options: Partial<ExtendedRequestInit>
): Promise<T | null> => {
  const [, dispatch] = context

  return apiFetchWithDispatch<T>(dispatch, url, options)
}

/**
 * useFetchState is a hook encapsulating loading of external data and correctly
 * telling about the loading state.
 *
 * If you set the apiURL to null, nothing will ever be fetched. This can be used to conditionally
 * call the hook when e.g. the URL contains an optional parameter.
 */
export const useFetchState = <T = any>(
  apiUrl: string | null,
  initialResponse: T | null = null,
  options?: Partial<ExtendedRequestInit>,
  config?: FetchConfig
): UseFetchState<T> => {
  const [response, setResponse] = useState(initialResponse)
  const [isLoading, setIsLoading] = useState(true)

  const [, dispatch] = useStateValue()

  const fetchResponse = useCallback<
    (refreshOptions?: Partial<ExtendedRequestInit>) => Promise<T | null>
  >(
    async (refreshOption) => {
      if (!apiUrl) {
        return null
      }

      setIsLoading(true)

      const response = await apiFetchWithDispatch<T>(
        dispatch,
        apiUrl,
        refreshOption ?? options,
        config
      )

      setResponse(response)
      setIsLoading(false)

      return response
    },
    [dispatch, apiUrl]
  )

  useEffect(() => {
    if (config?.initialFetch === undefined || config?.initialFetch === true) {
      fetchResponse()
    }
  }, [fetchResponse])

  const refresh = useCallback(
    (refreshOptions?: Partial<ExtendedRequestInit>) => {
      return fetchResponse(refreshOptions)
    },
    [fetchResponse]
  )

  return [response, isLoading, refresh]
}
