import { UseFetchState, useFetchState } from 'lib/fetch'

import { CollectedMaterialsSummaryDto } from './collected-materials.types'

export class CollectedMaterialsApiService {
  public static readonly BASE_URL = '/api/collectedMaterials'

  public static getSummary(): UseFetchState<CollectedMaterialsSummaryDto | null> {
    return useFetchState<CollectedMaterialsSummaryDto | null>(
      `${CollectedMaterialsApiService.BASE_URL}/summary`
    )
  }
}
