import {
  EntityFilterConfig,
  EntityFilterOperator,
  EntityFilterType
} from './entity-filters.types'

export function getFilterValue(filter: EntityFilterConfig): string | null {
  if (filter.operator === EntityFilterOperator.IS_EMPTY) {
    return ''
  }

  if (filter.type === EntityFilterType.SELECT) {
    if (Array.isArray(filter.value)) {
      return filter.value.map((value) => value.value).join(',')
    }

    return filter.value?.value ?? null
  }

  return filter.value
}

export function getFiltersBody(
  filters: EntityFilterConfig[] | null,
  page: number,
  size: number
): string {
  return JSON.stringify({
    page,
    size,
    filters: filters
      ?.map((item) => ({
        field: item.field,
        operator: item.operator,
        value: getFilterValue(item)
      }))
      .filter(
        (item) =>
          !!item.value || item.operator === EntityFilterOperator.IS_EMPTY
      )
  })
}

export function getSingleFilterValue(
  filters: EntityFilterConfig[] | null
): string | null {
  return filters && filters?.length > 0 ? getFilterValue(filters[0]) : null
}
