import moment from 'moment'

export function mergeDateAndTime(
  date: Date | string,
  time: Date | string
): moment.Moment {
  const timeMoment = moment(time)

  return moment(date).set({
    hours: timeMoment.hours(),
    minutes: timeMoment.minutes(),
    seconds: timeMoment.seconds()
  })
}
