import { makeStyles } from '@material-ui/styles'

import { Theme } from '../theme'

export const useModalStyles = makeStyles<Theme>((theme) => ({
  paper: {
    position: 'absolute',
    width: 300,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none'
  },
  content: {
    marginTop: theme.spacing(2)
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  title: {
    flexGrow: 2,
    marginBottom: theme.spacing(2)
  },
  formFields: {
    marginTop: theme.spacing()
  },
  closeButton: {
    border: 'none',
    padding: 0,
    marginTop: '-20px',
    marginRight: '-15px',
    alignSelf: 'flex-end',
    fontSize: 22
  },
  actionButtons: {
    marginTop: theme.spacing(4)
  },
  cancelButton: {
    marginRight: theme.spacing()
  }
}))
