import { Box, makeStyles } from '@material-ui/core'
import React, { FunctionComponentElement, ReactNode } from 'react'

import { Theme } from '../../common/theme'

interface Props {
  children?: ReactNode
}

const useStyles = makeStyles<Theme>(() => ({
  portalLayout: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex'
  }
}))

export function PortalLayout({
  children
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.portalLayout}>{children}</Box>
    </>
  )
}
