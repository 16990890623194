import { Box, CircularProgress, Typography } from '@material-ui/core'
import {
  Check as CheckIcon,
  PersonPin as PersonPinIcon,
  PlayCircleFilled as PlayCircleFilledIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import cx from 'classnames'
import React, { FunctionComponentElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGeneralStyles } from 'common/styles/general'
import { Theme } from 'common/theme'
import FlowActionButton from 'components/FlowActionButton'
import SummaryLine from 'components/SummaryLine'
import { CollectionUnitsApiService } from 'services/collection-units/collection-units-api.service'
import { getDisplayedQuantityUnit } from 'services/quantityunit'
import { useStateValue } from 'state'
import { getCurrentUser } from 'services/user'
import { apiFetchWithDispatch } from 'lib/fetch'

import maleWithBoxSvg from '../../../../assets/images/empty-state/male-with-box.svg'
import { SecurePortalLayout } from '../../../../layouts/SecurePortalLayout'
import { PortalContent } from '../../../../layouts/portal/PortalContent'

const useStyles = makeStyles<Theme>((theme) => ({
  collectionCenterCollectionsOverview: {
    marginBottom: theme.spacing(2)
  },
  emptyStateImage: {
    width: '75%',
    display: 'block',
    margin: `${theme.spacing(3)}px auto ${theme.spacing(5)}px`
  }
}))

function CollectionCenterCollectionsOverview(): FunctionComponentElement<void> | null {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()
  const titleClasses = cx([generalClasses.title, generalClasses.sectionTitle])

  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [, dispatch] = useStateValue()

  const [
    ongoingCollection,
    isOngoingCollectionLoading,
    refetchOngoingCollection
  ] = CollectionUnitsApiService.getOngoing()
  const [
    summary,
    isSummaryLoading,
    refetchSummary
  ] = CollectionUnitsApiService.getSummary()

  async function handleFinishWithoutWeighMaterialCollection() {
    setIsLoading(true)

    await apiFetchWithDispatch(
      dispatch,
      `/api/collectionUnits/${ongoingCollection?.uuid}/finish`,
      {
        method: 'POST',
        body: JSON.stringify({})
      }
    )
    setIsLoading(false)

    await refetchOngoingCollection()
    await refetchSummary()
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('finishCollectionTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (isSummaryLoading || isOngoingCollectionLoading) {
    return (
      <div className={generalClasses.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  if (!summary) {
    return (
      <img
        className={classes.emptyStateImage}
        src={maleWithBoxSvg}
        alt={t('noResults')}
      />
    )
  }

  const summaryContent = (
    <>
      <Typography className={titleClasses} variant="h3">
        {t('collectionsOverview')}
      </Typography>

      <Box className={generalClasses.content}>
        <SummaryLine
          title={t('collectionsCount')}
          value={summary.collectionUnitCount}
        />

        <SummaryLine
          title={t('collectedUnits')}
          value={summary.collectedMaterialsCount}
        />

        <SummaryLine
          title={t('totalAmount')}
          value={
            <>
              {summary.totalQuantity.toFixed(2)}{' '}
              {getDisplayedQuantityUnit(summary.totalQuantityUnit)}
            </>
          }
        />

        <FlowActionButton
          color="secondary"
          href="/start-collection"
          icon={PlayCircleFilledIcon}
        >
          {t('startCollectionButton')}
        </FlowActionButton>
      </Box>
    </>
  )

  const ongoingCollectionContent = ongoingCollection && (
    <>
      <Typography className={titleClasses} variant="h3">
        {t('ongoingCollectionTitle')}
      </Typography>

      <Box className={generalClasses.content}>
        <SummaryLine
          title={t('pickUpPointsVisited')}
          value={ongoingCollection.pickUpPointsVisited}
        />

        <SummaryLine
          title={t('collectedUnitsCount')}
          value={ongoingCollection.collectedUnitsCount}
        />

        <SummaryLine
          title={t('totalCollectedQuantity')}
          value={
            <>
              {ongoingCollection.collectedQuantityTotal.toFixed(2)}{' '}
              {getDisplayedQuantityUnit(
                ongoingCollection.collectedQuantityUnit
              )}
            </>
          }
        />

        <FlowActionButton color="primary" href="/check-in" icon={PersonPinIcon}>
          {t('checkInButton')}
        </FlowActionButton>

        {getCurrentUser()?.weighMaterialCollection ? (
          <FlowActionButton
            color="secondary"
            variant="outlined"
            href={`/collection-units/${ongoingCollection.uuid}/finish`}
            icon={CheckIcon}
            mt={2}
          >
            {t('finishCollectionButton')}
          </FlowActionButton>
        ) : (
          <FlowActionButton
            color="secondary"
            variant="outlined"
            icon={CheckIcon}
            mt={2}
            onClick={handleFinishWithoutWeighMaterialCollection}
          >
            {t('finishCollectionButton')}
          </FlowActionButton>
        )}
      </Box>
    </>
  )

  return (
    <Box className={classes.collectionCenterCollectionsOverview}>
      {ongoingCollection ? ongoingCollectionContent : summaryContent}
    </Box>
  )
}

export default CollectionCenterCollectionsOverview
