export default theme => ({
  root: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  allowUnregisteredSuppliersSegmentedControl: {
    width: '100%',
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  registerTruckLoadButton: {
    width: '100%',
    fontSize: 18,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  }
})
