import { UseFetchState, useFetchState } from 'lib/fetch'
import { ClearableMaterialsDto } from './clearable-materials.types'

export class MaterialClearancesApiService {
  public static readonly BASE_URL = '/api/materialClearances'

  public static getSummary(): UseFetchState<ClearableMaterialsDto | null> {
    return useFetchState<ClearableMaterialsDto | null>(
      `${MaterialClearancesApiService.BASE_URL}/summary`
    )
  }
}
