import { Receipt as ReceiptIcon } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'

import { compose } from 'recompose'

// Material components
import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  RadioGroup,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'

// Shared layouts
import { mergeDateAndTime } from '../../../helpers/date-time.helpers'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'

// Component styles
import styles from './styles'
import { useTheme } from '@material-ui/styles'

import { useStateValue } from 'state'
import schema from './schema'

import { useCamera } from 'lib/camera'

import { useTranslation } from 'react-i18next'

import { apiFetchWithDispatch } from 'lib/fetch'
import { useForm } from 'lib/form'

import { ImagePreview, FullScreenCamera } from 'components'

import { QuantityUnits } from 'services/quantityunit'

import LinkButton from '../../../components/LinkButton'

import { getMaterialTypeOptions } from '../../../services/materialtype'
import { useFetchState } from '../../../lib/fetch'

function RegisterAdditionalMaterial (props) {
  const { classes, history, match } = props
  const { ongoingTransportUuid } = match.params

  const [isLoading, setIsLoading] = useState(false)

  const [ongoingTransport, ] = useFetchState(`/api/ongoingTransports/${ongoingTransportUuid}`)

  const [alreadyRegisteredMaterials, ] = useFetchState(`/api/registeredMaterials/ongoingTransport/${ongoingTransportUuid}`)

  const { t } = useTranslation()

  const theme = useTheme()

  const [, dispatch] = useStateValue()

  const {
    pictureDataUri,
    pictureFileUpload,
    showCamera,
    pictureConfirmed,
    handleTakePhotoFinished,
    handleFileUploadFinished,
    handleConfirmPicture,
    handleCameraError
  } = useCamera()

  const {
    formState: {
      values,
      errors,
      isValid
    },
    setValues,
    handleChange,
    resetForm,
    hasError
  } = useForm(schema, {
    weight: '',
    supplier: '',
    internalId: '',
    weighedAt: new Date(),
    weighedAtTime: new Date(),
    materialType: '',
    quantityUnit: QuantityUnits.KG
  })

  useEffect(() => {
    if (!showCamera) {
      history.push('/hub-overview')
    }
  }, [showCamera])

  function handleWeighedAtChange (e) {
    const newValues = { ...values }
    newValues.weighedAt = e
    setValues(newValues)
  }

  function handleWeighedAtTimeChange (e) {
    const newValues = { ...values }
    newValues.weighedAtTime = e
    setValues(newValues)
  }

  async function handleSave () {
    setIsLoading(true)

    const {
      weight,
      supplier,
      internalId,
      weighedAt,
      weighedAtTime,
      materialType
    } = values

    const response = await apiFetchWithDispatch(dispatch, '/api/registeredMaterials/', {
      method: 'POST',
      body: JSON.stringify({
        weight,
        licensePlate: ongoingTransport.transporterLicensePlate,
        supplier,
        internalId,
        transportOwner: ongoingTransport.owner,
        weighedAt: mergeDateAndTime(weighedAt, weighedAtTime).toISOString(),
        materialType,
        pictureFileUploadUuid: pictureFileUpload ? pictureFileUpload.uuid : null
      })
    })

    if (response) {
      resetForm()

      if (getPendingMaterialTypes().length > 1) {
        history.go(`/register-additional-material/${ongoingTransport.owner.uuid}`)
      } else {
        history.push('/hub-overview')
      }
    } else {
      setIsLoading(false)
    }
  }

  function getAlreadyRegisteredMaterialTypes () {
    return alreadyRegisteredMaterials.map(registeredMaterial => registeredMaterial.materialType)
  }

  function getPendingMaterialTypes () {
    const alreadyRegisteredMaterials = getAlreadyRegisteredMaterialTypes()
    return ongoingTransport.materialTypes.filter(materialType => !alreadyRegisteredMaterials.includes(materialType))
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('registerAdditionalMaterialTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  return (
    <div>
      {
        pictureConfirmed ? (
          <SecurePortalLayout title={t('registerAdditionalMaterialTitle')}>
            <PortalContent>
              <div className={classes.root}>
                <div className={classes.content}>
                  <form autoComplete='off' noValidate>
                    <Paper className={classes.formSection}>
                      <Typography
                        className={classes.title}
                        variant='h3'
                      >
                        {t('registerAdditionalMaterialTitle')}
                      </Typography>

                      <FormControl component='fieldset'>
                        <FormLabel component='legend' required>
                          {t('weighSlipPicture')}
                        </FormLabel>
                        <div className={classes.imagePreview}>
                          <ImagePreview
                            dataUri={pictureDataUri}
                            isPictureUploading={!pictureFileUpload}
                          />
                        </div>
                      </FormControl>

                      <div className={classes.field}>
                        <TextField
                          name='weight'
                          autoFocus
                          className={classes.weightField}
                          type='number'
                          label={`${t('addWeight')}`}
                          margin='dense'
                          required
                          value={values.weight}
                          onChange={handleChange}
                          error={hasError('weight')}
                          helperText={hasError('weight') ? t(errors.weight[0]) : ''}
                        />
                        <TextField
                          name='quantityUnit'
                          className={classes.quantityUnitField}
                          disabled
                          label=' '
                          margin='dense'
                          value={values.quantityUnit}
                          InputProps={{
                            style: {
                              color: theme.palette.text.primary
                            }
                          }}
                        />
                      </div>

                      <div className={classes.field}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            margin='normal'
                            className={classes.dateField}
                            id='mui-pickers-date'
                            name='weighedAt'
                            label={t('weighedAt')}
                            value={values.weighedAt}
                            onChange={handleWeighedAtChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                          />

                          <KeyboardTimePicker
                            margin='normal'
                            className={classes.timeField}
                            id='time-picker'
                            label=' '
                            value={values.weighedAtTime}
                            onChange={handleWeighedAtTimeChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change time'
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                      <div className={classes.field}>
                        <TextField
                          name='licensePlate'
                          disabled
                          label={t('licensePlate')}
                          className={classes.textField}
                          value={ongoingTransport.transporterLicensePlate}
                          onChange={handleChange}
                          margin='dense'
                        />
                      </div>

                      <div className={classes.radioGroupField}>
                        <FormControl component='fieldset'>
                          <FormLabel
                            component='legend'
                            required
                            className={classes.radioGroupLabel}
                          >
                            {t('selectMaterial')}
                          </FormLabel>
                          <RadioGroup
                            name='materialType'
                            value={values.materialType}
                            onChange={handleChange}
                          >
                            {getMaterialTypeOptions(getPendingMaterialTypes(), t)}
                          </RadioGroup>
                        </FormControl>
                      </div>

                      <div className={classes.field}>
                        <TextField
                          name='internalId'
                          label={t('internalId')}
                          className={classes.textField}
                          value={values.internalId}
                          onChange={handleChange}
                          margin='dense'
                        />
                      </div>

                      <div className={classes.actionButtons}>
                        <LinkButton
                          size='large'
                          className={classes.cancelButton}
                          color='default'
                          variant='outlined'
                          href='/hub-overview'
                        >
                          {t('cancelButton')}
                        </LinkButton>
                        <Button
                          size='large'
                          variant='contained'
                          className={classes.registerTruckLoadButton}
                          disabled={!isValid || isLoading || !pictureFileUpload}
                          onClick={handleSave}
                        >
                          {getPendingMaterialTypes().length > 1 ? t('saveAndContinueRegistrationButton') : t('addTruckLoadButton')}
                        </Button>
                      </div>
                    </Paper>
                  </form>
                </div>
              </div>
            </PortalContent>
          </SecurePortalLayout>
        ) : (
          showCamera && (
            <FullScreenCamera
              fileName={`register-additional-material-${Date.now()}`}
              headerMessage={t('registerTruckLoadPictureExplanation')}
              shutterIcon={<ReceiptIcon />}
              onTakePhotoFinished={handleTakePhotoFinished}
              onFileUploadFinished={handleFileUploadFinished}
              onConfirmPicture={handleConfirmPicture}
              onCameraError={handleCameraError}
            />
          )
        )
      }
    </div>
  )
}

export default compose(
  withRouter,
  withStyles(styles)
)(RegisterAdditionalMaterial)
