import { Box, ButtonBase, Collapse, Typography } from '@material-ui/core'
import { ArrowRight as ArrowRightIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import cx from 'classnames'
import React, { FunctionComponentElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from '../common/theme'
import {
  convertKilogramsToOilLiters,
  formatQuantity
} from '../helpers/quantity.helpers'
import { MassBalanceOutputTypeBreakdownEntry } from '../services/mass-balance'
import { QuantityUnit } from '../services/quantityunit'
import {
  getQuantityUnitByProductType,
  ProductType
} from '../services/producttype/product-type.types'

interface MaterialTypeBreakdownProps {
  outputTypeBreakdown: MassBalanceOutputTypeBreakdownEntry[]
  quantityUnit: QuantityUnit
  total?: number | null
  totalDescription?: string
  totalText: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  materialTypeBreakdown: {
    width: '100%',
    display: 'block',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.border}`
  },
  chevronIcon: {
    marginLeft: theme.spacing(-0.5),
    marginRight: theme.spacing(0.5),
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard
    })
  },
  chevronIconDisabled: {
    color: theme.palette.text.disabled
  },
  chevronIconOpen: {
    transform: 'rotate(90deg)'
  },
  quantity: {
    fontWeight: 500,
    whiteSpace: 'nowrap'
  }
}))

export function MaterialTypeBreakdown({
  outputTypeBreakdown,
  quantityUnit,
  totalDescription,
  total,
  totalText
}: MaterialTypeBreakdownProps): FunctionComponentElement<MaterialTypeBreakdownProps> {
  const classes = useStyles()

  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const uniqueProductTypes = Array.from(
    new Set(outputTypeBreakdown.map((item) => item.productType))
  )

  const finalQuantityUnit =
    uniqueProductTypes.length > 1
      ? QuantityUnit.KG
      : getQuantityUnitByProductType(uniqueProductTypes[0])

  function getMaterialTypeBreakdownTotal(): number {
    return outputTypeBreakdown.reduce(
      (sum, item) =>
        sum +
        (finalQuantityUnit === QuantityUnit.LITER
          ? convertKilogramsToOilLiters(item.weight)
          : item.weight),
      0
    )
  }

  return (
    <ButtonBase
      className={classes.materialTypeBreakdown}
      disabled={outputTypeBreakdown.length === 0}
      onClick={() => setIsOpen((value) => !value)}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <ArrowRightIcon
            className={cx(classes.chevronIcon, {
              [classes.chevronIconDisabled]: outputTypeBreakdown.length === 0,
              [classes.chevronIconOpen]: isOpen
            })}
          />

          <Typography variant="h5">{totalText}</Typography>
        </Box>

        <Box textAlign="right">
          <Typography variant="h5">
            {formatQuantity(
              total || getMaterialTypeBreakdownTotal(),
              finalQuantityUnit
            )}
          </Typography>

          {totalDescription && (
            <Typography variant="caption">{totalDescription}</Typography>
          )}
        </Box>
      </Box>

      <Collapse in={isOpen}>
        <Box mt={1}>
          {outputTypeBreakdown.map(({ materialType, productType, weight }) => (
            <Box
              key={`${materialType}_${productType}`}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              pl={3}
            >
              <Box mr={2}>
                <Typography align="left">
                  {t(`materialType_${materialType}`)}

                  {productType ? ` - ${t(`productType_${productType}`)}` : ''}
                </Typography>
              </Box>

              <Typography className={classes.quantity}>
                {formatQuantity(
                  productType === ProductType.OIL
                    ? convertKilogramsToOilLiters(weight)
                    : weight,
                  getQuantityUnitByProductType(productType, quantityUnit)
                )}
              </Typography>
            </Box>
          ))}
        </Box>
      </Collapse>
    </ButtonBase>
  )
}
