import { Box, Button, CircularProgress, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FunctionComponentElement, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGeneralStyles } from '../../../common/styles/general'
import { Theme } from '../../../common/theme'
import { ClnModal, ClnModalContent } from '../../../components/modal'
import { ClnModalContentProps } from '../../../components/modal/ClnModalContent'
import { apiFetchWithDispatch } from '../../../lib/fetch'
import { useForm } from '../../../lib/form'
import { useStateValue } from '../../../state'
import { HubEventDto } from '../../../services/hub-events'

interface DeleteDuplicateHubEventModalProps {
  hubEvent: HubEventDto | null
  show: boolean
  onClose: () => any
  onError: () => any
}

const useStyles = makeStyles<Theme>(() => ({
  addHubManagerModal: {
    //
  }
}))

const formSchema = {
  originalHubEventUuid: {
    presence: { allowEmpty: false, message: 'validationRequired' },
    length: { maximum: 36, message: 'validationExceedsMaxLength' }
  }
}

export function DeleteDuplicateHubEventModal({
  hubEvent,
  show,
  onClose,
  onError
}: DeleteDuplicateHubEventModalProps): FunctionComponentElement<DeleteDuplicateHubEventModalProps> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()

  const [, dispatch] = useStateValue()
  const { t } = useTranslation()

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const {
    formState: { values, errors, isValid },
    handleChange,
    hasError
  } = useForm(formSchema, {
    originalHubEventUuid: ''
  })

  const handleDelete = async (): Promise<void> => {
    setIsSaving(true)

    const response = await apiFetchWithDispatch(
      dispatch,
      `/api/admin/hubEvents/${hubEvent?.uuid}/removeAsDuplicate`,
      {
        method: 'POST',
        body: JSON.stringify(values)
      }
    )

    setIsSaving(false)

    if (response === null) {
      onError()
    } else {
      onClose()
    }
  }

  const getContent = (): ReactElement<
    ClnModalContentProps,
    typeof ClnModalContent
  > => {
    if (isSaving) {
      return (
        <ClnModalContent>
          <Box className={generalClasses.progressWrapper}>
            <CircularProgress />
          </Box>
        </ClnModalContent>
      )
    }

    return (
      <ClnModalContent>
        <form autoComplete="off" noValidate>
          <Box mb={3}>
            <TextField
              name="originalHubEventUuid"
              type="text"
              label="Original Hub Event ID"
              fullWidth={true}
              required={true}
              value={values.originalHubEventUuid}
              onChange={handleChange}
              error={hasError('originalHubEventUuid')}
              helperText={
                hasError('originalHubEventUuid')
                  ? t(errors.originalHubEventUuid?.[0] ?? '')
                  : ''
              }
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={8}>
            <Button
              size="large"
              color="default"
              variant="outlined"
              onClick={onClose}
            >
              {t('cancelButton')}
            </Button>

            <Box ml={1}>
              <Button
                size="large"
                color="primary"
                variant="contained"
                disabled={!isValid}
                onClick={handleDelete}
              >
                {t('deleteButton')}
              </Button>
            </Box>
          </Box>
        </form>
      </ClnModalContent>
    )
  }

  return (
    <Box className={classes.addHubManagerModal}>
      <ClnModal
        content={getContent()}
        onClose={onClose}
        show={show}
        maxWidth="440px"
        title="Delete as duplicate"
      />
    </Box>
  )
}
