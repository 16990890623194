/* global atob, File */

import { saveAs } from 'file-saver'

export function dataUriToFile(dataUri, fileName) {
  const byteString = atob(dataUri.split(',')[1])

  // separate out the mime component
  const mimeType = dataUri.split(',')[0].split(':')[1].split(';')[0]

  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new File([ab], fileName, { type: mimeType, lastModified: Date.now() })
}

export async function saveExportFile(result) {
  if (result) {
    const arrayBuffer = await result.arrayBuffer()
    const fileName = result.headers
      .get('content-disposition')
      .split('filename=')[1]

    const blob = new Blob([new Uint8Array(arrayBuffer)], {
      type: result.headers.get('content-type')
    })

    saveAs(blob, fileName)
  }
}
