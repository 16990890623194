import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FunctionComponentElement, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGeneralStyles } from '../../../common/styles/general'
import { Theme } from '../../../common/theme'
import { ClnModal, ClnModalContent } from '../../../components/modal'
import { ClnModalContentProps } from '../../../components/modal/ClnModalContent'
import { apiFetchWithDispatch } from '../../../lib/fetch'
import { useForm } from '../../../lib/form'
import { useStateValue } from '../../../state'
import { HubEventDto } from '../../../services/hub-events'

interface DeleteHubEventModalProps {
  hubEvent: HubEventDto | null
  show: boolean
  onClose: () => any
  onError: () => any
}

const useStyles = makeStyles<Theme>(() => ({
  deleteHubEventModal: {
    //
  },
  deleteEventWarning: {
    color: 'red'
  }
}))

const formSchema = {
  comment: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  }
}

export function DeleteHubEventModal({
  hubEvent,
  show,
  onError,
  onClose
}: DeleteHubEventModalProps): FunctionComponentElement<DeleteHubEventModalProps> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()

  const [, dispatch] = useStateValue()
  const { t } = useTranslation()

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const {
    formState: { values, errors, isValid },
    handleChange,
    hasError
  } = useForm(formSchema, {
    comment: ''
  })

  const handleDelete = async (): Promise<void> => {
    setIsSaving(true)

    const response = await apiFetchWithDispatch(
      dispatch,
      `/api/admin/hubEvents/${hubEvent?.uuid}/remove`,
      {
        method: 'POST',
        body: JSON.stringify(values)
      }
    )

    setIsSaving(false)

    if (response === null) {
      onError()
    } else {
      onClose()
    }
  }

  const getContent = (): ReactElement<
    ClnModalContentProps,
    typeof ClnModalContent
  > => {
    if (isSaving) {
      return (
        <ClnModalContent>
          <Box className={generalClasses.progressWrapper}>
            <CircularProgress />
          </Box>
        </ClnModalContent>
      )
    }

    return (
      <ClnModalContent>
        <form autoComplete="off" noValidate>
          <Box mb={3}>
            <TextField
              name="comment"
              type="text"
              label="Please specify a reason for the deletion"
              fullWidth={true}
              required={true}
              value={values.comment}
              onChange={handleChange}
              error={hasError('comment')}
              helperText={
                hasError('comment') ? t(errors.comment?.[0] ?? '') : ''
              }
            />
          </Box>
          <Typography variant="body1" className={classes.deleteEventWarning}>
            This action cannot be reversed. Please make sure this action is
            required before you submit it.
          </Typography>

          <Box display="flex" justifyContent="flex-end" mt={8}>
            <Button
              size="large"
              color="default"
              variant="outlined"
              onClick={onClose}
            >
              {t('cancelButton')}
            </Button>

            <Box ml={1}>
              <Button
                size="large"
                color="primary"
                variant="contained"
                disabled={!isValid || isSaving}
                onClick={handleDelete}
              >
                {t('deleteButton')}
              </Button>
            </Box>
          </Box>
        </form>
      </ClnModalContent>
    )
  }

  return (
    <Box className={classes.deleteHubEventModal}>
      <ClnModal
        content={getContent()}
        onClose={onClose}
        show={show}
        maxWidth="440px"
        title="Delete Hub Event"
      />
    </Box>
  )
}
