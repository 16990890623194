import React, { ReactElement } from 'react'

import { FileUploadDto } from '../fileupload/file-upload.types'
import { Feature } from '../feature'

export enum CompanyCategory {
  HUB = 'HUB',
  BUYER = 'BUYER'
}

export enum CompanyType {
  GENERATOR = 'GENERATOR',
  PICKUP_POINT = 'PICKUP_POINT',
  COLLECTION_CENTER = 'COLLECTION_CENTER',
  RECYCLER = 'RECYCLER',
  CARRIER = 'CARRIER',
  MATERIAL_HANDLING_CENTER = 'MATERIAL_HANDLING_CENTER',
  CO_PROCESSING_FACILITY = 'CO_PROCESSING_FACILITY',
  ORGANIZATION = 'ORGANIZATION',

  DEPRECATED = 'DEPRECATED'
}

export enum CompanyState {
  DEMO = 'DEMO',
  TRIAL = 'TRIAL',
  ACTIVE = 'ACTIVE',
  LOST = 'LOST',
  CHURNED = 'CHURNED',
  ARCHIVED = 'ARCHIVED',
  PAUSED = 'PAUSED'
}

export enum DataExportReportFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export interface MinimalCompanyDto {
  uuid: string
  name: string
  type: CompanyType
  category: CompanyCategory
  state: CompanyState

  street: string
  zipCode: string
  city: string
  region: string
  country: string
  address: string
}

export interface CompanyDto {
  uuid: string
  name: string
  type: CompanyType
  category: CompanyCategory
  state: CompanyState

  street: string
  zipCode: string
  city: string
  region: string
  country: string
  address: string

  registrationId: string
  registrationOffice: string

  certified: boolean
  certificate: FileUploadDto
}

export interface CompanyProjectDto {
  uuid: string
  externalId: string
  isCleanHub: boolean
}

export const HubCompanyTypes = Object.freeze({
  PICKUP_POINT: 'PICKUP_POINT',
  COLLECTION_CENTER: 'COLLECTION_CENTER',
  RECYCLER: 'RECYCLER',
  CARRIER: 'CARRIER',
  MATERIAL_HANDLING_CENTER: 'MATERIAL_HANDLING_CENTER',
  CO_PROCESSING_FACILITY: 'CO_PROCESSING_FACILITY',
  ORGANIZATION: 'ORGANIZATION'
})

export const getHubCompanyTypeOptions = (): ReactElement => {
  return (
    <>
      <option key="" value="" />

      {Object.keys(HubCompanyTypes).map((type) => (
        <option key={type} value={type}>
          {type}
        </option>
      ))}
    </>
  )
}

export const getHubCompanyFeatureOptions = (): ReactElement => {
  return (
    <>
      <option key="" value="" />

      {Object.keys(Feature).map((feature) => (
        <option key={feature} value={feature}>
          {feature}
        </option>
      ))}
    </>
  )
}

export const getActiveAndPausedCompanyStateOptions = (): ReactElement => {
  return (
    <>
      <option key="" value="" />

      {[CompanyState.ACTIVE, CompanyState.PAUSED].map((state) => (
        <option key={state} value={state}>
          {state}
        </option>
      ))}
    </>
  )
}

export const getCompany = (): MinimalCompanyDto | null => {
  const userIdentity = window.localStorage.getItem('userIdentity')
    ? JSON.parse(window.localStorage.getItem('userIdentity') ?? '')
    : null

  return userIdentity ? userIdentity.company : null
}

export const getCompanyType = (): CompanyType | null => {
  const company = getCompany()

  return company ? company.type : null
}
