import {
  Box,
  CircularProgress,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Button
} from '@material-ui/core'
import React, { FunctionComponentElement, useState } from 'react'
import { match } from 'react-router-dom'
import { LocalConvenienceStore as StoreIconIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import { AdminPortalLayout } from '../../layouts/AdminPortalLayout'
import { PortalContent } from '../../layouts/portal/PortalContent'
import { Paper, Portlet, PortletContent } from '../../components'
import { Theme } from '../../common/theme'
import { AddProjectModal } from './HubList/components/AddProjectModal'
import { useFetchState } from '../../lib/fetch'
import { CompanyProjectDto } from '../../services/company'
import { NewLogo } from '../../components/NewLogo'

interface AdminManageProjectsProps {
  match: match<{ hubCompanyUuid: string }>
}

const useStyles = makeStyles<Theme>((theme) => ({
  chip: {
    backgroundColor: theme.palette.background.default
  },
  typeTableCell: {
    padding: theme.spacing(1.5, 1),
    width: 100,
    textAlign: 'center',

    '&:first-of-type': {
      paddingLeft: 0
    },

    '&:last-of-type': {
      paddingRight: 0
    }
  }
}))

function AdminManageProjects({
  match
}: AdminManageProjectsProps): FunctionComponentElement<AdminManageProjectsProps> {
  const {
    params: { hubCompanyUuid }
  } = match

  const classes = useStyles()

  const [
    companyProjects,
    isCompanyProjectsLoading,
    refreshProjects
  ] = useFetchState<CompanyProjectDto[]>(
    `/api/admin/companies/${hubCompanyUuid}/projects`
  )

  const [showAddProjectModal, setShowAddProjectModal] = useState(false)

  function handleClickAddProject() {
    setShowAddProjectModal(true)
  }

  function handleCloseAddProjectModal() {
    setShowAddProjectModal(false)

    refreshProjects()
  }

  return (
    <AdminPortalLayout title="Manage Projects">
      <PortalContent
        titleActions={
          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={() => handleClickAddProject()}
          >
            Add project
          </Button>
        }
      >
        <Box>
          <Paper elevation={5} outlined={false}>
            <Box p={3}>
              {isCompanyProjectsLoading || !companyProjects ? (
                <Box display="flex" justifyContent="center" p={5}>
                  <CircularProgress />
                </Box>
              ) : (
                <Portlet>
                  <PortletContent noPadding={true}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.typeTableCell}>
                            Type
                          </TableCell>
                          <TableCell>ID</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {companyProjects.map((project) => (
                          <TableRow key={project.uuid}>
                            <TableCell className={classes.typeTableCell}>
                              <Chip
                                className={classes.chip}
                                icon={
                                  project.isCleanHub ? (
                                    <Tooltip title="CleanHub Project">
                                      <NewLogo variant="wave" />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="External Project">
                                      <StoreIconIcon />
                                    </Tooltip>
                                  )
                                }
                                size="small"
                              />
                            </TableCell>
                            <TableCell>{project.externalId}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </PortletContent>
                </Portlet>
              )}
            </Box>
          </Paper>
        </Box>
      </PortalContent>

      {showAddProjectModal && (
        <AddProjectModal
          hubUuid={hubCompanyUuid}
          show={showAddProjectModal}
          onClose={handleCloseAddProjectModal}
        />
      )}
    </AdminPortalLayout>
  )
}

export default AdminManageProjects
