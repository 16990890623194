import {
  Box,
  CircularProgress,
  FormControl,
  RadioGroup,
  Typography
} from '@material-ui/core'
import { LocalShipping as LocalShippingIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { FunctionComponentElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { debounce } from 'underscore'

import celebratingSvg from '../../../../assets/images/empty-state/celebrating.svg'
import { Theme } from '../../../../common/theme'
import { EmptyState } from '../../../../components/EmptyState'
import FlowActionButton from '../../../../components/FlowActionButton'
import {
  InputRadioMaterialProductType,
  InputRadioMaterialProductTypeValue
} from '../../../../components/InputRadioMaterialProductType'
import { MaterialTypeBreakdown } from '../../../../components/MaterialTypeBreakdown'
import { convertKilogramsToOilLiters } from '../../../../helpers/quantity.helpers'
import { useForm } from '../../../../lib/form'
import { MassBalanceApiService } from '../../../../services/mass-balance/mass-balance-api.service'
import {
  getQuantityUnitByProductType,
  ProductType
} from '../../../../services/producttype/product-type.types'
import { useStateValue } from '../../../../state'
import { Dispatch, State } from '../../../../state/state.types'

interface FormValue {
  materialProduct: InputRadioMaterialProductTypeValue | null
}

const useStyles = makeStyles<Theme>((theme) => ({
  massBalanceClearanceOverview: {
    margin: theme.spacing(-3, 0, 1)
  },
  radioControl: {
    display: 'block'
  }
}))

const formSchema = {
  materialProduct: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  }
}

export function MassBalanceClearanceOverview(): FunctionComponentElement<void> {
  const classes = useStyles()

  const [{ overviewPeriod }]: [State, Dispatch] = useStateValue()
  const { t } = useTranslation()

  const {
    formState: { isValid, values },
    setValue
  } = useForm<FormValue>(formSchema, {
    materialProduct: null
  })

  const [
    summary,
    isSummaryLoading,
    refetchSummary
  ] = MassBalanceApiService.getClearanceSummary()

  const debouncedRefetchSummary = useCallback<typeof refetchSummary>(
    debounce(refetchSummary, 500),
    [refetchSummary]
  )

  function getClearMaterialStockQueryParams(): string {
    const params = new URLSearchParams({
      materialTypes: values.materialProduct?.materialType ?? '',
      productType: values.materialProduct?.productType ?? ''
    })

    if (!params.get('productType')) {
      params.delete('productType')
    }

    return params.toString()
  }

  useEffect(() => {
    if (overviewPeriod.endDate && overviewPeriod.startDate) {
      debouncedRefetchSummary({
        queryParams: {
          endDate: overviewPeriod.endDate.toISOString(),
          startDate: overviewPeriod.startDate.toISOString()
        }
      })
    }
  }, [overviewPeriod.endDate, overviewPeriod.startDate])

  if (isSummaryLoading || !summary) {
    return (
      <Box mb={3} textAlign="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.massBalanceClearanceOverview}>
      <Box mx={-1} mb={4}>
        <MaterialTypeBreakdown
          outputTypeBreakdown={summary.clearedTypeBreakdown}
          quantityUnit={summary.quantityUnit}
          totalText={t('totalCleared')}
        />
      </Box>

      {summary.stockedTypeBreakdown.length > 0 ? (
        <>
          <Typography variant="subtitle1">
            {t('selectMaterialTypesForClearance')}:
          </Typography>

          <form autoComplete="off" noValidate={true}>
            <FormControl className={classes.radioControl} component="fieldset">
              <Box mt={2}>
                <RadioGroup
                  name="materialProduct"
                  value={values.materialProduct}
                  onChange={(_, value) => {
                    setValue('materialProduct', JSON.parse(value))
                  }}
                >
                  {summary.stockedTypeBreakdown.map(
                    ({ materialType, productType, weight }) => (
                      <InputRadioMaterialProductType
                        key={`${materialType}_${productType}`}
                        isSelected={
                          materialType ===
                            values.materialProduct?.materialType &&
                          productType === values.materialProduct?.productType
                        }
                        materialType={materialType}
                        productType={productType}
                        quantity={
                          productType === ProductType.OIL
                            ? convertKilogramsToOilLiters(weight)
                            : weight
                        }
                        quantityUnit={getQuantityUnitByProductType(
                          productType,
                          summary?.quantityUnit
                        )}
                      />
                    )
                  )}
                </RadioGroup>
              </Box>
            </FormControl>
          </form>

          <FlowActionButton
            color="secondary"
            disabled={!isValid}
            icon={LocalShippingIcon}
            href={`/clear-material-stock?${getClearMaterialStockQueryParams()}`}
          >
            {t('clearMaterialStockButton')}
          </FlowActionButton>
        </>
      ) : (
        <Box mb={3}>
          <EmptyState
            image={celebratingSvg}
            heading={t('allDone')}
            message={t('noMaterialForClearance')}
          />
        </Box>
      )}
    </Box>
  )
}
