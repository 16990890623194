import { getCurrentUser } from '../user'

export interface FeatureOption {
  label: string
  value: Feature
}

export enum Feature {
  MATERIAL_COLLECTION = 'MATERIAL_COLLECTION',
  TRUCK_LOAD_REGISTRATION = 'TRUCK_LOAD_REGISTRATION',
  WASTE_BAG_REGISTRATION = 'WASTE_BAG_REGISTRATION',
  CONTAINER_REGISTRATION = 'CONTAINER_REGISTRATION',
  MATERIAL_REGISTRATION = 'MATERIAL_REGISTRATION',
  MATERIAL_SEGREGATION = 'MATERIAL_SEGREGATION',
  MATERIAL_CLEARANCE = 'MATERIAL_CLEARANCE',
  MATERIAL_DISPATCH = 'MATERIAL_DISPATCH',
  MATERIAL_PROCESSING = 'MATERIAL_PROCESSING',
  MATERIAL_CERTIFICATION = 'MATERIAL_CERTIFICATION',
  MASS_BALANCE_TRACKING = 'MASS_BALANCE_TRACKING',
  EVENT_REVIEW = 'EVENT_REVIEW',
  IMAGE_IMMEDIATE_REVIEW = 'IMAGE_IMMEDIATE_REVIEW',
  COLLECTOR_WEEKLY_REPORT = 'COLLECTOR_WEEKLY_REPORT',
  COLLECTION_CENTER_WEEKLY_DATA_EXPORT = 'COLLECTION_CENTER_WEEKLY_DATA_EXPORT',
  WASTE_BAG_REVIEW = 'WASTE_BAG_REVIEW',
  HUB_OWNER_NOTIFICATIONS = 'HUB_OWNER_NOTIFICATIONS',
  COLLECTOR_HOUSEHOLD_FEE_PAYMENT = 'COLLECTOR_HOUSEHOLD_FEE_PAYMENT',
  COLLECTOR_HOUSEHOLD_NO_WASTE = 'COLLECTOR_HOUSEHOLD_NO_WASTE',
  COLLECTOR_HOUSEHOLD_VACANT = 'COLLECTOR_HOUSEHOLD_VACANT'
}

export const getFeatureOptions = (): FeatureOption[] => {
  return Object.keys(Feature).map((feature) => ({
    label: feature,
    value: Feature[feature]
  }))
}

export function hasFeature(feature: Feature): boolean {
  const featureSet = new Set<Feature>(getCurrentUser()?.features)

  return featureSet.has(feature)
}

export const hasOnlyOneRegistrationEnabled = (feature?: Feature): boolean => {
  const registrationsCount =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    hasFeature(Feature.WASTE_BAG_REGISTRATION) +
    hasFeature(Feature.TRUCK_LOAD_REGISTRATION) +
    hasFeature(Feature.CONTAINER_REGISTRATION) +
    hasFeature(Feature.MATERIAL_REGISTRATION)

  if (feature) {
    return registrationsCount === 1 && hasFeature(feature)
  }

  return registrationsCount === 1
}
