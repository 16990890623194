import { MinimalCompanyDto } from '../company'
import { MaterialType } from '../materialtype/material-type.types'
import { QuantityUnit } from '../quantityunit'

export enum MaterialRegistrationUnit {
  BAG = 'BAG',
  BALE = 'BALE',
  CONTAINER = 'CONTAINER',
  TRUCK_LOAD = 'TRUCK_LOAD'
}

export interface CollectedMaterialsSummaryDto {
  collectionCenter: MinimalCompanyDto
  totalStock: number
  totalStockUnit: QuantityUnit

  materialTypeBreakdown: Record<MaterialType, number>

  formattedTotalStock: string
}
