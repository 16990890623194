export default {
  root: {
    padding: '0 8px 0 8px',
    '&$expanded': {
      minHeight: 40
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  }
}
