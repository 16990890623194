import { Box, makeStyles, TextField } from '@material-ui/core'
import React, { FunctionComponentElement } from 'react'

import { Theme } from '../../common/theme'

import {
  EntityFilterField,
  EntityFilterOperator,
  EntityFilterType
} from './entity-filters.types'
import { EntityFilterOperatorSelector } from './EntityFilterOperatorSelector'

export interface EntityFilterTextConfig {
  availableOperators?: EntityFilterTextConfig['operator'][]
  field: EntityFilterField
  label: string
  operator: EntityFilterOperator.CONTAINS | EntityFilterOperator.EQUALS
  type: EntityFilterType.TEXT
  value: string
}

export interface EntityFilterTextProps {
  config: EntityFilterTextConfig

  onChange: (
    value: EntityFilterTextConfig['value'],
    operator: EntityFilterOperator
  ) => void
}

const useStyles = makeStyles<Theme>(() => ({
  entityFilterSelect: {
    //
  }
}))

export function EntityFilterText({
  config,
  onChange
}: EntityFilterTextProps): FunctionComponentElement<EntityFilterTextProps> {
  const classes = useStyles()

  return (
    <Box className={classes.entityFilterText}>
      <TextField
        fullWidth={true}
        label={config.label}
        size="small"
        variant="outlined"
        defaultValue={config.value}
        InputProps={{
          startAdornment: (
            <EntityFilterOperatorSelector
              availableOperators={config.availableOperators}
              operator={config.operator}
              onChange={(operator) => onChange(config.value, operator)}
            />
          )
        }}
        onChange={(event) => onChange(event.target.value, config.operator)}
      />
    </Box>
  )
}
