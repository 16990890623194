import {
  Box,
  CircularProgress,
  FormControl,
  RadioGroup,
  Typography
} from '@material-ui/core'
import { RestoreFromTrash as RestoreFromTrashIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, {
  FunctionComponentElement,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { debounce } from 'underscore'

import personalSettingsSvg from '../../../../assets/images/empty-state/personal-settings.svg'
import { Theme } from '../../../../common/theme'
import { EmptyState } from '../../../../components/EmptyState'
import FlowActionButton from '../../../../components/FlowActionButton'
import {
  InputRadioMaterialProductType,
  InputRadioMaterialProductTypeValue
} from '../../../../components/InputRadioMaterialProductType'
import { MaterialTypeBreakdown } from '../../../../components/MaterialTypeBreakdown'
import { apiFetchWithDispatch, useFetchState } from '../../../../lib/fetch'
import { useForm } from '../../../../lib/form'
import {
  MassBalanceAccount,
  MassBalanceOverviewDto
} from '../../../../services/mass-balance'
import { getCurrentUser } from '../../../../services/user'
import { useStateValue } from '../../../../state'
import { Dispatch, State } from '../../../../state/state.types'

interface MassBalanceGradingOverviewProps {
  onStartGrading: () => any
}

interface FormValue {
  materialProduct: InputRadioMaterialProductTypeValue | null
}

const useStyles = makeStyles<Theme>((theme) => ({
  massBalanceGradingOverview: {
    margin: theme.spacing(-3, 0, 1)
  },
  radioControl: {
    display: 'block'
  }
}))

const formSchema = {
  materialProduct: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  }
}

export function MassBalanceGradingOverview({
  onStartGrading
}: MassBalanceGradingOverviewProps): FunctionComponentElement<MassBalanceGradingOverviewProps> {
  const segregationInputMaterialTypes =
    getCurrentUser()?.segregationInputMaterialTypes ?? []

  const classes = useStyles()

  const [{ overviewPeriod }, dispatch]: [State, Dispatch] = useStateValue()
  const { t } = useTranslation()

  const {
    formState: { isValid, values },
    setValue
  } = useForm<FormValue>(formSchema, {
    materialProduct: null
  })

  const [isStarting, setIsStarting] = useState<boolean>()

  const [
    overview,
    isOverviewLoading,
    refetchOverview
  ] = useFetchState<MassBalanceOverviewDto>(
    '/api/massBalance/overview',
    null,
    {},
    { initialFetch: false }
  )

  const debouncedRefetchOverview = useCallback<typeof refetchOverview>(
    debounce(refetchOverview, 500),
    [refetchOverview]
  )

  async function handleStartGrading(): Promise<void> {
    setIsStarting(true)

    const { materialProduct } = values

    await apiFetchWithDispatch(dispatch, '/api/massBalance/grading/start', {
      method: 'POST',
      body: JSON.stringify({
        materialType: materialProduct?.materialType
      })
    })

    setIsStarting(false)

    onStartGrading()
  }

  useEffect(() => {
    if (overviewPeriod.endDate && overviewPeriod.startDate) {
      debouncedRefetchOverview({
        queryParams: {
          account: MassBalanceAccount.SEGREGATION,
          endDate: overviewPeriod.endDate.toISOString(),
          startDate: overviewPeriod.startDate.toISOString()
        }
      })
    }
  }, [overviewPeriod.endDate, overviewPeriod.startDate])

  if (isOverviewLoading || !overview || isStarting) {
    return (
      <Box mb={3} textAlign="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.massBalanceGradingOverview}>
      <Box mx={-1} mb={4}>
        <MaterialTypeBreakdown
          outputTypeBreakdown={overview.outputTypeBreakdown}
          quantityUnit={overview.quantityUnit}
          total={overview.total ?? 0}
          totalText={t('totalGraded')}
        />
      </Box>

      {segregationInputMaterialTypes.length > 0 ? (
        <>
          <Typography variant="subtitle1">
            {t('selectMaterialTypeForGrading')}:
          </Typography>

          <form autoComplete="off" noValidate={true}>
            <FormControl className={classes.radioControl} component="fieldset">
              <Box mt={2}>
                <RadioGroup
                  name="materialProduct"
                  value={values.materialProduct}
                  onChange={(_, value) => {
                    setValue('materialProduct', JSON.parse(value))
                  }}
                >
                  {segregationInputMaterialTypes.map((materialType) => (
                    <InputRadioMaterialProductType
                      key={materialType}
                      isSelected={
                        materialType === values.materialProduct?.materialType
                      }
                      materialType={materialType}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </FormControl>
          </form>

          <FlowActionButton
            color="secondary"
            disabled={!isValid}
            icon={RestoreFromTrashIcon}
            onClick={handleStartGrading}
          >
            {t('gradeMaterialButton')}
          </FlowActionButton>
        </>
      ) : (
        <Box mb={3}>
          <EmptyState
            image={personalSettingsSvg}
            heading={t('noMaterialTypes')}
            message={t('noMaterialTypesGradingDescription')}
          />
        </Box>
      )}
    </Box>
  )
}
