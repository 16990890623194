export enum ReleaseStage {
  DEV = 'dev',
  DEMO = 'demo',
  PROD = 'prod'
}

export function getReleaseStage(): ReleaseStage {
  const { hostname } = window.location

  if (hostname === 'app.cleanhub.io' || hostname === 'app-sa.cleanhub.io') {
    return ReleaseStage.PROD
  }

  if (hostname === 'demo.cleanhub.io') {
    return ReleaseStage.DEMO
  }

  return ReleaseStage.DEV
}
