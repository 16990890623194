import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { FunctionComponentElement } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Receipt as ReceiptIcon } from '@material-ui/icons'
import { Theme } from '../../../../common/theme'

import { SummaryLine } from 'components'
import LinkButton from 'components/LinkButton'
import { MaterialClearanceDto } from 'services/material-clearance/material-clearance-types'

interface Props {
  materialClearance: MaterialClearanceDto
}

const useStyles = makeStyles((theme: Theme) => ({
  ongoingClearance: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  title: {
    marginLeft: theme.spacing(),
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.danger.main,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '4rem',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: '4rem'
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '2rem',
    height: '2rem',
    width: '2rem'
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  addWeighSlipButton: {
    width: '100%',
    fontSize: 18,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}))

function OngoingClearance({
  materialClearance
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Box className={classes.ongoingClearance}>
      <Typography className={classes.title} variant="h3">
        {t('ongoingClearanceSummary')}
      </Typography>
      <div className={classes.content}>
        <SummaryLine
          title={t('loadedAt')}
          value={`${moment(materialClearance.createdAt).format(
            'DD/MM/YYYY HH:mm'
          )}`}
        />
        <SummaryLine
          title={t('licensePlate')}
          value={materialClearance.transporterLicensePlate}
        />

        <LinkButton
          size="large"
          variant="contained"
          className={classes.addWeighSlipButton}
          href={`/weigh-collection-center-material-clearance/${materialClearance.uuid}`}
          startIcon={
            <ReceiptIcon
              style={{
                height: 50,
                width: 50,
                fontWeight: 30
              }}
            />
          }
        >
          {t('addWeighSlipButton')}
        </LinkButton>
      </div>
    </Box>
  )
}

export default OngoingClearance
