import { createContext } from 'react'

export interface PortalLayoutContextInterface {
  currentUser: any | null
  title?: string
}

export const PortalLayoutContext = createContext<PortalLayoutContextInterface>({
  currentUser: null
})
