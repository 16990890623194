import React from 'react'

import {
  Button,
  makeStyles
} from '@material-ui/core'

import { useTranslation } from 'react-i18next'

// Material icons
import {
  PhotoCamera as PhotoCameraIcon,
  Done as DoneIcon
} from '@material-ui/icons'

function FullScreenImagePreview (props) {
  const { dataUri, onRetakePhoto, onConfirmPhoto } = props

  const useStyles = makeStyles(theme => ({
    root: {
      height: '100vh',
      width: '100%',
      backgroundImage: `url(${dataUri})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    actionButtons: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      height: 100,
      width: '100%',
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around'
    },
    actionButton: {
      fontSize: 14,
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.white}`,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5)
    },
    confirmButton: {
      fontSize: 14,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),

      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        border: `1px solid ${theme.palette.primary.dark}`
      }
    }
  }))

  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <div className={classes.actionButtons}>
        <Button
          className={classes.actionButton}
          variant='contained'
          onClick={onRetakePhoto}
          startIcon={<PhotoCameraIcon />}
        >
          {t('retakeButton')}
        </Button>
        <Button
          className={classes.confirmButton}
          variant='contained'
          onClick={onConfirmPhoto}
          startIcon={<DoneIcon />}
        >
          {t('confirmButton')}
        </Button>
      </div>
    </div>
  )
}

export default FullScreenImagePreview
