import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useMemo } from 'react'

import { AUTH0_REGIONS_MAP, USER_METADATA_KEY } from '../lib/auth0'
import { getReleaseStage } from '../services/environment'
import { AppRegion } from '../types/app-region.enum'
import { Auth0RegionConfig } from '../types/auth0-region-config.interface'
import { Auth0User } from '../types/auth0-user.interface'

export interface UseAuth0Session {
  auth0User: Auth0User | undefined
  isAuth0Loading: boolean
  isCorrectRegionOrigin: boolean | null

  getAuth0RegionConfig: () => Auth0RegionConfig
  redirectToRegionOrigin: () => void
}

export function useAuth0Session(): UseAuth0Session {
  const { isLoading: isAuth0Loading, user: auth0User } = useAuth0<Auth0User>()

  const getAuth0RegionConfig = useCallback((): Auth0RegionConfig => {
    const releaseStage = getReleaseStage()
    const userMetadata = auth0User?.[USER_METADATA_KEY] ?? {
      region: AppRegion.DEFAULT
    }
    const region = userMetadata.region ?? AppRegion.DEFAULT

    return (
      AUTH0_REGIONS_MAP[releaseStage][region] ??
      AUTH0_REGIONS_MAP[releaseStage][AppRegion.DEFAULT]
    )
  }, [auth0User])

  const redirectToRegionOrigin = useCallback(() => {
    const regionConfig = getAuth0RegionConfig()

    window.location.href = regionConfig.origin
  }, [getAuth0RegionConfig])

  const isCorrectRegionOrigin = useMemo(() => {
    if (!auth0User) {
      return null
    }

    return getAuth0RegionConfig().origin === window.location.origin
  }, [auth0User, getAuth0RegionConfig])

  return {
    auth0User,
    isAuth0Loading,
    isCorrectRegionOrigin,

    getAuth0RegionConfig,
    redirectToRegionOrigin
  }
}
