import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { compose } from 'recompose'

// Material components
import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Typography,
  withStyles,
  RadioGroup
} from '@material-ui/core'

// Shared layouts
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'

// Component styles
import styles from './styles'
import { useTheme } from '@material-ui/styles'

import { useStateValue } from 'state'
import schema from './schema'

import { useCamera } from 'lib/camera'

import { useTranslation } from 'react-i18next'

import { apiFetchWithDispatch } from 'lib/fetch'
import { useForm } from 'lib/form'

import { ImagePreview, FullScreenCamera } from 'components'

import { ReactComponent as BagIcon } from 'icons/bag-black.svg'

import { QuantityUnits } from 'services/quantityunit'
import LinkButton from '../../../components/LinkButton'
import { getCurrentUser } from '../../../services/user'
import { getSegregationOutputMaterialTypeOptions } from '../../../services/materialtype'

function RegisterSegregationOutputMaterialBag({ classes, history, match }) {
  const { segregationUnitUuid } = match.params
  const { segregationOutputMaterialTypes } = getCurrentUser()
  const hasMultipleOutputMaterialTypes =
    segregationOutputMaterialTypes.length > 1

  const queryParams = queryString.parse(location.search)

  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  const theme = useTheme()

  const [, dispatch] = useStateValue()

  const {
    pictureDataUri,
    pictureFileUpload,
    showCamera,
    pictureConfirmed,
    handleTakePhotoFinished,
    handleFileUploadFinished,
    handleConfirmPicture,
    handleCameraError
  } = useCamera()

  const {
    formState: { values, errors, isValid },
    handleChange,
    resetForm,
    hasError
  } = useForm(schema, {
    weight: '',
    materialType: hasMultipleOutputMaterialTypes
      ? ''
      : segregationOutputMaterialTypes[0],
    quantityUnit: QuantityUnits.KG
  })

  useEffect(() => {
    if (!showCamera) {
      history.push('/hub-overview')
    }
  }, [showCamera])

  async function handleSave() {
    setIsLoading(true)

    const { weight, materialType } = values

    const response = await apiFetchWithDispatch(
      dispatch,
      `/api/segregationUnits/${segregationUnitUuid}/outputMaterialBags`,
      {
        method: 'POST',
        body: JSON.stringify({
          weight,
          materialType,
          pictureFileUploadUuid: pictureFileUpload
            ? pictureFileUpload.uuid
            : null,
          trackedItemUuid: queryParams?.trackedItemUuid
        })
      }
    )

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/finish-grading')
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout
        title={t('registerOutputMaterialBagTitle', {
          materialType: hasMultipleOutputMaterialTypes
            ? undefined
            : t(`materialType_${segregationOutputMaterialTypes[0]}`),
          context: hasMultipleOutputMaterialTypes
            ? 'multipleOutputMaterialTypes'
            : undefined
        })}
      >
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  return (
    <div>
      {pictureConfirmed ? (
        <SecurePortalLayout
          title={t('registerOutputMaterialBagTitle', {
            materialType: hasMultipleOutputMaterialTypes
              ? undefined
              : t(`materialType_${segregationOutputMaterialTypes[0]}`),
            context: hasMultipleOutputMaterialTypes
              ? 'multipleOutputMaterialTypes'
              : undefined
          })}
        >
          <PortalContent>
            <div className={classes.root}>
              <div className={classes.content}>
                <form autoComplete="off" noValidate>
                  <Paper className={classes.formSection}>
                    <Typography className={classes.title} variant="h3">
                      {t('registerOutputMaterialBagTitle', {
                        materialType: hasMultipleOutputMaterialTypes
                          ? undefined
                          : t(
                              `materialType_${segregationOutputMaterialTypes[0]}`
                            ),
                        context: hasMultipleOutputMaterialTypes
                          ? 'multipleOutputMaterialTypes'
                          : undefined
                      })}
                    </Typography>

                    <FormControl component="fieldset">
                      <FormLabel component="legend" required>
                        {t('outputMaterialBagPicture')}
                      </FormLabel>
                      <div className={classes.imagePreview}>
                        <ImagePreview
                          dataUri={pictureDataUri}
                          isPictureUploading={!pictureFileUpload}
                        />
                      </div>
                    </FormControl>

                    <div className={classes.field}>
                      <div className={classes.field}>
                        <TextField
                          name="weight"
                          autoFocus
                          className={classes.weightField}
                          type="number"
                          label={`${t('addWeight')}`}
                          margin="dense"
                          required
                          value={values.weight}
                          onChange={handleChange}
                          error={hasError('weight')}
                          helperText={
                            hasError('weight') ? t(errors.weight[0]) : ''
                          }
                        />
                        <TextField
                          name="quantityUnit"
                          className={classes.quantityUnitField}
                          disabled
                          label=" "
                          margin="dense"
                          value={values.quantityUnit}
                          InputProps={{
                            style: {
                              color: theme.palette.text.primary
                            }
                          }}
                        />
                      </div>
                    </div>

                    {hasMultipleOutputMaterialTypes && (
                      <div className={classes.radioGroupField}>
                        <FormControl component="fieldset">
                          <FormLabel
                            component="legend"
                            required
                            className={classes.radioGroupLabel}
                          >
                            {t('selectMaterial')}
                          </FormLabel>
                          <RadioGroup
                            name="materialType"
                            value={values.materialType}
                            onChange={handleChange}
                            error={hasError('materialType')}
                            helperText={
                              hasError('materialType')
                                ? t(errors.materialType[0])
                                : ''
                            }
                          >
                            {getSegregationOutputMaterialTypeOptions(t)}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}

                    <div className={classes.actionButtons}>
                      <LinkButton
                        size="large"
                        className={classes.cancelButton}
                        color="default"
                        variant="outlined"
                        href="/finish-grading"
                      >
                        {t('cancelButton')}
                      </LinkButton>
                      <Button
                        size="large"
                        variant="contained"
                        className={classes.registerOutputMaterialBagButton}
                        disabled={!isValid || isLoading || !pictureFileUpload}
                        onClick={handleSave}
                      >
                        {t('registerOutputButton')}
                      </Button>
                    </div>
                  </Paper>
                </form>
              </div>
            </div>
          </PortalContent>
        </SecurePortalLayout>
      ) : (
        showCamera && (
          <FullScreenCamera
            fileName={`registered-segregation-output-material-bag-${Date.now()}`}
            headerMessage={t('registerOutputMaterialBagPictureExplanation', {
              materialType: hasMultipleOutputMaterialTypes
                ? undefined
                : t(`materialType_${segregationOutputMaterialTypes[0]}`),
              context: hasMultipleOutputMaterialTypes
                ? 'multipleOutputMaterialTypes'
                : undefined
            })}
            shutterIcon={<BagIcon />}
            onTakePhotoFinished={handleTakePhotoFinished}
            onFileUploadFinished={handleFileUploadFinished}
            onConfirmPicture={handleConfirmPicture}
            onCameraError={handleCameraError}
          />
        )
      )}
    </div>
  )
}

export default compose(
  withRouter,
  withStyles(styles)
)(RegisterSegregationOutputMaterialBag)
