import {
  Box,
  Button,
  FormControl,
  FormLabel,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core'
import { Receipt as ReceiptIcon } from '@material-ui/icons'
import React, { FunctionComponentElement, useEffect, useState } from 'react'
import { match } from 'react-router-dom'
import { useGeneralStyles } from '../../common/styles/general'
import { useTranslation } from 'react-i18next'
import { useForm } from 'lib/form'

import { useStateValue } from 'state'
import { useCamera } from 'lib/camera'
import { QuantityUnits } from 'services/quantityunit'
import { PortalContent } from '../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../layouts/SecurePortalLayout'
import { apiFetchWithDispatch } from '../../lib/fetch'

import { useHistory } from 'react-router'
import FullScreenCamera from 'components/FullScreenCamera'
import { useFormStyles } from '../../common/styles/form'
import LinkButton from 'components/LinkButton'
import ImagePreview from 'components/ImagePreview'

interface Props {
  match: match<{ handlingCenterMaterialClearanceUuid: string }>
}

const useStyles = makeStyles(() => ({
  weighHandlingCenterMaterialClearance: {
    //
  }
}))

const schema = {
  weight: {
    presence: { allowEmpty: false, message: 'validationRequired' },
    length: { maximum: 10, message: 'validationExceedsMaxLength' }
  }
}

function WeighHandlingCenterMaterialClearance({
  match
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()
  const formClasses = useFormStyles()

  const {
    params: { handlingCenterMaterialClearanceUuid }
  } = match

  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()
  const theme = useTheme()
  const history = useHistory()

  const [, dispatch] = useStateValue()

  const weighSlipCamera = useCamera()

  const {
    formState: { values, isValid },
    getErrorMessage,
    handleChange,
    hasError,
    resetForm
  } = useForm(schema, {
    weight: '',
    quantityUnit: QuantityUnits.KG
  })

  useEffect(() => {
    if (!weighSlipCamera.showCamera) {
      history.push('/hub-overview')
    }
  }, [weighSlipCamera.showCamera])

  async function handleSave() {
    setIsLoading(true)

    const { weight } = values

    const response = await apiFetchWithDispatch(
      dispatch,
      `/api/handlingCenterMaterialClearances/${handlingCenterMaterialClearanceUuid}/weigh`,
      {
        method: 'POST',
        body: JSON.stringify({
          weight,
          weighSlipPictureFileUploadUuid: weighSlipCamera.pictureFileUpload
            ? weighSlipCamera.pictureFileUpload.uuid
            : null
        })
      }
    )

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout
        title={t('weighHandlingCenterMaterialClearanceTitle')}
      >
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!weighSlipCamera.pictureConfirmed) {
    return (
      <FullScreenCamera
        fileName={`weigh-handling-center-material-clearance-${Date.now()}`}
        headerMessage={t('weighRecyclerClearancePictureExplanation')}
        shutterIcon={<ReceiptIcon />}
        onTakePhotoFinished={weighSlipCamera.handleTakePhotoFinished}
        onFileUploadFinished={weighSlipCamera.handleFileUploadFinished}
        onConfirmPicture={weighSlipCamera.handleConfirmPicture}
        onCameraError={weighSlipCamera.handleCameraError}
      />
    )
  }

  return (
    <Box className={classes.weighHandlingCenterMaterialClearance}>
      <SecurePortalLayout
        title={t('weighHandlingCenterMaterialClearanceTitle')}
      >
        <PortalContent>
          <Box className={generalClasses.content}>
            <form autoComplete="off" noValidate>
              <Paper className={formClasses.formSection}>
                <Typography className={generalClasses.title} variant="h3">
                  {t('weighHandlingCenterMaterialClearanceTitle')}
                </Typography>

                <FormControl component="fieldset">
                  <FormLabel component="legend" required>
                    {t('weighSlipPicture')}
                  </FormLabel>
                  <div className={generalClasses.imagePreview}>
                    <ImagePreview
                      dataUri={weighSlipCamera.pictureDataUri}
                      isPictureUploading={!weighSlipCamera.pictureFileUpload}
                    />
                  </div>
                </FormControl>

                <div className={formClasses.field}>
                  <TextField
                    name="weight"
                    autoFocus
                    className={formClasses.weightField}
                    type="number"
                    label={`${t('addWeight')}`}
                    margin="dense"
                    required
                    value={values.weight}
                    onChange={handleChange}
                    error={hasError('weight')}
                    helperText={t(getErrorMessage('weight'))}
                  />
                  <TextField
                    name="quantityUnit"
                    className={formClasses.quantityUnitField}
                    disabled
                    label=" "
                    margin="dense"
                    value={values.quantityUnit}
                    InputProps={{
                      style: {
                        color: theme.palette.text.primary
                      }
                    }}
                  />
                </div>

                <div className={formClasses.actionButtons}>
                  <LinkButton
                    size="large"
                    className={formClasses.cancelButton}
                    color="default"
                    variant="outlined"
                    href="/hub-overview"
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size="large"
                    variant="contained"
                    className={generalClasses.primaryButton}
                    disabled={
                      !isValid ||
                      isLoading ||
                      !weighSlipCamera.pictureFileUpload
                    }
                    onClick={handleSave}
                  >
                    {t('addWeighSlipButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </Box>
        </PortalContent>
      </SecurePortalLayout>
    </Box>
  )
}

export default WeighHandlingCenterMaterialClearance
