import { useAuth0 } from '@auth0/auth0-react'
import { Box, CircularProgress } from '@material-ui/core'
import React, { FunctionComponentElement, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useFetchState } from '../../../../lib/fetch'
import { redirectToHomeScreen } from '../../../../services/signin'
import { IdentityDto } from '../../../../services/user'

export function AuthCallback(): FunctionComponentElement<void> {
  const { getAccessTokenSilently, isLoading, user } = useAuth0()
  const history = useHistory()
  const [, , refetchIdentity] = useFetchState<IdentityDto>(
    '/api/users/current',
    null,
    {},
    {
      initialFetch: false
    }
  )

  const redirectUser = useCallback(async () => {
    if (isLoading) {
      return
    }

    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('migration')) {
      urlParams.set('complete', 'true')

      window.location.href = `${
        window.location.origin
      }/auth/migrate?${urlParams.toString()}`
    } else if (user) {
      await getAccessTokenSilently()

      const identity = await refetchIdentity()
      if (identity) {
        window.localStorage.setItem('userIdentity', JSON.stringify(identity))
      }

      redirectToHomeScreen(history, user)
    } else {
      history.push('/sign-in')
    }
  }, [getAccessTokenSilently, history, isLoading, user])

  useEffect(() => {
    redirectUser()
  }, [redirectUser])

  return (
    <Box
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress />
    </Box>
  )
}
