import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles
} from '@material-ui/core'
import { Receipt as ReceiptIcon } from '@material-ui/icons'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useFetchState } from '../../../../../lib/fetch'
import { useForm } from '../../../../../lib/form'
import { QuantityUnits } from '../../../../../services/quantityunit'

import styles from './styles'

function CoProcessingFacilityAddCertificateForm({ classes }) {
  const [coProcessingMaterials, isLoading] = useFetchState(
    '/api/coProcessingMaterials',
    []
  )

  const { t } = useTranslation()

  const history = useHistory()

  const {
    formState: { values },
    handleChange,
    setValue
  } = useForm(null, {
    coProcessingMaterialUuid: ''
  })

  useEffect(() => {
    if (
      coProcessingMaterials.length === 1 &&
      values.coProcessingMaterialUuid === ''
    ) {
      setValue('coProcessingMaterialUuid', coProcessingMaterials[0].uuid)
    }
  }, [coProcessingMaterials, values.coProcessingMaterialUuid, setValue])

  function getCoProcessingMaterialLabel(coProcessingMaterial) {
    const displayedQuantityUnit =
      QuantityUnits[coProcessingMaterial.quantityUnit]

    return `${moment(coProcessingMaterial.weighedAt).format(
      'DD/MM/YYYY HH:mm'
    )} - ${coProcessingMaterial.weight.toFixed(2)} ${displayedQuantityUnit}, ${
      coProcessingMaterial.licensePlate
    }`
  }

  function handleAddCertificate() {
    history.push(
      `/co-processing-material/${values.coProcessingMaterialUuid}/add-certificate`
    )
  }

  if (isLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress color="inherit" />
      </div>
    )
  }

  if (coProcessingMaterials.length === 0) {
    return (
      <>
        <Typography className={classes.inactiveFormMessage} variant="subtitle1">
          {t('noMaterialForCertification')}
        </Typography>
      </>
    )
  }

  return (
    <div>
      <form autoComplete="off" noValidate>
        <div className={classes.radioGroupField}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.radioGroupLabel}>
              {t('selectTruckLoadForCertification')}:
            </FormLabel>
            <RadioGroup
              name="coProcessingMaterialUuid"
              value={values.collectedMaterialUuid}
              onChange={handleChange}
            >
              <>
                {coProcessingMaterials.map((coProcessingMaterial) => (
                  <FormControlLabel
                    className={classes.radioFieldLabel}
                    key={coProcessingMaterial.uuid}
                    value={coProcessingMaterial.uuid}
                    control={<Radio />}
                    label={getCoProcessingMaterialLabel(coProcessingMaterial)}
                  />
                ))}
              </>
            </RadioGroup>
          </FormControl>
        </div>
      </form>

      <Button
        size="large"
        variant="outlined"
        className={classes.addCertificateButton}
        disabled={!values.coProcessingMaterialUuid}
        onClick={handleAddCertificate}
        startIcon={<ReceiptIcon style={{ height: 30 }} />}
      >
        {t('addCertificateButton')}
      </Button>
    </div>
  )
}

export default withStyles(styles)(CoProcessingFacilityAddCertificateForm)
