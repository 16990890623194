import { Box, CircularProgress } from '@material-ui/core'
import React, { FunctionComponentElement, useEffect, useState } from 'react'

import {
  EntityFilterConfig,
  EntityFilterField,
  EntityFilterOperator,
  EntityFilterType
} from '../../../components/EntityFilters'
import { EntityFilters } from '../../../components/EntityFilters/EntityFilters'
import {
  TrackedItemState,
  TrackedItemType
} from '../../../services/tracked-item'

export interface TrackedItemsFiltersProps {
  hubUuid: string

  onChange: (filters: EntityFilterConfig[]) => void
}

const trackedItemStateOptions = [
  { label: 'Unassigned', value: TrackedItemState.UNASSIGNED },
  { label: 'Collected', value: TrackedItemState.COLLECTED },
  { label: 'Registered', value: TrackedItemState.REGISTERED },
  { label: 'In Sorting', value: TrackedItemState.IN_SORTING },
  { label: 'Sorted', value: TrackedItemState.SORTED },
  { label: 'In Processing', value: TrackedItemState.IN_PROCESSING },
  { label: 'Processed', value: TrackedItemState.PROCESSED },
  { label: 'In Transit', value: TrackedItemState.IN_TRANSIT }
]

const trackedItemTypeOptions = [
  { label: 'Bag', value: TrackedItemType.BAG },
  { label: 'Bale', value: TrackedItemType.BALE },
  { label: 'Sack', value: TrackedItemType.SACK }
]

export function TrackedItemsFilters({
  hubUuid,
  onChange
}: TrackedItemsFiltersProps): FunctionComponentElement<TrackedItemsFiltersProps> {
  const [filters, setFilters] = useState<EntityFilterConfig[]>([
    {
      field: EntityFilterField.TRACKED_ITEM_TYPE,
      label: 'Type',
      operator: EntityFilterOperator.EQUALS,
      options: trackedItemTypeOptions,
      type: EntityFilterType.SELECT,
      value: null
    },
    {
      field: EntityFilterField.TRACKED_ITEM_STATE,
      label: 'State',
      operator: EntityFilterOperator.EQUALS,
      options: trackedItemStateOptions,
      type: EntityFilterType.SELECT,
      value: null
    },
    {
      field: EntityFilterField.TRACKED_ITEM_TRACKING_NUMBER,
      label: 'Tracking number',
      operator: EntityFilterOperator.CONTAINS,
      type: EntityFilterType.TEXT,
      value: ''
    }
  ])

  useEffect(() => {
    onChange([
      {
        field: EntityFilterField.TRACKED_ITEM_HUB_UUID,
        label: '',
        operator: EntityFilterOperator.EQUALS,
        type: EntityFilterType.TEXT,
        value: hubUuid
      },
      ...filters
    ])
  }, [filters])

  return (
    <Box>
      {filters.length ? (
        <EntityFilters filters={filters} onChange={setFilters} />
      ) : (
        <Box display="flex" justifyContent="center" p={5}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}
