export default theme => ({
  content: {
    marginTop: theme.spacing(3)
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  }
})
