import { QuantityUnit } from '../quantityunit'

export enum MaterialClearanceUnitState {
  IN_PROGRESS = 'IN_PROGRESS',
  PROCESSING_FINISHED = 'CLEARANCE_FINISHED'
}

export interface MaterialClearanceUnitDto {
  uuid: string
  createdAt: string
  state: MaterialClearanceUnitState
  quantityUnit: QuantityUnit
  inputTrackedItemsCount: number
  weight: number
  materialType: string
}
