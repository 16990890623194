import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  makeStyles,
  TextField,
  TextFieldProps
} from '@material-ui/core'
import {
  CancelOutlined,
  CheckOutlined,
  EditOutlined as EditOutlinedIcon
} from '@material-ui/icons'
import cx from 'classnames'
import React, {
  FormEvent,
  FunctionComponentElement,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'

import { Theme } from 'common/theme'
import { useTranslation } from 'react-i18next'

import { useForm } from '../../lib/form'

export interface InlineEditProps<T> {
  disabled?: boolean
  displayText: ReactNode | string
  formSchema?: Record<string, any>
  value: T

  Input?: typeof TextField
  InputProps?: TextFieldProps

  onConfirm: (value: T) => Promise<any>
  onSave: (value: T) => void
}

interface FormValue<T> {
  inputValue: T
}

const useStyles = makeStyles<Theme>((theme) => ({
  inlineEdit: {
    //
  },
  actionIcon: {
    width: 18,
    height: 18,
    marginLeft: theme.spacing(1),
    transition: 'color 200ms'
  },
  actionIconCancel: {
    margin: 0,
    color: theme.palette.danger.dark
  },
  actionIconConfirm: {
    margin: 0,
    color: theme.palette.success.dark
  },
  displayText: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(-0.5, -1),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 3,
    cursor: 'pointer',
    transition: 'border-color 200ms',

    '&:hover': {
      borderColor: theme.palette.primary.light,

      '& $actionIcon': {
        color: theme.palette.primary.dark
      }
    }
  },
  displayText__disabled: {
    border: 'none',
    cursor: 'auto'
  },
  form: {
    paddingRight: 90,
    position: 'relative'
  },
  formActions: {
    position: 'absolute',
    top: 5,
    right: 0
  },
  formActions__loading: {
    visibility: 'hidden'
  },
  formSpinner: {
    marginTop: -9,
    position: 'absolute',
    top: '50%',
    right: 60,
    visibility: 'hidden'
  },
  formSpinner__loading: {
    visibility: 'visible'
  }
}))

export function InlineEdit<T extends string>({
  disabled,
  displayText,
  formSchema,
  value,
  Input = TextField,
  InputProps = {},
  onConfirm,
  onSave
}: InlineEditProps<T>): FunctionComponentElement<InlineEditProps<T>> {
  const classes = useStyles()

  const { t } = useTranslation()

  const [stableFormSchema] = useState<Record<string, any>>({
    inputValue: formSchema ?? {}
  })
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const ref = useRef<HTMLDivElement>(null)

  const {
    formState: { values },
    getErrorMessage,
    handleChange,
    handleSubmit,
    hasError,
    resetForm
  } = useForm<FormValue<T>>(stableFormSchema, {
    inputValue: value
  })

  async function handleDismissClick(): Promise<void> {
    setIsEditing(false)
    resetForm()
  }

  function handleFormSubmit(event: FormEvent): void {
    handleSubmit(event, async () => {
      setIsSaving(true)

      try {
        await onConfirm(values.inputValue)

        setIsEditing(false)
        resetForm({
          inputValue: values.inputValue
        })
        await onSave(values.inputValue)
      } catch {
        // the displaying of an error should be handled by the parent
        // most likely via apiFetchWithDispatch
      }

      setIsSaving(false)
    })
  }

  function handleDisplayTextClick(): void {
    if (disabled) {
      return
    }

    setIsEditing((value) => value || true)

    // wait for the input to be rendered
    setTimeout(() => {
      // focus the input
      ref.current?.querySelector('input')?.focus()
    })
  }

  useEffect(() => {
    resetForm({
      inputValue: value
    })
  }, [value])

  return (
    // using "div" here because ref on Box has broken types in MUI v4
    <div ref={ref} className={classes.inlineEdit}>
      {isEditing ? (
        <form className={classes.form} onSubmit={handleFormSubmit}>
          <Input
            size="small"
            variant="outlined"
            {...InputProps}
            disabled={isSaving}
            error={hasError('inputValue')}
            fullWidth={true}
            helperText={t(getErrorMessage('inputValue'))}
            name="inputValue"
            value={values.inputValue}
            onChange={handleChange}
          />

          <CircularProgress
            className={cx(classes.formSpinner, {
              [classes.formSpinner__loading]: isSaving
            })}
            size={18}
          />

          <ButtonGroup
            className={cx(classes.formActions, {
              [classes.formActions__loading]: isSaving
            })}
            size="small"
          >
            <Button type="submit">
              <CheckOutlined
                className={cx([classes.actionIcon, classes.actionIconConfirm])}
              />
            </Button>

            <Button onClick={handleDismissClick}>
              <CancelOutlined
                className={cx([classes.actionIcon, classes.actionIconCancel])}
              />
            </Button>
          </ButtonGroup>
        </form>
      ) : (
        <Box
          className={cx(classes.displayText, {
            [classes.displayText__disabled]: disabled
          })}
          onClick={handleDisplayTextClick}
        >
          {displayText}

          {!disabled && <EditOutlinedIcon className={classes.actionIcon} />}
        </Box>
      )}
    </div>
  )
}
