import { Box, makeStyles } from '@material-ui/core'
import React, { FunctionComponentElement, ReactNode } from 'react'

export interface ClnModalActionsProps {
  children?: ReactNode
}

const useStyles = makeStyles((theme) => ({
  clnModalActions: {
    textAlign: 'right',
    '& > button': {
      marginLeft: theme.spacing(2)
    }
  }
}))

export function ClnModalActions({
  children
}: ClnModalActionsProps): FunctionComponentElement<ClnModalActionsProps> {
  const classes = useStyles()

  return <Box className={classes.clnModalActions}>{children}</Box>
}
