import { Box, Paper, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { CropFree as CropFreeIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import React, { FunctionComponentElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import AsyncSelect from 'react-select/async/dist/react-select.esm'

import { Theme } from 'common/theme'
import { useGeneralStyles } from 'common/styles/general'
import { useFormStyles } from 'common/styles/form'
import QrScanner from 'components/QrScanner'
import { useForm } from 'lib/form'
import { useStateValue } from 'state'
import { CompaniesApiService } from 'services/company/companies-api.service'
import {
  ActivePickUpPointActions,
  ActivePickUpPointActionType
} from 'state/reducers/active-pick-up-point.reducer'
import LinkButton from 'components/LinkButton'
import TextDivider from 'components/TextDivider'
import { PortalContent } from '../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../layouts/SecurePortalLayout'

interface SelectOption {
  label: string
  value: string
}

const formSchema = {}

const useStyles = makeStyles<Theme>(() => ({
  pickUpPointCheckIn: {
    //
  },
  '@global': {
    '.selectedPickUpPoint__indicator-separator': {
      display: 'none'
    },

    '.selectedPickUpPoint__dropdown-indicator': {
      display: 'none !important'
    },

    '.selectedPickUpPoint__menu': {
      boxShadow: 'none !important'
    }
  }
}))

function PickUpPointCheckIn(): FunctionComponentElement<void> {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const generalClasses = useGeneralStyles()

  const history = useHistory()
  const [hubUuid, setHubUuid] = useState<string | null>(null)
  const [showQrScanner, setShowQrScanner] = useState<boolean>(false)
  const [, dispatch] = useStateValue()
  const { t } = useTranslation()

  const {
    formState: { values, isValid },
    setValue
  } = useForm(formSchema, {
    selectedPickUpPoint: {
      value: '',
      label: ''
    }
  })

  const handleManualCheckInButtonClick = (): void => {
    setHubUuid(values.selectedPickUpPoint.value)
  }

  const handlePickUpPointSelect = (value: SelectOption): void => {
    setValue('selectedPickUpPoint', value)
  }

  const handleQrScannerClose = (): void => {
    setShowQrScanner(false)
  }

  const handleQrScannerScan = (result: string): void => {
    const containsUuid = result.match(/\/identity\/hub\/(.{36})$/)
    const uuid = containsUuid && containsUuid[1]
    if (uuid) {
      setHubUuid(uuid)
    }

    setShowQrScanner(false)
  }

  const handleScanQrCodeButtonClick = (): void => {
    setShowQrScanner(true)
  }

  const pickUpPointSearchResponseOptions = AwesomeDebouncePromise<any>(
    (searchQuery: string) => {
      handlePickUpPointSelect({
        value: '',
        label: ''
      })

      return CompaniesApiService.searchPickupPoints(dispatch, searchQuery).then(
        (response) =>
          (response || []).map((pickUpPoint) => ({
            value: pickUpPoint.uuid,
            label: pickUpPoint.name
          }))
      )
    },
    500
  )

  useEffect(() => {
    if (hubUuid) {
      const action: ActivePickUpPointActions = {
        type: ActivePickUpPointActionType.SET,
        value: {
          uuid: hubUuid
        }
      }

      dispatch(action)

      history.push('/hub-overview')
    }
  }, [hubUuid])

  const qrScannerTemplate = (
    <QrScanner
      width="100%"
      height="calc(100vw - 36px)"
      onClose={handleQrScannerClose}
      onScan={handleQrScannerScan}
    />
  )

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      boxShadow: 'none'
    }),
    noOptionsMessage: () => ({
      display: 'none'
    })
  }

  const manualInputTemplate = (
    <>
      <Box className={formClasses.textField}>
        <Typography variant="body1">{t('name')}</Typography>

        <AsyncSelect
          name="selectedPickUpPoint"
          classNamePrefix="selectedPickUpPoint"
          styles={customSelectStyles}
          required
          value={values.selectedPickUpPoint}
          onChange={handlePickUpPointSelect}
          loadOptions={pickUpPointSearchResponseOptions}
          cacheOptions
          defaultOptions
        />
      </Box>

      <Box mt={2}>
        <Button
          color="primary"
          fullWidth={true}
          size="large"
          variant="outlined"
          disabled={!isValid || !values.selectedPickUpPoint.value}
          onClick={handleManualCheckInButtonClick}
        >
          {t('checkInManuallyButton')}
        </Button>
      </Box>

      <TextDivider text={t('or')} />

      <Box mb={1}>
        <Button
          color="secondary"
          fullWidth={true}
          size="large"
          variant="contained"
          startIcon={<CropFreeIcon />}
          onClick={handleScanQrCodeButtonClick}
        >
          {t('scanQrCodeButton')}
        </Button>
      </Box>

      <LinkButton
        color="default"
        fullWidth={true}
        size="large"
        variant="outlined"
        href="/hub-overview"
      >
        {t('cancelButton')}
      </LinkButton>
    </>
  )

  return (
    <Box className={classes.pickUpPointCheckIn}>
      <SecurePortalLayout title={t('startCollectionTitle')}>
        <PortalContent>
          <div className={classes.root}>
            <div className={generalClasses.content}>
              <form autoComplete="off" noValidate>
                <Paper className={formClasses.formSection}>
                  <Typography className={generalClasses.title} variant="h3">
                    {t('pickUpPointCheckInTitle')}
                  </Typography>

                  {showQrScanner && qrScannerTemplate}

                  {!showQrScanner && manualInputTemplate}
                </Paper>
              </form>
            </div>
          </div>
        </PortalContent>
      </SecurePortalLayout>
    </Box>
  )
}

export default PickUpPointCheckIn
