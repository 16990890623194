import { MaterialType } from '../materialtype/material-type.types';
import { QuantityUnits } from '../quantityunit'

import moment from 'moment'

export function getRegisteredMaterialLabel (registeredMaterial, t) {
  const displayedQuantityUnit = QuantityUnits[registeredMaterial.quantityUnit]
  const materialType = registeredMaterial.materialType ? registeredMaterial.materialType : MaterialType.MIXED
  const supplier = registeredMaterial.supplier ? registeredMaterial.supplier : '-'
  const licensePlate = registeredMaterial.licensePlate ? registeredMaterial.licensePlate : '-'
  const internalId = registeredMaterial.internalId ? `, ${registeredMaterial.internalId}` : ''

  return `${moment(registeredMaterial.weighedAt).format('DD/MM/YYYY HH:mm')} - ${registeredMaterial.formattedWeight} ${displayedQuantityUnit}, ${t(`materialType_${materialType}`)}, ${supplier}, ${licensePlate}${internalId}`
}
