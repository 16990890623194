import 'react-image-lightbox/style.css'

import { Box, makeStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import React, { FunctionComponentElement } from 'react'
import Lightbox from 'react-image-lightbox'

import { Theme } from 'common/theme'

import { MinimalFileUploadDto } from '../services/fileupload/file-upload.types'

import { PdfViewer } from './PdfViewer'

export interface MediaViewerProps {
  file: MinimalFileUploadDto
  fileName?: string

  onClose: () => void
}

const useStyles = makeStyles<Theme>(() => ({
  mediaViewer: {
    //
  }
}))

export function MediaViewer({
  file,
  fileName,
  onClose
}: MediaViewerProps): FunctionComponentElement<MediaViewerProps> | null {
  const classes = useStyles()
  const theme = useTheme<Theme>()

  return (
    <Box className={classes.mediaViewer}>
      {file.mimeType === 'application/pdf' ? (
        <PdfViewer file={file} onClose={onClose} />
      ) : (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: 1400
            }
          }}
          clickOutsideToClose={true}
          enableZoom={true}
          imagePadding={theme.spacing(3)}
          imageTitle={fileName}
          mainSrc={file.directLink}
          onCloseRequest={onClose}
        />
      )}
    </Box>
  )
}
