import { Button } from '@material-ui/core'
import { SvgIconComponent } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, {
  createElement,
  FunctionComponentElement,
  MouseEventHandler,
  ReactNode
} from 'react'

import { Theme } from 'common/theme'

import LinkButton from './LinkButton'

interface Props {
  color: 'primary' | 'secondary'
  href?: string
  icon: SvgIconComponent
  children?: ReactNode
  disabled?: boolean
  mt?: number
  onClick?: MouseEventHandler<any>
  variant?: 'contained' | 'outlined'
}

interface StyleProps {
  mt?: number
}

const useStyles = makeStyles<Theme>((theme) => ({
  flowActionButton: {
    width: '100%',
    fontSize: 18,
    marginTop: (props: StyleProps) => theme.spacing(props.mt ?? 3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))

function FlowActionButton({
  children,
  color,
  disabled,
  href,
  icon,
  mt,
  onClick,
  variant = 'contained'
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles({ mt })
  const startIcon = createElement(icon, {
    style: {
      height: 30,
      width: 30,
      fontWeight: 30
    }
  })

  if (href) {
    return (
      <LinkButton
        size="large"
        className={classes.flowActionButton}
        color={color}
        disabled={disabled}
        href={href}
        variant={variant}
        startIcon={startIcon}
      >
        {children}
      </LinkButton>
    )
  }

  return (
    <Button
      size="large"
      className={classes.flowActionButton}
      color={color}
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      startIcon={startIcon}
    >
      {children}
    </Button>
  )
}

export default FlowActionButton
