import { MinimalFileUploadDto } from 'services/fileupload/file-upload.types'

export enum CollectorType {
  INDIVIDUAL = 'INDIVIDUAL',
  HOUSEHOLD = 'HOUSEHOLD',
  COLLECTION_POINT = 'COLLECTION_POINT'
}

export interface CollectorDto {
  uuid: string
  createdAt: string
  state: string
  name?: string
  birthDate?: string
  trackingNumber: string
  phoneNumber: string
  houseNumber: string
  type: CollectorType
  qrCode: MinimalFileUploadDto
}

export const CollectorStates = Object.freeze({
  UNDEFINED: 'UNDEFINED',
  DEFINED: 'DEFINED'
})
