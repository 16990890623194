import React, { FunctionComponentElement, useEffect } from 'react'

import { useAuth0Session } from '../../hooks/auth0-session.hook'

import { PortalLayoutContext } from './portal-layout.context'

interface PortalLayoutProviderProps {
  children?: any
  currentUser: any
  title?: string
}

export const PortalLayoutProvider = ({
  children,
  currentUser,
  title
}: PortalLayoutProviderProps): FunctionComponentElement<PortalLayoutProviderProps> => {
  const { isCorrectRegionOrigin, redirectToRegionOrigin } = useAuth0Session()

  useEffect(() => {
    if (isCorrectRegionOrigin === false) {
      redirectToRegionOrigin()
    }
  }, [isCorrectRegionOrigin, redirectToRegionOrigin])

  return (
    <PortalLayoutContext.Provider
      value={{
        currentUser,
        title
      }}
    >
      {children}
    </PortalLayoutContext.Provider>
  )
}
