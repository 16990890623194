export default theme => ({
  root: {
    paddingTop: theme.spacing(),
    padding: theme.spacing(3)
  },
  title: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(3),
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  inactiveFormMessage: {
    paddingLeft: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  radioGroupField: {
    width: 300,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  radioGroupLabel: {
    fontSize: 18,
    marginBottom: theme.spacing(3)
  },
  radioFieldLabel: {
    marginBottom: theme.spacing(0.5)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  textField: {
    width: 280,
    maxWidth: '100%',
    marginTop: theme.spacing()
  },
  actionButtons: {
    marginTop: theme.spacing(3)
  },
  cancelButton: {
    marginRight: theme.spacing()
  },
  clearMaterialStockButton: {
    width: '100%',
    fontSize: 18,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing()
  }
})
