import { useState } from 'react'

import {
  ExtendedRequestInit,
  useFetchState,
  UseFetchState
} from '../../lib/fetch'
import { QuantityUnit } from '../quantityunit'

import {
  ClearableMassBalanceOverviewDto,
  ClearanceMassBalanceSummary,
  MassBalanceAccount,
  MassBalanceOverviewDto
} from './index'

export class MassBalanceApiService {
  public static readonly BASE_URL = '/api/massBalance'

  public static getClearanceSummary(): UseFetchState<ClearanceMassBalanceSummary | null> {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [
      clearedTypeBreakdown,
      ,
      refetchOverview
    ] = MassBalanceApiService.getOverview()

    const [
      stockedTypeBreakdown,
      ,
      refetchClearable
    ] = MassBalanceApiService.getClearableMassBalanceOverview()

    async function refetch(
      refetchOptions?: Partial<ExtendedRequestInit>
    ): Promise<null> {
      setIsLoading(true)

      await refetchClearable()
      await refetchOverview({
        ...(refetchOptions ?? {}),
        queryParams: {
          ...(refetchOptions?.queryParams ?? {}),
          account: MassBalanceAccount.CLEARANCE
        }
      })

      setIsLoading(false)

      return null
    }

    return [
      clearedTypeBreakdown && stockedTypeBreakdown
        ? {
            clearedTypeBreakdown:
              clearedTypeBreakdown.outputTypeBreakdown ?? [],
            stockedTypeBreakdown:
              stockedTypeBreakdown.clearableTypeBreakdown ?? [],
            quantityUnit: clearedTypeBreakdown.quantityUnit ?? QuantityUnit.KG
          }
        : null,
      isLoading,
      refetch
    ]
  }

  public static getRegistrationOverview(): UseFetchState<MassBalanceOverviewDto> {
    return MassBalanceApiService.getOverview()
  }

  private static getClearableMassBalanceOverview(): UseFetchState<ClearableMassBalanceOverviewDto> {
    const [
      clearable,
      isClearableLoading,
      refetchClearable
    ] = useFetchState<ClearableMassBalanceOverviewDto | null>(
      `${MassBalanceApiService.BASE_URL}/clearable`,
      null,
      {},
      {
        initialFetch: false
      }
    )

    async function refetch(): Promise<ClearableMassBalanceOverviewDto> {
      const result = await refetchClearable()

      return (
        result ?? {
          clearableTypeBreakdown: [],
          quantityUnit: QuantityUnit.KG
        }
      )
    }

    return [clearable, isClearableLoading, refetch]
  }

  private static getOverview(): UseFetchState<MassBalanceOverviewDto> {
    return useFetchState<MassBalanceOverviewDto | null>(
      `${MassBalanceApiService.BASE_URL}/overview`,
      null,
      {},
      {
        initialFetch: false
      }
    )
  }
}
