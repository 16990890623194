export enum MaterialType {
  PET = 'PET',
  PET_BLUE = 'PET_BLUE',
  PET_NATURAL = 'PET_NATURAL',
  PET_GREEN = 'PET_GREEN',
  PET_BROWN = 'PET_BROWN',
  PET_WHITE = 'PET_WHITE',
  PET_BLUISH = 'PET_BLUISH',
  PET_FLAKES = 'PET_FLAKES',
  PET_BLUE_FLAKES = 'PET_BLUE_FLAKES',
  PET_NATURAL_FLAKES = 'PET_NATURAL_FLAKES',
  PET_GREEN_FLAKES = 'PET_GREEN_FLAKES',
  PET_BROWN_FLAKES = 'PET_BROWN_FLAKES',
  PET_WHITE_FLAKES = 'PET_WHITE_FLAKES',
  PET_BLUISH_FLAKES = 'PET_BLUISH_FLAKES',
  HDPE = 'HDPE',
  HDPE_BLACK = 'HDPE_BLACK',
  HDPE_RED = 'HDPE_RED',
  HDPE_WHITE = 'HDPE_WHITE',
  LDPE = 'LDPE',
  PP = 'PP',
  PP_CUP_MIX = 'PP_CUP_MIX',
  PP_CUP_PRINT = 'PP_CUP_PRINT',
  PP_WHITE = 'PP_WHITE',
  PP_TRANSPARENT = 'PP_TRANSPARENT',
  PS = 'PS',
  PE = 'PE',
  PVC = 'PVC',
  PLASTIC_WASTE = 'PLASTIC_WASTE',
  RIGID_PLASTIC = 'RIGID_PLASTIC',
  FLEXIBLE_PLASTIC = 'FLEXIBLE_PLASTIC',
  FLEXIBLE_PLASTIC_TRANSPARENT_WHITE = 'FLEXIBLE_PLASTIC_TRANSPARENT_WHITE',
  TETRAPAK = 'TETRAPAK',
  MLP = 'MLP',
  FLEXIBLE_LOW_VALUE = 'FLEXIBLE_LOW_VALUE',
  RDF = 'RDF',
  FISHING_NET = 'FISHING_NET',
  BOTTLE_CAP = 'BOTTLE_CAP',
  BOTTLE_CAP_BLUE = 'BOTTLE_CAP_BLUE',
  BOTTLE_CAP_COLOURED = 'BOTTLE_CAP_COLOURED',
  BROKEN_PLASTIC = 'BROKEN_PLASTIC',

  CARDBOARD_AND_PAPER = 'CARDBOARD_AND_PAPER',
  CARDBOARD = 'CARDBOARD',
  PAPER_WHITE = 'PAPER_WHITE',
  PAPER_COLOUR = 'PAPER_COLOUR',
  PAPER_MAGAZINE = 'PAPER_MAGAZINE',
  PAPER_NEWSPRINT = 'PAPER_NEWSPRINT',

  GLASS = 'GLASS',
  GLASS_WHITE = 'GLASS_WHITE',
  GLASS_BROWN = 'GLASS_BROWN',
  GLASS_GREEN = 'GLASS_GREEN',

  CLOTHING_AND_FOOTWEAR = 'CLOTHING_AND_FOOTWEAR',
  CLOTH_WASTE = 'CLOTH_WASTE',
  FOOTWEAR_WASTE = 'FOOTWEAR_WASTE',
  CLOTH_COTTON = 'CLOTH_COTTON',
  CLOTH_COTTON_WHITE = 'CLOTH_COTTON_WHITE',
  CLOTH_COTTON_COLOUR = 'CLOTH_COTTON_COLOUR',
  CLOTH_POLYESTER = 'CLOTH_POLYESTER',
  CLOTH_OTHER = 'CLOTH_OTHER',

  METAL = 'METAL',
  METAL_ALUMINIUM = 'METAL_ALUMINIUM',
  METAL_BRASS = 'METAL_BRASS',
  METAL_COPPER = 'METAL_COPPER',
  METAL_STEEL = 'METAL_STEEL',
  METAL_SHEET_METAL = 'METAL_SHEET_METAL',

  RUBBER = 'RUBBER',
  RUBBER_TYRE = 'RUBBER_TYRE',
  RUBBER_TUBE = 'RUBBER_TUBE',

  WOOD_PINE = 'WOOD_PINE',
  WOOD_LOCAL = 'WOOD_LOCAL',
  WOOD_MIXED = 'WOOD_MIXED',

  PYROLYSIS_OIL = 'PYROLYSIS_OIL',
  OIL = 'OIL',

  MIXED = 'MIXED',
  MIXED_DRY = 'MIXED_DRY',

  BATTERIES = 'BATTERIES',
  E_WASTE = 'E_WASTE',
  RESIDUE = 'RESIDUE',
  SANITARY_WASTE = 'SANITARY_WASTE',
  ORGANIC_WASTE = 'ORGANIC_WASTE'
}
