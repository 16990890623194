import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import React, { FunctionComponentElement, ReactElement, useState } from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'

import { useStateValue } from '../../../../state'
import { apiFetchWithDispatch } from '../../../../lib/fetch'
import { ComplianceActionDto } from '../../../../services/compliance'
import { uploadFileByAdminWithDispatch } from 'services/fileupload'
import { FileUploadDto } from '../../../../services/fileupload/file-upload.types'
import { Theme } from '../../../../common/theme'
import { ClnModal, ClnModalContent } from 'components/modal'
import { ClnModalContentProps } from 'components/modal/ClnModalContent'
import { useGeneralStyles } from '../../../../common/styles/general'
import { UploadedFile } from '../../../../components'

interface MarkAuditActionAsReviewedModalProps {
  hubUuid: string
  complianceAction: ComplianceActionDto | null
  show: boolean
  onClose: () => void
}

interface FileUploadWrapper {
  fileUpload: FileUploadDto
  comment?: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  dropzoneAreaRoot: {
    minHeight: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 2, 2)
  },
  dialogActions: {
    marginRight: theme.spacing()
  },
  documentsInfo: {
    marginBottom: theme.spacing(2)
  }
}))

export function MarkAuditActionAsReviewedModal({
  hubUuid,
  complianceAction,
  show,
  onClose
}: MarkAuditActionAsReviewedModalProps): FunctionComponentElement<MarkAuditActionAsReviewedModalProps> {
  const [, dispatch] = useStateValue()
  const classes = useStyles()

  const { t } = useTranslation()

  const generalClasses = useGeneralStyles()

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [pendingFileUploadCount, setPendingFileUploadCCount] = useState(0)
  const [fileUploads, setFileUploads] = useState<FileUploadWrapper[]>([])

  async function handleFilesUpload(files) {
    if (files.length === 0) {
      return
    }

    setPendingFileUploadCCount(files.length)

    for (const file of files) {
      const fileUpload = await uploadFileByAdminWithDispatch(
        dispatch,
        file,
        hubUuid
      )

      setPendingFileUploadCCount((prevPendingCount) => prevPendingCount - 1)

      if (fileUpload) {
        setFileUploads((prevFileUploads: FileUploadWrapper[]) => [
          ...prevFileUploads,
          {
            fileUpload
          }
        ])
      }
    }
  }

  async function handleSave() {
    setIsSaving(true)

    await apiFetchWithDispatch(
      dispatch,
      `/api/admin/complianceActions/${complianceAction?.uuid}/markAsReviewed`,
      {
        method: 'POST',
        body: JSON.stringify({
          documents: fileUploads.map((fileUpload) => ({
            fileUploadUuid: fileUpload.fileUpload.uuid
          }))
        })
      }
    )

    onClose()
  }

  const getContent = (): ReactElement<
    ClnModalContentProps,
    typeof ClnModalContent
  > => {
    if (isSaving) {
      return (
        <ClnModalContent>
          <Box className={generalClasses.progressWrapper}>
            <CircularProgress />
          </Box>
        </ClnModalContent>
      )
    }

    return (
      <ClnModalContent>
        <form autoComplete="off" noValidate>
          <Box mb={2}>
            <Box p={1}>
              {pendingFileUploadCount > 0 ? (
                <Box
                  width="100%"
                  height={200}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box>
                  <Box className={classes.documentsInfo}>
                    <Typography variant="h5">
                      Upload additional documents (optional)
                    </Typography>
                  </Box>
                  <DropzoneArea
                    acceptedFiles={[
                      'image/*',
                      'application/pdf',
                      'video/mp4',
                      'video/quicktime'
                    ]}
                    dropzoneText="Upload a document"
                    maxFileSize={30000000}
                    filesLimit={1}
                    onChange={handleFilesUpload}
                    showPreviewsInDropzone={false}
                    showAlerts={true}
                    classes={{
                      root: classes.dropzoneAreaRoot
                    }}
                  />
                </Box>
              )}
            </Box>

            <Box mt={2}>
              {fileUploads.map((wrapper: FileUploadWrapper) => (
                <Box p={1}>
                  <UploadedFile
                    fileUploads={[wrapper.fileUpload]}
                    allowClear={false}
                  />
                </Box>
              ))}
            </Box>

            <Box display="flex" justifyContent="space-between" mt={4}>
              <Button
                size="medium"
                onClick={onClose}
                color="default"
                variant="outlined"
              >
                {t('cancelButton')}
              </Button>

              <Box ml={1}>
                <Button
                  size="medium"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSave()}
                >
                  Mark as reviewed
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </ClnModalContent>
    )
  }

  return (
    <Box>
      <ClnModal
        content={getContent()}
        onClose={onClose}
        show={show}
        maxWidth="440px"
        title="Mark audit action as reviewed"
      />
    </Box>
  )
}
