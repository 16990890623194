import { Auth0Client } from '@auth0/auth0-spa-js'
import { WebAuth } from 'auth0-js'

import { getReleaseStage, ReleaseStage } from '../services/environment'
import { AppRegion } from '../types/app-region.enum'
import { Auth0RegionConfigMap } from '../types/auth0-region-config-map.interface'
import { ReleaseStageOrigin } from '../types/release-stage-origin.enum'

export const AUTH0_AUDIENCE = (() => {
  switch (getReleaseStage()) {
    case ReleaseStage.PROD:
      return 'https://app.cleanhub.io'
    case ReleaseStage.DEMO:
      return 'https://demo.cleanhub.io'
    default:
      return 'http://localhost:8080'
  }
})()

export const AUTH0_CLIENT_ID = (() => {
  switch (getReleaseStage()) {
    case ReleaseStage.PROD: {
      if (window.location.origin.includes('app-sa')) {
        return 'bND3evoISaN4YTqv6DE1Gh8gXlDbaEHC'
      }

      return 'au3CxHyQtKTVueyLhPTPsDmR2DEfBX28'
    }

    case ReleaseStage.DEMO:
      return 'bUtO2Hxs3xQIqd38bfJsmVR3zDLtLSeR'

    default:
      return '1kqIr8DyNeb33LUABmFdMFpyjzjFaYTH'
  }
})()

export const AUTH0_DOMAIN = (() => {
  switch (getReleaseStage()) {
    case ReleaseStage.PROD:
      return 'auth.cleanhub.io'
    case ReleaseStage.DEMO:
      return 'auth-demo.cleanhub.io'
    default:
      return 'cleanhub-os-dev.eu.auth0.com'
  }
})()

export const AUTH0_REDIRECT_URL = `${window.location.origin}/auth/callback`

export const AUTH0_REGIONS_MAP: Auth0RegionConfigMap = {
  [ReleaseStage.PROD]: {
    [AppRegion.AP_MUMBAI]: {
      origin: ReleaseStageOrigin.PROD
    },
    [AppRegion.DEFAULT]: {
      origin: ReleaseStageOrigin.PROD
    },
    [AppRegion.SA_SAO_PAULO]: {
      origin: ReleaseStageOrigin.PROD_SA
    }
  },
  [ReleaseStage.DEMO]: {
    [AppRegion.DEFAULT]: {
      origin: ReleaseStageOrigin.DEMO
    },
    [AppRegion.EU_FRANKFURT]: {
      origin: ReleaseStageOrigin.DEMO
    }
  },
  [ReleaseStage.DEV]: {
    [AppRegion.DEFAULT]: {
      origin: ReleaseStageOrigin.DEV
    },
    [AppRegion.EU_FRANKFURT]: {
      origin: ReleaseStageOrigin.DEV
    }
  }
}

export const USER_METADATA_KEY = 'https://cleanhub.io/user_metadata'
export const USER_ROLES_KEY = 'https://cleanhub.io/roles'

export const auth0Client = new Auth0Client({
  clientId: AUTH0_CLIENT_ID,
  domain: AUTH0_DOMAIN,
  authorizationParams: {
    audience: AUTH0_AUDIENCE,
    redirect_uri: AUTH0_REDIRECT_URL
  }
})

export const auth = new WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  responseType: 'code',
  redirectUri: AUTH0_REDIRECT_URL,
  audience: AUTH0_AUDIENCE,
  scope: 'openid profile email'
})
