import React from 'react'
import { FormControlLabel, Radio } from '@material-ui/core'

export const MaterialSourceTypes = {
  RECLAIMER: 'RECLAIMER',
  HOUSEHOLD: 'HOUSEHOLD',
  BUSINESS: 'BUSINESS',
  CLEANUP: 'CLEANUP'
}

export const getMaterialSourceTypesRadioOptions = (t) => {
  return Object.keys(MaterialSourceTypes).map(key => (
    <FormControlLabel
      key={key}
      value={key}
      control={<Radio />}
      label={t(`materialSourceType_${MaterialSourceTypes[key]}`)}
    />
  ))
}
