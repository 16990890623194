import { SessionStorage, StorageKey } from '../../services/storage'
import { getCurrentUser } from '../../services/user'

export interface SessionAchievements {
  [uuid: string]: UserSessionAchievements
}

export interface UserSessionAchievements {
  hubEventReviews?: number
}

export function getSessionAchievements(): SessionAchievements {
  return (
    SessionStorage.getItem<SessionAchievements>(StorageKey.ACHIEVEMENTS) ?? {}
  )
}

export function getCurrentUserSessionAchievements(): UserSessionAchievements {
  const sessionAchievements = getSessionAchievements()

  return sessionAchievements[getCurrentUser()?.uuid ?? ''] ?? {}
}

export function updateCurrentUserSessionAchievements(
  userSessionAchievements: UserSessionAchievements
): UserSessionAchievements {
  const sessionAchievements = getSessionAchievements()
  const userUuid = getCurrentUser()?.uuid

  if (userUuid) {
    SessionStorage.setItem(StorageKey.ACHIEVEMENTS, {
      ...sessionAchievements,
      [userUuid]: {
        ...(sessionAchievements[userUuid] ?? {}),
        ...userSessionAchievements
      }
    })
  }

  return getCurrentUserSessionAchievements()
}

export function increaseHubEventReviewsAchievementCounter(): number {
  const currentUserSessionAchievements = getCurrentUserSessionAchievements()

  return (
    updateCurrentUserSessionAchievements({
      ...currentUserSessionAchievements,
      hubEventReviews: (currentUserSessionAchievements.hubEventReviews ?? 0) + 1
    }).hubEventReviews ?? 0
  )
}
