import { TFunction } from 'i18next'
import { apiFetchWithDispatch } from 'lib/fetch'

import {
  getOngoingTransportLabel,
  OngoingTransportDto,
  OngoingTransportOption
} from './index'

export class OngoingTransportsApiService {
  public static readonly BASE_URL = '/api/ongoingTransports'

  public static async search(
    dispatch: () => any,
    t: TFunction,
    transporterLicensePlate: string
  ): Promise<OngoingTransportOption[]> {
    if (!transporterLicensePlate) {
      return Promise.resolve([])
    }

    const result = await apiFetchWithDispatch<OngoingTransportDto[]>(
      dispatch,
      `${OngoingTransportsApiService.BASE_URL}/search`,
      {
        method: 'POST',
        body: JSON.stringify({ transporterLicensePlate })
      }
    )

    return (result ?? []).map((item) => ({
      label: getOngoingTransportLabel(t, item),
      licensePlate: item.transporterLicensePlate,
      materialTypes: item.materialTypes ?? [],
      value: item.owner
    }))
  }
}
