import React, { Component } from 'react'

// Externals
import PropTypes from 'prop-types'

// Material components
import { Grid, Typography, withStyles } from '@material-ui/core'

// Component styles
import styles from './styles'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

class NotFound extends Component {
  render () {
    const { classes, t } = this.props

    return (
      <div className={classes.root}>
        <Grid
          container
          justify='center'
          spacing={4}
        >
          <Grid
            item
            lg={6}
            xs={12}
          >
            <div className={classes.content}>
              <Typography variant='h1'>
                {t('notFoundMessage')}
              </Typography>
              <img
                alt='Under development'
                className={classes.image}
                src='/images/not_found.png'
              />
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  withTranslation()
)(NotFound)
