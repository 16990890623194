import MomentUtils from '@date-io/moment'
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import { PopoverProps } from '@material-ui/core/Popover'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Today as TodayIcon
} from '@material-ui/icons'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import { makeStyles } from '@material-ui/styles'
import moment, { Moment } from 'moment'
import React, {
  FunctionComponentElement,
  MouseEvent,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from '../common/theme'
import { useForm } from '../lib/form'
import { useStateValue } from '../state'

export enum PeriodType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  CUSTOM = 'CUSTOM'
}

enum PeriodTypeToUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter'
}

enum PeriodTypeToUnitOfTime {
  DAY = 'day',
  WEEK = 'isoWeek',
  MONTH = 'month',
  QUARTER = 'quarter'
}

interface Dates {
  endDate: Moment
  startDate: Moment
}

interface PeriodSelectorProps {
  periodType: PeriodType
  onDatesChange: (startDate: Moment, endDate: Moment) => any
  onPeriodTypeChange: (periodType: PeriodType) => any
}

const useStyles = makeStyles<Theme>(() => ({
  periodSelector: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

const today = moment()
const formSchema = {}

export function PeriodSelector({
  periodType,
  onDatesChange,
  onPeriodTypeChange
}: PeriodSelectorProps): FunctionComponentElement<PeriodSelectorProps> {
  const classes = useStyles()

  const [{ overviewPeriod }] = useStateValue()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<PopoverProps['anchorEl'] | null>(
    null
  )
  const [selectedPeriodType, setSelectedPeriodType] = useState<PeriodType>(
    periodType ?? PeriodType.WEEK
  )
  const [dates, setDates] = useState<Dates | null>(null)

  const {
    formState: { values },
    setValue,
    setValues
  } = useForm(formSchema, {
    endDate: overviewPeriod.endDate ?? today.clone(),
    startDate: overviewPeriod.startDate ?? today.clone()
  })

  function handleDateInputChange(
    type: 'endDate' | 'startDate',
    date: MaterialUiPickersDate
  ): void {
    if (!date) {
      return
    }

    setValue(type, date)

    setDates((value) => {
      if (!value) {
        return value
      }

      return {
        ...value,
        [type]:
          type === 'startDate'
            ? moment(date).startOf('day')
            : moment(date).endOf('day')
      }
    })
  }

  function handleMenuClose(): void {
    setAnchorEl(null)
  }

  function handleMenuItemClick(periodType: PeriodType): void {
    setSelectedPeriodType(periodType)
    handleMenuClose()
  }

  function handlePeriodClick(event: MouseEvent): void {
    setAnchorEl(event.currentTarget)
  }

  function handlePeriodNextInstanceIconClick(): void {
    setDates((dates) => {
      return dates
        ? {
            endDate: dates.endDate
              .clone()
              .add(1, PeriodTypeToUnit[selectedPeriodType]),
            startDate: dates.startDate
              .clone()
              .add(1, PeriodTypeToUnit[selectedPeriodType])
          }
        : null
    })
  }

  function handlePeriodPreviousInstanceIconClick(): void {
    setDates((dates) => {
      return dates
        ? {
            endDate: dates.endDate
              .clone()
              .subtract(1, PeriodTypeToUnit[selectedPeriodType])
              .endOf(PeriodTypeToUnitOfTime[selectedPeriodType]),
            startDate: dates.startDate
              .clone()
              .subtract(1, PeriodTypeToUnit[selectedPeriodType])
              .startOf(PeriodTypeToUnitOfTime[selectedPeriodType])
          }
        : null
    })
  }

  function isLastPeriodInstance(): boolean {
    return dates?.endDate.isSameOrAfter(today) ?? true
  }

  useEffect(() => {
    if (selectedPeriodType) {
      if (selectedPeriodType !== PeriodType.CUSTOM) {
        const dates = {
          endDate: moment().endOf(PeriodTypeToUnitOfTime[selectedPeriodType]),
          startDate: moment().startOf(
            PeriodTypeToUnitOfTime[selectedPeriodType]
          )
        }

        setDates(dates)
        setValues(dates)
      }

      onPeriodTypeChange?.(selectedPeriodType)
    }
  }, [selectedPeriodType])

  useEffect(() => {
    if (dates) {
      onDatesChange?.(dates.startDate, dates.endDate)
    }
  }, [dates])

  return (
    <Box className={classes.periodSelector}>
      <Box display="flex" alignItems="center" onClick={handlePeriodClick}>
        <Box display="flex">
          <IconButton>
            <TodayIcon />
          </IconButton>
        </Box>

        {dates && selectedPeriodType !== PeriodType.CUSTOM && (
          <Typography variant="h5">
            {dates.startDate.format('DD/MM/YYYY')}
            {' - '}
            {dates.endDate.format('DD/MM/YYYY')}
          </Typography>
        )}
      </Box>

      {selectedPeriodType !== PeriodType.CUSTOM && (
        <Box display="flex">
          <IconButton onClick={handlePeriodPreviousInstanceIconClick}>
            <ChevronLeftIcon />
          </IconButton>

          <IconButton
            disabled={isLastPeriodInstance()}
            onClick={handlePeriodNextInstanceIconClick}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      )}

      {selectedPeriodType === PeriodType.CUSTOM && (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Box flexGrow={1} pr={1} pl={1}>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={6}>
                <DatePicker
                  name="startDate"
                  label={t('startDate')}
                  value={values.startDate}
                  format="DD/MM/YYYY"
                  inputVariant="outlined"
                  size="small"
                  onChange={(date) => handleDateInputChange('startDate', date)}
                />
              </Grid>

              <Grid item={true} xs={6}>
                <DatePicker
                  name="endDate"
                  label={t('endDate')}
                  value={values.endDate}
                  format="DD/MM/YYYY"
                  inputVariant="outlined"
                  size="small"
                  onChange={(date) => handleDateInputChange('endDate', date)}
                />
              </Grid>
            </Grid>
          </Box>
        </MuiPickersUtilsProvider>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {Object.values(PeriodType).map((period) => (
          <MenuItem
            key={period}
            selected={selectedPeriodType === period}
            onClick={() => {
              handleMenuItemClick(period)
            }}
          >
            {t(`PeriodType.${period}`)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
