// The import actually works, but we need all these imports
// because TS is a bit confused by it
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import QrScannerWorkerPath from '!!file-loader!../../node_modules/qr-scanner/qr-scanner-worker.min.js'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, {
  FunctionComponentElement,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import QrScannerLib from 'qr-scanner'

import { Theme } from 'common/theme'

QrScannerLib.WORKER_PATH = QrScannerWorkerPath

interface Props {
  height: string
  onClose: () => void
  onScan: (result: string) => void
  width: string
}

interface VideoProps {
  onInit: (ref: MutableRefObject<HTMLVideoElement>) => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  qrScanner: {
    position: 'relative',
    borderRadius: '10px',
    overflow: 'hidden'
  },
  closeButton: {
    width: 24,
    height: 24,
    lineHeight: '22px',
    backgroundColor: 'rgba(0, 0, 0, .54)',
    padding: theme.spacing(1),
    position: 'absolute',
    top: 5,
    right: 5,
    color: theme.palette.common.white,
    fontSize: 20,
    textAlign: 'center',
    borderRadius: '7px',
    zIndex: 1
  }
}))

function QrScanner({
  height,
  onClose,
  onScan,
  width
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const [qrResult, setQrResult] = useState<string>('')

  let qrScanner: QrScannerLib | null

  useEffect(() => {
    if (qrResult) {
      onScan(qrResult)
    }

    return () => {
      qrScanner?.destroy()
    }
  }, [qrResult])

  const handleInit = (ref: MutableRefObject<HTMLVideoElement>): void => {
    qrScanner = new QrScannerLib(ref.current, (result) => {
      setQrResult(result)

      qrScanner?.stop()
    })

    qrScanner.start()
  }

  const Video = React.forwardRef<any, VideoProps>((props, ref) => {
    useImperativeHandle(ref, () => {
      props.onInit(ref as MutableRefObject<HTMLVideoElement>)
    })

    return (
      <video
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        ref={ref}
      />
    )
  })

  const videoElem = React.createRef<void>()

  return (
    <Box className={classes.qrScanner} style={{ height, width }}>
      <Box className={classes.closeButton} onClick={onClose}>
        &times;
      </Box>

      <Video ref={videoElem} onInit={handleInit} />
    </Box>
  )
}

export default QrScanner
