import { Box, Typography } from '@material-ui/core'
import { TFunction } from 'i18next'
import moment from 'moment'
import React, { ReactNode } from 'react'

import { MaterialType } from '../materialtype/material-type.types'
import { OwnerDto } from '../owner'

export interface OngoingTransportDto {
  owner: OwnerDto
  transporterLicensePlate: string
  transporterDispatchedAt: string
  materialTypes: MaterialType[] | null
}

export interface OngoingTransportOption {
  label: ReactNode
  licensePlate: string
  materialTypes: MaterialType[]
  value: OwnerDto
}

export function getOngoingTransportLabel(
  t: TFunction,
  ongoingTransport: OngoingTransportDto
): ReactNode {
  const date = moment(ongoingTransport.transporterDispatchedAt)
  const dateFormat = `DD/MM/YYYY [${t('atDate')}] HH:mm`

  return (
    <>
      <Box>{ongoingTransport.transporterLicensePlate}</Box>

      <Box>
        <Typography color="textSecondary" variant="caption">
          {t('dispatchedAt')}: {date.format(dateFormat)} -{' '}
          {(ongoingTransport.materialTypes ?? [])
            .map((materialType) => t(`materialType_${materialType}`))
            .join(', ')}
        </Typography>
      </Box>
    </>
  )
}
