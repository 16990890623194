import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { compose } from 'recompose'

// Material components
import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

// Shared layouts
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'
import { getCurrentUser } from '../../../services/user'

// Component styles
import styles from './styles'
import { useTheme } from '@material-ui/styles'

import { useStateValue } from 'state'
import schema from './schema'

import { useCamera } from 'lib/camera'

import { useTranslation } from 'react-i18next'

import { apiFetchWithDispatch } from 'lib/fetch'
import { useForm } from 'lib/form'

import { ImagePreview, FullScreenCamera } from 'components'

import { ReactComponent as BagIcon } from 'icons/bag-black.svg'

import LinkButton from '../../../components/LinkButton'
import {
  getDisplayedQuantityUnit,
  QuantityUnit
} from '../../../services/quantityunit'
import { ProductType } from '../../../services/producttype/product-type.types'
import { useFormStyles } from '../../../common/styles/form'

function RegisterProcessingOutputMaterialBag({ classes, history, match }) {
  const { processingUnitUuid } = match.params
  const {
    processingOutputMaterialTypes,
    processingOutputProductTypes
  } = getCurrentUser()

  const queryParams = queryString.parse(location.search)

  const theme = useTheme()
  const formClasses = useFormStyles()

  const [, dispatch] = useStateValue()
  const { t } = useTranslation()

  const maxProcessedDate = new Date()

  const [isLoading, setIsLoading] = useState(false)

  const {
    pictureDataUri,
    pictureFileUpload,
    showCamera,
    pictureConfirmed,
    handleTakePhotoFinished,
    handleFileUploadFinished,
    handleConfirmPicture,
    handleCameraError
  } = useCamera()

  const {
    formState: { values, errors, isValid },
    setValues,
    handleChange,
    resetForm,
    hasError
  } = useForm(schema, {
    materialType:
      processingOutputMaterialTypes?.length === 1
        ? processingOutputMaterialTypes[0]
        : '',
    productType:
      processingOutputProductTypes?.length === 1
        ? processingOutputProductTypes[0]
        : '',
    weight: '',
    quantityUnit: QuantityUnit.KG,
    processedAt: new Date()
  })

  useEffect(() => {
    if (!showCamera) {
      history.push('/hub-overview')
    }
  }, [showCamera, values])

  function handleProcessedDateChange(e) {
    const newValues = { ...values }
    newValues.processedAt = e
    setValues(newValues)
  }

  function handleProductTypeChange(e) {
    const newValues = { ...values }

    newValues.productType = e.target.value

    if (newValues.productType === ProductType.OIL) {
      newValues.quantityUnit = QuantityUnit.LITER
    } else {
      newValues.quantityUnit = QuantityUnit.KG
    }

    setValues(newValues)
  }

  async function handleSave() {
    setIsLoading(true)

    const {
      materialType,
      productType,
      weight,
      quantityUnit,
      processedAt
    } = values

    const response = await apiFetchWithDispatch(
      dispatch,
      `/api/processingUnits/${processingUnitUuid}/outputMaterialBags`,
      {
        method: 'POST',
        body: JSON.stringify({
          materialType,
          productType,
          processedAt,
          weight,
          quantityUnit,
          pictureFileUploadUuid: pictureFileUpload
            ? pictureFileUpload.uuid
            : null,
          trackedItemUuid: queryParams?.trackedItemUuid
        })
      }
    )

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('registerProcessingOutputMaterialBagTitle')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  return (
    <div>
      {pictureConfirmed ? (
        <SecurePortalLayout
          title={t('registerProcessingOutputMaterialBagTitle')}
        >
          <PortalContent>
            <div className={classes.root}>
              <div className={classes.content}>
                <form autoComplete="off" noValidate>
                  <Paper className={classes.formSection}>
                    <Typography className={classes.title} variant="h3">
                      {t('registerProcessingOutputMaterialBagTitle')}
                    </Typography>

                    <FormControl component="fieldset">
                      <FormLabel component="legend" required>
                        {t('outputMaterialBagPicture')}
                      </FormLabel>
                      <div className={classes.imagePreview}>
                        <ImagePreview
                          dataUri={pictureDataUri}
                          isPictureUploading={!pictureFileUpload}
                        />
                      </div>
                    </FormControl>

                    {processingOutputMaterialTypes?.length > 1 && (
                      <div className={classes.field}>
                        <TextField
                          name="materialType"
                          autoFocus
                          className={classes.selectField}
                          label={t('materialType')}
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          select={true}
                          SelectProps={{ native: true }}
                          value={values.materialType}
                          onChange={handleChange}
                          error={hasError('materialType')}
                          helperText={
                            hasError('materialType')
                              ? t(errors.materialType?.[0] ?? '')
                              : ''
                          }
                        >
                          <option key="" value="" />

                          {processingOutputMaterialTypes.map((item) => (
                            <option key={item} value={item}>
                              {t(`materialType_${item}`)}
                            </option>
                          ))}
                        </TextField>
                      </div>
                    )}

                    {processingOutputProductTypes?.length > 1 && (
                      <div className={classes.field}>
                        <TextField
                          name="productType"
                          className={classes.selectField}
                          label={t('productType')}
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          select={true}
                          SelectProps={{ native: true }}
                          value={values.productType}
                          onChange={handleProductTypeChange}
                          error={hasError('productType')}
                          helperText={
                            hasError('productType')
                              ? t(errors.productType?.[0] ?? '')
                              : ''
                          }
                        >
                          <option key="" value="" />

                          {processingOutputProductTypes.map((item) => (
                            <option key={item} value={item}>
                              {t(`productType_${item}`)}
                            </option>
                          ))}
                        </TextField>
                      </div>
                    )}

                    <div className={classes.field}>
                      <TextField
                        name="weight"
                        className={classes.weightField}
                        type="number"
                        label={t('addWeight')}
                        margin="dense"
                        required
                        value={values.weight}
                        onChange={handleChange}
                        error={hasError('weight')}
                        helperText={
                          hasError('weight') ? t(errors.weight[0]) : ''
                        }
                      />
                      <TextField
                        name="quantityUnit"
                        className={classes.quantityUnitField}
                        disabled
                        label={getDisplayedQuantityUnit(values.quantityUnit)}
                        margin="dense"
                        value=""
                        InputProps={{
                          style: {
                            color: theme.palette.text.primary
                          }
                        }}
                      />
                    </div>

                    <div className={classes.field}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          className={formClasses.dateField}
                          id="mui-pickers-date"
                          name="processedAt"
                          label="Processed Date"
                          value={values.processedAt}
                          maxDate={maxProcessedDate}
                          onChange={handleProcessedDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    <div className={classes.actionButtons}>
                      <LinkButton
                        size="large"
                        className={classes.cancelButton}
                        color="default"
                        variant="outlined"
                        href="/hub-overview"
                      >
                        {t('cancelButton')}
                      </LinkButton>
                      <Button
                        size="large"
                        variant="contained"
                        className={classes.registerOutputMaterialBagButton}
                        disabled={!isValid || isLoading || !pictureFileUpload}
                        onClick={handleSave}
                      >
                        {t('registerOutputButton')}
                      </Button>
                    </div>
                  </Paper>
                </form>
              </div>
            </div>
          </PortalContent>
        </SecurePortalLayout>
      ) : (
        showCamera && (
          <FullScreenCamera
            fileName={`registered-processing-output-material-bag-${Date.now()}`}
            headerMessage={t(
              'registerProcessingOutputMaterialBagPictureExplanation'
            )}
            shutterIcon={<BagIcon />}
            onTakePhotoFinished={handleTakePhotoFinished}
            onFileUploadFinished={handleFileUploadFinished}
            onConfirmPicture={handleConfirmPicture}
            onCameraError={handleCameraError}
          />
        )
      )}
    </div>
  )
}

export default compose(
  withRouter,
  withStyles(styles)
)(RegisterProcessingOutputMaterialBag)
