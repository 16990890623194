import React from 'react'

import { Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: 4
  },
  line: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.common.neutral}`
  },
  title: {
    maxWidth: '50%',
    color: theme.palette.text.main,
    fontWeight: 400
  },
  value: {
    minWidth: '50%',
    color: theme.palette.text.main,
    fontWeight: 500,
    textAlign: 'right'
  }
}))

export default function SummaryLine (props) {
  const { title, value, className } = props

  const classes = useStyles()
  const rootClassName = classNames(classes.root, className)

  return (
    <div className={rootClassName}>
      <div className={classes.line}>
        <Typography
          className={classes.title}
          variant='h5'
        >
          {title}:
        </Typography>
        <Typography
          className={classes.value}
          variant='h5'
        >
          {value}
        </Typography>
      </div>
    </div>
  )
}
