import { Receipt as ReceiptIcon } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { compose } from 'recompose'

// Material components
import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'

import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'

// Shared layouts
import { DocumentWithoutLocationUpload } from '../../../components/DocumentWithoutLocationUpload'
import { mergeDateAndTime } from '../../../helpers/date-time.helpers'
import { PortalContent } from '../../../layouts/portal/PortalContent'
import { SecurePortalLayout } from '../../../layouts/SecurePortalLayout'
import { OngoingTransportsApiService } from '../../../services/ongoing-transport/ongoing-transports-api.service'
import { getCurrentUser } from '../../../services/user'

// Component styles
import styles from './styles'
import { useTheme } from '@material-ui/styles'

import { useStateValue } from 'state'
import schema from './schema'

import { useCamera } from 'lib/camera'

import { useTranslation } from 'react-i18next'

import { useForm } from 'lib/form'

import { ImagePreview, FullScreenCamera } from 'components'

import { QuantityUnits } from 'services/quantityunit'
import AwesomeDebouncePromise from 'awesome-debounce-promise'

import { apiFetchWithDispatch } from 'lib/fetch'
import AsyncSelect from 'react-select/async/dist/react-select.esm'
import LinkButton from '../../../components/LinkButton'

function RegisterCoProcessingMaterialTruckLoad (props) {
  const { classes, history } = props

  const [isLoading, setIsLoading] = useState(false)
  const [documentUpload, setDocumentUpload] = useState(null)

  const [, dispatch] = useStateValue()

  const { t } = useTranslation()

  const theme = useTheme()

  const { enableDocumentUpload } = getCurrentUser()

  const {
    pictureDataUri,
    pictureFileUpload,
    showCamera,
    pictureConfirmed,
    handleTakePhotoFinished,
    handleFileUploadFinished,
    handleConfirmPicture,
    handleCameraError
  } = useCamera()

  const {
    formState: {
      values,
      errors,
      isValid
    },
    setValues,
    setValue,
    handleChange,
    resetForm,
    hasError
  } = useForm(schema, {
    weight: '',
    quantityUnit: QuantityUnits.KG,
    weighedAt: new Date(),
    weighedAtTime: new Date(),
    selectedOngoingTransport: {
      label: '',
      licensePlate: '',
      materialTypes: [],
      value: ''
    }
  })

  useEffect(() => {
    if (!showCamera) {
      history.push('/hub-overview')
    }
  }, [showCamera])

  function handleWeighedAtChange (e) {
    const newValues = { ...values }
    newValues.weighedAt = e
    setValues(newValues)
  }

  function handleWeighedAtTimeChange (e) {
    const newValues = { ...values }
    newValues.weighedAtTime = e
    setValues(newValues)
  }

  const ongoingTransportSearchResponseOptions = (inputLicensePlate) => {
    handleSelectOngoingTransport({
      label: '',
      licensePlate: '',
      materialTypes: [],
      value: ''
    })

    return OngoingTransportsApiService.search(dispatch, t, inputLicensePlate)
  }

  const debouncedOngoingTransportSearchResponseOptions = AwesomeDebouncePromise(ongoingTransportSearchResponseOptions, 500)

  async function handleSave () {
    setIsLoading(true)

    const {
      weight,
      weighedAt,
      weighedAtTime,
      selectedOngoingTransport
    } = values

    const response = await apiFetchWithDispatch(dispatch,
      '/api/coProcessingMaterials', {
        method: 'POST',
        body: JSON.stringify({
          weight,
          weighedAt: mergeDateAndTime(weighedAt, weighedAtTime).toISOString(),
          licensePlate: selectedOngoingTransport.licensePlate,
          transportOwner: selectedOngoingTransport.value,
          pictureFileUploadUuid: documentUpload
            ? documentUpload.uuid
            : pictureFileUpload
              ? pictureFileUpload.uuid
              : null
        })
      })

    setIsLoading(false)

    if (response) {
      resetForm()

      history.push('/hub-overview')
    }
  }

  function handleSelectOngoingTransport (event) {
    setValue('selectedOngoingTransport', event)
  }

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      boxShadow: 'none'
    }),
    noOptionsMessage: () => ({
      display: 'none'
    })
  }

  if (isLoading) {
    return (
      <SecurePortalLayout title={t('registerMaterialTruckLoad')}>
        <PortalContent loading={true} />
      </SecurePortalLayout>
    )
  }

  if (!enableDocumentUpload && !pictureConfirmed) {
    return (
      <FullScreenCamera
        fileName={`co-processing-material-truck-load-${Date.now()}`}
        headerMessage={t('registerMaterialTruckLoadPictureExplanation')}
        shutterIcon={<ReceiptIcon />}
        onTakePhotoFinished={handleTakePhotoFinished}
        onFileUploadFinished={handleFileUploadFinished}
        onConfirmPicture={handleConfirmPicture}
        onCameraError={handleCameraError}
      />
    )
  }

  return (
    <SecurePortalLayout title={t('registerMaterialTruckLoad')}>
      <PortalContent>
        <div className={classes.root}>
          <div className={classes.content}>
            <form autoComplete='off' noValidate>
              <Paper className={classes.formSection}>
                <Typography
                  className={classes.title}
                  variant='h3'
                >
                  {t('registerMaterialTruckLoad')}
                </Typography>

                {enableDocumentUpload ? (
                  <DocumentWithoutLocationUpload
                    acceptedFiles={['application/pdf', 'image/*']}
                    dropzoneText={t('registerMaterialTruckLoadUploadExplanation')}
                    fileName={`co-processing-material-truck-load-${Date.now()}`}
                    maxFileSize={5000000}
                    onClear={() => setDocumentUpload(null)}
                    onUpload={setDocumentUpload}
                  />
                ) : (
                  <FormControl component='fieldset'>
                    <FormLabel component='legend' required>
                      {t('weighSlipPicture')}
                    </FormLabel>
                    <div className={classes.imagePreview}>
                      <ImagePreview
                        dataUri={pictureDataUri}
                        isPictureUploading={!pictureFileUpload}
                      />
                    </div>
                  </FormControl>
                )}

                <div className={classes.field}>
                  <div className={classes.field}>
                    <TextField
                      name='weight'
                      autoFocus
                      className={classes.weightField}
                      type='number'
                      label={`${t('addWeight')}`}
                      margin='dense'
                      required
                      value={values.weight}
                      onChange={handleChange}
                      error={hasError('weight')}
                      helperText={hasError('weight') ? t(errors.weight[0]) : ''}
                    />
                    <TextField
                      name='quantityUnit'
                      className={classes.quantityUnitField}
                      disabled
                      label=' '
                      margin='dense'
                      value={values.quantityUnit}
                      InputProps={{
                        style: {
                          color: theme.palette.text.primary
                        }
                      }}
                    />
                  </div>
                </div>

                <div className={classes.field}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      margin='normal'
                      className={classes.dateField}
                      id='mui-pickers-date'
                      name='weighedAt'
                      label={t('weighedAt')}
                      value={values.weighedAt}
                      onChange={handleWeighedAtChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />

                    <KeyboardTimePicker
                      margin='normal'
                      className={classes.timeField}
                      id='time-picker'
                      label=' '
                      value={values.weighedAtTime}
                      onChange={handleWeighedAtTimeChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change time'
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className={classes.textField}>
                  <Typography variant='body1' className={classes.transporterLicensePlateLabel}>
                    {t('licensePlate')}
                  </Typography>
                  <AsyncSelect
                    name='selectedOngoingTransport'
                    classNamePrefix='selectedOngoingTransport'
                    styles={customSelectStyles}
                    required
                    label={t('selectedOngoingTransport')}
                    value={values.selectedOngoingTransport}
                    onChange={handleSelectOngoingTransport}
                    cacheOptions
                    defaultOptions
                    loadOptions={debouncedOngoingTransportSearchResponseOptions}
                  />
                </div>

                <div className={classes.actionButtons}>
                  <LinkButton
                    size='large'
                    className={classes.cancelButton}
                    color='default'
                    variant='outlined'
                    href='/hub-overview'
                  >
                    {t('cancelButton')}
                  </LinkButton>
                  <Button
                    size='large'
                    variant='contained'
                    className={classes.saveRegistrationButton}
                    disabled={!isValid || isLoading || (!documentUpload && !pictureFileUpload) || !values.selectedOngoingTransport.value}
                    onClick={handleSave}
                  >
                    {t('registerButton')}
                  </Button>
                </div>
              </Paper>
            </form>
          </div>
        </div>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default compose(
  withRouter,
  withStyles(styles)
)(RegisterCoProcessingMaterialTruckLoad)
