import { UseFetchState, useFetchState } from 'lib/fetch'
import { CarrierMaterialsSummaryDto } from './carrier-material.types'

export class CarrierMaterialApiService {
  public static readonly BASE_URL = '/api/carrierMaterials'

  public static getSummary(): UseFetchState<CarrierMaterialsSummaryDto> {
    return useFetchState(`${CarrierMaterialApiService.BASE_URL}/summary`)
  }
}
