export default theme => ({
  '@global': {
    '.MuiCircularProgress-colorPrimary': {
      color: theme.palette.common.white
    }
  },

  root: {
    position: 'relative',
    width: '60%',

    '& img': {
      width: '100%'
    },

    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  },

  retakePhotoButton: {
    fontSize: 10,
    position: 'absolute',
    color: theme.palette.common.white,
    opacity: 0.7,
    border: `1px dashed ${theme.palette.common.white}`,
    width: 80,
    height: 40,
    left: '50%',
    top: '50%',
    marginLeft: '-40px',
    marginTop: '-20px'
  },

  progressWrapper: {
    position: 'absolute',
    width: 40,
    height: 40,
    left: '50%',
    top: '50%',
    marginLeft: '-20px',
    marginTop: '-20px'
  }
})
