import { EntityFilterDateConfig } from './EntityFilterDate'
import { EntityFilterSelectConfig } from './EntityFilterSelect'
import { EntityFilterTextConfig } from './EntityFilterText'

export enum EntityFilterField {
  COLLECTOR_HUB_UUID = 'COLLECTOR_HUB_UUID',
  COLLECTOR_STATE = 'COLLECTOR_STATE',
  COLLECTOR_TRACKING_NUMBER = 'COLLECTOR_TRACKING_NUMBER',
  COLLECTOR_TYPE = 'COLLECTOR_TYPE',

  TRACKED_ITEM_HUB_UUID = 'TRACKED_ITEM_HUB_UUID',
  TRACKED_ITEM_STATE = 'TRACKED_ITEM_STATE',
  TRACKED_ITEM_TRACKING_NUMBER = 'TRACKED_ITEM_TRACKING_NUMBER',
  TRACKED_ITEM_TYPE = 'TRACKED_ITEM_TYPE',

  HUB_EVENT_CREATED_AT = 'HUB_EVENT_CREATED_AT',
  HUB_EVENT_HUB_UUID = 'HUB_EVENT_HUB_UUID',
  HUB_EVENT_STATUS = 'HUB_EVENT_STATUS',
  HUB_EVENT_TYPE = 'HUB_EVENT_TYPE',
  HUB_EVENT_ANOMALY = 'HUB_EVENT_ANOMALY'
}

export enum EntityFilterOperator {
  CONTAINS = 'CONTAINS',
  EQUALS = 'EQUALS',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  IN = 'IN',
  IS_EMPTY = 'IS_EMPTY',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL'
}

export enum EntityFilterType {
  DATE = 'DATE',
  SELECT = 'SELECT',
  TEXT = 'TEXT'
}

export type EntityFilterConfig =
  | EntityFilterDateConfig
  | EntityFilterSelectConfig
  | EntityFilterTextConfig
