import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography
} from '@material-ui/core'
import React, { FunctionComponentElement, useState } from 'react'
import { useModalStyles } from '../../../../common/styles/modal'
import { HubCompanyDto } from '../../../../services/hub'
import { useTranslation } from 'react-i18next'
import { useForm } from '../../../../lib/form'
import { TrackedItemType } from '../../../../services/tracked-item'
import { useStateValue } from '../../../../state'
import { getModalStyle } from '../../../../lib/modal'
import { apiFetchWithDispatch } from '../../../../lib/fetch'
import { Close as CloseIcon } from '@material-ui/icons'
import { useFormStyles } from '../../../../common/styles/form'

interface Props {
  hub: HubCompanyDto
  showGenerateTrackedItemsModal: boolean
  onCloseGenerateTrackedItemsModal: () => void
  onGenerateTrackedItems: () => void
}

const schema = {
  trackedItemsCount: {
    presence: { allowEmpty: false, message: 'validationRequired' },
    length: { maximum: 3, message: 'validationExceedsMaxLength' }
  },
  type: {
    presence: { allowEmpty: false, message: 'validationRequired' }
  }
}

function GenerateTrackedItemsModal({
  hub,
  showGenerateTrackedItemsModal,
  onCloseGenerateTrackedItemsModal,
  onGenerateTrackedItems
}: Props): FunctionComponentElement<Props> {
  const modalClasses = useModalStyles()

  const modalStyle = getModalStyle()
  const formClasses = useFormStyles()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  const [, dispatch] = useStateValue()

  const {
    formState: { values, isValid },
    handleChange,
    resetForm,
    hasError
  } = useForm(schema, {
    trackedItemsCount: '',
    type: ''
  })

  async function handleSave() {
    setIsLoading(true)

    const { trackedItemsCount, type } = values

    await apiFetchWithDispatch(
      dispatch,
      `/api/admin/companies/hubs/${hub.uuid}/generateTrackedItems`,
      {
        method: 'POST',
        body: JSON.stringify({
          trackedItemsCount,
          type
        })
      }
    )

    setIsLoading(false)

    resetForm()

    onGenerateTrackedItems()
  }

  function handleClose() {
    resetForm()
    onCloseGenerateTrackedItemsModal()
  }

  return (
    <Modal
      open={showGenerateTrackedItemsModal}
      onClose={onCloseGenerateTrackedItemsModal}
    >
      <Box style={modalStyle} className={modalClasses.paper}>
        <Box className={modalClasses.titleBar}>
          <IconButton
            className={modalClasses.closeButton}
            key="close"
            color="default"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h4"
            id="modal-title"
            className={modalClasses.title}
          >
            Generate Tracked Items
          </Typography>

          <Divider />
        </Box>

        <form autoComplete="off" noValidate>
          <Box>
            <Box>
              <TextField
                name="trackedItemsCount"
                type="number"
                label="Tracked Items Count"
                margin="dense"
                required
                className={formClasses.selectField}
                value={values.trackedItemsCount}
                onChange={(event) => handleChange(event)}
                error={hasError('trackedItemsCount')}
              />
            </Box>
            <Box>
              <TextField
                name="type"
                select
                required
                className={formClasses.selectField}
                label="Type"
                value={values.type}
                onChange={handleChange}
                error={hasError('type')}
                SelectProps={{ native: true }}
                margin="dense"
              >
                <>
                  <option key="" value="" />
                  {Object.keys(TrackedItemType).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </>
              </TextField>
            </Box>
          </Box>

          <Box className={modalClasses.actionButtons}>
            <Button
              size="large"
              className={modalClasses.cancelButton}
              color="default"
              variant="outlined"
              onClick={handleClose}
            >
              {t('cancelButton')}
            </Button>
            <Button
              size="large"
              disabled={!isValid || isLoading}
              color="primary"
              variant="contained"
              onClick={handleSave}
            >
              {isLoading ? <CircularProgress size={24} /> : t('saveButton')}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  )
}

export default GenerateTrackedItemsModal
