import React, { FunctionComponentElement } from 'react'
import { Helmet } from 'react-helmet'

import { getHomeScreen } from '../../../services/signin'

export function PipelineOverview(): FunctionComponentElement<void> {
  return (
    <Helmet>
      <meta httpEquiv="refresh" content={`0; url=${getHomeScreen()}`} />
    </Helmet>
  )
}
