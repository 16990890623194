/* global FormData */

import { apiFetchWithDispatch } from 'lib/fetch'

export async function uploadFileWithDispatch(
  dispatch,
  file,
  { latitude, longitude, accuracy } = {}
) {
  const body =
    latitude && longitude && accuracy
      ? {
          file,
          latitude,
          longitude,
          accuracy
        }
      : { file }
  return apiFetchWithDispatch(
    dispatch,
    '/api/fileUploads',
    {
      method: 'POST',
      body
    },
    {
      multipart: true
    }
  )
}

export async function uploadFileByAdminWithDispatch(
  dispatch,
  file,
  hubCompanyUuid,
  latitude,
  longitude
) {
  return apiFetchWithDispatch(
    dispatch,
    '/api/admin/fileUploads',
    {
      method: 'POST',
      body: {
        file,
        hubCompanyUuid,
        latitude: latitude | '',
        longitude: longitude | ''
      }
    },
    {
      multipart: true
    }
  )
}
