import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography
} from '@material-ui/core'
import { Add as AddIcon, Done as DoneIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import cx from 'classnames'
import React, { FunctionComponentElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useGeneralStyles } from 'common/styles/general'
import { Theme } from 'common/theme'
import LinkButton from 'components/LinkButton'
import SummaryLine from 'components/SummaryLine'
import { useFetchState } from '../../../../lib/fetch'

import { match } from 'react-router-dom'
import { useHistory } from 'react-router'
import { SecurePortalLayout } from '../../../../layouts/SecurePortalLayout'
import { PortalContent } from '../../../../layouts/portal/PortalContent'

interface Props {
  match: match<{ collectorUuid: string }>
}

const useStyles = makeStyles<Theme>((theme) => ({
  collectionCenterActiveCollector: {
    marginBottom: theme.spacing()
  },
  paper: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),

    [theme.breakpoints.up('md')]: {
      margin: theme.spacing()
    }
  },
  actionButtons: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  actionButton: {
    width: '50%',
    fontSize: 12,
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  weighBagButtonLabel: {
    textAlign: 'center'
  },
  finishButton: {
    marginLeft: theme.spacing()
  },
  secondSectionTitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(-2)
  }
}))

function CollectionCenterActiveCollector({
  match
}: Props): FunctionComponentElement<Props> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()
  const titleClasses = cx([generalClasses.title, generalClasses.sectionTitle])
  const secondSectionTitleClasses = cx([
    titleClasses,
    classes.secondSectionTitle
  ])
  const finishButtonClasses = cx([classes.actionButton, classes.finishButton])

  const history = useHistory()

  const {
    params: { collectorUuid }
  } = match

  const [collector, isLoading] = useFetchState(
    `/api/collectors/${collectorUuid}`
  )
  const { t } = useTranslation()

  function handleFinishButtonClick(): void {
    history.push('/hub-overview')
  }

  if (isLoading) {
    return (
      <div className={generalClasses.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <SecurePortalLayout title={t('finishGradingTitle')}>
      <PortalContent>
        <Paper className={classes.paper}>
          <Box className={classes.collectionCenterActiveCollector}>
            <Typography className={titleClasses} variant="h4">
              {t('activeCollectorTitle')}
            </Typography>

            <Box className={generalClasses.content}>
              <SummaryLine title={t('name')} value={collector?.name} />

              <Typography className={secondSectionTitleClasses} variant="h4">
                {t('finishGradingQuestion')}
              </Typography>

              <Box className={classes.actionButtons}>
                <LinkButton
                  size="large"
                  color="primary"
                  variant="outlined"
                  className={classes.actionButton}
                  href={`/register-material-bag?collectorUuid=${collector?.uuid}`}
                  startIcon={<AddIcon />}
                >
                  <span className={classes.weighBagButtonLabel}>
                    {t('yesWeighBagButton')}
                  </span>
                </LinkButton>

                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className={finishButtonClasses}
                  onClick={handleFinishButtonClick}
                  startIcon={<DoneIcon />}
                >
                  {t('noAllWeighedButton')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </PortalContent>
    </SecurePortalLayout>
  )
}

export default CollectionCenterActiveCollector
