import { Reducer } from 'react'

export type ToastState = {
  message: string | null
  variant: string | null
}
export enum ToastActionType {
  ADD_TOAST = 'ADD_TOAST',
  RESET_TOAST = 'RESET_TOAST'
}

export interface ToastActionAddToast {
  type: ToastActionType.ADD_TOAST
  value: {
    message: string | null
    variant: string | null
  }
}

export interface ToastActionResetToast {
  type: ToastActionType.RESET_TOAST
}

export type ToastActions = ToastActionAddToast | ToastActionResetToast

export const toastReducer: Reducer<ToastState, ToastActions> = (
  state,
  action
) => {
  switch (action.type) {
    case ToastActionType.ADD_TOAST:
      return {
        ...state,
        message: action.value.message,
        variant: action.value.variant
      }

    case ToastActionType.RESET_TOAST:
      return {
        ...state,
        message: null,
        variant: null
      }

    default:
      return state
  }
}
