import React, { FunctionComponentElement, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export function Home(): FunctionComponentElement<void> {
  const history = useHistory()

  useEffect(() => {
    history.push('/sign-in')
  }, [])

  return <></>
}
