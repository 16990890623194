import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { Add as AddIcon, Done as DoneIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import cx from 'classnames'
import React, { FunctionComponentElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useGeneralStyles } from 'common/styles/general'
import { Theme } from 'common/theme'
import LinkButton from 'components/LinkButton'
import SummaryLine from 'components/SummaryLine'
import { CollectionUnitsApiService } from 'services/collection-units/collection-units-api.service'
import { useStateValue } from 'state'
import {
  ActivePickUpPointActions,
  ActivePickUpPointActionType
} from 'state/reducers/active-pick-up-point.reducer'
import { CompaniesApiService } from 'services/company/companies-api.service'

const useStyles = makeStyles<Theme>((theme) => ({
  collectionCenterActivePickUp: {
    marginBottom: theme.spacing()
  },
  actionButtons: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  actionButton: {
    width: '50%',
    fontSize: 12,
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  weighBagButtonLabel: {
    textAlign: 'center'
  },
  finishButton: {
    marginLeft: theme.spacing()
  },
  secondSectionTitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(-2)
  }
}))

function CollectionCenterActivePickUp(): FunctionComponentElement<void> {
  const classes = useStyles()
  const generalClasses = useGeneralStyles()
  const titleClasses = cx([generalClasses.title, generalClasses.sectionTitle])
  const secondSectionTitleClasses = cx([
    titleClasses,
    classes.secondSectionTitle
  ])
  const finishButtonClasses = cx([classes.actionButton, classes.finishButton])

  const [{ activePickUpPoint }, dispatch] = useStateValue()
  const [
    ongoingCollection,
    isOngoingCollectionLoading
  ] = CollectionUnitsApiService.getOngoing()
  const [pickUpPoint, isPickUpPointLoading] = CompaniesApiService.getDetails(
    activePickUpPoint?.uuid
  )
  const { t } = useTranslation()

  function handleFinishPickUpButtonClick(): void {
    const action: ActivePickUpPointActions = {
      type: ActivePickUpPointActionType.CLEAR
    }

    dispatch(action)
  }

  if (isOngoingCollectionLoading || isPickUpPointLoading) {
    return (
      <div className={generalClasses.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  const collectedBags =
    ongoingCollection?.collectedUnitsCountByPickupPoint[
      activePickUpPoint?.uuid
    ] ?? 0
  const bags = collectedBags === 1 ? t('bag') : t('bags')

  return (
    <Box className={classes.collectionCenterActivePickUp}>
      <Typography className={titleClasses} variant="h4">
        {t('ongoingPickUpPointCollectionTitle')}
      </Typography>

      <Box className={generalClasses.content}>
        <SummaryLine title={t('pickUpPoint')} value={pickUpPoint?.name} />

        <SummaryLine
          title={t('registeredMaterialBagsNumber')}
          value={
            <>
              {collectedBags} {bags}
            </>
          }
        />

        <Typography className={secondSectionTitleClasses} variant="h4">
          {t('finishGradingQuestion')}
        </Typography>

        <Box className={classes.actionButtons}>
          <LinkButton
            size="large"
            color="primary"
            variant="outlined"
            className={classes.actionButton}
            href={`/register-material-bag/${activePickUpPoint?.uuid}?collectionUnitUuid=${ongoingCollection?.uuid}`}
            startIcon={<AddIcon />}
          >
            <span className={classes.weighBagButtonLabel}>
              {t('yesWeighBagButton')}
            </span>
          </LinkButton>

          <Button
            size="large"
            color="primary"
            variant="contained"
            className={finishButtonClasses}
            onClick={handleFinishPickUpButtonClick}
            startIcon={<DoneIcon />}
          >
            {t('noAllWeighedButton')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default CollectionCenterActivePickUp
