import { Moment } from 'moment'
import { Reducer } from 'react'

import { PeriodType } from '../../components/PeriodSelector'

export type OverviewPeriodState = {
  endDate: Moment | null
  periodType: PeriodType
  startDate: Moment | null
}

export enum OverviewPeriodActionType {
  SET_PERIOD_TYPE = 'SET_PERIOD_TYPE',
  SET_DATES = 'SET_DATES'
}

export interface OverviewPeriodActionSetPeriodType {
  type: OverviewPeriodActionType.SET_PERIOD_TYPE
  value: PeriodType
}

export interface OverviewPeriodActionSetDates {
  type: OverviewPeriodActionType.SET_DATES
  value: {
    endDate: Moment
    startDate: Moment
  }
}

export type OverviewPeriodActions =
  | OverviewPeriodActionSetPeriodType
  | OverviewPeriodActionSetDates

export const OVERVIEW_PERIOD_TYPE_KEY = 'overviewPeriodType'

export const overviewPeriodReducer: Reducer<
  OverviewPeriodState,
  OverviewPeriodActions
> = (state, action) => {
  switch (action.type) {
    case OverviewPeriodActionType.SET_DATES: {
      return {
        ...state,
        endDate: action.value.endDate,
        startDate: action.value.startDate
      }
    }

    case OverviewPeriodActionType.SET_PERIOD_TYPE: {
      if (action.value !== PeriodType.CUSTOM) {
        window.localStorage.setItem(OVERVIEW_PERIOD_TYPE_KEY, action.value)
      }

      return {
        ...state,
        periodType: action.value
      }
    }

    default:
      return state
  }
}
