import React from 'react'

import { CircularProgress, withStyles } from '@material-ui/core'

// Component styles
import styles from './styles'

function ImagePreview ({ dataUri, isPictureUploading, classes }) {
  return (
    <div className={classes.root}>
      <img
        src={dataUri}
        alt=''
      />

      {isPictureUploading && (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(ImagePreview)
