import React, { useState, useEffect } from 'react'

// Material components
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles
} from '@material-ui/core'

// Component styles
import styles from './styles'
import { useTranslation } from 'react-i18next'

import {
  RestoreFromTrash as RestoreFromTrashIcon
} from '@material-ui/icons'

import { QuantityUnits } from 'services/quantityunit'

import { useForm } from 'lib/form'
import { apiFetchWithDispatch, useFetchState } from 'lib/fetch'

import { useStateValue } from 'state'
import { RegistrationUnit } from 'services/registrationunit'

function CollectionCenterGradeForm({ classes, onStartGrading }) {
  const [mixedMaterials, isMixedMaterialsLoading] = useFetchState('/api/collectedMaterials/mixed', [])

  const [isLoading, setIsLoading] = useState(false)

  const [, dispatch] = useStateValue()

  const { t } = useTranslation()

  const isRegistrationUnitBag = mixedMaterial => mixedMaterial.registrationUnit === RegistrationUnit.BAG

  const truckLoadMixedMaterials = mixedMaterials
      .filter(mixedMaterial => mixedMaterial.registrationUnit === RegistrationUnit.TRUCK_LOAD)
  const hasMixedWasteBags = mixedMaterials.some(isRegistrationUnitBag)

  const {
    formState: {
      values
    },
    handleChange,
    setValue
  } = useForm(null, {
    collectedMaterialUuid: ''
  })

  useEffect(() => {
    if (truckLoadMixedMaterials.length === 1 && values.collectedMaterialUuid === '') {
      setValue('collectedMaterialUuid', truckLoadMixedMaterials[0].uuid)
    }
  }, [truckLoadMixedMaterials, values.collectedMaterialUuid, setValue])

  function getMixedTruckLoadLabel (mixedMaterial) {
    const displayedQuantityUnit = QuantityUnits[mixedMaterial.quantityUnit]
    const location = mixedMaterial.location ? `, ${mixedMaterial.location}` : ''

    return `${mixedMaterial.weight.toFixed(2)} 
    ${displayedQuantityUnit}, ${t(`registrationUnit_${mixedMaterial.registrationUnit}`)}
    , ${mixedMaterial.licensePlate}${location}`
  }

  function getMixedWasteBagsLabel () {
    const displayedQuantityUnit = QuantityUnits.KG
    const mixedWasteBagsTotal = mixedMaterials
      .filter(isRegistrationUnitBag)
      .reduce((result, item) => result + item.weight, 0)

    return `${mixedWasteBagsTotal.toFixed(2)} ${displayedQuantityUnit}, ${t(`registrationUnit_${RegistrationUnit.BAG}`)}`
  }

  async function handleStartGrading () {
    setIsLoading(true)

    const { collectedMaterialUuid } = values

    await apiFetchWithDispatch(dispatch, '/api/collectedMaterials/grade', {
      method: 'POST',
      body: JSON.stringify({
        collectedMaterialUuid: collectedMaterialUuid === 'WASTE_BAGS' ? undefined : collectedMaterialUuid
      })
    })

    setIsLoading(false)

    onStartGrading()
  }

  if (isLoading || isMixedMaterialsLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    )
  }

  if (truckLoadMixedMaterials.length === 0 && !hasMixedWasteBags) {
    return (
      <>
        <Typography
          className={classes.inactiveFormMessage}
          variant='subtitle1'
        >
          {t('noMaterialSourceForGrading')}
        </Typography>
      </>
    )
  }

  return (
    <div>
      <form autoComplete='off' noValidate>
        <div className={classes.radioGroupField}>
          <FormControl component='fieldset'>
            <FormLabel
              component='legend'
              className={classes.radioGroupLabel}
            >
              {t('selectMaterialForGrading')}:
            </FormLabel>
            <RadioGroup
              name='collectedMaterialUuid'
              value={values.collectedMaterialUuid}
              onChange={handleChange}
            >
              <>
                {
                  truckLoadMixedMaterials
                    .map(mixedMaterial => (
                      <FormControlLabel
                        className={classes.radioFieldLabel}
                        key={mixedMaterial.uuid}
                        value={mixedMaterial.uuid}
                        control={<Radio />}
                        label={getMixedTruckLoadLabel(mixedMaterial)}
                      />
                    ))
                }

                {hasMixedWasteBags && (
                  <FormControlLabel
                    className={classes.radioFieldLabel}
                    key='WASTE_BAGS'
                    value='WASTE_BAGS'
                    control={<Radio />}
                    label={getMixedWasteBagsLabel()}
                  />
                )}
              </>
            </RadioGroup>
          </FormControl>
        </div>
      </form>

      <Button
        size='large'
        variant='outlined'
        className={classes.startGradingButton}
        onClick={handleStartGrading}
        startIcon={<RestoreFromTrashIcon style={{ height: 30 }} />}
      >
        {t('gradeMaterialButton')}
      </Button>
    </div>
  )
}

export default withStyles(styles)(CollectionCenterGradeForm)
