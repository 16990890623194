export default theme => ({
  root: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),

    [theme.breakpoints.up('md')]: {
      margin: theme.spacing()
    }
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    marginLeft: theme.spacing(),
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  question: {
    marginLeft: theme.spacing(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(),
    fontWeight: 400
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  actionButtons: {
    display: 'flex'
  },
  weighBagButton: {
    width: '50%',
    fontSize: 12,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  weighBagButtonLabel: {
    textAlign: 'center'
  },
  finishShiftButton: {
    width: '50%',
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),

    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }
})
