import { useCallback } from 'react'

import { getCurrencySymbol } from '../services/currency'

import { useFormatNumber } from './format-number.hook'

export type FormatPrice = (
  value: FormatterValue,
  currency: string,
  options?: FormatterOptions
) => string

export interface FormatterOptions {
  precision?: number
}

export type FormatterValue = number

export function useFormatPrice(): FormatPrice {
  const formatNumber = useFormatNumber()

  return useCallback<FormatPrice>(
    (value, currency, options) => {
      return `${getCurrencySymbol(currency)} ${formatNumber(value, options)}`
    },
    [formatNumber]
  )
}
