const ITEM_HEIGHT = 48

export default theme => ({
  root: {},
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  tableRow: {
    height: '64px'
  },
  tableCell: {
    whiteSpace: 'normal'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  actionMenu: {
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  actionMenuPaper: {
    maxHeight: ITEM_HEIGHT * 4.5,
    width: 200,
    boxShadow: theme.shadows[0],
    border: `1px solid ${theme.palette.border}`
  },
  signInAction: {
    color: theme.palette.success.main
  },
  archiveAction: {
    color: theme.palette.danger.main
  }
})
